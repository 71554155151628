<template>
    <v-row class="mt-4">
        <v-col cols="12">
            <span>Orden para mostrar los datos en el curso:</span>
            <v-select
                v-model="form_data.invert_degrees_hierarchy"
                :items="options"
                item-title="title"
                item-value="value"
                variant="underlined"
                class="select-primary"
                placeholder="Estado del idioma"
                hide-details
                :menu-props="{width: '400px'}"
            ></v-select>
        </v-col>
    </v-row>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "InvertDegrees",
    props: {
        data_url: String,
        form_data: Object
    },
    setup(props) {
        onBeforeMount(()=>{
            if(!variableIsDefined(props.form_data.invert_degrees_hierarchy)){
                props.form_data.invert_degrees_hierarchy = 0
            }
        })

        let options = ref([
            {'title': 'Logos y diplomas por programa formativo - Logos y diplomas por curso', 'value': 0},
            {'title': 'Logos y diplomas por curso - Logos y diplomas por programa formativo', 'value': 1},

        ])
        return {options}
    }
}
</script>


<style scoped lang="scss">

</style>