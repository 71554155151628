import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getAlertsWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'faculty/getAlerts', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/getAlerts/');
    }
}

export async function executeActionAlertWS(id_alert, action) {
    let params = {
        'id_alerta': id_alert
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_alerta': id_alert
    }

    try {
        return await aiseoBackendPetition('POST', action === 'accept' ? 'faculty/performAlert' : 'faculty/discardAlert', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + action === 'accept' ? 'faculty/performAlert/' : 'faculty/discardAlert');
    }
}