<template>
    <div v-if="loading_all_degree" class="general-loading">
        <Loading class_loading="loading-gif-s"/>
    </div>
    <div v-else>
    <v-row>
        <v-col cols="12">
            <p class="title-section">Diplomas</p>
            <draggable v-if="diploma_list.length!==0" :list="diploma_list" @change="changeOrder('diplomas_weight')" class="custom-draggable">
                <v-col cols="12" sm="6" md="3" v-for="diploma in diploma_list" :key="diploma.id">
                    <ImageCard class="draggable_image" :image="diploma"></ImageCard>
                </v-col>
            </draggable>
            <p v-else>{{txt_not_data_diplomas}}</p>
        </v-col>
        <v-col cols="12">
            <p class="title-section">Logos</p>
            <draggable v-if="logos_list.length !== 0" :list="logos_list" @change="changeOrder('logos_weight')" class="custom-draggable">
                <v-col cols="12" sm="6" md="3" v-for="logo in logos_list" :key="logo.id">
                    <ImageCard class="draggable_image" :image="logo"></ImageCard>
                </v-col>
            </draggable>
            <p v-else>{{txt_not_data_logos}}</p>
        </v-col>
        <v-col class="text-end" v-if="show_save_btn">
            <v-btn class="btn button-primary" :loading="loading" @click="saveNewOrder">
                Guardar
            </v-btn>
        </v-col>
    </v-row>
    </div>
</template>

<script>
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import {VueDraggableNext} from "vue-draggable-next";
import {ref} from "vue";
import {assignDegreeUrlWS} from "@/utils/degrees_course";
import {showNotification} from "@/utils/aux_functions";
import {assignDegreeToFormativeProgramWS} from "@/utils/training_program";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "DraggableQualificationsTemplate",
    components: {Loading, draggable: VueDraggableNext, ImageCard},
    props: {
        getAllDegreesUrl: Function,
        logos_list: Array,
        diploma_list: Array,
        degree_data: Object,
        id_element: String,
        id_portal: String,
        iso_language: String,
        type_component: String,
        txt_not_data_diplomas: String,
        txt_not_data_logos: String,

    },
    setup(props) {
        let show_save_btn = ref(false)
        let loading = ref(false)
        let loading_all_degree = ref(false)

        /*
        * MODIFICA LA LISTA DE LOS PESOS CORRESPONDIENTE
        * PARA ACTUALIZAR degree_data DESPUÉS DE MOVER
        * UN ELEMENTO
        * */
        function changeOrder(type) {
            show_save_btn.value = true;
            let weight_list = [];
            switch (type) {
                case 'diplomas_weight':
                    props.diploma_list.forEach((diploma, index) => {
                        weight_list[diploma.id_diploma] = index;
                    });
                    break;
                case 'logos_weight':
                    props.logos_list.forEach((logo, index) => {
                        weight_list[logo.id_logo] = index;
                    });

                    break;
            }
            props.degree_data[type] = weight_list;
        }

        //GUARDA EN LA BBDD EL NUEVO VALOR DE LOS PESOS
        async function saveNewOrder() {
            loading.value = true
            let response

            let json_degree_data = JSON.stringify(props.degree_data);
            if (props.type_component === 'qualifications') {
                response = await assignDegreeUrlWS(props.id_element, json_degree_data);
            } else if (props.type_component === 'training_program') {
                response = await assignDegreeToFormativeProgramWS(props.id_portal, props.iso_language, props.id_element, json_degree_data)
            }

            if (response['result']) {
                if(response['data']['success']){
                    /*SE HAN REGISTRADO CAMBIOS*/
                    showNotification('success', response['data']['message']);
                }else{
                    /*CUANDO NO SE HA REGISTRADO NINGÚN CAMBIO*/
                    showNotification('info', response['data']['message']);
                }
            } else {
                showNotification('error', response['errors']);
            }
            show_save_btn.value = false;
            loading.value = false
        }

        return {
            changeOrder,
            saveNewOrder,

            loading,
            show_save_btn,
            loading_all_degree,
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-draggable {
    display: flex;
    flex-wrap: wrap;
}
</style>