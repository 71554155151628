<template>
    <v-table fixed-header height="42vh">
        <thead>
        <tr>
            <th class="text-left">Imagen
            </th>
            <th class="text-left">
                Nombre
            </th>
            <th class="text-left">
                Título
            </th>
            <th class="text-left">
                Categoría
            </th>
        </tr>
        </thead>
        <tbody>
        <tr class="td-general"
            v-for="item in properties_files"
            :key="item.id_file">
            <td v-if="item.type === 'image'" class="td-img-video">
                <template v-if="item.name.split(['.'])[1]==='svg'">
                    <img
                        :src="require('@/assets/icons/icon_svg.svg')"
                        class="icon-video">
                </template>
                <template v-else><img
                    :src="item.upload_url" alt="Image" type="img/svg"
                    class="img-file"></template>
            </td>
            <td v-else-if="item.type === 'video'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-video.svg')" alt="Video"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'audio'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-audio.svg')"
                alt="Audio"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'application'" class="td-img-video"><img
                :src="require('@/assets/icons/file.svg')"
                alt="Archivo"
                class="icon-video">
            </td>
            <td v-else class="td-img-video"><img
                :src="require('@/assets/icons/icon-push.svg')"
                class="icon-video">
            </td>
            <td> {{ item.name }}</td>
            <td v-if="variableIsDefined(item.title)"> {{ item.title }}</td>
            <td v-else>-</td>
            <td>
                <v-row v-for="category in item.name_category">
                    <v-col>{{ category }}</v-col>
                </v-row>
            </td>
        </tr>
        </tbody>
    </v-table>
</template>

<script>
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: 'TableFinal',
    methods: {variableIsDefined},
    props: {
        properties_files: Array
    },
}
</script>

<style scoped lang="scss">
.td-general {
    height: 10vh;

    .td-img-video {
        width: 10vw;
        text-align: left;
    }

    .icon-video {
        filter: $filter-primary-color-1;
        width: 30px;
        height: auto;
    }

    .img-file {
        width: auto;
        max-height: 7vh;
    }
}

@media (max-width: 800px) {
    .td-general {
        .icon-video {
            width: 20px;
            height: auto;
        }

        .img-file {
            width: auto;
            max-height: 5vh;
        }
    }
}
</style>