<template>
    <BaseModalTemplate
        title="Cambios del curso"
        :card_extra_classes="comparison ? 'modal-width-xl' : 'modal-width-50'"
        :loading="loading"
        :hide_actions="!comparison"
        :open-close-dialog="displayModal"
    >
        <template #body-modal-content>
            <v-row v-if="comparison">
                <v-col cols="12" md="6">
                    <span class="data_type">Datos antiguos</span>
                    <ul v-for="(value,key) in old_data">
                        <li v-if="new_data[key] !== old_data[key]"><b>{{ key }}: </b>{{ value }}</li>
                    </ul>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="data_type">Datos nuevos</span>
                    <ul v-for="(value,key) in new_data">
                        <li v-if="new_data[key] !== old_data[key]"><b>{{ key }}: </b>{{ value }}</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12">
                    <p><b>Datos de la URL:</b></p>
                    <ul class="list-unstyled">
                        <li>Código innotutor: {{ data_url.codigo_innotutor }}</li>
                        <li>Identificador Texto Url: {{ data_url.identificador_texto_url }}</li>
                        <li>Dominio: {{ data_url.dominio }}</li>
                        <li>Portal: {{ getNamePortal(data_url.id_portal) }}</li>
                        <li>Idioma: {{ getNameLanguage(data_url.id_idioma) }}</li>
                        <li>URL: {{ data_url.url }}</li>
                        <li>Enlace al editor curso: <a target="_blank" v-if="data_url.absolute_url"
                                                       :href="data_url.absolute_url">{{ data_url.url }}</a>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <v-btn class="btn button-bg-blue"
                           @click="displayDialogKeepConfirmationMessage">Gestionar baja/alta
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>

</template>

<script>

import {onBeforeMount, ref} from "vue";
import {duplicateCourse} from "@/utils/courses";
import {showNotification} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {getNameLanguage, getNamePortal} from "../../utils/portal";

export default {
    name: 'DataComparative',
    methods: {getNamePortal, getNameLanguage},
    components: {BaseModalTemplate},
    props: {
        new_data: Array,
        old_data: Array,
        item_changes: Object,
        displayModal: Function,
        getURLsByPortal: Function,
    },
    setup(props) {
        let comparison = ref(true);
        let data_url_old = ref({});
        let data_url_new = ref({});
        let data_url_conflict = ref({});
        let loading = ref(false)

        let data_url = ref({})

        onBeforeMount(() => {
            loading.value = true
            initSweetAlert()
            loading.value = false

        })

        function displayDialogKeepConfirmationMessage() {
            fireSweetAlert("Realizar baja/alta", keepOldURL, "¿Está completamente seguro?")
        }

        async function keepOldURL() {

            let response = await duplicateCourse(props.item_changes)
            if (response['result']) {
                showNotification('success', 'Se ha gestionado correctamente la baja/alta')
                comparison.value = false;

                data_url.value = response['data']

                await props.getURLsByPortal();
            } else {
                showNotification('error', response['errors'])
            }
        }

        return {
            displayDialogKeepConfirmationMessage,
            comparison,
            data_url_old,
            data_url_new,
            data_url_conflict,
            loading,
            data_url,
        }
    }
}
</script>


<style scoped lang="scss">
.modal-width-50 {
    max-width: 50% !important;
}

.data_type {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;
}
</style>