import {showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition, uploadFileToAiseo} from "@/utils/aiseo_petition";
import store from "@/store";

export async function getFilesByEntityWS(related_entity, name_img) {
    let params_token = {
        'relatedEntity': related_entity,
    }
    let params = {
        'relatedEntity': related_entity,
        'nodeIdentificator': name_img
    }
    try {
        return await aiseoBackendPetition('POST', 'files/getFilesByEntity', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'files/getFilesByEntity');
    }
}

export async function uploadFileWS(form_data, related_entity) {
    let params_token = {
        'relatedEntity': related_entity,
        'relatedField': 'imagen',
        'relatedNodeIdentificator': form_data.image_name,
    }
    let params = {
        'file': form_data.new_image,
        'relatedEntity': related_entity,
        'relatedField': 'imagen',
        'relatedNodeIdentificator': form_data.image_name,

    }
    try {
        return await aiseoBackendPetition('POST', 'files/uploadFile', params, params_token, 'axios', 'multipart/form-data');
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'files/uploadFile');
    }
}

export async function updateFileWS(form_data, file_id) {
    let params_token = {
        'fileID': file_id,
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'file': form_data.new_image,
        'fileID': file_id,
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
    }
    try {
        return await aiseoBackendPetition('POST', 'files/updateFileImage', params, params_token, 'axios', 'multipart/form-data');
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'files/updateFileImage');
    }
}

export async function changeStatusFileWS(id_file, status){
    let params = {
        'fileID': id_file,
        'status': status,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'files/changeStatusFile', params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'files/changeStatusFile');
    }
}

export async function deleteFileWS(id_file, status){
    let params = {
        'fileID': id_file,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'files/deleteFile', params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'files/deleteFile');
    }
}

export async function getImagesByPortalWS(image_type, id_portal, name_filter) {
    let url;
    let params = {
        'id_portal': id_portal,
        'language': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': id_portal,
        'language': store.getters.getLanguageISO,
    }
    switch (image_type) {
        case 'Rankings':
            url = 'rankings/getAllRankings';
            break;
        case 'Diplomas':
            url = 'degrees/getAllDegrees';
            params['type'] = 'diplomas'
            params_token['type'] = 'diplomas'
            break;
        case 'Logos':
            url = 'degrees/getAllDegrees';
            params['type'] = 'logos'
            params_token['type'] = 'logos'
            break;
    }

    if (name_filter !== '') params['name_filter'] = name_filter;

    try {
        return await aiseoBackendPetition('POST', url, params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + url);
    }
}

export async function insertImageWS(image_type, id_file, alt_text, title, portal_data, status) {
    let url;
    let params = {
        'id_portal': portal_data['id_portal'],
        'language': portal_data['languages'][0]['iso_6391'],
    }

    let image_data = {
        'id_file': id_file,
        'alt_text': alt_text,
        'title': title,
    }

    if (image_type === 'Diploma') {
        params['diploma_data'] = JSON.stringify(image_data);
        url = 'degrees/insertDiploma';

    } else if (image_type === 'Ranking') {
        params['ranking_data'] = JSON.stringify(image_data);
        url = 'rankings/insertRanking';

    } else if (image_type === 'Logo') {
        params['logo_data'] = JSON.stringify(image_data);
        url = 'degrees/insertLogo';
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername
    };

    try {
        return await aiseoBackendPetition('POST', url, params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + url);
    }
}

export async function updateImageWS(type, id_image, title, alt_text, id_file, update_img, status) {
    let params;
    let url;

    let image_data = {
        'update_img': update_img,
        'id_file': id_file,
        'alt_text': alt_text,
        'title': title,
        'active': status,
    };


    if (type === 'diplomas') {
        params = {
            'id_diploma': id_image,
            'diploma_data': JSON.stringify(image_data),
        }
        url = 'degrees/updateDiploma';
    } else if (type === 'rankings') {
        params = {
            'id_ranking': id_image,
            'ranking_data': JSON.stringify(image_data),
        }
        url = 'rankings/updateRanking';
    } else if (type === 'logos') {
        params = {
            'id_logo': id_image,
            'logo_data': JSON.stringify(image_data),
        }
        url = 'degrees/updateLogo';
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername
    };

    try {
        return await aiseoBackendPetition('POST', url, params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + url);
    }
}

export async function saveFile(file_template, related_entity, portal_data) {
    let file = file_template;
    let name_file_extension;
    let name_file;
    let id_file_img;
    let portal_id = portal_data['id_portal'];
    let language = portal_data['languages'][0]['iso_6391'];

    file.forEach((file) => {
        name_file_extension = file.name
    })
    let extension = /[^.]+$/.exec(name_file_extension)
    name_file = name_file_extension.replace("." + extension, "")

    const response = await uploadFileToAiseo(file, related_entity, 'imagen', name_file, portal_id, language);

    if (response.data.result) {
        id_file_img = response.data.data['fileID'].toString();
        return id_file_img
    } else {
        showNotification('error', 'Fallo al subir la imagen')
    }
}
