<template>
    <BaseModalTemplate
        title="Enlaces destacados"
        :loading="loading"
        :open-close-dialog="displayDialog"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <FileInputDragDrop></FileInputDragDrop>
            <div v-for="link_box_id in link_boxes_array" :key="link_box_id" class="link-box-container">
                <RowLinkBox :link_boxes="link_boxes_array" :getAllLinks="getAllLinkBoxes" :link_box="link_box_id"
                            :url="url" :addLinkBox="addLinkBox"
                >
                </RowLinkBox>
            </div>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import RowLinkBox from "@/components/LinkBoxes/RowLinkBox.vue";
import {getLinkBoxesWS} from "@/utils/link_boxes";
import Loading from "@/components/Generic/Loading.vue";
import FileInputDragDrop from "@/components/Generic/FileInputDragDrop.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalLinkBoxes",
    components: {BaseModalTemplate, FileInputDragDrop, RowLinkBox},
    props: {
        displayDialog: Function,
        url: String,
    },
    setup(props){
        const link_boxes_array = ref([]);
        const loading = ref(false);

        onBeforeMount(async () => {
            loading.value = true;

            await getAllLinkBoxes();

            loading.value = false;
        });

        /*
        *
        * AÑADE UN NUEVO ELEMENTO AL ARRAY DE LINKS
        * DE FORMA LOCAL
        *
        * */
        async function addLinkBox() {
            let links = await getLinkBoxesWS(props.url);

            let {activo, id_enlace_relacionado, texto_enlace, enlace: url_enlace, url_imagen} = links.data.enlaces.filter(x => !link_boxes_array.value.some(e => e.id_enlace_relacionado === x.id_enlace_relacionado))[0];

            link_boxes_array.value[link_boxes_array.value.length-1] = {activo, id_enlace_relacionado, texto_enlace, url_enlace, url_imagen}
            link_boxes_array.value.push({})
        }

        /*
        *
        * OBTIENE TODOS LOS ENLACES DEL WS Y RELLENA
        * EL ARRAY CON SUS VALORES
        * */
        async function getAllLinkBoxes(){
            let links = await getLinkBoxesWS(props.url)
            link_boxes_array.value = []

            links.data.enlaces.forEach((enlace) =>{
                link_boxes_array.value.push({
                        'url_enlace': enlace.enlace,
                        'texto_enlace': enlace.texto_enlace,
                        'url_imagen': enlace.url_imagen,
                        'id_enlace_relacionado': enlace.id_enlace_relacionado,
                        'activo': enlace.activo,
                })
            })

            link_boxes_array.value.push({})
        }

        return{
            addLinkBox,
            getAllLinkBoxes,

            link_boxes_array,
            loading,
        }
    }
}
</script>

<style lang="scss">
    .link-box-container {
        margin-bottom: 5vh;
    }
</style>