<template>
    <v-btn v-if="allowed_redirection_url"
           @click="displayDialogRedirection"
           class="btn button-bg-blue button-action"
    >
        Redireccionar
    </v-btn>

    <v-dialog v-model="display_dialog_redirection">
        <ModalUpdateDeleteRedirection
            :showDialog="displayDialogRedirection"
            :origin_url="data_url"
        >
        </ModalUpdateDeleteRedirection>
    </v-dialog>
</template>

<script>
import ModalUpdateDeleteRedirection from "@/components/Redirections/ModalUpdateDeleteRedirection.vue";
import {onBeforeMount, ref} from "vue";
import {userHaveAllowedRol} from "@/utils/auth";

export default {
    name: "Redirects",
    components: {ModalUpdateDeleteRedirection},
    props:{
        data_url: String,
    },

    setup(){
        let display_dialog_redirection = ref(false);
        let allowed_redirection_url = ref(false);

        onBeforeMount(async () => {
            allowed_redirection_url.value = userHaveAllowedRol('PERMISO_REDIRECT_URLS')

        })
        function displayDialogRedirection() {
            display_dialog_redirection.value = !display_dialog_redirection.value;
        }
        return{
            displayDialogRedirection,
            display_dialog_redirection,
            allowed_redirection_url,
        }
    }
}
</script>


<style scoped lang="scss">

</style>