<template>
    <BaseModalTemplate
        title="Añade propiedades al archivo"
        :open-close-dialog="displayModal"
        card_extra_classes="modal-file"
    >
        <template #body-modal-content>
            <v-row>
                <v-col>
                    <span>Nombre</span>
                    <v-text-field class="text-field-primary field-with-label" variant="underlined" hide-details
                                  v-model="files_upload.name"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span>Título</span>
                    <v-text-field class="text-field-primary field-with-label" variant="underlined" hide-details
                                  v-model="files_upload.title"></v-text-field>

                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span>Descripción</span>
                    <v-textarea
                        class="input-error text-area-secondary mt-3"
                        hide-details
                        rows="1"
                        variant="underlined"
                        v-model="files_upload.description"

                    ></v-textarea>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col>
                    <v-btn @click="saveProperties(files_upload.name, files_upload.title, files_upload.description)"
                           class="btn button-bg-blue">Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalPropertiesFile",
    components: {BaseModalTemplate},
    props: {
        displayModal: Function,
        saveAllProperties: Function,
        obj_file_selected: Object
    },
    setup(props) {
        let files_upload = ref()
        onBeforeMount(() => {
            files_upload.value = props.obj_file_selected
        })

        function saveProperties(name, title, description) {
            props.saveAllProperties(files_upload.value.id_file, name, title, description)
            props.displayModal()
        }

        return {
            saveProperties,
            files_upload
        }
    }
}
</script>


<style scoped lang="scss">

</style>