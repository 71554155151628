<template>
    <v-row class="text-end row-page" no-gutters>
        <v-col cols="4" sm="2" md="3" lg="4" class="position-icon-back">
            <v-btn :disabled="btn_back" v-model="selected_option_page"
                   icon class="btn-icons-view-card btn-back-page">
                <img src="@/assets/icons/arrow_left_circle.svg" alt="Icono" class="icon-page" @click="previous_page">
            </v-btn>
        </v-col>
        <v-col cols="4" sm="2" md="2" lg="1">
            <v-select class="select-secondary select-page"
                      bg-color="white"
                      hide-details
                      :disabled="!active_action"
                      v-model="selected_option_page"
                      :items="page_selector_options"
                      @update:modelValue="change_select"
                      no-data-text="No hay datos disponibles"
            >
            </v-select>
        </v-col>
        <v-col cols="4" sm="2" md="1" lg="1" class="position-icon-next">
            <v-btn v-model="selected_option_page" class="btn-icons-view-card btn-next-page"
                   icon>
                <img src="@/assets/icons/arrow_right_circle.svg" alt="Icono" class="icon-page" @click="next_page">
            </v-btn>
        </v-col>
        <v-col cols="6" class="text-center" v-if="see_phone">
            <v-btn class="btn button-bg-blue edit-btn" @click="optionSee">OPCIONES</v-btn>
        </v-col>
        <v-col cols="6" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn-icons-view-card me-3" :disabled="view_files===true" @click="viewFiles(true)" icon>
                <img class="icons_view_card" :src="require('@/assets/icons/grid.svg')"
                     alt="Cuadricula" title="Cuadrícula"></v-btn>

            <v-btn class="btn-icons-view-card" :disabled="view_files===false" @click="viewFiles(false)" icon>
                <img class="icons_view_card" :src="require('@/assets/icons/list.svg')"
                     alt="Lista" title="Lista"></v-btn>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3" class="text-center">
            <v-btn class="btn button-bg-white" @click="displayModalNewCategory">Categorias</v-btn>
        </v-col>
    </v-row>
    <v-row class="all_categories">
        <v-col cols="12" sm="5" md="5" lg="3" class="menu-categories" v-if="view_phone_category">
            <div class="scroll-horizontal">
                <v-text-field class="text-field-primary search-file"
                              variant="underlined" placeholder="Buscar por título..."
                              v-model="search_file"
                              @keyup.enter="searchFileWS(search_file)">
                </v-text-field>
                <ul v-if="!options" class="box-ul">
                    <li :class="category_main.id" class="click-category">
                        <img src="@/assets/gifs/example_loading.gif" alt="loading" class="gif-loading-browse">
                        <img src="@/assets/icons/plus_circle.svg" alt="Icono" class="icon-plus-browse"
                             @click="callOpenCategory(category_main.id)">
                        <img v-if="state_file" src="@/assets/icons/folder-close.svg" alt="Icono"
                             class="icon-plus-browse">
                        <img v-else src="@/assets/icons/folder-open.svg" alt="Icono" class="icon-file">
                        <span class="primary-category" @click="callCategoryId(category_main.id)">Library</span>
                    </li>
                </ul>
                <v-row v-else class="box-return">
                    <v-col>
                        <v-btn @click="returnCategory" class="btn button-bg-white">
                            <img :src="require('@/assets/icons/navbar/arrow-back.svg')" class="icon-return"
                                 alt="Volver">
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col cols="12" sm="7" md="7" lg="9" class="col-files" v-if="!view_phone_files">
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <v-row class="position-files personalize-scroll" v-if="show_img">
                    <template v-if="view_files">
                        <GridFiles
                            :all_files_select="all_files_select"
                            :getFileActiveWS="getFileActiveWS"
                            :identifier_category="identifier_category"
                            :displayModalUrlCdn="displayModalUrlCdn"
                        ></GridFiles>
                    </template>
                    <template v-else>
                        <ListFiles
                            :all_files_select="all_files_select"
                            :getFileActiveWS="getFileActiveWS"
                            :identifier_category="identifier_category"
                            :displayModalUrlCdn="displayModalUrlCdn"
                        ></ListFiles>
                    </template>
                </v-row>
                <v-row v-else class="row-msg-not-img">
                    <v-col><span class="msg-not-img">No hay imagenes</span></v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
    <v-row class="number_page">
        <v-col cols="11" class="align-self-center">
            <span v-if="view_control_page" class="view-pages">Página {{ view_page }} de {{ total_page }}</span>
        </v-col>
    </v-row>

    <v-dialog persistent
              v-model="open_modal_new_category">
        <AddNewCategory
            :category_main="category_main"
            :field_browse=fields
            :displayModalNewCategory="displayModalNewCategory"
        />
    </v-dialog>
</template>

<script>
import {
    createDirectLinkFile,
    getDirectLinkFile,
    getFilesCategory,
    recoverCategoryGeneral,
    searchFiles
} from "@/utils/media_valet";
import {onBeforeUnmount, onMounted, ref} from "vue";
import GridFiles from "@/components/MediaValet/GridFiles.vue";
import Loading from "@/components/Generic/Loading.vue";
import ListFiles from "@/components/MediaValet/ListFiles.vue";
import ModalFile from "@/components/MediaValet/ModalFile.vue";
import AddNewCategory from "@/components/MediaValet/AddNewCategory.vue";
import {copyClipboard, showNotification, variableIsDefined} from "@/utils/aux_functions";
import Swal from "sweetalert2";

export default {
    name: "Browse",
    components: {AddNewCategory, ModalFile, ListFiles, Loading, GridFiles},
    props: {
        root_category: Object
    },
    setup(props) {
        let category_main = ref({})
        let fields = ref(new Map());
        let all_files_select = ref([])
        let all_files = []
        let file = {}
        let show_img = ref(false)
        let loading = ref(false)
        let state_file = ref(false)
        let search_file = ref("")
        let options = ref(false)
        let view_files = ref(true)
        let selected_option_page = ref(25)
        let start_page = 0;
        let last_page = 0;
        let current_id
        let active_action = ref(false)
        let page_selector_options = ref([10, 25, 50, 75, 100])
        let btn_back = ref(true)
        let view_page = ref(1)
        let total_page = ref()
        let view_control_page = ref(false)
        let view_phone_category = ref(true)
        let view_phone_files = ref(false)
        let see_phone = ref(false)
        let open_modal_new_category = ref(false)
        let disables = false
        let identifier_category = ref()
        let old_id_selected = ""
        let btn_next = ref(false)


        onMounted(() => {
            category_main.value = props.root_category
            window.addEventListener('resize', openViewPhone)
        })
        onBeforeUnmount(() => {
            $('.btn-next-page').attr('disabled', true);
            window.removeEventListener('resize', openViewPhone)

        })


        function displayModalNewCategory() {
            open_modal_new_category.value = !open_modal_new_category.value
        }

        async function callCategoryId(id_category) {
            old_id_selected = identifier_category.value
            identifier_category.value = id_category
            await getFileActiveWS(id_category)
        }

        async function getFileActiveWS(id_category_selected = identifier_category.value) {
            if (old_id_selected !== identifier_category.value || !variableIsDefined(old_id_selected)) {
                view_page.value = 1
                start_page = 0
            }
            loading.value = true
            all_files = [];
            all_files_select.value = []
            let response_files = await getFilesCategory(id_category_selected, selected_option_page.value, start_page, last_page)
            if (response_files.result) {
                last_page = response_files.response.recordCount.totalRecordsFound
                total_page.value = Math.ceil(last_page / selected_option_page.value)
                await getFiles(response_files.response)
                view_control_page.value = true;
                loading.value = false
            } else {
                showNotification('error', 'Error al obtener los archivos')
            }

        }

        async function searchFileWS(search_value) {
            options.value = true
            loading.value = true
            let response = await searchFiles(search_value)
            if (response.result) {
                await getFiles(response.response)
                loading.value = false
            } else {
                showNotification('error', 'Error al hacer la busqueda')
            }

        }

        function returnCategory() {
            options.value = false
            search_file.value = ""
            show_img.value = false
        }

        async function getFiles(response) {
            all_files = [];
            all_files_select.value = []
            if (response.payload.assetCount > 0) {
                response.payload.assets.forEach((all_file) => {
                    all_files.push(all_file)
                })
                show_img.value = true
                all_files.forEach((files) => {
                    file = {
                        'title': files.file.title, //string
                        'front': files.media.thumb, //string
                        'img': files.media.original, //string
                        'video': files.media.download, //string
                        'type': files.media.type, //string
                        'img_height': files.file.imageHeight, //number
                        'img_width': files.file.imageWidth, //number
                        'duration': files.file.length, //string LAS IMAGENES NO TIENEN
                        'size': files.file.sizeInBytes, //number
                        'date_create': files.record.createdAt, //string
                        'date_modify': files.record.modifiedAt, //object
                        'identifier_create': files.record.createdBy, //string
                        'identifier_modify': files.record.modifiedBy, //object
                        'date_uploaded': files.file.uploadedAt, //object
                        'status': !!files.file.approvedAt,
                        'fileType': files.file.fileType,
                        'description': files.description,
                        'id_file': files.id
                    }
                    if (!all_files_select.value.includes(file)) {
                        all_files_select.value.push(file)
                    }
                })
            } else {
                show_img.value = false
            }
        }

        function viewFiles(response_view) {
            view_files.value = response_view
        }

        async function next_page() {
            if (last_page > selected_option_page.value) {
                if (start_page + selected_option_page.value < last_page) {
                    old_id_selected = identifier_category.value
                    view_page.value++
                    start_page = start_page + selected_option_page.value
                    await getFileActiveWS(current_id)
                    btn_back.value = false

                } else {
                    btn_back.value = false
                    $('.btn-next-page').attr('disabled', true);
                }

            } else {
                btn_back.value = true
                $('.btn-next-page').attr('disabled', true);
            }
        }

        async function previous_page() {
            if (start_page >= selected_option_page.value) {
                btn_back.value = false
                start_page = start_page - selected_option_page.value
                view_page.value--
                await getFileActiveWS(current_id)
                if (start_page + selected_option_page.value < last_page) {
                    $('.btn-next-page').attr('disabled', false);
                }
            } else {
                btn_back.value = true
            }
        }

        async function change_select() {
            start_page = 0
            view_page.value = 1
            total_page.value = 1
            $('.btn-next-page').attr('disabled', false);
            btn_back.value = true
            await getFileActiveWS(current_id)
        }

        function openViewPhone() {
            if (window.innerWidth < 600) {
                see_phone.value = true
                view_phone_category.value = true
                view_phone_files.value = true
            } else {
                fields.value.clear();
                see_phone.value = false
                view_phone_category.value = true
                view_phone_files.value = false
            }
        }

        function optionSee() {
            view_phone_category.value = !view_phone_category.value
            view_phone_files.value = !view_phone_files.value
            if (view_phone_category.value) {
                fields.value.clear();
            }
        }

        async function callOpenCategory(id_category) {
            if (!disables) {
                $('.icon-plus-browse').hide()
                $('.gif-loading-browse').show()
                disables = true
                start_page = 0
                view_page.value = 1
                let response = await recoverCategoryGeneral(id_category, category_main.value, fields.value, callCategoryId, document, 0, active_action.value, state_file.value, null)
                active_action.value = response.active_action
                $('.btn_next_page').disable = true;
                current_id = response.current_id
                disables = false
                $('.gif-loading-browse').hide()
                $('.icon-plus-browse').show()
            }
        }


        /*
        *
        * NOTIFICACIÓN PARA GENERAR URL CDN
        *
        * */

        async function displayModalUrlCdn(file) {
            Swal.fire({
                customClass: {
                    loader: 'custom-loader-color',
                },
                title: "Cargando",
                html: "Estamos recuperando la url del archivo seleccionado",
                iconColor: 'rgba(244,167,73,0.75)',
                didOpen: async () => {
                    Swal.showLoading();
                    let res = await getUrlCdn(file)
                    if (variableIsDefined(res)) {
                        Swal.hideLoading()
                        Swal.fire({
                            customClass: {
                                container: 'z-index-max',
                                popup: 'card-modal body-modal',
                                htmlContainer: 'personalize-scroll max-height-50',
                                confirmButton: 'btn button-bg-blue',
                                cancelButton: 'btn button-bg-white',
                                actions: 'footer-modal',
                            },
                            title: 'Archivo: ' + file.title,
                            html: res,
                            icon: "success",
                            showCancelButton: true,
                            confirmButtonText: "Copiar",
                            cancelButtonText: "Volver atrás",
                            showLoaderOnConfirm: true,
                            async preConfirm() {
                                return await copyPortapeles(res)
                            },
                        })
                    }
                },
            })
        }

        async function getUrlCdn(file_select) {
            let response = await getDirectLinkFile(file_select.id_file)
            if (variableIsDefined(response.payload.length)) {
                return response.payload[0].cdnLink
            } else {
                let response = await createDirectLinkFile(file_select.id_file, file_select.type)
                return response.payload.cdnLink
            }
        }

        async function copyPortapeles(url) {
            await copyClipboard(url)
            showNotification('success', 'La url se ha copiado al porta papeles')
        }


        return {
            change_select,
            searchFileWS,
            returnCategory,
            viewFiles,
            next_page,
            previous_page,
            optionSee,
            getFileActiveWS,
            displayModalNewCategory,
            callOpenCategory,
            callCategoryId,
            displayModalUrlCdn,

            see_phone,
            view_phone_category,
            view_phone_files,
            btn_back,
            category_main,
            fields,
            all_files_select,
            show_img,
            loading,
            state_file,
            search_file,
            options,
            view_files,
            selected_option_page,
            page_selector_options,
            active_action,
            view_page,
            total_page,
            view_control_page,
            open_modal_new_category,
            identifier_category,
            btn_next,
        }
    }
}

</script>

<style lang="scss">
@import "@/styles/components/media-valet/browse.scss";
</style>
