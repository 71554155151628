<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/edit-user.svg')" alt="icono de docente" width="28" height="28">
                    Gestor de docentes
                </span>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="3" xl="2" v-if="!pending_language && !loading">
                <v-btn class="btn button-bg-blue main-title-page float-end" @click="showForm('insert')">
                    Crear docente
                </v-btn>
            </v-col>
        </v-row>

        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <template v-if="pending_language">
                <v-row>
                    <v-col cols="12">
                        <span>Selecciona un idioma para continuar.</span>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="search"
                            label="¿Qué quieres buscar?"
                            @keyup.enter="searchTeachers"
                            append-inner-icon="mdi-magnify"
                            bg-color="white"
                            color="transparent"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-lg-2 col-md-4 col-xs-6 p-1 " v-for="(teacher, index) in teachers"
                           :key="teacher.title">
                        <GenericUserProfile
                            :displayDialogConfirmationMessage="preDeactivateTeacher"
                            :profile="teacher"
                            :user_active="teacher.activo"
                            :showForm="showForm"
                            :user_image="teacher.imagen"
                            :user_name="teacher.nombre"
                            :can_delete="false"
                            :can_edit="true"
                            :can_activate="true"
                            :can_view="true"
                        ></GenericUserProfile>
                    </v-col>
                </v-row>
            </template>
        </template>

        <!-- Modal VISTA Docentes -->
        <v-dialog v-model="dialog" scrollable>
            <FormTeacher :title_modal="title_modal"
                         :all_teachers="all_teachers"
                         :list_available_users="list_available_users"
                         :teacher="teacher_selected"
                         :displayDialog="displayDialog"
                         :disable_update="disable_update"
                         :getAllTeachers="getAllTeachers"
            >
            </FormTeacher>
        </v-dialog>
        <!--  fin modal -->

    </v-container>


</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {
    getAllTeachersWS,
    removeTeacherWS, updateTeacherWS
} from "@/utils/teachers";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import FormTeacher from "@/components/Forms/FormTeacher.vue";
import GenericUserProfile from "@/components/Generic/GenericUserProfile.vue";
import {hasPermission} from "@/utils/auth";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {getAllUsersRolWS, getIDRolWS} from "@/utils/admin_functions";
import {getCategoriesWS} from "@/utils/web_categorization";
import Loading from "@/components/Generic/Loading.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";


export default {
    name: "TeachersManagerView",
    components: {Loading, GenericUserProfile, FormTeacher},
    setup: function () {
        const route = useRoute();
        const router = useRouter()
        const store = useStore();
        let teachers = ref([]);
        let teacher_selected = ref([]);
        let all_teachers = ref([]);
        let loading = ref();
        let search = ref();
        let dialog = ref(false);

        let key_form = ref(1); /*ESTA VARIABLE NO SÉ QUE HACE*/

        let disable_update = ref(false);
        let title_modal = ref();
        let title_msg_drop = ref("");
        let language_active_iso = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        let pending_language = ref(false);
        let list_available_users = ref();

        onBeforeMount(async () => {
            loading.value = true;
            await hasPermission(route);
            await getAllUserRol();
            await router.isReady()
            initSweetAlert();
            language_active_iso.value !== 0 ? await getAllTeachers() : pending_language.value = true
            loading.value = false;
        })

        watch(portal, async () => {
            await getAllTeachers();
        });

        /*
        * ABRE EL MODAL DE CREACIÓN/VISUALIZACIÓN
        * DE DOCENTE
        * */
        function displayDialog() {
            dialog.value = !dialog.value
        }

        function displayDialogConfirmationMessage() {
            fireSweetAlert(title_msg_drop.value, deactivateTeacher, 'Podrá revertir los cambios más adelante')
        }

        async function getAllTeachers() {
            const response = await getAllTeachersWS();
            let final_teachers = [];
            if (response['result']) {
                Object.entries(response['data']).forEach(teacher => {
                    let teacher_obj = {
                        'nombre': teacher[1].datos_innocualificados.nombre_completo,
                        'usuario': teacher[1].datos_innocualificados.usuario,
                        'externo': teacher[1].datos_innocualificados.externo,
                        'activo': teacher[1].datos_personalizaciones[0].activo,
                        'imagen': teacher[1].datos_personalizaciones[0].imagen,
                        'titulacion': teacher[1].datos_personalizaciones[0].titulacion,
                        'experiencia': teacher[1].datos_personalizaciones[0].experiencia,
                        'id_docente': teacher[1].datos_personalizaciones[0].id_tecnico,
                        'linkedin': teacher[1].datos_innocualificados.linkedin,
                        'datos_innocualificados': {
                            'formacion_complementaria': teacher[1].datos_innocualificados.formacion_complementaria,
                            'formacion_reglada': teacher[1].datos_innocualificados.formacion_reglada
                        }
                    }
                    final_teachers.push(teacher_obj)
                })
                all_teachers.value = final_teachers;
                teachers.value = all_teachers.value

            } else {
                showNotification('error', response['errors'])
            }
        }

        async function showForm(method, teacher = {}) {
            teacher_selected.value = teacher;

            switch (method) {
                case 'update':
                    title_modal.value = "Editar"
                    disable_update.value = false;
                    await router.push({path: `/bridge/docentes/${teacher_selected.value.usuario}`})
                    break;
                case 'insert':
                    title_modal.value = "Nuevo"
                    disable_update.value = false;
                    break;
                case 'view':
                    title_modal.value = "Vista"
                    disable_update.value = true;
                    $(document).ready(function () {
                        $('body .box_get_file').hide()
                    })
                    break;
            }

            teacher_selected.value = teacher;
            key_form.value++;
            displayDialog();
        }

        async function getAllUserRol() {
            let id_rol = await getIDRolWS('docente');
            if (variableIsDefined(id_rol)) {
                let response = await getAllUsersRolWS(id_rol)
                if (response['result']) {
                    list_available_users.value = response['data'];
                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                showNotification('error', 'No se ha encontrado rol para recuperar usuarios')
            }
        }


        async function preDeactivateTeacher(teacher) {
            let action;
            teacher_selected.value = _.cloneDeep(teacher);
            teacher_selected.value.activo === 1 ? action = 'desactivar' : action = 'activar';
            title_msg_drop.value = '¿Quieres ' + action + ' a ' + teacher_selected.value.nombre + '?';
            displayDialogConfirmationMessage()
        }

        async function deactivateTeacher() {
            let json_teacher =
                {'activo': !teacher_selected.value.activo, 'id_docente': teacher_selected.value.id_docente}

            let response = await updateTeacherWS(json_teacher)

            if (response['result']) {
                showNotification('success', 'Docente actualizado correctamente')
                await getAllTeachers();
            } else {
                showNotification('error', response['errors'])
            }
        }


        /*FUNCIONES SIN USAR PARA ELIMINAR DOCENTE*/
        async function preDeleteTeacher(teacher) {
            title_msg_drop.value = "¿Deseas eliminar el docente?"
            teacher_selected.value = teacher
            displayDialogConfirmationMessage()
        }

        async function deleteTeacher() {
            const response = await removeTeacherWS(teacher_selected.value);

            if (response['result']) {
                showNotification('success', 'Docente eliminado correctamente')
                await getAllTeachers();
                displayDialogConfirmationMessage()
            } else {
                showNotification('error', response['errors'])
            }
        }


        // TODAS LAS ÁREAS
        /*ESTO CREO QUE SOBRA*/
        /*async function getCategories() {
            const response = await getCategoriesWS();
            if (response['result'] && response['data']) {
                areas_teacher.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }*/

        /* buscador por nombre de docente*/
        function searchTeachers() {
            let result_search = [];
            all_teachers.value.forEach(teacher => {
                if (teacher['nombre'].toLowerCase().includes(search.value.toLowerCase())) {
                    result_search.push(teacher)
                }
            })
            teachers.value = result_search;
        }


        return {
            searchTeachers,
            displayDialog,
            showForm,
            getAllTeachers,
            preDeactivateTeacher,

            title_modal,
            teacher_selected,
            teachers,
            all_teachers,
            loading,
            search,
            key_form,
            dialog,
            disable_update,
            pending_language,
            list_available_users
        }
    }
}
</script>
<style lang="scss">
@import "@/styles/views/teachers.scss";
</style>
