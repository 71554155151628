<template>
    <v-container class="container-configuration-gads">
        <v-row class="row-title-btn-add">
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/google-ads.svg')" alt="icono de Google Ads" width="28" height="28">
                    Configuración GADS
                </span>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="3" xl="2" class="col-create-configuration" v-if="!general_loading">
                <v-btn
                    class="btn button-bg-blue"
                    @click="showForm('insert')"
                >
                    Crear configuración
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        class="primary-table gads-table-specific"
                        :headers="columns_table"
                        :items="data_config"
                        :loading="loading"
                        buttons-pagination
                        :rows-per-page="10"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-icon_edit="{id_configuracion}">

                            <img :src="require('@/assets/icons/edit.svg')"
                                 alt="Editar"
                                 class="icon-data-table icon-edit"
                                 title="Editar"
                                 id="copy"
                                 @click="showForm('update', id_configuracion)"
                            >
                        </template>

                        <template #item-icon_delete="{id_configuracion}">
                            <img :src=" require('@/assets/icons/trash.svg')"
                                 alt="Borrar"
                                 class="icon-data-table icon-delete"
                                 title="Borrar"
                                 id="delete"
                                 @click="preDeleteConfig(id_configuracion)"
                            >
                        </template>
                        <template #item-icon_export="{id_configuracion}">
                            <div :class="'export-'+id_configuracion">
                                <img :src=" require('@/assets/icons/export-xlsx.svg')"
                                     alt="Exportar XLSX"
                                     class="icon-data-table icon-export"
                                     title="Exportar XLSX"
                                     id="export"
                                     @click="exportCSVRanking(id_configuracion)"
                                >
                                <v-progress-circular
                                    class="icons-loading"
                                    indeterminate
                                    :size="20"
                                    :width="2"
                                    color="white"
                                ></v-progress-circular>
                            </div>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
        <v-dialog class="modal-create-edit"
                  v-model="open_create" persistent>
            <ConfigurationGADS
                :title_form="title_form"
                :displayModalCreate="displayModalCreate"
                :id="id"
                :getAllConfig="getAllConfig"
            />
        </v-dialog>
    </v-container>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import {getAicoreToken, showNotification} from "@/utils/aux_functions";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import ConfigurationGADS from "@/components/ConfigurationGads/FormConfigurationGADS.vue";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import store from "@/store";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ListConfigurationGadsView",
    components: {Loading, ConfigurationGADS, Vue3EasyDataTable},
    setup() {
        const route = useRoute();
        const columns_table = ref([
            {text: 'ID', value: 'id_configuracion', sortable: true},
            {text: 'Nombre', value: 'nombre', sortable: true},
            {text: 'Descripción', value: 'descripcion', sortable: true},
            {text: '', value: 'icon_edit'},
            {text: '', value: 'icon_delete'},
            {text: '', value: 'icon_export'},

        ]);
        let data_config = ref([])
        let open_create = ref(false);
        let id_config = ref()
        let title_dialog_confirmation = ref("")
        let title_form = ref("")
        let id = ref()
        let status_url_export;
        let loading = ref(true);
        let general_loading = ref(false)

        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route);
            await getAllConfig();
            initSweetAlert()
            general_loading.value=false
        })

        async function getAllConfig() {
            loading.value = true;
            let response = await aicoreBackendPetition('GET', "/feeds/get_all_configs", {})
            if (response['result']) {
                data_config.value = response['data']
            } else {
                showNotification("error", response['errors'])
            }
            loading.value = false;
        }

        function displayModalDelete() {
            fireSweetAlert(title_dialog_confirmation.value, deleteConfig)
        }

        function displayModalCreate() {
            open_create.value = !open_create.value
        }

        async function deleteConfig() {
            let params = {
                "id_config": id_config.value,
                "user": store.getters.getUsername,
            }
            let response_delete = await aicoreBackendPetition('GET', "/feeds/delete_config", params)
            if (response_delete['result']) {
                await getAllConfig()
                showNotification("success", "Configuración Eliminada")

            }
        }

        function preDeleteConfig(id_configuration = 0) {
            title_dialog_confirmation.value = "¿Deseas eliminar esta configuración?"
            id_config.value = id_configuration
            displayModalDelete()
        }

        function showForm(method, id_configuracion = 0) {
            if ('insert' && id_configuracion === 0) {
                title_form.value = "Crear configuración GADS"
            } else if ('update' && id_configuracion !== 0) {
                title_form.value = "Editar configuración GADS"
            }
            displayModalCreate()
            id.value = id_configuracion
        }

        async function exportCSVRanking(id_config) {
            let icon_export = $(".export-" + id_config + " .icon-export")
            let icon_loading = $(".export-" + id_config + " .icons-loading")
            icon_export.hide()
            icon_loading.show()
            try{
                let response = await exportCsvRankingWS(id_config)
                if (status_url_export === 200) {
                    showNotification('success', "La configuración se ha descargado.")
                } else {
                    showNotification('error', "No se pudo recuperar la configuración.")
                    return "No se pudo recuperar la configuración"
                }
            }catch (e){
                showNotification('error', "No se pudo recuperar la configuración.")

            }

            icon_export.show()
            icon_loading.hide()
        }

        async function exportCsvRankingWS(id_config) {
            let url = process.env.VUE_APP_AICORE_URL_API + "feeds/export_csv_ranking_cursos_por_matriculas"
            let response = await getAicoreToken({})
            let token = response['access_token']

            return await $.ajax({
                type: "GET",
                url: url,
                data: {
                    "id_configuracion": id_config,
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "username": store.getters.getUsername,
                },
                success: function (data, textStatus, xhr) {
                    status_url_export = xhr.status
                    let disposition = xhr.getResponseHeader("content-disposition")
                    let title = disposition.split("=")[1]
                    let blob = new Blob([data], {type: 'text/csv'});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = title;
                    link.click();
                },
                async: true,
            })
        }


        return {
            preDeleteConfig,
            displayModalCreate,
            showForm,
            getAllConfig,
            exportCSVRanking,
            columns_table,
            loading,
            general_loading,
            data_config,
            open_create,
            id_config,
            title_form,
            id,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/list-configuration-gads.scss";
</style>
