<template>
    <paginate
            :page-count="totalPages"
            :click-handler="clickHandler"
            :container-class="'pagination'"
    ></paginate>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
    components: {
        Paginate
    },
    props: {
        clickHandler: {
            type: Function,
            required: true
        },
        totalPages: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/search-engine/search-pagination.scss";
</style>
