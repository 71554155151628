<template>
    <BaseFilterUrl
        :getDeleteFilter="deleteFilters"
        :getApplyBaseFilters="applyFilter"
        :image_screen="false"
        :type_order_col_template="false"
    >
        <template #filters>
            <v-col cols="12" :lg="url_type === 'courses' ? '5' : '4'">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.url_search"
                    label="Buscar por url..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
                <v-checkbox
                    label="Búsqueda exacta"
                    v-model="check_exact_search"
                    :true-value="true"
                    :false-value="false"
                    color="blue"
                    hide-details
                ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="6" lg="3" v-if="url_type === 'courses'">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.id_simo"
                    label="Buscar por id simo..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" lg="4" v-if="url_type === 'courses'">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.id_innotutor"
                    label="Buscar por código Innotutor..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3"
                   class="align-self-start">
                <v-select v-model="filter_searches.state_urls"
                          class="input-error select-primary personalize-text-field field-with-label"
                          label="Selecciona un estado"
                          :items="options_state"
                          item-title="title_state"
                          item-value="value_state"
                          variant="underlined"
                          hide-details
                          no-data-text="No hay datos disponibles"
                >
                </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" :lg="url_type === 'courses' ? '5' : '4'"
                   class="align-self-start">
                <InputCalendar
                    msg_hint="Filtrar por fecha de creación y de actualización"
                    label_input="Filtro de fecha"
                    :deleteFilters="deleteFilters"
                    ref="filtered_date"
                ></InputCalendar>
            </v-col>

            <v-col cols="12" sm="12" md="4" :lg="url_type === 'courses' ? '4' : '3'"
                   :xl="url_type === 'courses' ? '4' : '2'"
                   class="align-self-start">
                <v-checkbox
                    label="Mostrar sólo borradores"
                    v-model="filter_searches.check_get_draft"
                    :true-value="true"
                    :false-value="false"
                    color="blue"
                    hide-details
                ></v-checkbox>
            </v-col>
        </template>
    </BaseFilterUrl>
</template>

<script>

import {onBeforeMount, ref} from "vue";
import {formatOnlyDate, variableIsDefined} from "@/utils/aux_functions";
import BaseFilterUrl from "@/components/Generic/BaseFilterUrl.vue";
import {VDateInput} from 'vuetify/labs/VDateInput'
import InputCalendar from "@/components/Generic/InputCalendar.vue";

export default {
    name: "FilterUrl",
    methods: {variableIsDefined},
    components: {
        InputCalendar,
        BaseFilterUrl,
        VDateInput

    },
    props: {
        getApplyFilter: Function,
        deleteFilter: Function,
        exportData: Function,
        disabled_export: Boolean,
        url_type: String,
        filter_search: Object,
    },
    setup(props) {
        let check_exact_search = ref(false);
        let options_state = ref([
            {title_state: 'Activo', value_state: 1},
            {title_state: 'Inactivo', value_state: 0},
        ])
        let filter_searches = ref()
        let filtered_date = ref(null)

        onBeforeMount(() => {
            filter_searches.value = _.cloneDeep(props.filter_search);
        })

        async function applyFilter(common_filters) {
            filter_searches.value.filter_date = null
            /*FILTRO BÚSQUEDA EXACTA*/
            if (check_exact_search.value) {
                filter_searches.value.url_search_type = 'is_equal';
            } else {
                filter_searches.value.url_search_type = 'contains';
            }

            /*FILTRO FECHA*/
            if (variableIsDefined(filtered_date.value.input_date)) {
                if (filtered_date.value.error_date) {
                    filter_searches.value.filter_date = formatOnlyDate(filtered_date.value.input_date, true, 'aaaa-mm-dd');
                }
            }
            if (filtered_date.value.error_date) {
                let merged_filters = {...common_filters, ...filter_searches.value}
                props.getApplyFilter(merged_filters)
            }
        }


        function deleteFilters() {
            if (check_exact_search.value || (variableIsDefined(filter_searches.value.url_search_type))
                || filter_searches.value.state_urls !== 1 || filter_searches.value.check_get_draft !== false
                || variableIsDefined(filter_searches.value.url_search) || variableIsDefined(filtered_date.value.input_date)) {
                filtered_date.value.deleteDate()
                check_exact_search.value = false
                filter_searches.value.url_search_type = null;
                filter_searches.value = {'state_urls': 1, 'check_get_draft': false, 'url_search': ""};
                props.deleteFilter(filter_searches.value)
            }
        }

        return {
            applyFilter,
            deleteFilters,

            check_exact_search,
            filter_searches,
            options_state,
            filtered_date,
        }
    }
}
</script>


<style scoped lang="scss">

</style>