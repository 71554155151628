<template>
    <v-row>
        <v-col cols="12" sm="5">
            <v-select
                :items="portals"
                item-title="nombre"
                item-value="id_portal"
                v-model="form_data.id_portal"
                placeholder="PORTAL"
                variant="underlined"
                class="input-error select-primary"
                :error="errors.portal_error"
                :disabled="disable_portals || disabled_general"
                @update:modelValue="changePortalSelection()"
                hide-details
                no-data-text="No hay datos disponibles"
            >
            </v-select>
            <span v-if="errors.portal_error" class="input-error msg-error">El campo no puede estar vacio</span>
        </v-col>

        <v-col cols="12" sm="5">
            <v-select
                :items="languages"
                item-title="nombre"
                item-value="id_idioma"
                v-model="form_data.id_language"
                variant="underlined"
                placeholder="IDIOMA"
                class="input-error select-primary"
                :error="errors.language_error"
                :disabled="disable_languages || disabled_general"

                hide-details
                no-data-text="No hay datos disponibles"
            >
            </v-select>
            <span v-if="errors.language_error" class="input-error msg-error">El campo no puede estar vacio</span>
        </v-col>

        <v-col cols="12" sm="2" v-if="!disable && !disabled_general" class="text-center">
            <v-btn class="btn button-primary btn-add-url"
                   hide-details
                   type="button"
                   :disabled="disable || disabled_general"
                   @click="addElement()"
            >
                Añadir
            </v-btn>
        </v-col>
        <v-col cols="12" sm="2" v-if="disable && !disabled_general"
               class="align-self-center text-center">
            <img class="btn-erase" @click="eraseElement" src="@/assets/icons/close-circle.svg" alt="eliminar url"/>
        </v-col>


        <!--     error para cuando repita portal   -->
        <v-col cols="12" class="error-repeat">
            <span v-if="errors.repeated_value" class="error-repeat">El valor introducido esta repetido</span>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {translateTextWS} from "@/utils/translator";
import {useStore} from "vuex";
import {checkURLWS} from "@/utils/editor_course_blog";
import {validateURL} from "@/utils/validations";
import store from "@/store";

export default {
    name: "RowFormRedirection",
    props: {
        portals: Array,
        fields: Map,
        increaseID: Function,
        id: Number,
    },
    setup(props) {
        let disabled_general = ref(false)
        let disable_portals = ref(false)
        let disable_languages = ref(true)


        let form_data = ref({});
        let errors = ref({});
        let disable = ref(false);
        let languages = ref([]);


        onBeforeMount(() => {
            autocompleteValues();
        })


        /******************************************************/

        function autocompleteValues() {
            if (variableIsDefined(props.fields)) {
                if (props.fields.get(props.id) !== "") {
                    form_data.value.id_portal = props.fields.get(props.id).originPortal;
                    showLanguages();
                    let language_index = languages.value.findIndex((element) => element.id_idioma === props.fields.get(props.id).originLangID);
                    form_data.value.id_language = languages.value[language_index].id_idioma;
                    disableBtn();
                }
            }
        }

        function disableBtn() {
            disable_languages.value = true;
            disable_portals.value = true;
            return disable.value = !disable.value;
        }

        function eraseElement() {
            props.fields.delete(props.id);
        }

        /*
        *
        * AÑADE UN NUEVO ELEMENTO AL MAP, SEGÚN SI ESTÁ
        *
        * */
        function addElement() {
            if (validarDatos()) {
                let data;
                data = {
                    'originPortal': form_data.value.id_portal,
                    'originLangID': form_data.value.id_language,
                };
                let id = props.increaseID();
                let next_id = id + 1;
                props.fields.set(id, data);
                props.fields.set(next_id, '');
                disableBtn();
            }


        }

        function validarDatos() {
            clearErrors()
            let data_error = true
            errors.value.repeated_value = false
            if (!variableIsDefined(form_data.value.id_portal)) {
                errors.value.portal_error = true
                data_error = false
            }
            if (!variableIsDefined(form_data.value.id_language)) {
                errors.value.language_error = true
                data_error = false
            }

            for (let [key, value] of props.fields.entries()) {
                if (variableIsDefined(value)) {
                    if (value['originPortal'] === form_data.value.id_portal &&
                        value['originLangID'] === form_data.value.id_language
                    ) {
                        errors.value.repeated_value = true;
                        data_error = false;
                    }
                }
            }
            return data_error
        }

        function clearErrors() {
            errors.value = {
                portal_error: false,
                language_error: false,
            }
        }

        function showLanguages() {
            disable_languages.value = false;
            props.portals.forEach(portal => {
                if (portal.id_portal === form_data.value.id_portal) {
                    languages.value = portal.languages;
                }
            })
        }

        function changePortalSelection() {
            form_data.value.id_language = null;
            showLanguages();
        }


        return {
            eraseElement,
            changePortalSelection,
            addElement,

            disable_portals,
            disable_languages,
            disabled_general,
            form_data,
            errors,
            disable,
            languages,

        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/url-list/modal-url.scss";
@import "@/styles/views/redirection.scss";

</style>