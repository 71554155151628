<template>
    <BaseModalTemplate
        title="Copiar contenido SEO"
        :open-close-dialog="displayDialog"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">
                        URL origen
                    </span>
                    <v-text-field
                        class="text-field-secondary"
                        v-model="url_origin"
                        label="Buscar url origen"
                        single-line
                        bg-color="white"
                        color="transparent"
                        :disabled=true
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                     <span class="input-title">
                         URL destino
                     </span>

                    <div id="new_urls" v-for="field in fields.keys()">
                        <RowFormUrl :portals="portals" :fields="fields" :increaseID="increaseID"
                                    :id="field" :inherit="false" :related_url="stored_related_urls"
                                    :url="url.url" :copy_seo="true" :id_portal="fields.get(field).portal"
                                    :key="field"
                        ></RowFormUrl>
                    </div>
                </v-col>

            </v-row>
        </template>
        <template #actions-modal>
            <v-row class="justify-end">
                <v-col cols="12">
                    <v-btn class="btn button-primary"
                           @click="saveNewURL"
                           :loading="loading"
                           hide-details>
                        Copiar contenido
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import RowFormUrl from "@/components/UrlList/RowFormUrl.vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {fireInfoAlert, initInfoAlert} from "@/utils/sweetalert";
import {getNamePortalLanguage} from "@/utils/portal";
import {setElementsSEOCopyWS} from "@/utils/queue_seo";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "CopySEOModal",
    components: {BaseModalTemplate, RowFormUrl},
    props: {
        displayDialog: Function,
        url: Object,
        portals: Array,
    },
    setup(props) {
        let url_origin = ref('');
        let loading = ref(false);
        let fields = ref(new Map());
        let count = 0;
        let stored_related_urls = ref([]);
        let correct_dates = []
        let incorrect_dates = []

        onBeforeMount(() => {
            url_origin.value = props.url.url;
            fields.value.set(0, '');
            initInfoAlert()
        });

        function increaseID() {
            let count_before = count;
            count++;
            return count_before;
        }

        async function saveNewURL() {
            loading.value = true;
            for (let [key, value] of fields.value.entries()) {
                if (variableIsDefined(value) && value.sobreescribir) {
                    let names_portal_language = getNamePortalLanguage(value.portal, value.languageISO)
                    let response = await setElementsSEOCopyWS(url_origin.value, value.portal, value.languageISO, value.url)
                    if (response.result) {
                        correct_dates.push({
                            'state': 'Correcto',
                            'url': value.url,
                            'portal': names_portal_language.name_portal,
                            'language':  variableIsDefined(names_portal_language.name_language) ? names_portal_language.name_language : "No se han podido obtener los datos",
                        })
                    } else {
                        incorrect_dates.push({
                            'state': 'Error:  Ya existe en cola una copia con estos datos',
                            'url': value.url,
                            'portal': names_portal_language.name_portal,
                            'language':  variableIsDefined(names_portal_language.name_language) ? names_portal_language.name_language : "No se han podido obtener los datos",
                        })
                    }
                }
            }
            props.displayDialog()
            loading.value = false;
            if (correct_dates.length > 0 || incorrect_dates.length > 0) {
                await fireInfoAlert('Aviso', viewHtml())
            }
        }

        function viewHtml() {
            let incorrect = incorrect_dates.map(e =>
                `<div style="text-align: left">
                    <span style="color: #f83850; font-size: 15px">${e.state}</span><br>
                    <span style="font-size: 14px"><b>Nueva URL: </b>${e.url}</span><br>
                    <span style="font-size: 14px"><b>Portal: </b>${e.portal}</span><br>
                    <span style="font-size: 14px"><b>Idioma: </b>${e.language}</span>
                </div>
                <hr>`
            ).join("")

            let correct = correct_dates.map(c =>
                `<div style="text-align: left">
                    <p style="color: #21E228; font-size: 16px">${c.state}</p>
                    <p style="font-size: 15px"><b>Nueva URL: </b>${c.url}</p>
                    <p style="font-size: 15px"><b>Portal: </b>${c.portal}</p>
                    <p style="font-size: 15px"><b>Idioma: </b>${c.language}</p>
                </div>
                <hr>`
            ).join("")

            return incorrect + correct
        }

        return {
            saveNewURL,
            increaseID,
            url_origin,
            loading,
            fields,
            stored_related_urls,
        }
    }
}
</script>

<style scoped lang="scss">
.input-title {
    margin-top: 0;
    margin-bottom: 10px;
}

</style>