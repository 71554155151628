<template>
    <QueueTemplate
            page_title="Blogs encolados para copia SEO"
            :headers="headers"
            :get-all-queue-elements="getAllElementSEOCopyWS"
            :change-element-state="changeStateElementSEOCopyWS"
            @send-element-data="receiveElementData"
    >
        <template #custom-title>
            <span class="main-title-page">
                <img :src="require('@/assets/icons/navbar/queue-files.svg')" alt="icono de blogs encolados" width="28" height="28">
                Blogs encolados para copia SEO
            </span>
        </template>
        <template #queue-filters="{form_data}">
            <v-col cols="12" lg="6">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.origin_url"
                    label="Buscar por url origen..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.destiny_url"
                    label="Buscar por url destino..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                ></v-text-field>
            </v-col>
        </template>
        <template #modal-element-log>
            <v-dialog v-model="show_log_modal" persistent>
                <ModalLogUrl
                    :open_modal_log="open_modal_log"
                    :log_id="log_id"
                ></ModalLogUrl>
            </v-dialog>
        </template>
    </QueueTemplate>
</template>

<script>
import ModalLogRedirection from "@/components/Redirections/ModalLogRedirection.vue";
import QueueTemplate from "@/components/Generic/QueueTemplate.vue";
import {ref} from "vue";
import {changeStateElementSEOCopyWS, getAllElementSEOCopyWS} from "@/utils/queue_seo";
import ModalLogUrl from "@/components/QueueSEOCopy/ModalLogUrl.vue";

export default {
    name: "SEOCopyQueueView",
    methods: {getAllElementSEOCopyWS, changeStateElementSEOCopyWS},
    components: {ModalLogUrl, QueueTemplate, ModalLogRedirection},
    setup(){
        const headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'ID URL ORIGEN', value: 'id_url_origen', sortable: true},
            {text: 'URL ORIGEN', value: 'url_origen'},
            {text: 'URL DESTINO', value: 'url_destino'},
            {text: 'PORTAL ORIGEN', value: 'id_portal_origen', width: 200},
            {text: 'PORTAL DESTINO', value: 'id_portal_destino', width: 200},
            {text: 'FECHA CREACIÓN', value: 'created_at', sortable: true},
            {text: 'FECHA ACTUALIZACIÓN', value: 'updated_at', sortable: true},
            {text: 'EMPLEADO', value: 'id_empleado'},
            {text: 'ESTADO', value: 'id_estado'},
            {text: 'REINTENTOS', value: 'reintentos', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])

        const show_log_modal = ref(false)
        const log_id = ref()

        const open_modal_log = () => {
            show_log_modal.value = !show_log_modal.value
        }

        const receiveElementData = (element_data) => {
            log_id.value = element_data.id

            open_modal_log()
        }

        return{
            receiveElementData,
            open_modal_log,

            headers,
            show_log_modal,
            log_id,
        }
    }
}
</script>

<style scoped>

</style>