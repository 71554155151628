import Swal from "sweetalert2";
import {defineComponent} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import img_prueba from '@/assets/gifs/Animacion-mantenimiento.gif'

export default defineComponent({
    components: {Loading}
})


let swal_delete
let swal_notification
let swal_info
let swal_maintenance_info

/*
*
* MODAL DE CONFIRMACIÓN DE BORRADO
*  1º LLAMAR A initSweetAlert
*
*  2º LLAMAR A fireSweetAlert PARA MOSTRAR EL MODAL
*       PARAMS:
*           title (String): Titulo del modal
*           text (String): Texto que se muestra en el cuerpo del modal
*           confirm_function (Function): Función que se ejecuta al presionar
*                                        el botón de aceptar
*
* */
export function initSweetAlert() {
    swal_delete = Swal.mixin({
        customClass: {
            container: 'z-index-max',
            popup: 'card-modal body-modal',
            htmlContainer: 'personalize-scroll max-height-50',
            confirmButton: 'btn button-bg-blue',
            cancelButton: 'btn button-primary',
            actions: 'footer-modal',
            loader: 'custom-loader'
        },
        loaderHtml:
            `<div class="spinner-border text-warning" role="status">
            </div>`,
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'No, volver atrás',
        showLoaderOnConfirm: true,
        reverseButtons: true,
    })
}

export async function fireSweetAlert(title, confirm_function, text = 'No podrás deshacer esta acción') {
    return swal_delete.fire({
        title,
        text,
        preConfirm: async () => {
            return await confirm_function()
        },
    })
}

export function getSweetAlert() {
    return swal_delete
}

export function initInfoAlert() {
    swal_info = Swal.mixin({
        customClass: {
            container: 'z-index-max',
            popup: 'card-modal body-modal',
            confirmButton: 'btn button-bg-blue',
            actions: 'footer-modal',
            loader: 'custom-loader'
        },
        loaderHtml:
            `<div class="spinner-border text-warning" role="status">
            </div>`,
        showCancelButton: false,
        icon: 'warning',
        confirmButtonText: 'Continuar',

    })
}


export function initMaintenanceAlert() {
    swal_maintenance_info = Swal.mixin({
        customClass: {
            container: 'z-index-max',
            popup: 'card-modal body-modal',
            confirmButton: 'btn button-bg-blue',
            actions: 'footer-modal',
            loader: 'custom-loader'
        },
        loaderHtml:
            `<div class="spinner-border text-warning" role="status">
            </div>`,
        showCancelButton: false,
        imageUrl: img_prueba,
        confirmButtonText: 'Continuar',
    })
}

export async function fireInfoAlert(title, html, confirm_function = ()=>{}) {
    return swal_info.fire({
        title,
        html,
        preConfirm: async () => {
            return await confirm_function()
        },
    })
}

export async function fireMaintenanceAlert(title, html, confirm_function = ()=>{}) {
    return swal_maintenance_info.fire({
        title,
        html,
        preConfirm: async () => {
            return await confirm_function()
        },
    })
}

export function initSweetNotification() {
    swal_notification = Swal.mixin({
        toast: true,
        position: "bottom-right",
        iconColor: 'white',
        customClass: {
            container: 'colored-toast',
            popup: 'colored-toast',
            htmlContainer: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
}

export function getSweetNotification() {
    return swal_notification
}