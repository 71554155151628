<template>
    <BaseModalTemplate
        :title="msg_title_modal"
        :open-close-dialog="displayDialogCourse"
        :send-form="saveCourse"
        body_extra_classes="modal-height-xs"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" sm="8" lg="9">
                    <v-text-field
                        label="Identificador curso"
                        v-model="url_course"
                        variant="underlined"
                        class="text-field-primary field-with-label"
                        :disabled="blocked_text"
                        :class="{error : error.show_msg_url_course}"
                        hint="Identificador del curso sin incluir el / inicial"
                    ></v-text-field>
                    <span v-if="error.show_msg_url_course"
                          class="input-error msg-error">{{ error.msg_error_url_course }}</span>
                </v-col>
                <v-col cols="12" sm="4" lg="3">
                    <v-text-field
                        label="Orden del curso (1 al 9)"
                        v-model="weight"
                        variant="underlined"
                        class="text-field-primary field-with-label"
                        type="number"
                        hide-details
                        min="1"
                        max="9"
                        :class="{error : error.show_msg_weight}"
                    ></v-text-field>
                    <span v-if="error.show_msg_weight"
                          class="input-error msg-error">{{ error.msg_error_weight }}</span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {validateURL} from "@/utils/validations";
import {
    changeFacultyFeaturedCourseWeightWS,
    editCoursePriority,
    newCoursePriority,
    setFacultyFeaturedCourseWS
} from "@/utils/courses";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalPriorityCourses",
    components: {BaseModalTemplate},
    methods: {variableIsDefined},
    props: {
        displayDialogCourse: Function,
        msg_title_modal: String,
        date_course_selected: Object,
        getAllCoursesPriority: Function
    },
    setup(props) {
        let state_loading = ref(false)
        let url_course = ref("")
        let blocked_text = ref(false)
        let weight = ref()
        let error = ref({})

        onBeforeMount(async () => {
            if (variableIsDefined(props.date_course_selected)) {
                url_course.value = props.date_course_selected.url
                weight.value = props.date_course_selected.weight
                blocked_text.value = true
            } else {
                url_course.value = ""
                blocked_text.value = false
            }
        })

        async function saveCourse() {
            await validate()
            if (Object.keys(error.value).length === 0) {
                if (variableIsDefined(props.date_course_selected)) {
                    let response = await changeFacultyFeaturedCourseWeightWS(props.date_course_selected.url, weight.value)
                    if (response['result']) {
                        showNotification('success', 'Se ha modificado correctamente el orden del curso')
                        props.getAllCoursesPriority()
                        props.displayDialogCourse()
                    } else {
                        showNotification('error', response['errors'])
                    }

                } else {
                    let response = await setFacultyFeaturedCourseWS(url_course.value, weight.value)
                    if (response['result']) {
                        showNotification('success', 'Se ha añadido correctamente el curso a prioritarios')
                        props.getAllCoursesPriority()
                        props.displayDialogCourse()
                    } else {
                        showNotification('error', response['errors'])
                    }
                }
            }
        }

        async function validate() {
            error.value = {}
            if (variableIsDefined(url_course.value)) {
                if (!validateURL(url_course.value)) {
                    error.value.show_msg_url_course = true
                    error.value.msg_error_url_course = "Los datos introducidos no son válidos, compruebe el espaciado"
                }
            } else {
                error.value.show_msg_url_course = true
                error.value.msg_error_url_course = "El campo no puede estar vacío"
            }

            if (variableIsDefined(weight.value)) {
                if (!/^[1-9]$/.test(weight.value)) {
                    error.value.show_msg_weight = true
                    error.value.msg_error_weight = "Solo se admiten números del 1 al 9"
                }
            } else {
                error.value.show_msg_weight = true
                error.value.msg_error_weight = "El campo no puede estar vacío"
            }

        }

        return {
            saveCourse,
            state_loading,
            url_course,
            blocked_text,
            weight,
            error,

        }
    }
}
</script>


<style lang="scss">

</style>