<template>
    <v-container fluid>
        <div v-if="generate_token" class="position-loading">
            <loading></loading>
        </div>
        <div v-else class="container-mediaValet">
            <v-card class="card-menu">
                <v-tabs
                    v-model="menu_media_valet"
                    color="blue"
                    align-tabs="start"
                >
                    <v-tab class="space-menu" :value="1">BUSCAR ARCHIVO</v-tab>
                    <v-tab class="space-menu" :value="2">CARGAR ARCHIVO</v-tab>
                </v-tabs>
                <v-window v-model="menu_media_valet" class="container-options">
                    <v-window-item :value="1">
                        <Browse
                        :root_category="root_category"></Browse>
                    </v-window-item>
                    <v-window-item :value="2">
                        <Upload
                        :root_category="root_category"></Upload>
                    </v-window-item>
                </v-window>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import Browse from "@/components/MediaValet/Browse.vue";
import Upload from "@/components/MediaValet/Upload.vue";
import {
    accessToken, getRootCategory, getRootCategoryWS, getToken,
} from "@/utils/media_valet";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "MediaValetView",
    components: {Loading, Upload, Browse},
    setup() {
        let menu_media_valet = ref(null)
        let generate_token = ref(false)
        let root_category = ref({})

        onBeforeMount(async () => {
            generate_token.value = true
            let response = await accessToken()
            if (response){
                await getRootCategory()
                generate_token.value = false
            }
        })
        async function getRootCategory(){
            let {response} = await getRootCategoryWS()
            root_category.value = {
                name: response.payload[1].name,
                id: response.payload[1].id
            }
        }

        return {
            menu_media_valet,
            generate_token,
            root_category,
        }
    }
}
</script>

<style scoped lang="scss">
.position-loading {
    padding: 10px;
    min-height: 80vh;
    max-height: 90vh;
    display: flex;
    align-items: center;
}

.container-mediaValet {
    height: 100%;
    padding: 15px;

    .card-menu {
        //padding: 10px;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $gray-light;
        box-shadow: none;
    }
}


@media (max-width: 600px) {
    .container-mediaValet {
        .card-menu {
            margin: 0 0;
        }
    }
}
</style>