import store from "@/store";
import {createTokenAISEO, showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";

export async function importProposalKeyword(imported_file){
    let form_data = new FormData();
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    form_data.append('file', imported_file['imported_file'])
    form_data.append('usuario_logueado', store.getters.getUsername)
    form_data.append('token', createTokenAISEO(params_token))

    try {
        return $.ajax({
            url: process.env.VUE_APP_AISEO_BACKEND_URL + 'propuestaKeywordMasivo',
            type: 'post',
            contentType: false,
            processData: false,
            data: form_data
        })
    }catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'propuestaKeywordMasivo');
    }
}


//OBTIENE TODOS LOS ELEMENTOS EN COLA
export async function getAllElementsProposalWS(page, rows_per_page, {keyword_actual, keyword_propuesta, estado_kw, state, tries, orderBy, orderDirection, strict}) {
    const params_token = {
        usuario_logueado: store.getters.getUsername,
    };
    
    const params = {
        'keyword_actual': keyword_actual,
        'keyword_propuesta' : keyword_propuesta,
        'estado_kw' : estado_kw,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'reintentos' : tries,
        'estadoID' : state,
        'pageSize': rows_per_page,
        'exactSearch': strict,
    };
    
    try {
        return await aiseoBackendPetition('POST', 'queueKw/getAllElementsPropuestasKeyword', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueKw/getAllElementsPropuestasKeyword');
    }
}

//CAMBIA EL ESTADO DE UN ELEMENTO EN COLA, SOLO PUEDE CAMBIAR ENTRE CANCELADO Y PENDIENTE
export async function changeStateElementProposalWS (id_element, id_state){
    const params_token= {
        usuario_logueado: store.getters.getUsername,
    }
    const params = {
        id_state: id_state
    }
    try {
        return await aiseoBackendPetition('POST', 'queueKw/changeStateElementPropuestasKeyword/'+id_element, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueKw/changeStateElementPropuestasKeyword/');
    }
}

export async function getLogElementProposalWS(id_cola) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'queueKw/getLogElementPropuestasKeyword/' + id_cola, {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueKw/getLogElementPropuestasKeyword');
    }
}