import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {createTokenAISEO, showNotification, variableIsDefined} from "@/utils/aux_functions";
export async function getConfigSearchReplaceWS(){
    let params_token = {
        "usuario_logueado": store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/getConfigSearchReplace', {}, params_token);
    }
    catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/getAllElements');
    }
}

export async function getSearchReplaceRegexWS(){
    let params_token = {
        "usuario_logueado": store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/getSearchReplaceRegex', params_token, params_token);
    }
    catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/getSearchReplaceRegex');
    }
}


export async function setElementWS(configuration_table, form_data){
    let params = {
        "usuario_logueado": store.getters.getUsername,
        "replace": form_data.replace_word,
        "search": form_data.search_word,
        "configuration": configuration_table,
        'strict': form_data.strict,
        'search_by_regex': form_data.selected_search_type.id,
        'urls': form_data.urls,
        'id_empleado': store.getters.getUsername,
    }

    let params_token = {
        "usuario_logueado": store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/setElements', params, params_token);
    }
    catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/setElements');
    }
}

export async function previewSearchReplaceWS(configuration_table, form_data, page, rows_per_page){
    let params = {
        "usuario_logueado": store.getters.getUsername,
        "replace": form_data.replace_word,
        "search": form_data.search_word,
        "configuration": configuration_table,
        'strict': form_data.strict,
        'search_by_regex': form_data.selected_search_type.id,
        'page_size': rows_per_page,

    }
    let params_token = {
        "usuario_logueado": store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/previewSearchReplace?page='+page, params, params_token);
    }
    catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/previewSearchReplace');
    }
}
//CREA UN NUEVO ELEMENTO EN COLA REVIRTIENDO LOS CAMBIOS DE UNO YA FINALIZADO
export async function revertElementChangesWS ({search, replace, configuration, strict, urls, search_by_regex}, queue_id) {
    const params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    const params = {
        'search': search,
        'replace': replace,
        'configuration': configuration,
        'strict': strict,
        'urls': urls,
    };

    try {
        if (search_by_regex > 1){
            return await aiseoBackendPetition('POST', 'queueSearchReplace/revertRegexChanges' , {'id_cola': queue_id}, params_token)
        }else{
            return await aiseoBackendPetition('POST', 'queueSearchReplace/setElements' , params, params_token)
        }
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/setElements')
    }
}

//OBTIENE TODOS LOS ELEMENTOS EN COLA
export async function getAllElementsReplacementsWS(page, rows_per_page, {origin_url, destiny_url, state, tries, orderBy, orderDirection,searched_word, replaced_word, strict}) {
    const params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    const params = {
        'pageSize': rows_per_page,
        'originURL': origin_url,
        'destinyURL' : destiny_url,
        'estadoID' : state,
        'reintentos' : tries,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
        'search': searched_word,
        'replace': replaced_word,
        'strict': strict
    };

    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/getAllElements?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/getAllElements');
    }
}

// A PARTIR DEL ID DE UN ELEMENTO EN COLA OBTIENE LOS DATOS DE SU LOG
export async function getLogElementReplacementWS(id){
    let params_token= {
        usuario_logueado: store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/getLogElements/'+id, {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearchReplace/getLogElements/');
    }
}

//CAMBIA EL ESTADO DE UN ELEMENTO EN COLA, SOLO PUEDE CAMBIAR ENTRE CANCELADO Y PENDIENTE
export async function changeStateElementReplacementWS (id_element, id_state){
    const params_token= {
        usuario_logueado: store.getters.getUsername,
    }
    const params = {
        id_state: id_state
    }
    try {
        return await aiseoBackendPetition('POST', 'queueSearchReplace/updateElements/'+id_element, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueSearcReplace/updateElements/');
    }
}

export async function importSearchReplaceWS(import_data){
    let formData = new FormData();
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    if (import_data.selected_search_type.id !== 1 && variableIsDefined(import_data.selected_regex)){
        formData.append('regex_to_use', import_data.selected_regex.replace(/\\\\/g, '\\'))
    }

    formData.append('file', import_data.imported_file)
    formData.append('search_by_regex', import_data.selected_search_type.id)
    formData.append('usuario_logueado', store.getters.getUsername)
    formData.append('token', createTokenAISEO(params_token))
    
    try {
        return $.ajax({
            url: process.env.VUE_APP_AISEO_BACKEND_URL + 'importSearchReplace/import',
            type: 'post',
            contentType: false,
            processData: false,
            data: formData
        })
    }catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'importSearchReplace/import');
    }
}