<template>
    <BaseModalTemplate
        :title="`Detalles de la cola con ID ${log_id}`"
        :open-close-dialog="open_modal_log"
        :loading="loading"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <template v-if="logs_array.length > 0" v-for="info in logs_array">
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12" v-if="info.response !== 'OK'">
                                <v-row>
                                    <v-col cols="6">
                                        <span class="titles">Búsqueda: </span>
                                        <span>{{ info.data.buscar }}</span>
                                    </v-col>
                                    <v-col cols="6">
                                        <span class="titles">Reemplazo: </span>
                                        <span>{{ info.data.reemplazar }}</span><br>
                                    </v-col>
                                </v-row>

                            </v-col>
                            <v-col cols="12">
                                <p>
                                    <span class="subtitle-secondary">Id Reemplazo: </span><span>{{ info.id }}</span>
                                </p>
                                <p>
                                    <span class="subtitle-secondary">Id Empleado: </span><span>{{
                                        info.id_empleado
                                    }}</span>
                                </p>
                                <p>
                            <span
                                class="subtitle-secondary">Fecha: </span><span>{{ formatDate(info.created_at) }}</span>
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <p class="titles">Configuración: </p>
                                <v-table density="compact"
                                         class="personalize-scroll personalize-table-simple">
                                    <thead>
                                    <tr>
                                        <th class="text-left" style="font-weight: bold"> Tabla</th>
                                        <th class="text-left" style="font-weight: bold"> Campo</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="config in info.data.configuracion"

                                    >
                                        <td>{{ config.tabla }}</td>
                                        <td>{{ config.campo }}</td>
                                    </tr>
                                    </tbody>
                                </v-table>
                            </v-col>
                        </v-row>
                    </v-col>


                    <v-col cols="12" class="mt-2">
                        <p class="titles">Respuesta del servidor: </p>
                        <template v-if="variableIsDefined(info.response.URLs)">
                            <p class="subtitle-secondary">URLs afectadas</p>
                            <Vue3EasyDataTable
                                table-class-name="primary-table modal-table"
                                theme-color="#F4A649"
                                buttons-pagination
                                :headers="headers"
                                :items="info.response.URLs"
                                must-sort
                                :hide-footer="info.response.URLs.length<=25"
                                rows-per-page-message="Elementos por página: "
                                rows-of-page-separator-message="de"
                                empty-message="No hay datos disponibles"
                            >
                                <template #item-id_portal="item">
                                    {{ getNamePortal(item.id_portal) }}
                                </template>
                                <template #item-id_idioma="item">
                                    {{ getNameLanguage(item.id_idioma) }}
                                </template>
                            </Vue3EasyDataTable>
                        </template>
                        <template v-else-if="variableIsDefined(info.response)">
                            <span>{{ info.response }}</span>
                        </template>
                        <template v-else>
                            <span>Sin Respuesta</span>
                        </template>
                    </v-col>
                </v-row>

                <hr>
            </template>
            <template v-else>
                <span>No hay datos disponibles para este elemento</span>
            </template>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getLogElementReplacementWS} from "@/utils/find_replace_text";
import {getNameLanguage, getNamePortal} from "../../utils/portal";
import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
    name: "ModalLogFindReplace",
    methods: {variableIsDefined, formatDate, getNameLanguage, getNamePortal},
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        open_modal_log: Function,
        log_id: Number,
    },
    setup(props) {
        let loading = ref(false)
        let logs_array = ref([])
        const headers = [
            {text: 'URL', value: 'url'},
            {text: 'Portal', value: 'id_portal'},
            {text: 'Idioma', value: 'id_idioma'},
        ]

        onBeforeMount(async () => {
            loading.value = true
            const response = await getLogElementReplacementWS(props.log_id)

            if (response['result']) {
                logs_array.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }

            loading.value = false
        })


        return {
            loading,
            logs_array,
            headers,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/modal-log.scss";
</style>