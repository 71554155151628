<template>
    <BaseModalTemplate
        :title="selection"
        :open-close-dialog="showDialog"
        card_extra_classes="w-100"
    >
        <template #body-modal-content>
            <v-row v-if="selection === 'Iniciar curso' || selection === 'Continuar curso'">
                <v-col
                    cols="12">
                                    <span class="input-title">
                                            Temática *</span>
                </v-col>
            </v-row>
            <v-row v-if="selection === 'Iniciar curso' || selection === 'Continuar curso'">
                <v-col>
                    <v-text-field class="input-error text-field-primary"
                                  placeholder="Temática"
                                  v-model="form_data.topic"
                                  variant="underlined"
                                  hide-details
                                  :class="{error :form_error.topic}"
                    >
                    </v-text-field>
                    <span v-if="form_error.topic" class="input-error msg-error">
                                'El campo no puede estar vacío'
                            </span>
                </v-col>
            </v-row>

            <v-row v-if="selection === 'Iniciar curso' || selection === 'Contenido SEO HTML' || selection === 'Continuar curso' || selection === 'Aumento contenido HTML'">
                <v-col
                    cols="12">
                                    <span class="input-title">
                                            Keywords *</span>
                </v-col>
            </v-row>
            <v-row v-if="selection === 'Iniciar curso' || selection === 'Contenido SEO HTML' || selection === 'Continuar curso' || selection === 'Aumento contenido HTML'">
                <v-col>
                    <v-text-field
                        class="input-error text-field-primary"
                        placeholder="keywords"
                        v-model="form_data.keywords"
                        variant="underlined"
                        hide-details
                        :class="{error :form_error.keywords}"
                    >
                    </v-text-field>
                    <span v-if="form_error.keywords" class="input-error msg-error">
                                El campo no puede estar vacío
                            </span>
                </v-col>
            </v-row>

            <v-row v-if="selection === 'Contenido SEO HTML' || selection === 'Aumento contenido HTML'">
                <v-col
                    cols="12">
                                    <span class="input-title">
                                            Número de secciones aproximadas*</span>
                </v-col>
            </v-row>
            <v-row v-if="selection === 'Contenido SEO HTML' || selection === 'Aumento contenido HTML'">
                <v-col>
                    <v-text-field
                        class="input-error text-field-primary"
                        placeholder="Número de secciones"
                        v-model="form_data.num_sections"
                        variant="underlined"
                        :class="{error :form_error.num_sections}"
                        type="number"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.num_sections" class="input-error msg-error">
                                El campo no puede estar vacío
                        </span>
                </v-col>
            </v-row>

            <v-row v-if="selection === 'Iniciar curso' || selection === 'Contenido SEO HTML' || selection === 'Aumento contenido HTML'">
                <v-col
                    cols="12">
                                    <span class="input-title">
                                            Número de palabras *</span>
                </v-col>
            </v-row>
            <v-row v-if="selection === 'Iniciar curso' || selection === 'Contenido SEO HTML' || selection === 'Aumento contenido HTML'">
                <v-col>
                    <v-text-field
                        class="input-error text-field-primary"
                        placeholder="Nº de palabras"
                        v-model="form_data.num_words"
                        variant="underlined"
                        hide-details
                        type="number"
                        :class="{error :form_error.num_words}"
                    >
                    </v-text-field>
                    <span v-if="form_error.num_words" class="input-error msg-error">
                                El campo no puede estar vacío
                            </span>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12">
                    <v-btn
                        variant="outlined"
                        class="btn button-primary"
                        @click="chooseFunction()"
                        :loading="loading"
                    >
                        Crear
                    </v-btn>
                    <v-btn
                        variant="outlined"
                        class="btn button-primary"
                        @click="showDialog"

                    >
                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {
    chatgptContinueCourseWS,
    chatgptGenerateReviewWS,
    chatgptIncreaseHTMLWS,
    chatgptInitCourseWS,
    chatgptSEOHTML
} from "@/utils/seo_chatgpt_functions";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {useRoute} from "vue-router";
import store from "@/store";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormChatGPT",
    components: {BaseModalTemplate},
    props: {
        showDialog: Function,
        updateFunction: Function,
        selection: String,
        html_doc: String,
        keywords: String,
        message_history: Array,
    },
    setup(props) {
        let selection = ref('');
        let route = useRoute();

        let final_content = ref('');
        let course = ref('');
        let num_comments = ref(10);
        let html_doc = ref('');
        let html_petition = ref('');
        let message_history = ref([]);

        let form_data = ref({});
        let form_error = ref({});

        let loading = ref(false);


        onBeforeMount(()=>{
            form_data.value.keywords = props.keywords;
        })
        function clearForm() {
            final_content.value = '';
            form_data.value = {};
            form_error.value = {};

            course.value = '';
            num_comments.value = 10;
            html_doc.value = '';
            html_petition.value = '';
        }

        onBeforeMount(() => {
            selection.value = props.selection;
            html_doc.value = props.html_doc;
            message_history.value = props.message_history;
        })

        async function chooseFunction() {
            loading.value = true;
            if(validateForm()){
                switch (selection.value) {
                    case 'Iniciar curso':
                        await chatgptInitCourse();
                        break;
                    case 'Continuar curso':
                        await chatgptContinueCourse()
                        break;
                    case 'Generar comentarios':
                        await chatgptGenerateReview();
                        break;
                    case 'Contenido SEO HTML':
                        await chatgptSEO();
                        break;
                    case 'Aumento contenido HTML':
                        await chatgptIncreaseHTML();
                        break;
                }

                props.updateFunction(final_content.value);
                clearForm();
                props.showDialog();
            }

            loading.value = false;
        }

        function validateForm(){
            let correct = true;

            if(!variableIsDefined(form_data.value.keywords)){
                correct = false;
                form_error.value.keywords = true;
            }

            switch (selection.value) {
                case 'Iniciar curso':
                    if(!variableIsDefined(form_data.value.topic)){
                        correct = false;
                        form_error.value.topic = true;
                    }
                    if(!variableIsDefined(form_data.value.num_words)){
                        correct = false;
                        form_error.value.num_words = true;
                    }
                    break;
                case 'Continuar curso':
                    if(!variableIsDefined(form_data.value.topic)){
                        correct = false;
                        form_error.value.topic = true;
                    }
                    break;
                case 'Contenido SEO HTML':
                    if(!variableIsDefined(form_data.value.num_sections)){
                        correct = false;
                        form_error.value.num_sections = true;
                    }
                    if(!variableIsDefined(form_data.value.num_words)){
                        correct = false;
                        form_error.value.num_words = true;
                    }
                    break;
                case 'Aumento contenido HTML':
                    if(!variableIsDefined(form_data.value.num_sections)){
                        correct = false;
                        form_error.value.num_sections = true;
                    }
                    if(!variableIsDefined(form_data.value.num_words)){
                        correct = false;
                        form_error.value.num_words = true;
                    }
                    break;
            }

            return correct;
        }
        /****
         *
         * Inicializa un curso
         *
         * ****/
        async function chatgptInitCourse() {
            let response = await chatgptInitCourseWS(form_data.value.topic, form_data.value.keywords, form_data.value.num_words)

            if (response['result']) {
                final_content.value = {
                    'message_history': response['data']['message_history'],
                    'response': courseTextFormat(response['data']['response'])
                };
            } else {
                showNotification('error', response['error']);
            }
        }

        function courseTextFormat(text){
             return text.replace('Introducción:', '<h3>Introducción:</h3><p>')
                 .replace('Sección uno:', '</p><h3>Sección uno:</h3><p>')
                 .replace('Sección dos:', '</p><h3>Sección dos:</h3><p>')
                 .replace('Sección tres:', '</p><h3>Sección tres:</h3><p>')
                 .replace('Conclusión:', '</p><h3>Conclusión:</h3><p>');
        }
        /****
         *
         * Continua un curso ya empezado
         *
         * ****/
        async function chatgptContinueCourse() {
            let original_content = html_doc.value;
            let response;

            if(variableIsDefined(original_content)){
                let parag_end = html_doc.value.search('XXX');
                if(parag_end > 0){
                    let parag_init = parag_end - 100;
                    if (parag_init < 0) parag_init = 0;
                    let paragraph = html_doc.value.substring(parag_init, parag_end);

                    if (message_history.value.length > 0) {
                        response = await chatgptContinueCourseWS({'message_history': message_history.value}, paragraph)
                    } else {
                        let continue_params = {
                            'pre_initialized': true,
                            'original_content': original_content,
                            'topic': form_data.value.topic,
                            'keywords': form_data.value.keywords,
                            'message_history': message_history.value,
                        }
                        response = await chatgptContinueCourseWS(continue_params, paragraph);
                    }

                    if (response['result']) {
                        final_content.value = {
                            'response_continue': response['data']['response'],
                            'message_history': response['data']['message_history']
                        };
                    } else {
                        showNotification('error', response['error']);
                    }
                }else{
                    showNotification('error', 'Error: Vuelva a indicar desde donde expandir');
                }
            }else{
                showNotification('error', 'Error: El curso no está iniciado');
            }

        }

        /****
         *
         * Genera comentarios sobre una url
         *
         * ****/
        async function chatgptGenerateReview() {
            let response = await chatgptGenerateReviewWS(course.value, idioma.value, num_comments.value);

            if (response['result']) {
                final_content.value = response['data']['response'];
            } else {
                showNotification('error', response['error']);
            }
        }

        /****
         *
         * Genera contenido SEO
         *
         * ****/
        async function chatgptSEO() {
            let url = route.query.url;

            let response = await chatgptSEOHTML(url, form_data.value.keywords, form_data.value.num_words, form_data.value.num_sections);

            if (response['result']) {
                final_content.value = response['data'];
            } else {
                showNotification('error', response['error']);
            }
        }

        /****
         *
         * Aumenta el contenido HTML
         *
         * ****/
        async function chatgptIncreaseHTML() {
            if(variableIsDefined(html_doc.value)){
                html_petition.value = 'postear(url :' + route.query.url +
                    ', Keywords: ' + form_data.value.keywords +
                    ', idioma: ' + store.getters.getLanguageISO +
                    ', npalabras: ' + form_data.value.num_words +
                    ', nsecciones: ' + form_data.value.num_sections + ' )';

                let response = await chatgptIncreaseHTMLWS(html_doc.value, html_petition.value, form_data.value.num_words);

                if (response['result']) {
                    final_content.value = response['data'];
                } else {
                    showNotification('error', response['error']);
                }
            }else{
                showNotification('error', 'No se puede ampliar contenido vacío');
            }

        }

        return {
            chooseFunction,
            selection,
            final_content,
            course,
            loading,
            html_doc,
            form_data,
            form_error,
        }
    }
}
</script>

<style scoped>

</style>