import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getLanguagesPortalWS(id_portal) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal' : id_portal,
    }
    try {
        return await aiseoBackendPetition('POST', 'portalLanguage/getPortalLanguages', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'portalLanguage/getPortalLanguages');
    }
}

export async function insertPortalLanguageWS(id_portal, id_language) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal' : id_portal,
        'id_language': id_language,
    }
    try {
        return await aiseoBackendPetition('POST', 'portalLanguage/insertPortalLanguage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'portalLanguage/insertPortalLanguage');
    }
}

export async function deletePortalLanguageWS(id_portal, id_language) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal' : id_portal,
        'id_language': id_language,
    }
    try {
        return await aiseoBackendPetition('POST', 'portalLanguage/deletePortalLanguage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'portalLanguage/deletePortalLanguage');
    }
}

export async function updateMainLanguageOfPortalWS(portal_id, language_id) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': portal_id,
        'id_language': language_id,
    }
    try {
        return await aiseoBackendPetition('POST', 'portalLanguage/updateMainLanguageOfPortal', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'portalLanguage/updateMainLanguageOfPortal');
    }
}