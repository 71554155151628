import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";
import {getAllUsersRolWS} from "@/utils/admin_functions";


export async function getEditorCopywritersWS() {
    return await getAllUsersRolWS(1)
}

export async function changeAuthorsStatus(author_list, active_flag) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };
    let author = {
        'authors': author_list,
    }

    let params = {
        'authors_list': JSON.stringify(author),
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'active': active_flag,
    };

    try {
        return await aiseoBackendPetition('POST', 'authors/changeActiveStatus', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'authors/changeActiveStatus');
    }
}

export async function getAllAuthorsWS(active) {
    let params = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO
    };

    if (active != null) {
        params['active'] = active;
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'authors/getAllAuthors', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'authors/getAllAuthors');
    }
}

export async function sendCopywriterDataWS(form_data, action) {
    let response;

    let authors_data = {
        'id_author': form_data.id_author,
        'url': form_data.url,
        'id_profile_image': form_data.id_profile_image,
        'update_image': form_data.update_image,
        'education': form_data.education,
        'experience': form_data.experience,
        'themes': form_data.themes,
        'active': form_data.active,
        'description': form_data.description,
    }

    let author_portal = store.getters.getPortalID;

    let params = {
        'authors_data': JSON.stringify(authors_data),
        'id_portal': author_portal,
        'language': store.getters.getLanguageISO,
    };

    if (action === 'Editar') {
        params['username'] = form_data.username;
    }

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };


    try {
        if (action === 'Editar') {
            response = await aiseoBackendPetition('POST', 'authors/updateAuthor', params, params_token)
        } else if (action === 'Nuevo') {
            response = await aiseoBackendPetition('POST', 'authors/insertAuthor', params, params_token)
        }

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + action === 'Editar' ? 'authors/updateAuthor':'authors/insertAuthor');
    }
    return response;
}