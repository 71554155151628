<template>
    <v-row class="institution-portal-row">
        <v-col cols="12">
            <p> Comienza a gestionar tus <br> publicaciones:</p>
            <p v-if="!variableIsDefined(selected_institutions)">Selecciona una institución:</p>
            <SelectSecondary
                placeholder="Instituciones educativas"
                item-title="nombre"
                item-value="id_portal"
                :items="portals"
                v-model="selected_institutions"
                :default_values="store.getters.getSelectedInstitutions"
                :prepend_image="'@/assets/icons/navbar/icon-institutions.svg'"
            ></SelectSecondary>
        </v-col>
        <v-col cols="12">
            <p v-if="variableIsDefined(selected_institutions) && !variableIsDefined(selected_portals)">Selecciona un portal:</p>
            <SelectSecondary
                placeholder="Portales"
                item-title="nombre"
                item-value="id_portal"
                :items="portals"
                v-model="selected_portals"
                :prepend_image="'@/assets/icons/navbar/icon-institutions.svg'"
                :disabled="!variableIsDefined(selected_institutions)"
                :default_values="store.getters.getSelectedPortals"
            >
                <template #prepend_image>
                    <v-img :src="require('@/assets/icons/navbar/icon-portal-select.svg')" height="24" width="24"></v-img>
                </template>
            </SelectSecondary>
        </v-col>
        <v-col cols="12" v-if="variableIsDefined(selected_institutions) && variableIsDefined(selected_portals)">
            <p> Todo listo! Ya puedes comenzar</p>
        </v-col>
        <v-col cols="12" class="text-end d-block">
            <v-btn class="btn button-bg-blue" @click="savePortalInstitutionSelection" :disabled="!variableIsDefined(selected_portals) || !variableIsDefined(selected_institutions)">
                Comenzar
            </v-btn>
        </v-col>
    </v-row>
    <v-row class="bridge-btn-container">
        <v-col cols="12" class="d-flex justify-center">
            <router-link to='/bridge' class="text-decoration-none" @click="closeDialog">
                <v-btn class="btn button-primary to-bridge-btn">
                    Iniciar bridge
                    <img :src="require('@/assets/icons/sidebar/bridge-icon.svg')" alt="icono de bridge" width="24" height="24">
                </v-btn>
            </router-link>
        </v-col>

    </v-row>
</template>

<script>
    import {useStore} from "vuex";
    import {computed, onBeforeMount, ref} from "vue";
    import {variableIsDefined} from "@/utils/aux_functions";
    import SelectSecondary from "@/components/Generic/SelectSecondary.vue";

    export default {
        name: "InstitutionPortalSelection",
        methods: {variableIsDefined},
        components: {SelectSecondary},
        props:{
            closeDialog: Function,
            is_dialog: {
                type: Boolean,
                default: false,
            },

        },
        emits: ['selectionIsComplete'],
        setup(props, {emit}) {
            const store = useStore();

            let selected_institutions = ref([])
            let selected_portals = ref([]);
            let portals = computed(() => store.getters.getStoredPortals).value;

            onBeforeMount(() => {
                selected_portals.value = [...store.getters.getSelectedPortals]
                selected_institutions.value = [...store.getters.getSelectedInstitutions]
            })

            const savePortalInstitutionSelection = () => {
                store.commit('setShowPortalInstitution', true);
                store.commit('setSelectedPortals', selected_portals.value);
                store.commit('setSelectedInstitutions', selected_institutions.value);
                localStorage.setItem('selected_portals', JSON.stringify(selected_portals.value))
                localStorage.setItem('selected_institutions', JSON.stringify(selected_institutions.value))
                localStorage.setItem('show_portal_institution', JSON.stringify(true))

                emit('selectionIsComplete', true);

                if (props.is_dialog){
                    props.closeDialog()
                }
            }

            return {
                savePortalInstitutionSelection,

                store,
                portals,
                selected_portals,
                selected_institutions,
            }
        }
    }
</script>


<style lang="scss">
.institution-portal-row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}


.bridge-btn-container{
    width: 100%;
    position: absolute;
    bottom: 16px;

    .to-bridge-btn {
        display: flex;
        gap: 10px;

        .v-btn__content {
            display: flex;
            gap: 10px;
        }

        img{
            filter: $filter-sidebar-active;
        }

        &:hover {
            img{
                filter: $filter-white;
            }
        }
    }
}

@media (max-width: 990px) {
    .institution-portal-row {
        width: 90%;
    }
}


</style>