<template>
    <template v-for="file in all_files_select">
        <v-col cols="12" md="6" lg="3">
            <v-hover v-slot="{ isHovering, props }">
                <v-card class="card-file"
                        :elevation="isHovering ? 12 : 2"
                        :class="{ 'on-hover': isHovering }"
                        v-bind="props">
                    <v-card-text class="card-text-file" @click="displayModalFile(file)">
                        <v-row class="row-img">
                            <v-col class="col-img" cols="12">
                                <v-img :src="file.front" alt="img" class="image">
                                    <div class="icons-video" v-if="file.type==='Video'">
                                        <img class="img-play" :src="require('@/assets/icons/play.svg')" alt="">
                                    </div>
                                </v-img>
                            </v-col>
                            <v-col cols="12" class="col-title">
                                <span :title="file.title">{{ file.title }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <div class="align-self-center box-icon-hover" :class="{ 'box-hover': isHovering }">
                        <a :href="file.video">
                            <img :src="require('@/assets/icons/download.svg')" alt="Descargar" class="icon-download"
                                 :class="{ 'show-btns': isHovering }">
                        </a>
                        <img :src="require('@/assets/icons/icon-code.svg')" alt="Url CDN" class="icon-download ms-5"
                             :class="{ 'show-btns': isHovering }" @click="displayModalUrlCdn(file)">

                    </div>
                </v-card>
            </v-hover>
        </v-col>
    </template>

    <v-dialog persistent
              v-model="open_modal_file">
        <ModalFile
            :displayModal="displayModalFile"
            :file_select="file_select"
            :getFileActiveWS="getFileActiveWS"
            :identifier_category="identifier_category"
        />
    </v-dialog>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import FormStatus from "@/components/Forms/FormStatus.vue";
import ModalFile from "@/components/MediaValet/ModalFile.vue";
import {
    initSweetAlert,
} from "@/utils/sweetalert";
import Swal from "sweetalert2";

export default {
    name: "GridFiles",
    components: {ModalFile, FormStatus},
    props: {
        all_files_select: Object,
        getFileActiveWS: Function,
        identifier_category: String,
        displayModalUrlCdn: Function,

    },
    setup() {
        let open_modal_file = ref(false)
        let open_modal_url = ref(false)
        let file_select = ref({})

        onBeforeMount(() => {
            initSweetAlert()
        })

        function displayModalFile(file) {
            open_modal_file.value = !open_modal_file.value
            file_select.value = file
        }

        return {
            displayModalFile,
            open_modal_file,
            open_modal_url,
            file_select,
        }
    }

}
</script>

<style scoped lang="scss">
@import "@/styles/components/media-valet/grid-files";
</style>