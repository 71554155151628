<template>
    <v-container v-if="show_bread_crumb && this.$route.path !== '/'">
        <template v-for="(item, index) in breadCrumbs">
            <v-breadcrumbs-item
                    :to="{name: item.to}"
                    :disabled="item.disabled"
                    :exact="true"
                    class="breadcrumb-link"
                    @click="returnSubmenu(item)"
            >
                {{item.title}}
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider  v-if="index < breadCrumbs.length -1" >
                <v-icon class="separator" icon="mdi-chevron-right"></v-icon>
            </v-breadcrumbs-divider>
        </template>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import store from "@/store";

export default {
    name: "BreadCrumb",
    setup(){
        const router = useRouter()
        const route = useRoute()

        let breadCrumbs = ref([])
        let show_bread_crumb = computed(() => store.getters.getShowBreadCrumb);


        onBeforeMount(async () => {
            await router.isReady()
            breadCrumbs.value = fillBreadCrum()
        })

        watch(route, () => {
            breadCrumbs.value = fillBreadCrum()
        })

        watch(show_bread_crumb, () => {
            if(show_bread_crumb.value){
                breadCrumbs.value = fillBreadCrum()
            }
        })

        function returnSubmenu(submenu){
            if (submenu.children){
                store.commit('setChildrenMenu', submenu);
                localStorage.setItem('children_menu', JSON.stringify(submenu))
            }else{
                store.commit('setChildrenMenu', false);
                localStorage.setItem('children_menu', JSON.stringify(false))
            }
        }

        const fillBreadCrum = () => {
            if (typeof route.meta.breadCrumb === "function") {
                return route.meta.breadCrumb(route);
            }
            return route.meta.breadCrumb;
        }

        return{
            returnSubmenu,
            breadCrumbs,
            show_bread_crumb
        }
    }
}
</script>

<style lang="scss">
.separator{
    color: $gray-medium-bold;
}

.v-breadcrumbs-item--link{
    text-decoration: none;
    color: $gray-medium-bold !important;

    &:hover{
        font-weight: bold;
        text-decoration: none !important;
        color: $blue-darker !important;
    }


}
</style>