<template>
    <v-card variant="outlined" class="card-ranking" :class="'div-ranking div-ranking-'+id_ranking">
        <!--LECTURA                -->
        <div class="reading">
            <v-row>
                <v-col cols="12" sm="3" class="col-imagen">
                    <img class="img-ranking-reading text-center" :alt="text_alt_img" :src="img_ranking">
                </v-col>
                <v-col cols="12" sm="9" class="col-txt">
                    <div class="position-card">
                        <v-card-text class="content-card personalize-scroll">
                            <v-row no-gutters>
                                <v-col>
                                    <span class="input-title">Nombre de la imagen:</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span>{{ title_ranking }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col>
                                    <span class="input-title">Texto:</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span>{{ text_ranking_course }}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="footer-card footer-modal">
                            <img :src="require('@/assets/icons/edit.svg')"
                                 alt="Editar Ranking"
                                 class="size-icons icons-edit disabled_btn"
                                 title="Editar Ranking"
                                 @click="editRanking(id_ranking)">
                            <img :src="require('@/assets/icons/trash.svg')"
                                 alt="Eliminar Ranking"
                                 class="size-icons icon-delete disabled_btn ms-2"
                                 title="Eliminar Ranking"
                                 @click="displayDelete(id_ranking)">
                        </v-card-actions>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!--MODIFICAR                                -->
        <div class="writing">
            <div v-if="loading" class="general-loading">
                <Loading class_loading="loading-gif-s" />
            </div>
            <div v-else>
                <v-row>
                    <v-col cols="12" sm="3" class="col-imagen">
                        <img class="img-ranking-writing text-center" :alt="text_alt_img" :src="img_ranking">
                    </v-col>
                    <v-col cols="12" sm="9">
                        <div class="position-card">
                            <div class="content-card personalize-scroll ">
                                <v-card-text class="personalize-scroll">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea v-model="text_ranking_course_update"
                                                        label="Descripción"
                                                        variant="underlined"
                                                        class="input-error text-area-secondary mt-2"
                                                        hide-details
                                                        rows="3"
                                                        no-resize
                                                        :class="{error :form_error_update.text}"
                                            ></v-textarea>
                                            <span v-if="form_error_update.text" class="input-error msg-error">{{
                                                    form_error_update.text
                                                }}</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </div>
                            <v-card-actions class="footer-card footer-modal">
                                <img :src="require('@/assets/icons/save.svg')"
                                     alt="Guardar Ranking"
                                     class="size-icons icon-save"
                                     title="Guardar Ranking"
                                     @click="updateRanking(id_ranking)"
                                     :class="'btn-save btn-save-'+id_ranking"
                                >
                                <v-progress-circular
                                    indeterminate
                                    :size="20"
                                    :width="2"
                                    color="blue"
                                    :class="'btn-save-loading btn-save-loading-'+id_ranking"
                                ></v-progress-circular>
                                <img :src="require('@/assets/icons/close-circle.svg')"
                                     alt="Cerrar Ranking"
                                     class="size-icons icons-close ms-2"
                                     title="Cerrar Ranking"
                                     @click="closeRanking(id_ranking)"
                                >

                            </v-card-actions>
                        </div>

                    </v-col>
                </v-row>
            </div>
        </div>
    </v-card>
    <v-dialog class="" v-model="open_img_ranking" persistent>
        <ModalImageRanking
            :displayModalImgRanking="displayModalImgRanking"
            title_form="Selecciona una imagen para el ranking"
            :id_url=id_url
        />
    </v-dialog>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup";
import ModalImageRanking from "@/components/Ranking/ModalImageRanking.vue";
import {variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "CardRanking",
    components: {Loading, ModalImageRanking},
    props: {
        text_alt_img: String,
        id_ranking: Number,
        img_ranking: String,
        title_ranking: String,
        text_ranking_course: String,
        displayDelete: Function,
        saveRanking: Function,
        id_url: String,
        ranking_course: Object,
    },
    setup(props) {
        let loading = ref(false);
        let form_error_update = ref({})
        let open_img_ranking = ref(false);
        let text_ranking_course_update = ref("");

        async function editRanking(id_ranking) {
            loading.value = true
            text_ranking_course_update.value = props.text_ranking_course
            $(".btn-save-" + id_ranking).show()
            $(".div-ranking-" + id_ranking + " .writing").show();
            $(".div-ranking-" + id_ranking + " .reading").hide();
            $(".disabled_btn").hide();
            loading.value = false
        }

        function displayModalImgRanking() {
            open_img_ranking.value = !open_img_ranking.value
        }


        async function validation() {
            form_error_update.value = {}
            if (text_ranking_course_update.value === "") {
                form_error_update.value.text = 'El campo no puede estar vacío';
            }
        }

        async function updateRanking(id_ranking) {
            await validation()
            if (Object.entries(form_error_update.value).length === 0) {
                $(".btn-save-" + id_ranking).hide()
                $(".btn-save-loading-" + id_ranking).show()
                props.ranking_course.forEach((ranking_course) => {
                    if (id_ranking === ranking_course.id_ranking) {
                        if (ranking_course.text !== text_ranking_course_update.value) {
                            ranking_course.text = text_ranking_course_update.value
                        }
                        props.saveRanking('actualizado', id_ranking, ranking_course.text)
                    }
                })
            }
        }

        async function closeRanking(id_ranking) {
            text_ranking_course_update.value = props.text_ranking_course;
            form_error_update.value = {}
            $(".div-ranking-" + id_ranking + " .writing").hide();
            $(".div-ranking-" + id_ranking + " .reading").show();
            $(".disabled_btn").show();
        }


        return {
            editRanking,
            updateRanking,
            displayModalImgRanking,
            closeRanking,
            loading,
            form_error_update,
            open_img_ranking,
            text_ranking_course_update,
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/styles/components/url-list/card-ranking.scss";
</style>