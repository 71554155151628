import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {removeAccents, showNotification, variableIsDefined} from "@/utils/aux_functions";

let categorization = [];

export async function getPortalCategorizationWS() {
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID
    };
    let params = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID
    }

    let custom_response = [];

    try {
        let response = await aiseoBackendPetition('POST', 'sites/getPortalCategorization', params, params_token)
        custom_response['data'] = [];
        if (response['result']) {
            let portal_categorization = [];
            Object.keys(response['data']).forEach((category, index) => {
                let category_name;
                let cleaned_category = removeAccents(category)
                switch (cleaned_category) {
                    case 'areas_web':
                        category_name = 'Área'
                        break;
                    case 'categorias_web':
                        category_name = 'Facultad'
                        break;
                    case 'subcategorias_web':
                        category_name = 'Escuela'
                        break;
                }
                portal_categorization[index] = {}
                portal_categorization[index].category_name = category_name
                portal_categorization[index].cleaned_name = cleaned_category

                let final_values = [];
                Object.keys(response['data'][category]).forEach(category_value => {
                    let obj_value = {
                        'tid': parseInt(category_value),
                        'nombre': response['data'][category][category_value].alias || response['data'][category][category_value].nombre,
                        'parent_id': response['data'][category][category_value].parent_id,
                    }
                    final_values.push(obj_value)
                })
                portal_categorization[index].values = final_values;
            })
            categorization = portal_categorization;
            custom_response['data'] = portal_categorization;
            custom_response['errors'] = response['errors']
            custom_response['result'] = response['result']
        }
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getPortalCategorization');
    }
    return custom_response;
}


export function getPortalAreas() {

    let obj_area = [];
    obj_area.push({tid: 0, nombre: '--SIN ÁREA--', tid_parent: null})
    categorization.forEach(category => {
        if (category.cleaned_name === 'areas_web') {
            category.values.forEach(area => {
                obj_area.push({tid: area.tid, nombre: area.nombre, tid_parent: null});
            })
        }
    })

    return obj_area
}

export function getPortalCategoriesByArea(id_area) {

    let obj_category = [];
    obj_category.push({tid: 0, nombre: '--SIN CATEGORÍA--', tid_parent: null})
    categorization.forEach(category => {
        if (category.cleaned_name === 'categorias_web') {
            category.values.forEach(cat => {
                if (variableIsDefined(cat.parent_id) && parseInt(cat.parent_id) === id_area) {
                    obj_category.push({tid: cat.tid, nombre: cat.nombre, tid_parent: id_area});
                }
            })
        }
    })
    return obj_category
}

export function getPortalCategories() {
    let obj_category = [];
    obj_category.push({tid: 0, nombre: '--SIN CATEGORÍA--', tid_parent: null})

    categorization.forEach(category => {
        if (category.cleaned_name === 'categorias_web') {
            category.values.forEach(cat => {
                obj_category.push({tid: cat.tid, nombre: cat.nombre});
            })
        }
    })
    return obj_category
}


export function getPortalSubcategoriesByCategory(id_category) {

    let obj_subcategory = [];
    obj_subcategory.push({tid: 0, nombre: '--SIN SUBCATEGORÍA--', tid_parent: null})

    categorization.forEach(category => {
        if (category.cleaned_name === 'subcategorias_web') {
            category.values.forEach(subcategory => {
                if (variableIsDefined(subcategory.parent_id) && parseInt(subcategory.parent_id) === id_category) {
                    obj_subcategory.push({tid: subcategory.tid, nombre: subcategory.nombre, tid_parent: id_category});
                }
            })
        }
    })
    return obj_subcategory
}

export function getPortalSubcategories() {

    let obj_subcategory = [];
    obj_subcategory.push({tid: 0, nombre: '--SIN SUBCATEGORÍA--', tid_parent: null})

    categorization.forEach(category => {
        if (category.cleaned_name === 'subcategorias_web') {
            category.values.forEach(subcategory => {
                obj_subcategory.push({tid: subcategory.tid, nombre: subcategory.nombre});
            })
        }
    })
    return obj_subcategory
}

export async function getAreasWS() {
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID
    };
    let params = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID
    }

    try {
        return await aiseoBackendPetition('POST', 'getAreas', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getAreas');
    }
}

export async function getCategoriesWS(id_area = null) {
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'areaTID': id_area
    }

    try {
        return await aiseoBackendPetition('POST', 'getCategories', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getCategories');
    }
}

export async function getSubcategoriesWS(id_category = null) {
    let params_token = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID
    };
    let params = {
        'languageISO': store.getters.getLanguageISO,
        'portal': store.getters.getPortalID,
        'categoryTID': id_category
    }

    try {
        return await aiseoBackendPetition('POST', 'getSubcategories', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getSubcategories');
    }
}
