<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" class="text-start">
                <span class="main-title-page">Gestión de idiomas</span>
            </v-col>
            <v-col cols="12" md="6" class="text-end align-self-center" v-if="!loading">
                <v-btn class="btn button-primary" @click="degreeSelection('create', null)">CREAR IDIOMA</v-btn>
            </v-col>
        </v-row>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <FiltersGetLanguage
                :items_languages="items_languages"
                :getFilters="getFilters"
                :getLanguages="getLanguages"
            />
            <v-row>
                <v-col>
                    <Vue3EasyDataTable
                        :key="key_table"
                        table-class-name="primary-table"
                        theme-color="#F4A649"
                        :headers="header_table"
                        :items="items_languages"
                        :loading="loading_table"
                        :rowsPerPage="10"
                        buttons-pagination
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                        must-sort
                    >
                        <template #item-id_idioma_base="items_languages">
                            {{ getNameLanguage(items_languages.id_idioma_base) }}
                        </template>
                        <template #item-activo="items_languages">
                            <span v-if="items_languages.activo===1">Activo</span>
                            <span v-else>Inactivo</span>
                        </template>
                        <template #item-in_table_iso="items_languages">
                            <span v-if="items_languages.in_table_iso===1">Activo</span>
                            <span v-else>Inactivo</span>
                        </template>
                        <template #item-btn_actions="items_languages">
                            <v-menu class="table-blog">
                                <template v-slot:activator="{ props }">
                                    <img :src="require('@/assets/icons/more-options.svg')"
                                         class="btn button-bg-white icon-actions"
                                         alt="Icono puntos"
                                         v-bind="props">
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title class="text-center"
                                                           @click="degreeSelection('details', items_languages)">
                                            <span style="cursor: pointer">Detalles</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title class="text-center"
                                                           @click="degreeSelection('update', items_languages)">
                                            <span style="cursor: pointer">Editar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <!--Activar/Desactivar url-->
                                    <v-list-item @click="displayStatusLanguage(items_languages, items_languages.activo)">
                                        <v-list-item-title>
                                            <span v-if="items_languages.activo===1">Desactivar</span>
                                            <span v-else>Activar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
        <v-dialog
            v-model="open_modal" persistent>
            <ModalLanguage
                :displayModalLanguages="displayModalLanguages"
                :options_modal="options_modal"
                :form_data="form_data"
                :items_languages="items_languages"
                :getLanguages="getLanguages"
                :displayStatusLanguage="displayStatusLanguage"
                :disableLanguage="disableLanguage"

            ></ModalLanguage>
        </v-dialog>
    </v-container>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {disableLanguageWS, getAllLanguagesWS} from "@/utils/language";
import Loading from "@/components/Generic/Loading.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import ModalPortal from "@/components/Admin/ManagerPortal/ModalPortal.vue";
import ModalLanguage from "@/components/Admin/ManagerLanguage/ModalLanguage.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import FiltersGetLanguage from "@/components/Admin/ManagerLanguage/FiltersGetLanguage.vue";

export default {
    name: "ManagerLanguagesView",
    components: {FiltersGetLanguage, ModalLanguage, ModalPortal, Vue3EasyDataTable, Loading},

    setup() {
        let loading = ref(false)
        let header_table = ref([
            {text: 'ID Idioma', value: 'id_idioma', sortable: true},
            {text: 'Nombre', value: 'nombre', sortable: true},
            {text: 'ID Idioma Base', value: 'id_idioma_base', sortable: true},
            {text: 'IN TABLE ISO', value: 'in_table_iso', sortable: true},
            {text: 'ISO 2', value: 'iso_2', sortable: true},
            {text: 'ISO 6391', value: 'iso_6391', sortable: true},
            {text: 'Estado', value: 'activo', sortable: true},
            {text: 'Acciones', value: 'btn_actions'},
        ])
        let items_languages = ref([])
        let loading_table = ref(false)
        let options_modal = ref({})
        let open_modal = ref(false)
        let form_data = ref({})
        let item_language_selected;
        const key_table = ref(0);
        let loading_filter = ref({
            btn_save: false,
            btn_delete: false,
        })

        onBeforeMount(async () => {
            loading.value = true
            initSweetAlert()
            await getLanguages()
            loading.value = false
        })

        async function getLanguages(data_filters = {}) {
            loading_table.value = true
            let response = await getAllLanguagesWS(data_filters)
            if (response['result']) {
                items_languages.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }


        async function getFilters(filters) {
            loading_filter.value.btn_save = true
            key_table.value++;
            await getLanguages(filters)
            loading_filter.value.btn_save = false
        }


        const getNameLanguage = ((id_idioma_base) => {
            const language = items_languages.value.find(item => item.id_idioma === id_idioma_base);
            return language ? `${language.nombre} (${language.id_idioma})` : '';
        })


        async function degreeSelection(select_type, data) {
            switch (select_type) {
                case 'create':
                    form_data.value = []
                    options_modal.value = {
                        'title_modal': 'Crear un idioma',
                        'option':'create',
                        'title_btn': 'Guardar',
                        'disabled_fields': false,
                        'see_btn_actions': false
                    }
                    displayModalLanguages()
                    break;
                case 'update':
                    form_data.value = data
                    options_modal.value = {
                        'title_modal': 'Modificar un idioma',
                        'option':'update',
                        'title_btn': 'Guardar',
                        'disabled_fields': false,
                        'see_btn_actions': false
                    }
                    displayModalLanguages()
                    break;
                case 'details':
                    form_data.value = data
                    options_modal.value = {
                        'title_modal': 'Detalles del idioma',
                        'option':'details',
                        'disabled_fields': true,
                        'see_btn_actions': true
                    }
                    displayModalLanguages()
                    break;
            }
        }

        function displayStatusLanguage(item){
            item_language_selected = item
            let state = item.activo === 1 ? 'desactivar' : 'activar';
            let msg_state = item.activo === 1 ? 'El idioma se eliminará de todos los portales asociados. No podrás deshacer esta acción.' : 'El idioma se habilitará para poder utilizarlo';
            fireSweetAlert(`¿Quieres ${state} el estado del idioma?`, disableLanguageData, msg_state)
        }

        async function disableLanguageData(){
            let state = item_language_selected.activo === 1 ? 0 : 1;
            await disableLanguage(item_language_selected.id_idioma, state)
        }

        async function disableLanguage(id_language, state){
            let response = await disableLanguageWS(id_language, state)
            if (response['result']){
                if (variableIsDefined(response['data']['delete_portal'])){
                    showNotification('success', response['data']['delete_portal'], true, response['data']['message'])
                }else{
                    showNotification('success', response['data']['message'])
                }
                await getLanguages()
            }else{
                showNotification('error', response['errors'])
            }
        }

        function displayModalLanguages() {
            open_modal.value = !open_modal.value
        }

        return {
            degreeSelection,
            displayModalLanguages,
            getLanguages,
            displayStatusLanguage,
            getFilters,
            getNameLanguage,
            disableLanguage,

            loading,
            loading_table,
            header_table,
            items_languages,
            options_modal,
            open_modal,
            form_data,
            loading_filter,
            key_table,


        }
    }
}
</script>


<style scoped lang="scss">

</style>
