<template>
    <v-form>
        <v-row>
            <v-col cols="12" lg="5">
                <span class="input-title">
                                    Elegir idioma</span>
                <v-select
                    v-model="form_data.language_from"
                    :items="languages"
                    item-text="title"
                    item-value="iso"
                    class="input-error select-secondary"
                    placeholder="Selecciona idioma"
                    bg-color="white"
                    :error="variableIsDefined(language_selection_errors.language_from)"
                    :menu-props="{
                            rounded: 'lg'
                        }"
                    hide-details
                    no-data-text="No hay datos disponibles"
                ></v-select>
                <span v-if="variableIsDefined(language_selection_errors.language_from)" class="input-error msg-error">
                    {{error_message.language_from}}
                </span>
            </v-col>
            <v-col cols="12" lg="2" class="container-icon-center">
                <img :src="require('@/assets/icons/arrow-right.svg')" class="icon-arrow"
                     alt="clean-maps">
            </v-col>
            <v-col cols="12" lg="5">
                <span class="input-title">
                                    Elegir idioma</span>
                <v-select
                    v-model="form_data.language_to"
                    :items="languages"
                    item-text="title"
                    item-value="iso"
                    class="input-error select-secondary"
                    bg-color="white"
                    placeholder="Selecciona idioma"
                    :error="variableIsDefined(language_selection_errors.language_to)"
                    :menu-props="{
                            rounded: 'lg',

                        }"
                    hide-details
                    no-data-text="No hay datos disponibles"
                ></v-select>
                <span v-if="variableIsDefined(language_selection_errors.language_to)" class="input-error msg-error">
                    {{error_message.language_to}}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                 <span class="input-title mb-3">
                                    Texto a traducir</span>
                <v-textarea
                    name="texto_original"
                    class="input-error text-area-primary"
                    placeholder="Texto a traducir"
                    v-model="form_data.text"
                    hide-details
                    variant="outlined"
                    :error="variableIsDefined(language_selection_errors.text)"
                    bg-color="white"
                    color="transparent"
                    counter
                ></v-textarea>
                <span v-if="variableIsDefined(language_selection_errors.text)" class="input-error msg-error">
                    {{error_message.text}}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-radio-group
                    v-model="form_data.type"
                    mandatory
                    inline>
                    <v-radio
                        label="HTML"
                        value="html"
                        class="radio-button-primary"
                    ></v-radio>
                    <v-radio
                        label="Texto plano"
                        value="texto"
                        class="radio-button-primary"
                    ></v-radio>
                </v-radio-group>
                <span v-if="variableIsDefined(language_selection_errors.type)" class="type-error">
                    {{error_message.type}}
                </span>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12">
                <v-btn
                    class="float-end btn button-bg-blue"
                    @click="submit"
                    :disabled="loading"
                >
                    Traducir
                </v-btn>
            </v-col>
        </v-row>
    </v-form>

    <v-row v-if="loading">
        <v-col cols="12" class="text-center">
            <div class="loading">
                <img class="loading-gif-s" :src="require('@/assets/img/loading.gif')"
                     alt="Loading Gif">
            </div>
        </v-col>
    </v-row>
    <v-row v-if="translated_text !== ''">
        <v-col cols="12">
            <v-textarea
                name="translated_text"
                placeholder="Resultado"
                class="text-area-primary"
                :value="translated_text"
                v-model="translated_text"
                variant="outlined"
                hide-details
                bg-color="white"
            ></v-textarea>
        </v-col>
    </v-row>
</template>

<script>
import {computed, ref} from "vue";
import store from "@/store";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import * as Yup from "yup"
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {translateTextWS} from "@/utils/translator";

export default {
    name: "FormTranslator",
    methods: {variableIsDefined},

    setup() {
        let languages = [
            {'title': 'Español', 'iso': 'es'},
            {'title': 'Italiano', 'iso': 'it'},
            {'title': 'Polaco', 'iso': 'pl'},
            {'title': 'Inglés Británico', 'iso': 'en-uk'},
            {'title': 'Inglés EEUU', 'iso': 'en-us'},
        ]

        let form_data = ref({});
        let translated_text = ref("");
        let loading = ref(false);

        let language_selection_errors = ref({})
        let error_message = ref({})

        const submit = async () => {
            try {
                loading.value = true;

                if (form_data.value.language_to === form_data.value.language_from) {
                    throw "same_language"
                }

                validateData()

                if(Object.keys(language_selection_errors.value).length === 0){
                    let response = await translateTextWS(form_data.value.type, form_data.value.text, form_data.value.language_from, form_data.value.language_to)

                    if (response.result && response.data) {
                        translated_text.value = response.data
                        showNotification('success', '¡Mensaje traducido correctamente!')
                    } else {
                        showNotification('error', response.errors);
                    }
                }

            } catch (e) {
                if (e === 'same_language') {
                    language_selection_errors.value['language_from'] = true
                    language_selection_errors.value['language_to'] = true
                    error_message.value['language_from'] = 'El idioma origen no puede ser igual que el idioma de destino'
                    error_message.value['language_to'] = 'El idioma origen no puede ser igual que el idioma de destino'
                }
            }
            loading.value = false;
        }

        const validateData = () => {
            language_selection_errors.value = {}
            const {text, language_from, language_to, type} = form_data.value

            if(!variableIsDefined(text)){
                language_selection_errors.value['text'] = true
                error_message.value['text'] = 'El campo de texto no es válido'
            }

            if(!variableIsDefined(language_from)){
                language_selection_errors.value['language_from'] = true
                error_message.value['language_from'] = 'Seleccione un elemento'
            }

            if(!variableIsDefined(language_to)){
                language_selection_errors.value['language_to'] = true
                error_message.value['language_to'] = 'Seleccione un elemento'
            }

            if(!variableIsDefined(type)){
                language_selection_errors.value['type'] = true
                error_message.value['type'] = 'Seleccione como quiere recibir la traducción'
            }
        }

        return {
            submit,
            error_message,
            form_data,
            language_selection_errors,
            languages,
            loading,
            translated_text,
        }
    }
}
</script>

<style scoped lang="scss">
.container-icon-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.input-title {
    margin-top: 0;
    margin-bottom: 10px;
}

.icon-arrow {
    width: 50px;
    filter: $filter-sidebar-active;
}

.type-error {
    color: $error;
    float: left !important;
    font-size: 14px;
    padding-top: 5px;
    font-style: italic;
}

@media (max-width: 1280px) {
    .container-icon-center {
        rotate: 90deg;
    }
}

</style>