<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Gestión de roles</span>
            </v-col>
        </v-row>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-form @submit.prevent="searchRol">
                        <v-text-field
                            v-model="filter_rol"
                            class="text-field-secondary"
                            label="Buscar rol..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" lg="8" v-if="show_all_rol">
                    <div class="list-title">Lista de roles</div>
                    <div>
                        <v-list class="personalize-scroll">
                            <v-list-item
                                v-for="rol in rol_list"
                                :key="rol.id_rol"
                                :title="rol.name + ' ['+rol.alias+']'"
                                append-icon="mdi-chevron-right"
                                class="active-list"
                                :value="rol.name"
                                color="blue"
                                @click="captureRol(rol.id_rol,rol.name, rol.alias, rol.descripcion_rol)"
                            ></v-list-item>
                        </v-list>
                    </div>
                </v-col>

                <v-col cols="12" md="6" lg="8" v-else>
                    <span>No se han encontrado roles</span>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <div class="list-title">Añadir rol</div>
                    <div class="form-add-rol">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form_rol.name_rol"
                                    placeholder="Nombre nuevo rol"
                                    class="text-field-secondary"
                                    :error=variableIsDefined(form_error.name_rol)
                                    :class="{'input-error' :form_error.name_rol}"
                                    bg-color="white"
                                    color="transparent"
                                    hide-details
                                ></v-text-field>
                                <span class="input-error msg-error"
                                      v-if="form_error.name_rol">{{ form_error.name_rol }}</span>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="form_rol.alias_rol"
                                    placeholder="Alias nuevo rol"
                                    class="text-field-secondary"
                                    :error=variableIsDefined(form_error.alias_rol)
                                    :class="{'input-error' :form_error.alias_rol}"
                                    bg-color="white"
                                    color="transparent"
                                    hide-details
                                ></v-text-field>
                                <span style="font-size:12px;text-align: right;display: block">Debe ser un valor sin espacios separados por guiones bajos</span>
                                <span class="input-error msg-error"
                                      v-if="form_error.alias_rol">{{ form_error.alias_rol }}</span>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea
                                    v-model="form_rol.description_rol"
                                    placeholder="Descripción del nuevo rol"
                                    class="text-field-secondary"
                                    :error="variableIsDefined(form_error.description_rol)"
                                    :class="{'input-error' :form_error.description_rol}"
                                    bg-color="white"
                                    color="transparent"
                                    auto-grow
                                    rows="3"
                                    hide-details
                                ></v-textarea>
                                <span class="input-error msg-error" v-if="form_error.description_rol">{{ form_error.description_rol }}</span>
                            </v-col>


                            <v-col cols="12" class="text-end">
                                <v-btn class="btn button-primary"
                                       @click="insertRol"
                                       :disabled="state_loading"
                                >
                                    Añadir
                                    <v-progress-circular
                                        v-if="state_loading"
                                        class="icons-loading ms-4"
                                        indeterminate
                                        :size="20"
                                        :width="2"
                                        color="white"
                                    ></v-progress-circular>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <template v-if="show_all_rol">
                        <div class="list-title">Detalles del Rol</div>
                        <template v-if="!show_edit">
                            <span>Selecciona un rol para ver y editar sus detalles</span>
                        </template>
                        <template v-else>
                            <v-card class="card-edit-rol">
                                <v-card-title>
                                    <span> {{ rol_selected.name }}</span>
                                </v-card-title>
                                <v-divider class="m-0"></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="description-section">
                                                <h5 class="description-label">Descripción del rol</h5>
                                                <div class="description-content">
                                                    {{ rol_selected.description_rol }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12 text-end">
                                            <v-btn class="btn button-bg-white me-2" @click="displayDialogDelete">
                                                Eliminar
                                            </v-btn>
                                            <v-btn class="btn button-bg-blue" @click="displayDialogConfirmationMessage">
                                                Editar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                    </template>
                </v-col>
            </v-row>

        </template>

        <v-dialog v-model="dialog_confirmation">
            <BaseModalTemplate
                title="¿Quieres cambiar el nombre de este rol?"
                card_extra_classes="modal-width-xs"
                body_extra_classes="content-edit-rol"
                :open-close-dialog="displayDialogConfirmationMessage"
            >
                <template #body-modal-content>
                    <v-text-field
                        placeholder="Nuevo nombre"
                        class="input-error text-field-primary"
                        :error="error_name"
                        v-model="rol_to_edit"
                        variant="underlined"
                    >
                    </v-text-field>
                </template>
                <template #actions-modal>
                    <v-row class="text-center">
                        <v-col cols="12" sm="6" class="">
                            <v-btn
                                class="btn button-bg-blue"
                                @click="updateRol"
                                :disabled="state_loading"
                            >
                                Sí, continuar
                                <v-progress-circular
                                    v-if="state_loading"
                                    class="icons-loading ms-4"
                                    indeterminate
                                    :size="20"
                                    :width="2"
                                    color="white"
                                ></v-progress-circular>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="">
                            <v-btn
                                class="btn button-bg-white"
                                @click="displayDialogConfirmationMessage"
                            >No, volver atrás
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </BaseModalTemplate>
        </v-dialog>
    </v-container>

</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {deleteRolWS, getAllRolesWS, insertRolWS, updateRolWS} from "@/utils/admin_functions"
import Loading from "@/components/Generic/Loading.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ManagerRolesView",
    methods: {variableIsDefined},
    components: {BaseModalTemplate, Loading},

    setup() {
        const route = useRoute();
        const all_roles = ref([]);
        let rol_list = ref([]);
        let rol_selected = ref({});
        let show_edit = ref(false);
        let dialog_confirmation = ref(false);
        let new_rol = ref('');
        let rol_to_edit = ref('');
        let filter_rol = ref('');
        let loading = ref(false);
        let state_loading = ref(false);
        let error_name = ref(false);
        let form_rol = ref({});
        let form_error = ref({});
        let show_all_rol = ref(false)

        onBeforeMount(async () => {
            loading.value = true;
            await hasPermission(route);
            await getAllRoles();
            initSweetAlert()
            loading.value = false;
        })

        function captureRol(rol_id, rol_name, alias, descripcion_rol) {
            rol_selected.value = {
                'id_rol': rol_id,
                'name': rol_name,
                'alias': alias,
                'description_rol': descripcion_rol,
            }

            show_edit.value = true;
        }

        function displayDialogConfirmationMessage() {
            rol_to_edit.value = rol_selected.value['name'];
            dialog_confirmation.value = !dialog_confirmation.value;
        }

        function displayDialogDelete() {
            fireSweetAlert(`¿Quiere eliminar el rol ${rol_selected.value['name']}?`, deleteRol)
        }

        async function getAllRoles() {
            let response = await getAllRolesWS()
            if (response['result']) {
                all_roles.value = response['data']
                rol_list.value = all_roles.value;
                show_all_rol.value = rol_list.value.length !== 0;
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function updateRol() {
            error_name.value = false;
            if (rol_to_edit.value.trim() !== '') {
                let rol_data = {
                    'id_rol': rol_selected.value['id_rol'],
                    'name': rol_to_edit.value,
                }
                state_loading.value = true;
                let response = await updateRolWS(rol_data)
                state_loading.value = false;
                if (response['result']) {
                    showNotification('success', 'Se ha actualizado correctamente el rol');
                    rol_selected.value['name'] = rol_to_edit.value.toUpperCase();
                    await getAllRoles();
                    displayDialogConfirmationMessage();
                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                error_name.value = true;
                showNotification('error', 'El campo no puede estar vacío');
            }
        }

        async function insertRol() {
            state_loading.value = true;
            validateNewRol();

            if (Object.keys(form_error.value).length === 0) {
                let response = await insertRolWS(form_rol.value.name_rol, form_rol.value.alias_rol, form_rol.value.description_rol);
                if (response['result']) {
                    showNotification('success', 'Rol creado correctamente');
                    await getAllRoles();
                    form_rol.value = {};
                    filter_rol.value = ""
                } else {
                    showNotification('error', response['errors']);
                }
            }
            state_loading.value = false;
        }

        async function deleteRol() {
            if (variableIsDefined(rol_selected.value['id_rol'])) {
                let response = await deleteRolWS(rol_selected.value['id_rol'])

                if (response.result) {
                    showNotification('success', 'Rol eliminado correctamente');
                    await getAllRoles();
                    show_edit.value = false;
                } else {
                    showNotification('error', response.errors);
                }
            } else {
                showNotification('error', 'No se ha seleccionado ningún rol')
            }
        }

        function validateNewRol() {
            form_error.value = {};

            if (!variableIsDefined(form_rol.value.name_rol)) {
                form_error.value.name_rol = 'Campo obligatorio'
            } else {
                if (form_rol.value.name_rol.trim() === "") {
                    form_error.value.name_rol = 'Error en el nombre'
                }
            }

            if (!variableIsDefined(form_rol.value.alias_rol)) {
                form_error.value.alias_rol = 'Campo obligatorio'
            } else {
                if (form_rol.value.alias_rol.trim() === "") {
                    form_error.value.alias_rol = 'Error en el alias'
                }
            }

            if (!variableIsDefined(form_rol.value.description_rol)) {
                form_error.value.description_rol = 'Campo obligatorio';
            } else {
                if (form_rol.value.description_rol.trim() === "") {
                    form_error.value.description_rol = 'Error en la descripción';
                }
            }

        }

        function searchRol() {
            let list_rol_filtered = [];
            all_roles.value.forEach((rol) => {
                if (rol.name.toString().includes(filter_rol.value.toUpperCase())) {
                    list_rol_filtered.push(rol);
                }
            });

            rol_list.value = list_rol_filtered;
            if (rol_list.value.length === 0) {
                show_all_rol.value = false
                rol_selected.value = {}
                show_edit.value = false
            } else {
                show_all_rol.value = true
                rol_selected.value = {}
                show_edit.value = false
            }
        }

        return {
            captureRol,
            displayDialogConfirmationMessage,
            displayDialogDelete,
            updateRol,
            insertRol,
            searchRol,
            form_rol,
            form_error,
            rol_list,
            rol_selected,
            rol_to_edit,
            new_rol,
            show_edit,
            dialog_confirmation,
            filter_rol,
            loading,
            state_loading,
            error_name,
            show_all_rol,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-roles.scss";
</style>