<template>
    <v-btn variant="outlined" class="btn button-bg-blue button-action"
           @click="showLinkBox">
        Editor de cajas de enlaces
    </v-btn>


    <v-dialog v-model="display_link_box">
        <ModalLinkBoxes
            :displayDialog="showLinkBox"
            :url="data_url"
        ></ModalLinkBoxes>
    </v-dialog>
</template>

<script>
import {ref} from "vue";
import ModalLinkBoxes from "@/components/LinkBoxes/ModalLinkBoxes.vue";

export default {
    name: "LinkBoxesEditor",
    components: {ModalLinkBoxes},
    props:{
        data_url: String
    },
    setup(){
        let display_link_box = ref(false);

        function showLinkBox() {
            display_link_box.value = !display_link_box.value;
        }

        return{
            showLinkBox,
            display_link_box
        }
    }
}
</script>


<style scoped lang="scss">

</style>