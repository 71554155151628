<template>
    <v-btn
        v-for="route in route_system.slice(0, 4)"
        :key="route.path"
        :to=route.path
        class="mobile-bottom-btn"
        :class="{ 'active-link': isActiveRoute(route.path) }"
    >
        <img class="icon-sidebar" :src="route.mobile_image" :alt=route.alt height="64" width="64">
    </v-btn>

    <v-btn
        @click="dialog = true"
        class="mobile-bottom-btn"
    >
        <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/btn-mobile-menu.svg')" alt="botón abrir menú" height="64" width="64">
    </v-btn>

    <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
        scrollable
    >
        <v-card class="mobile-menu">
            <v-card-title class="mobile-menu-title">
                <v-row>
                    <v-col cols="12" class="d-flex justify-space-between">
                        <router-link class="navbar-brand" to="/" @click="openCloseMobileMenu">
                            <img src="@/assets/logos/logotipo-eduseo.ai-full-color.svg" alt="EDUSEO" width="181" height="32"
                                 class="d-inline-block align-text-top"
                                 title="Inicio"
                                 id="logo-aiseo-menu">
                        </router-link>
                        <v-btn class="btn-close-modal"  @click="openCloseMobileMenu" :ripple="false">
                            <v-icon size="x-large">mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-start">
                        <div id="logo-user">
                            <span id="icon-user">{{ user_initial }}</span>
                        </div>
                        <span class="username">{{ username }}</span>
                    </v-col>
                    <v-col cols="12" class="text-end">
                        <div class="personal-links w-100">
                            <div class="docs-btn-container" :class="{ 'active-link': this.$route.path === '/documentacion' }">
                                <router-link to="/documentacion">
                                    <img id="doc-icon" src="@/assets/icons/navbar/new-doc-icon.svg" alt="icono documentación" width="32" height="32">
                                </router-link>
                            </div>
                        </div>
                    </v-col>
                    <v-divider :thickness="1"></v-divider>
                </v-row>

            </v-card-title>

            <v-card-text class="mobile-menu-body">
                <v-row>
                    <v-col cols="12">
                        <Sidebar :is_mobile="true" :closeMobileMenu="closeMobileMenu"></Sidebar>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mobile-menu-actions">
                <v-row>
                    <v-divider :thickness="1"></v-divider>
                    <v-col cols="12">
                        <v-list class="other-options-container">
                            <router-link class="other-options-link" to="/admin" v-if="admin_access" @click="closeMobileMenu">
                                <v-list-item>
                                    <v-list-item-title class="option-item" :class="{ 'active-link': isActiveRoute('/admin') }">
                                        <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-admin.svg')" alt="icono seccion admin" height="28" width="28">
                                        <span class="other-options-text">Panel admin</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </router-link>
                            <router-link class="other-options-link" id="log-out" to="" @click="logout">
                                <v-list-item>
                                    <v-list-item-title class="option-item">
                                        <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-logout.svg')" alt="icono línea" height="28" width="28">
                                        <span class="other-options-text">Cerrar sesión</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import {logout, userHaveAllowedRol} from "@/utils/auth";
import {systemRoute} from "@/router/routes_home";
import {useRoute} from "vue-router";

export default {
    name: "MobileBottomMenu",
    methods: {logout},
    components:{
      Sidebar,
    },
    props: {
    },
    setup(){
        const store = useStore();
        const route = useRoute();
        let username = computed(() => store.getters.getUsername).value
        let user_initial = (username.substring(0, 1).charAt(0).toUpperCase());
        let dialog = ref(false)
        let route_system = ref(systemRoute())
        let admin_access = ref(false);

        onBeforeMount( () => {
            admin_access.value = userHaveAllowedRol("ROOT");
        })

        const openCloseMobileMenu = () => {
            dialog.value = !dialog.value
        }

        const closeMobileMenu = () => {
            dialog.value = false
        }

        const isActiveRoute = (routePath) => {
            return route.path === routePath;
        };

        return{
            openCloseMobileMenu,
            isActiveRoute,
            closeMobileMenu,

            admin_access,
            user_initial,
            username,
            dialog,
            route_system,
        }
    }
}
</script>


<style scoped lang="scss">

.mobile-menu {
    background: $background;

    .mobile-menu-title{
        display: flex;
        justify-content: space-between;
        margin-top: 2vh;

        .btn-close-modal {
            position: inherit;
            float: right;
            background: none;
            box-shadow: none;
            font-size: 20px;

            .v-icon {
                --v-icon-size-multiplier: 1.5;
            }
        }

        #logo-user {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            background-color: #E39756;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-right: 10px;
            padding: 1%;

            #icon-user {
                color: white;
                font-size: 1.4rem;
                font-weight: bold;
            }
        }

        .username {
            font-size: 18px;
        }
    }

    .mobile-menu-actions{
        .other-options-container{
            background: $background;

            .other-options-link{
                text-decoration: none;

                .other-options-text{
                    font-size: 16px;
                    color: $gray-darker;
                }

                .option-item{
                    padding: 0;
                    display: flex;
                    gap: 12px;
                }
            }
        }
    }
}

.active-link {
    .icon-sidebar {
        filter: $filter-sidebar-active;
    }
    .other-options-text{
        color: $blue-medium !important;
    }
}

@media(max-width: 425px) {
    .mobile-bottom-btn{
        min-width: 60px !important;
        width: unset !important;
        padding: 0 !important;
    }
}
</style>