import axios from "axios";
import {getSincrocursoToken} from "@/utils/auth";

export async function sincrocursoBackendPetition(type, url, data, petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {
    let {access_token} = await getSincrocursoToken()

    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: process.env.VUE_APP_SINCROCURSO_URL_API + url,
                data: data,
                headers: {
                    'content-type': content_type,
                    'Authorization': 'Bearer ' + access_token
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(process.env.VUE_APP_SINCROCURSO_URL_API + url,
                        data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + access_token
                            }
                        }
                    )
                case 'get':
                    return axios.get(process.env.VUE_APP_SINCROCURSO_URL_API + url + data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + access_token
                            }
                        }
                    )
            }
    }

}