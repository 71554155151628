<template>
    <template v-if="loading">
        <div class="position-loading">
            <Loading/>
        </div>
    </template>
    <template v-else>
        <v-table fixed-header height="42vh">
            <thead>
            <tr>
                <th class="text-left">
                    Nombre
                </th>
                <th class="text-left">
                    Categorías
                </th>
                <th class="text-left">
                    Selecciona categoría
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="td-general"
                v-for="item in copy_date_file"
                :key="item.name_upload"
            >
                <td>{{ item.name_upload }}</td>
                <td>
                    <v-row v-if="variableIsDefined(item.nameCategory) && item.nameCategory.length!==0"
                           v-for="name_category in item.nameCategory"
                           no-gutters>
                        <v-col>
                            <span>{{ name_category }}</span>
                        </v-col>
                    </v-row>
                    <span v-else>
                    <img :src="require('@/assets/icons/spot.svg')" alt="Añadir Categoría" class="icons-spot-false">
                    No hay categorías
                </span>
                </td>
                <td>
                    <v-btn class="btn button-primary" @click="getIdFile(item.id_upload)">AÑADIR</v-btn>
                </td>

            </tr>
            </tbody>
        </v-table>
        <v-dialog persistent
                  v-model="open_modal">
            <ModalCategoryFile
                :root_category="root_category"
                :displayModal="displayModal"
                :id_file="id_file_selected"
                :saveCategory="saveCategory"
                :date_file="copy_date_file"
            />
        </v-dialog>
    </template>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import ModalFile from "@/components/MediaValet/ModalFile.vue";
import ModalCategoryFile from "@/components/MediaValet/ModalCategoryFile.vue";
import {variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: 'TableAddFilesCategories',
    methods: {variableIsDefined},
    components: {Loading, ModalCategoryFile, ModalFile},
    props: {
        root_category: Object,
        date_file: Array,
        saveFilesCategories: Function,
        add_section: String,
        action_section: String,
    },
    setup(props) {
        let open_modal = ref(false)
        let id_file_selected = ref()
        let copy_date_file = ref([])
        let loading = ref(false)

        onBeforeMount(async () => {
            loading.value = true
            copy_date_file.value = []
            copy_date_file.value = props.date_file
            loading.value = false
        })

        function saveCategory(category_file) {
            let index = copy_date_file.value.findIndex((category) => {
                return category.id_upload === category_file.id_file
            })
            if (index !== -1) {
                copy_date_file.value[index]['nameCategory'] = category_file['name_category']
            }
            displayModal()
            props.saveFilesCategories(category_file)
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }

        function getIdFile(id_file) {
            id_file_selected.value = id_file
            displayModal()
        }

        return {
            displayModal,
            saveCategory,
            getIdFile,

            open_modal,
            id_file_selected,
            copy_date_file,
            loading,
        }
    }
}
</script>


<style scoped lang="scss">
.position-loading {
    height: 40vh;
    display: flex;
}

.td-general {
    height: 10vh;

    .td-img-video {
        width: 10vw;
        text-align: center;
    }

    .icon-video {
        filter: $filter-primary-color-1;
        width: auto;
        max-height: 7vh;
    }

    .img-file {
        width: auto;
        max-height: 7vh;
    }

    .icons-spot-false {
        width: 8px;
        margin-right: 5px;
        filter: brightness(0) saturate(100%) invert(34%) sepia(86%) saturate(2667%) hue-rotate(331deg) brightness(96%) contrast(102%);
    }
}


@media (max-width: 800px) {
    .td-general {
        .icon-video {
            width: auto;
            max-height: 5vh;
        }

        .img-file {
            width: auto;
            max-height: 5vh;
        }
    }
}


</style>