import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

export async function insertExternalUserWS(user_data){
    let params_token = {
        usuario_logueado: store.getters.getUsername,
        userName: user_data.username,
        firstName: user_data.firstname,
        lastName: user_data.secondname,
        email: user_data.email,
    }

    let params = {
        userName: user_data.username,
        firstName: user_data.firstname,
        lastName: user_data.secondname,
        email: user_data.email,
    }

    if (variableIsDefined(user_data.linkedin)){
        params['linkedin'] = user_data.linkedin
    }

    try {
        return await aiseoBackendPetition('POST', 'users/insertExternalUser', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/insertExternalUse')
    }
}


export async function updateExternalUserWS(user_data){
    let params_token = {
        usuario_logueado: store.getters.getUsername,
        userName: user_data.username
    }

    let params = {
        userName: user_data.username,
        firstName: user_data.firstname,
        lastName: user_data.secondname,
        email: user_data.email,
    }
    if (variableIsDefined(user_data.linkedin)){
        params['linkedin'] = user_data.linkedin
    }

    try {
        return await aiseoBackendPetition('POST', 'users/updateExternalUser', params, params_token)
    }catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/updateExternalUser')
    }
}

export async function removeExternalUserWS(user_data){
    let params_token = {
        usuario_logueado: store.getters.getUsername,
        userName: user_data.username,
    };

    let params = {
        userName: user_data.username
    }

    try {
        return await aiseoBackendPetition('POST', 'users/removeExternalUser', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '')
    }
}

export async function getExternalUsersWS(){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getExternalUsers', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getExternalUsers')
    }
}