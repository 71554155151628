import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";


export async function getAllStatusCommentsWS(){
    let params_token = {
        "usuario_logueado": store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/getAllStatusComments', {}, params_token);
    }
    catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/getAllStatusComments');
    }
}




export async function getStatusCommentWS(id_status){
    let params = {
        'id_status': id_status,
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/getStatusComment', params, params_token)

    }catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/getStatusComment');
    }
}
export async function insertStatusCommentWS(status_data){
    let params = {
        'status_data': status_data,
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/insertStatusComment', params, params_token)

    }catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/insertStatusComment');
    }


}
export async function updateStatusCommentWS(id_status, status_data){
    let params = {
        'id_status': id_status,
        'status_data': status_data,
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/updateStatusComment', params, params_token)

    }catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/updateStatusComment');
    }
}

