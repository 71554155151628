import store from "@/store";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";

export async function getNegativeWordsWS(){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
    }


    try {
        return await aiseoBackendPetition('POST', 'seoPanel/getNegativeWords', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/getNegativeWords');
    }
}

export async function addWordsWS(new_word){
    let words_data = [{'negative_word':new_word}]

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/createNegativeWords', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/createNegativeWords');
    }
}


export async function editWordsWS(changed_word,id_word){
    let words_data = [{
        'negative_word':changed_word,
        'id_word' : id_word,
    }]

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/updateNegativeWords', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/updateNegativeWords');
    }
}

export async function deleteWordsWS(id_word){
    let words_data = [{
        'id_word' : id_word,
    }]

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
        'wordsData': JSON.stringify(words_data),
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/removeNegativeWords', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/removeNegativeWords');
    }
}


export async function checkNegativeWordsWS(text){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
        'texto': text,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/checkNegativeWords', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/checkNegativeWords');
    }
}