import {aicoreBackendPetition} from "@/utils/aicore_petition";
import store from "@/store";
import {getAicoreToken, showNotification, variableIsDefined} from "@/utils/aux_functions";

export async function chatgptSEOHTML(url, keywords, text_length, num_sections) {
    try {
        let params = {
            'user': store.getters.getUsername,
            'url': url,
            'keywords': keywords,
            'numero_de_palabras': text_length,
            'numero_de_secciones': num_sections,
            'idioma': store.getters.getLanguageISO,
            'id_portal': store.getters.getPortalID,
        }

        return await aicoreBackendPetition('POST', '/LLMs/chatGPT_Contenido_SEO_HTML', params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/chatGPT_Contenido_SEO_HTML');
    }
}

export async function chatgptInitCourseWS(topic, keywords, num_of_words = 1000) {
    try {
        let params = {
            'user': store.getters.getUsername,
            'topic': topic,
            'keywords': keywords,
            'num_of_words': num_of_words
        }

        return await aicoreBackendPetition('POST', '/LLMs/chatgpt_init_course', params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/chatgpt_init_course');
    }
}

export async function chatgptContinueCourseWS(aditional_params, paragraph) {
    let params;
    let token;

    if (aditional_params['message_history'].length > 0) {
        params = {
            'user': store.getters.getUsername,
            'paragraph': paragraph,
            'pre_initialized': false,
            'original_content': "",
            'topic': "",
            'keywords': "",
            'message_history': aditional_params['message_history'],
        }
    } else {
        params = {
            'user': store.getters.getUsername,
            'pre_initialized': aditional_params['pre_initialized'],
            'topic': aditional_params['topic'],
            'keywords': aditional_params['keywords'],
            'original_content': aditional_params['original_content'],
            'paragraph': paragraph,
            'message_history': [],
        }
    }

    try {
        const response = await getAicoreToken({})
        token = response['access_token']

        return await $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: process.env.VUE_APP_AICORE_URL_API + 'LLMs/chatgpt_continue_course',
            data: JSON.stringify(params),
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/chatgpt_continue_course');
    }
}

export async function chatgptGenerateReviewWS(course, language, quantity = 10) {

    let params = {
        'user': store.getters.getUsername,
        'curso': course,
        'idioma': language,
    }

    if (quantity !== 10) params['cantidad'] = quantity;

    try {
        return await aicoreBackendPetition('POST', '/LLMs/chatGPT_Generacion_comentarios', params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/chatGPT_Generacion_comentarios');
    }
}

export async function chatgptIncreaseHTMLWS(html_document, html_petition, required_words) {
    let params = {
        'user': store.getters.getUsername,
        'html_document': html_document,
        'html_petition': html_petition,
        'palabras_requeridas': required_words,
    }

    try {
        return await aicoreBackendPetition('POST', '/LLMs/aumento_contenido_HTML', params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/LLMs/aumento_contenido_HTML');
    }
}