import {createStore} from 'vuex'
const initial_state = {
    username: '',
    user_profile_pic: '',
    group: '',
    portal_id: 0,
    language_iso: '',
    language_id: 0,
    url_empresa: '',
    url_min: '',
    stored_portals: [],
    user_roles: [],
    roles: [],
    stored_languages: [],
    type_url: 'Cursos',
    children_menu: false,
    show_bread_crumb: true,
    selected_portals: [],
    selected_institutions: [],
    show_portal_institution: false,
}

export default createStore({
    state: {
        ...initial_state,
    },

    getters: {
        getUsername(state) {
            let username = state.username
            if (username === "" && localStorage.getItem('username')) {
                try {
                    username = atob(localStorage.getItem('username')).toLowerCase()
                } catch (err) {
                    localStorage.clear();
                }

            }
            return username;
        },

        getUserPic(state) {
            let user_pic = state.user_profile_pic

            if (user_pic === "") {
                user_pic = localStorage.getItem('user_pic')
            }

            return user_pic;
        },

        getPortalID(state) {
            let portal_id = state.portal_id
            if (portal_id === 0) {
                portal_id = localStorage.getItem('portal_id')
            }

            return portal_id;
        },

        getURLEmpresa(state) {
            let url_empresa = state.url_empresa
            if (url_empresa === "") {
                url_empresa = localStorage.getItem('url_empresa')
            }
            return url_empresa;
        },

        getURLMin(state) {
            let url_min = state.url_min
            if (url_min === "") {
                url_min = localStorage.getItem('url_min')
            }
            return url_min;
        },

        getUserGroup(state) {
            let group = state.group
            if (group === "" && localStorage.getItem('group')) {
                try {
                    group = atob(localStorage.getItem('group'))
                } catch (err) {
                    localStorage.clear();
                }
            }
            return group;
        },

        getLanguageISO(state) {
            let language_iso = state.language_iso
            if (language_iso === "") {
                language_iso = localStorage.getItem('language_iso')
            }

            return language_iso;
        },

        getLanguageID(state) {
            let language_id = state.language_id
            if (language_id === "") {
                language_id = localStorage.getItem('language_id')
            }

            return language_id;
        },

        getStoredPortals(state) {
            let portals = state.stored_portals
            if (portals.length === 0) {
                portals = JSON.parse(localStorage.getItem('portals'))
            }
            return portals;
        },

        getStoredLanguages(state) {
            let languages = state.stored_languages
            if (languages.length === 0) {
                languages = JSON.parse(localStorage.getItem('languages'))
            }
            return languages;
        },


        getRolesUser(state) {
            let checked_rol = state.user_roles;

            if (checked_rol.length === 0) {
                checked_rol = JSON.parse(localStorage.getItem('user_roles'))
            }

            return checked_rol;
        },

        getRoles(state) {
            let roles = state.roles;

            if (roles.length === 0) {
                roles = JSON.parse(localStorage.getItem('roles'))
            }

            return roles;
        },
        getTypeUrl(state){
            let type_url = state.type_url
            if (type_url === ""){
                type_url = localStorage.getItem('type_url')
            }
            return type_url;
        },
        getChildrenMenu(state){
            let children_menu = state.children_menu
            if (children_menu === ""){
                children_menu = localStorage.getItem('children_menu')
            }
            return children_menu;
        },
        getShowBreadCrumb(state){
            let show_bread_crumb = state.show_bread_crumb
            if (show_bread_crumb === ""){
                show_bread_crumb = localStorage.getItem('show_bread_crumb')
            }
            return show_bread_crumb;
        },
        getSelectedPortals(state) {
            let selected_portals = state.selected_portals

            if (selected_portals.length === 0){
                selected_portals = JSON.parse(localStorage.getItem('selected_portals'))
            }

            return selected_portals || [];
        },
        getSelectedInstitutions(state) {
            let selected_institutions = state.selected_institutions

            if (selected_institutions.length === 0){
                selected_institutions = JSON.parse(localStorage.getItem('selected_institutions'))
            }

            return selected_institutions || [];
        },
        getShowPortalInstitution(state){
            let show_portal_institution = state.show_portal_institution
            if (show_portal_institution === ""){
                show_portal_institution = localStorage.getItem('show_portal_institution')
            }
            return show_portal_institution;
        },

    },


    mutations: {
        setUser(state, payload) {
            state.username = payload;
        },

        setUserPic(state, payload) {
            state.user_profile_pic = payload;
        },

        setPortalId(state, payload) {
            state.portal_id = payload

        },

        setURLEmpresa(state, payload) {
            state.url_empresa = payload
        },

        setURLMin(state, payload) {
            state.url_min = payload
        },

        setGroup(state, payload) {
            state.group = payload
        },
        setLanguageISO(state, payload) {
            state.language_iso = payload
        },

        setLanguageID(state, payload) {
            state.language_id = payload
        },

        setStoredPortals(state, payload) {
            state.stored_portals = payload
        },

        setStoredLanguages(state, payload) {
            state.stored_languages = payload
        },

        setRolesUser(state, payload) {
            state.user_roles = payload;
        },

        setRoles(state, payload) {
            state.roles = payload
        },
        setTypeUrl(state, payload) {
            state.type_url = payload
        },
        setChildrenMenu(state, payload) {
            state.children_menu = payload
        },
        setShowBreadCrumb(state, payload) {
            state.show_bread_crumb = payload
        },
        setSelectedPortals(state, payload) {
            state.selected_portals = payload
        },
        setSelectedInstitutions(state, payload) {
            state.selected_institutions = payload
        },
        setShowPortalInstitution(state, payload) {
            state.show_portal_institution = payload
        },

        resetStore(state) {
            Object.assign(state, initial_state)
        }

    },
    actions: {
        resetAll({commit}){
            commit('resetStore');
        }
    },
    modules: {}
})
