<template>
    <v-card variant="outlined" class="card-create card-modal">
        <v-card-text class="content-modal personalize-scroll">
            <v-row>
                <v-col v-if="image_ranking_select">
                    <v-img class="img-select" :src="image_ranking_select"></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="position_error">
                    <v-btn @click="displayModalImgRanking" class="btn button-bg-white">Subir imagen
                    </v-btn>
                    <p v-if="not_select_img" class="input-error msg-error">Debes seleccionar una imagen para el ranking</p>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="text-card">
                    <v-textarea v-model="form_data.text"
                                variant="underlined"
                                class="input-error text-area-secondary"
                                rows="4"
                                no-resize
                                label="Descripción Ranking"
                                hide-details
                                :class="{error :form_error.text}"
                    ></v-textarea>
                    <span v-if="form_error.text" class="input-error msg-error">{{
                            form_error.text
                        }}</span>
                    </div>
                </v-col>

            </v-row>

        </v-card-text>
        <v-card-actions>
            <v-row class="row-btn-save-insert">
                <v-col>
                    <v-btn @click="validationRanking" :disabled="loading_save" class="btn button-primary">Guardar
                        <v-progress-circular
                            v-if="loading_save"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                            class="ms-2"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
    <v-dialog class="" v-model="open_img_ranking" persistent>
        <ModalImageRanking
            :displayModalImgRanking="displayModalImgRanking"
            title_form="Selecciona una imagen para el ranking"
            :id_url=id_url
            :addSelection="addSelection"
        />
    </v-dialog>
</template>


<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup";
import {getAllRankingWS} from "@/utils/ranking";
import ModalImageRanking from "@/components/Ranking/ModalImageRanking.vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "InsertRanking",
    components: {ModalImageRanking},
    props: {
        saveRanking: Function,
        loading_save: Boolean,
        id_url: String,

    },
    setup(props) {
        let form_data = ref({})
        let form_error = ref({})
        let open_img_ranking = ref(false);
        let id_ranking_select;
        let image_ranking_select = ref()
        let not_select_img = ref(false)

        onBeforeMount(async () => {
            form_data.value = {}
            form_error.value = {}
        })

        function displayModalImgRanking() {
            open_img_ranking.value = !open_img_ranking.value
        }

        async function validationRanking() {
            not_select_img.value=false
            form_error.value = {}
            const schemaForm = Yup.object().shape({
                text: Yup.string().required(true),
            })
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = 'El campo no puede estar vacío';
                })
            }
            if (variableIsDefined(id_ranking_select)) {
                if (Object.entries(form_error.value).length === 0){
                    props.saveRanking('creado', id_ranking_select, form_data.value.text)
                }
            } else {
                not_select_img.value = true
            }
        }

        async function addSelection(newSelection, img_ranking) {
            not_select_img.value=false
            displayModalImgRanking()
            id_ranking_select = newSelection
            image_ranking_select.value = img_ranking
        }


        return {
            validationRanking,
            displayModalImgRanking,
            addSelection,
            form_data,
            form_error,
            open_img_ranking,
            image_ranking_select,
            not_select_img,
        }
    }

}

</script>
<style scoped lang="scss">
.card-create {
    background: $white;
    border: 2px solid $blue-medium;
    border-radius: 10px;
    padding: 10px;


    .img-select {
        width: 6vw;
        height: auto;
    }

    .row-btn-save-insert {
        text-align: end;
    }
    .position_error{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
@media (max-width: 600px) {
    .card-create{
        max-height: 45vh;
        .content-modal{
            max-height: 35vh;
            height: auto!important;
        }
    }
    .img-select {
        width: 30vw!important;
        height: auto;
    }
}
</style>