import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {escicAPIBackendPetition} from "@/utils/escic_petition";




export async function getAllTypeformWS(page = 1, rows_per_page = 20, filters, order_direction = "", order_by = "") {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {
        name: filters['typeform_name'],
        typeform_id: filters['typeform_id'],
        page_size: rows_per_page,
        order_direction: order_direction,
        order_by: order_by,
    }

    try {
        return await escicAPIBackendPetition('POST', 'typeform/getAll?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'typeform/getAll');
    }
}

export async function createTypeformWS(typeform_data) {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }
    let params = {
        id: typeform_data.typeform_id,
        name: typeform_data.name,
        course: typeform_data.course,
        campanya: typeform_data.campanya,
        remitente: typeform_data.remitente,
        cupon_zoho: typeform_data.enviar_zoho,
        id_canal: typeform_data.id_canal,
    }

    try {
        return await escicAPIBackendPetition('POST', 'typeform/create', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'typeform/create');
    }
}

export async function editTypeformWS(typeform_data) {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {
        id: typeform_data.typeform_id,
        name: typeform_data.name,
        course: typeform_data.course,
        campanya: typeform_data.campanya,
        remitente: typeform_data.remitente,
        cupon_zoho: typeform_data.enviar_zoho,
        id_canal: typeform_data.id_canal,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'typeform/update', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'typeform/update');
    }

}


export async function getAvailableCoursesWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'Course/getAvailableCoursesTypeform', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Course/getAvailableCourses');
    }
    
}

export async function getAvailableDispatchersWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'Remitentes/getAvailableRemitenteTypeform', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Remitentes/getAvailableRemitenteTypeform');
    }
    
}

export async function getAvailableCampaignsWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'Campaign/getAvailableCampanyaTypeform', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Campaign/getAvailableCampanyaTypeform');
    }
    
}

export async function getAvailableSendersZohoWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'TypeformFormulary/getAvailableSendersZoho', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'TypeformFormulary/getAvailableSendersZoho');
    }
    
}

export async function getAvailableChannelsWS() {
    let params = {
        only_active: 1,
    }
    let params_token = {
        logged_user: store.getters.getUsername,
    }
    
    try {
        return await escicAPIBackendPetition('POST', 'channel/getAll', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'channel/getAll');
    }
    
}