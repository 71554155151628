<template>
    <BaseModalTemplate
        :title="`Portales vinculados a: ${ teacher_name }`"
        :open-close-dialog="displayDialog"
        :loading="loading"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <v-table
                fixed-header
                height="300px"
            >
                <thead>
                <tr>
                    <th class="text-left">
                        ID del portal
                    </th>
                    <th class="text-left">
                        Nombre del portal
                    </th>
                    <th class="text-left">
                        Idioma
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="!loading">
                    <tr
                        v-for="portal in portals_assigned"
                        :key="portal.id_portal"
                    >
                        <td>{{ portal.id_portal }}</td>
                        <td>{{ portal.nombre }}</td>
                        <td>{{ portal.idioma }}</td>
                    </tr>
                </template>
                <template v-else>
                    Cargando...
                </template>
                </tbody>
            </v-table>
        </template>
    </BaseModalTemplate>
</template>

<script>

import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "TeachersPortalsModal",
    components: {BaseModalTemplate},
    props: {
        displayDialog: Function,
        loading: Boolean,
        portals_assigned: Array,
        teacher_name: String,
    }
}

</script>

<style scoped lang="scss">

</style>