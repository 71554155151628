<template>
    <v-card variant="outlined" class="card-image" :class="{ 'icon-disabled': disabled_btn_cancel }">
        <v-card-title>
            <slot name="title-imagen-card">
            <v-row>
                <v-col :cols="show_button_cancel ? 9: 12" class="title-column">{{ image.title }}</v-col>
                <v-col v-if="show_button_cancel" cols="3" class="text-end" >
                    <v-icon @click="discardElement(image)" class="icon-close" >mdi-close</v-icon>
                </v-col>
            </v-row>
            </slot>
        </v-card-title>
        <v-card-text class="container-portals-image card-text-image">
            <slot name="text-imagen-card">
            <v-img class="image" :src="`${image.img_url}?reset=${random}`"></v-img>
            </slot>
        </v-card-text>
        <v-card-actions v-if="show_btns" class="image_options">
            <slot name="actions-imagen-card">
            <v-row >
                <v-col cols="12" class="btn-col">
                    <v-btn class="btn-edit" @click="selectImage(image,'edit')"></v-btn>
                    <v-btn class="btn-delete"
                           @click="selectImage(image,'delete')"></v-btn>
                </v-col>
            </v-row>
            </slot>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "ImageCard",
    props: {
        image : Object,
        selectImage : Function,
        displayDialogConfirmationMessage : Function,
        show_btns: Boolean,
        disabled_btn_cancel: Boolean,
        random: Number,

        /*Props para eliminar de la lista un elemento
        en asignación masiva de logos y diplomas*/
        show_button_cancel: Boolean,
        discardElement: Function,
    }
}
</script>

<style lang="scss">
@import "@/styles/views/images-library.scss";
</style>
