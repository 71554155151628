<template>
    <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <v-file-input label="Arrastra aquí tus archivos o pulsa para subir imagen"
                      v-model="file"
                      variant="underlined"
                      :loading="uploading"
                      :rules="file_rules"
                      show-size
                      accept="image/webp"
                      prepend-icon="mdi-camera"
                      type="file"
                      chips=""
                      @change="uploadBySelection"
                      multiple
                      class="text-field-primary field-with-label">
        </v-file-input>

        <div>
                        <span v-if="file_error" class="input-error msg-error">
                            No se han subido los archivos que no son de tipo webp
                        </span>
            <br>
            <ol id="source-img-uploaded" v-if="url_files_cdn.length > 0" v-for="url in url_files_cdn">
                <li><a :href="url" target="_blank">{{ url }}</a></li>
            </ol>
        </div>
    </div>

</template>

<script>
import {ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {setFileToOriginWS} from "@/utils/editor_course_blog";

export default {
    name: "FileInputDragDrop",
    setup() {
        let file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']
        let file_error = ref(false);
        let file = ref();
        let uploading = ref(false);
        let url_files_cdn = ref([]);

        function dragover(event) {
            event.preventDefault();

            event.currentTarget.classList.add('drop-zone');
        }

        function dragleave(event) {
            event.currentTarget.classList.remove('drop-zone');
        }

        async function drop(event) {
            event.preventDefault();
            event.currentTarget.classList.remove('drop-zone');

            file_error.value = false;
            if (event.dataTransfer.items) {
                let items = event.dataTransfer.items;

                await Promise.all(Object.keys(items).map(async (index) => {
                    let item = items[index];

                    if (item.kind === "file" && item.type === "image/webp") {
                        variableIsDefined(file.value) ? file.value.splice(0, 0, item.getAsFile()) : file.value = [item.getAsFile()];
                        await uploadImg();
                    } else {
                        file_error.value = true;
                    }
                }))
            } else {
                for (const file of [...event.dataTransfer.files]) {
                    if (file.type === "image/webp") {
                        variableIsDefined(file.value) ? file.value.splice(0, 0, file) : file.value = [file];
                        await uploadImg();
                    } else {
                        file_error.value = true;
                    }
                }
            }
        }

        async function uploadBySelection(){
            let files_copy = _.cloneDeep(file.value)
            for (const file_copy of files_copy){
                file.value = [file_copy]
                await uploadImg()
            }
        }

        const uploadImg = async () => {
            uploading.value = true

            const response = await setFileToOriginWS(file.value, 'image/webp')
            uploading.value = false
            if (response.data.result) {
                showNotification('success', 'Imagen subida correctamente')
                url_files_cdn.value.push(response.data.data)
            } else {
                showNotification('error', response.data.errors)
            }

        }


        return {
            uploadBySelection,
            uploadImg,
            dragover,
            dragleave,
            drop,
            file,
            file_rules,
            file_error,
            uploading,
            url_files_cdn
        }
    }
}
</script>

<style lang="scss">
#source-img-uploaded {
    li {
        list-style: none;
        margin-bottom: 12px;

        a {
            color: $blue-medium;

            &:hover {
                color: $primary-color-2;
            }

        }
    }
}
</style>