<template>
    <BaseModalTemplate
        card_extra_classes="modal-width-xs"
        :open-close-dialog="showDialog"
        :title="title"
        :loading="loading"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                        <span class="input-title mb-3">
                                   <u> Redirección actual</u>
                        </span>
                </v-col>
                <v-col v-if="action === 'edit'" cols="12" class="pt-0">
                        <span class="mb-3 redirection-data">
                            Código HTTP : {{ selected_redirection.cod_http }} <br>
                        </span>
                    <span class="mb-3 redirection-data" v-if="selected_redirection.cod_http === 301">
                            URL :  {{ selected_redirection.url }}
                        </span>
                </v-col>
                <v-col class="pt-0" v-else>
                        <span class="mb-3 redirection-data">
                            No hay redirección creada <br>
                        </span>
                </v-col>
                <v-col v-if="action === 'edit'" cols="12">
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Eliminar"
                            v-model="delete_redirection"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                            @update:modelValue="changeDelete()"
                        ></v-checkbox>
                    </div>
                </v-col>
                <v-col cols="12" v-if="!delete_redirection">
                        <span class="input-title">
                            <u>{{ title_action }}</u></span>
                    <span class="input-title">
                                    Tipo de redirección</span>
                    <v-select
                        placeholder="Elija tipo de redirección"
                        class="input-error select-primary"
                        :error="form_error.redirection_type"
                        :items="redirection_options"
                        v-model="form_data.redirection_type"
                        bg-color="white"
                        variant="underlined"
                        hide-details
                        no-data-text="No hay datos disponibles"
                    >
                    </v-select>
                    <span v-if="form_error.redirection_type"
                          class="input-error msg-error">El selector no puede estar vacío</span>
                </v-col>
                <v-col v-if="form_data.redirection_type === 301 && !delete_redirection" cols="12">
                        <span class="input-title">
                                    URL destino
                        </span>

                    <v-text-field
                        v-model.trim="form_data.destiny_url"
                        :error="form_error.destiny_url"
                        class="input-error text-field-secondary"
                        placeholder="URL destino"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.destiny_url"
                          class="input-error msg-error">{{ error_url }}</span>
                </v-col>
                <v-col cols="12" v-if="delete_redirection">
                    <p class="input-title">
                        Al eliminar la redirección se reactivará la URL
                    </p>
                </v-col>
            </v-row>

            <v-row v-if="!delete_redirection && form_data.redirection_type === 301">
                <v-col v-if="can_check_force" class="align-self-center" cols="6">
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Aplicar incluso si no existe la URL"
                            v-model="is_selected_force"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
                <v-col class="align-self-center" cols="6">
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Redirección a URL absoluta"
                            v-model="is_url_absolute"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                        >
                        </v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12">
                    <v-btn
                        variant="outlined"
                        class="btn button-bg-blue"
                        @click="sendForm"
                        :loading="loading_btn"
                        v-if="!delete_redirection"
                    >
                        {{ btn_action }}
                    </v-btn>
                    <v-btn
                        variant="outlined"
                        class="btn button-bg-blue"
                        @click="deleteRedirection"
                        :loading="loading_btn"
                        v-else
                    >
                        Eliminar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {isURLAbsolute, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getPortalRedirectionsWS,
    redirectURLWS,
    removeRedirectURLWS,
    updateRedirectURLWS
} from "@/utils/url_redirections";
import {useStore} from "vuex";
import {userHaveAllowedRol} from "@/utils/auth";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {checkURLWS} from "@/utils/editor_course_blog";

export default {
    name: "ModalUpdateDeleteRedirection",
    components: {BaseModalTemplate},
    props: {
        showDialog: Function,
        origin_url: String,
        redirection_general: Boolean,
    },
    setup(props) {
        const store = useStore();

        const title = ref('Crear redirección');
        const title_action = ref('');
        const title_before = ref();
        const btn_action = ref('Guardar')

        const action = ref('create');
        const delete_redirection = ref(false);
        const loading = ref(false);
        const loading_btn = ref(false);

        const redirection_options = ref([301, 410]);

        const selected_redirection = ref({});
        const form_data = ref({});
        const form_error = ref({});
        const error_url = ref();

        const can_check_force = ref(false)
        const is_selected_force = ref(false)

        let is_url_absolute = ref(false)

        onBeforeMount(async () => {
            loading.value = true;
            await getRedirectionURL()
            can_check_force.value = userHaveAllowedRol("CAN_FORCE_REDIRECT_URLS")
            loading.value = false;

        });

        /*
        *
        * COMPRUEBA SI LA URL CONTIENE UNA REDIRECCIÓN
        *
        * */
        async function getRedirectionURL() {
            let response = await getPortalRedirectionsWS(1, 1, props.origin_url);

            if (response['result']) {
                if (Object.keys(response.data.data).length > 0) {
                    title.value = 'Actualizar redirección';
                    action.value = 'edit';
                    title_action.value = 'Actualizar redirección';
                    btn_action.value = 'ACTUALIZAR';

                    selected_redirection.value.cod_http = form_data.value.redirection_type = response['data']['data'][0].codigo_http;
                    selected_redirection.value.url = form_data.value.destiny_url = response['data']['data'][0].url_destino;
                    form_data.value.id_redirection = response['data']['data'][0].id_redireccion;
                }
            } else {
                showNotification('error', response['errors']);
            }
        }

        /*
        *
        * ELIMINA LA REDIRECCIÓN DE UNA URL
        *
        * */
        async function deleteRedirection() {
            loading_btn.value = true;
            let id_to_send = []


            if (!Array.isArray(form_data.value.id_redirection)){
                    id_to_send.push(form_data.value.id_redirection)
            }else{
                id_to_send = form_data.value.id_redirection
            }

            let response = await removeRedirectURLWS(id_to_send);

            if (response['result']) {
                showNotification('success', response['data']['message']);
                props.showDialog(props.redirection_general);
            } else {
                showNotification('error', response['errors']);
            }

            loading_btn.value = false;
        }

        /*
        *
        * CAMBIA LA PANTALLA ENTRE
        * CREACIÓN/EDICIÓN Y ELIMINACIÓN
        *
        * */
        function changeDelete() {
            if (delete_redirection.value) {
                title_before.value = title.value;
                title.value = 'Eliminar redirección';
                form_error.value = {};
                form_data.value.redirection_type = selected_redirection.value.cod_http;
                form_data.value.destiny_url = selected_redirection.value.url;
            } else {
                title.value = title_before.value;
            }
        }

        /*
        *
        *ENVIA LOS DATOS DEL FORMULARIO:
        *   TIPO DE REDIRECCIÓN
        *   URL (SI EL TIPO DE REDIRECCIÓN ES 301)
        *
        *DEPENDIENDO DE LA ACCIÓN SELECCIONADA
        *CREA UNA REDIRECCIÓN O LA ACTUALIZA
        *
        * */
        async function sendForm() {
            if (await validateForm()) {
                loading_btn.value = true;

                let redirection_data = {
                    'id_redireccion': form_data.value.id_redirection,
                    'redirection_type': parseInt(form_data.value.redirection_type),
                }

                if (form_data.value.redirection_type === 301) {
                    redirection_data['redirection_url'] = form_data.value.destiny_url;
                }

                redirection_data['absolute_url'] = is_url_absolute.value ? 1 : 0

                if (is_url_absolute){//Si la url es absoluta, hay que pasar el force siempre a true
                    redirection_data['force'] = 1
                }else{
                    redirection_data['force'] = is_selected_force.value ? 1 : 0
                }

                let response;

                switch (action.value) {
                    case 'create':
                        response = await redirectURLWS(redirection_data, props.origin_url);
                        break;
                    case 'edit' :
                        response = await updateRedirectURLWS(redirection_data);
                        break;
                }

                if (response['result']) {
                    showNotification('success', response['data']['message']);
                    if (props.redirection_general) {
                        props.showDialog(props.redirection_general);
                    } else {
                        props.showDialog();
                    }

                } else {
                    showNotification('error', response['errors']);
                }

                loading_btn.value = false;
            }
        }

        /*
        *
        * COMPRUEBA QUE HAYA UN TIPO DE REDIRECCIÓN SELECCIONADA
        * Y SI ES EL TIPO 301 QUE ADEMÁS HAYA UNA URL DEFINIDA
        *
        * */
        async function validateForm() {
            let correct = true;
            form_error.value = {};

            if (!variableIsDefined(form_data.value.redirection_type)) {
                correct = false;
                form_error.value.redirection_type = true;
            } else if (form_data.value.redirection_type === selected_redirection.value.cod_http && selected_redirection.value.cod_http === 410) {
                correct = false;
                showNotification('info', 'No se han modificado los datos');
            }

            if (correct && form_data.value.redirection_type === 301) {

                let destiny_url = form_data.value.destiny_url;

                if (variableIsDefined(destiny_url)) {
                    if (selected_redirection.value.cod_http === 301) {
                        if (destiny_url === selected_redirection.value.url.trim()) {
                            correct = false;
                            showNotification('info', 'No se han modificado los datos');
                        }
                        if (correct) {
                            if (is_url_absolute.value) {  //Si es absoluta
                                correct = isURLAbsolute(destiny_url)
                                if (!correct){
                                    form_error.value.destiny_url = true;
                                    error_url.value = 'La url de destino NO se corresponde con una URL absoluta.'
                                }
                            } else { //Si es una url relativa
                                if (is_selected_force.value) { //Si el force está marcado
                                    //No debe tener estructura de URL absoluta y no tiene que existir en aiseo
                                    correct = !isURLAbsolute(destiny_url)
                                    if (!correct){
                                        form_error.value.destiny_url = true;
                                        error_url.value = 'La url de destino se corresponde con una URL absoluta.'
                                    }
                                } else {//Si el force NO está marcado
                                    //Tiene que existir en aiseo (en esta comprobación ya se mira que no tenga estructura de url absoluta)
                                    correct = await checkURL('destiny_url');
                                    if (!correct){
                                        form_error.value.destiny_url = true;
                                        error_url.value = 'La url de destino no existe en AISEO.'
                                    }
                                }
                            }
                        }
                    }
                } else {
                    error_url.value = 'La url de destino no puede estar vacía';
                    correct = false;
                    form_error.value.destiny_url = true;
                }

            }

            return correct;
        }

        /*
        *
        * COMPRUEBA QUE UNA URL ESTE
        * REGISTRADA EN EL SISTEMA
        *
        * */
        async function checkURL() {
            let response = await checkURLWS(store.getters.getPortalID, store.getters.getLanguageID, form_data.value.destiny_url, store.getters.getPortalID, store.getters.getLanguageID);

            if (response.result) {
                if (response['data'].length === 0) {
                    form_error.value.destiny_url = true;
                    error_url.value = 'La url de redirección debe estar registrada en el sistema';
                    return false
                } else {
                    return true;
                }
            } else {
                showNotification('error', 'Se ha producido un error al comprobar la url')
            }
        }

        return {
            variableIsDefined,
            deleteRedirection,
            changeDelete,
            sendForm,

            is_url_absolute,
            action,
            btn_action,
            can_check_force,
            delete_redirection,
            error_url,
            form_data,
            form_error,
            is_selected_force,
            loading,
            loading_btn,
            redirection_options,
            selected_redirection,
            title,
            title_action,
        }
    },
}
</script>

<style lang="scss">
.redirection-data {
    font-size: 16px;
    color: $gray-medium-bold;
}
</style>