import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getAllElementSEOCopyWS(page = 1, pageSize, {origin_url, destiny_url, state, tries, orderBy, orderDirection}) {
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    let params = {
        pageSize,
        originURL: origin_url ? origin_url : null,
        destinyURL: destiny_url || null,
        estadoID: state || null,
        reintentos: tries || null,
        orderBy: orderBy || null,
        orderDirection: orderDirection || null
    }

    try {
        return await aiseoBackendPetition('POST', 'queueCp/getAllElementsSEOCopy?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueCp/getAllElementsSEOCopy');
    }
}

export async function getCatalogQueueStatesWS() {
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'queueCp/getCatalogQueueStates', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueCp/getCatalogQueueStates');
    }
}

export async function getLogElementSEOCopyWS(id){
    let params_token= {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'queueCp/getLogElementSEOCopy/'+id, {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueCp/getLogElementSEOCopy/');
    }

}

export async function changeStateElementSEOCopyWS (id_url, id_state){
    let params_token= {
        usuario_logueado: store.getters.getUsername,
    }
    let params = {
        id_state: id_state
    }
    try {
        return await aiseoBackendPetition('POST', 'queueCp/changeStateElementSEOCopy/'+id_url, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueCp/changeStateElementSEOCopy/');
    }
}

export async function setElementsSEOCopyWS(originURL, destinyPortal, destinyLanguageID, destinyURL) {
    let params = {
        originPortal: store.getters.getPortalID ,
        originLanguageID: store.getters.getLanguageID,
        originURL: originURL,
        destinyPortal: destinyPortal,
        destinyLanguageID: destinyLanguageID,
        destinyURL: destinyURL
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'queueCp/setElementSEOCopy', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueCp/setElementSEOCopy');
    }
}