import store from "@/store";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";


export async function getAllTeachersWS(with_personalization = 1, with_language_portal = 1, teacherUsernames = []) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    };
    let params = {teacherUsernames}
    if (with_personalization) {
        params.withPersonalization = 1
        if (with_language_portal) {
            params.portal = store.getters.getPortalID
            params.languageISO = store.getters.getLanguageISO
        }
    } else {
        params.withPersonalization = 0
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/getTeachers', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/getTeachers');
    }
}

export async function insertTeacherWS(teacher_data, languageISO = store.getters.getLanguageISO, portal = store.getters.getPortalID) {
    let cloned_image = _.cloneDeep(teacher_data.imagen_final);

    let params_token = {
        'portal': portal,
        'languageISO': languageISO,
        'tUsername': teacher_data.usuario,
        'usuario_logueado': store.getters.getUsername,
        'tActive': teacher_data.activo
    };

    let params = {
        'portal': portal,
        'languageISO': languageISO,
        'tUsername': teacher_data.usuario,
        'tDegree': teacher_data.titulacion,
        'tImgID': cloned_image,
        'tActive': teacher_data.activo,
        'tExperience': teacher_data.experiencia,
        'showLinkedin': teacher_data.showLinkedin ?? 0,
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/insertTeacher', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/insertTeacher');
    }
}

export async function updateTeacherWS(teacher_data) {
    let obj_to_send = _.cloneDeep(teacher_data)
    let cloned_image = _.cloneDeep(teacher_data.imagen_final);
    let teacherID = _.cloneDeep(teacher_data.id_docente);
    if (variableIsDefined(cloned_image) && cloned_image > 0) {
        obj_to_send.tImgID = cloned_image
    }

    if (variableIsDefined(teacher_data.cursos_asignados)) {
        obj_to_send.featuredCourses = teacher_data.cursos_asignados.toString()
    }else{
        obj_to_send.featuredCourses = null
    }

    obj_to_send.username = teacher_data.usuario
    obj_to_send.featuredCategory = teacher_data.area_prioritaria
    obj_to_send.show_linkedin = teacher_data.show_linkedin
    delete obj_to_send.imagen_final
    delete obj_to_send.imagen
    delete obj_to_send.id_docente
    delete obj_to_send.nombre
    delete obj_to_send.usuario
    delete obj_to_send.datos_innocualificados
    delete obj_to_send.area_prioritaria
    delete obj_to_send.cursos_asignados
    let jsonTeacher = JSON.stringify(obj_to_send);

    let params_token = {
        'teacherData': jsonTeacher,
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'teacherID': teacherID,
    };

    let params = {
        'teacherData': jsonTeacher,
        'teacherID': teacherID,
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
    }
    try {
        return await aiseoBackendPetition('POST', 'faculty/updateTeacher', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/updateTeacher/');
    }
}

export async function removeTeacherWS(teacher_data) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'teacherID': teacher_data.id_docente,
        'usuario_logueado': store.getters.getUsername
    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'teacherID': teacher_data.id_docente
    }
    try {
        return await aiseoBackendPetition('DELETE', 'faculty/removeTeacher', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/removeTeacher/');
    }
}

export async function setTeacherToCourseWS(id_curso, id_teacher) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': id_curso,
        'teacherID': id_teacher,
        'usuario_logueado': store.getters.getUsername

    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': id_curso,
        'teacherID': id_teacher,
    }
    try {
        return await aiseoBackendPetition('POST', 'faculty/setCourseSpecificTeacher', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/setCourseSpecificTeacher/');
    }
}


export async function getAreasByTeacherWS(id_teacher) {
    let params_token = {
        'teacherID': id_teacher,
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'teacherID': id_teacher,
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/getTeacherCategories', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/getTeacherCategories/');
    }
}

export async function setAreaToTeacherWS(id_teacher, id_category) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'teacherID': id_teacher,
        'categoryID': id_category,
        'typeCategoryID': 2,
    }
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'teacherID': id_teacher,
        'categoryID': id_category,
        'typeCategoryID': 2,
    }
    try {
        return await aiseoBackendPetition('POST', 'faculty/setTeacherCategories', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/setTeacherCategories/');
    }
}

export async function removeAreaFromTeacherWS(id_teacher, id_category) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'teacherID': id_teacher,
        'categoryID': id_category,
        'typeCategoryID': 2,
    };

    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'teacherID': id_teacher,
        'categoryID': id_category,
        'typeCategoryID': 2,
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/removeTeacherFromCategory', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/removeTeacherFromCategory/');
    }
}

export async function removeSpecificTeacherFromCourseWS(url, id_teacher) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'usuario_logueado': store.getters.getUsername,
        'url': url,
        'teacherID': id_teacher,
    };
    let params = {
        'portal': store.getters.getPortalID,
        'languageISO': store.getters.getLanguageISO,
        'url': url,
        'teacherID': id_teacher,
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/removeSpecificTeacherFromCourse', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faculty/removeSpecificTeacherFromCourse/');
    }
}


/*
*
* Obtiene los cursos disponibles
* para selección de un docente a partir
* de un área especificada
* */

export async function getTeacherAvailableCoursesWS(teacher_id) {
    let available_courses =  {
        category: [],
        specific: [],
    }
    let {category, specific} = available_courses

    let params_token = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername,
        teacherID: teacher_id,
    };

    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        teacherID: teacher_id,
    }

    try {
        return await aiseoBackendPetition('POST', 'faculty/getAllCoursesByTeacher', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '');
    }
}


