<template>
    <BaseModalTemplate
        :title="`Detalles de la cola con ID ${log_id}`"
        :open-close-dialog="open_modal_log"
        :loading="loading"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <template v-for="info in all_info_blog">
                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="subtitle-secondary">Id Blog: </span><span>{{ info.id }}</span>
                            </v-col>
                            <v-col cols="12">
                                <span class="subtitle-secondary">Id Empleado: </span><span>{{
                                    info.id_empleado
                                }}</span>

                            </v-col>
                            <v-col cols="12">
                                <span class="subtitle-secondary">Fecha: </span><span>{{
                                    formatDate(info.created_at)
                                }}</span>

                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="titles">ORIGEN</span>
                            </v-col>
                            <v-col cols="12">
                                <span class="subtitle-primary">Url: </span> <span>{{
                                    info.url_origen
                                }}</span><br>
                                <span class="subtitle-primary">Portal e idioma: </span>
                                <span>{{ getNamePortal(info.id_portal_origen) }} -
                                                        {{ getNameLanguage(info.id_idioma_origen) }}</span>
                            </v-col>
                            <v-col>

                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="titles">DESTINO</span>
                            </v-col>
                            <v-col cols="12" md="" lg="">
                                <span class="subtitle-primary">Url: </span> <span>{{
                                    info.url_destino
                                }}</span><br>
                                <span class="subtitle-primary">Portal e idioma: </span>
                                <span>{{ getNamePortal(info.id_portal_destino) }} -
                                                        {{ getNameLanguage(info.id_idioma_destino) }}</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                        <span class="subtitle-secondary">Respuesta del servidor: </span><span>{{
                            info.response
                        }}</span>
                    </v-col>
                </v-row>
                <hr>
            </template>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getLogElementSEOCopyWS} from "@/utils/queue_seo";
import {formatDate, showNotification} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalLogUrl",
    methods: {formatDate, getNameLanguage, getNamePortal},
    components: {BaseModalTemplate},
    props: {
        open_modal_log: Function,
        log_id: Number,
    },
    setup(props) {
        let all_info_blog = ref([])
        let loading = ref(false)

        onBeforeMount(async () => {
            loading.value = true
            let response = await getLogElementSEOCopyWS(props.log_id)

            if (response['result']) {
                response['data'].forEach((info) => {
                    all_info_blog.value.push(info)
                })
            } else {
                showNotification('errors', response['error'])
            }
            loading.value = false
        })

        return {
            all_info_blog,
            loading,
        }
    }
}
</script>


<style lang="scss" scoped>
    @import "@/styles/components/modal-log.scss";
</style>