import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getQueuedElementsDiplomaWS (page = 1, pageSize, {
    diploma_id,
    url_name,
    state,
    tries,
    orderBy,
    orderDirection
}) {
    const portal_id = store.getters.getPortalID
    const language_ISO = store.getters.getLanguageISO

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': portal_id,
        'language': language_ISO,
    };

    let params = {
        'id_portal': portal_id,
        'language': language_ISO,
        'pageSize': pageSize,
        'orderBy': orderBy || null,
        'orderDirection': orderDirection || null,
        'reintentos': tries || null,
        'estadoID': state || null,
        'diplomaID': diploma_id || null,
        'urlname': url_name || null
    }

    try {
        return await aiseoBackendPetition('POST', 'degrees/getQueuedElementsDiploma?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'degrees/getQueuedElementsDiploma');
    }
}

export async function getLogElementDiplomaWS(cola_logo) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/getLogElementDiploma/' + cola_logo, {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'degrees/getLogElementDiploma');
    }
}

export async function changeStatusElementsDiplomasWS(id_lote, id_state) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_state': id_state
    }
    let params =  {
        'usuario_logueado': store.getters.getUsername,
        'id_state': id_state
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/changeStatusElementsDiploma/' +id_lote, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'degrees/changeStatusElementsDiploma');
    }
}