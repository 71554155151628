<template>
    <form id="form_keyword" ref="my_form">
        <v-row>
            <v-col cols="12">
            <span class="titles-keyword">Url Objetivo
                <v-progress-circular
                        v-if="loading_course"
                        class="icons-loading ms-4"
                        indeterminate
                        :size="20"
                        :width="2"
                        color="white"
                ></v-progress-circular>
            </span>
            </v-col>
            <v-col cols="12">
                <input
                        class="form-control autocomplete-url"
                        v-model="url"
                        placeholder="Url objetivo"
                        required
                        autocomplete="off"
                        @keyup.enter="updateUrl()"
                        @keydown.down="keydownAutocomplete('down')"
                        @keydown.up="keydownAutocomplete('up')"
                />
                <p class="msg-error-url" v-if="msg.url">{{ msg.url }}</p>
                <ul v-show="is_open" class="autocomplete-results personalize-scroll">
                    <li
                            v-for="(result,i) in items" :key="i"
                            @click="setUrl(result)"
                            class="autocomplete-result"
                            :class="{ 'is-active': i ===arrow_counter}"
                    >
                        {{ result.url }}
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <label for="id_url">
                    <span class="titles-keyword">Keyword Objetivo Final</span>
                </label>
            </v-col>
            <v-col cols="12">
                <input
                        class="form-control autocomplete-url"
                        v-model="keyword"
                        :placeholder="keyword"
                        required
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <label for="id_estado">
                    <span class="titles-keyword">Estado</span>
                </label>
            </v-col>
            <v-col cols="12">
<!--                <select id="estado" class=" autocomplete-url form-control"
                        v-model="selected">
                    <option class="select-estate" v-for="item in dropdown"
                            v-bind:value="{ id: item.id, name: item.name }">
                        {{ item.name }}
                    </option>
                </select>-->
                <v-select
                    id="estado"
                    class=" select-primary"
                    :items="dropdown"
                    v-model="selected"
                    variant="underlined"
                    hide-details
                    no-data-text="No hay datos disponibles"
                >
                    <template v-slot:selection="{ item }">
                        <span >{{ item.value.name}}</span>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                                v-bind="props"
                                :title="item?.raw?.name"
                        >
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row v-if="selected.id===5">
            <v-col cols="12">
                <label for="id_url_redirect">
                    <span class="titles-keyword">Redireccionar URL objetivo a</span>
                </label>
            </v-col>
            <v-col cols="12">
                <input
                        id="id_url_redirect"
                        class="form-control autocomplete-url"
                        v-model="url_redirect"
                        :placeholder="url_redirect"
                        required
                >
                <span v-if="msg.redirect">{{ msg.redirect }}</span>
            </v-col>
        </v-row>
        <v-row v-if="!msg.url && url && keyword && ((selected.id===5 && url_redirect) || (selected
        && selected.id!==5))">
            <v-col cols="12">
                <v-btn id="submit" type="button" @click="submitForm"
                       class="btn button-primary btn-personalized">Enviar
                </v-btn>
            </v-col>
        </v-row>
    </form>
</template>

<script>
import {computed, onBeforeMount, onMounted, onUnmounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {useRoute, useRouter} from "vue-router";
import {isInteger} from "lodash";
import el from "sizzle";

export default {
    name: "Keyword",
    emits: ['getURLData', 'submit'],

    setup(props, ctx) {
        const store = useStore();
        const router = useRouter();
        let route = useRoute();
        let username = computed(() => store.getters.getUsername).value;
        let portal_id = computed(() => store.getters.getPortalID);
        let language;
        let selected = ref('');
        let dropdown = [
            {id: 1, name: "Imposible"},
            {id: 2, name: "Optimizar"},
            {id: 3, name: "Optimizada y revisada"},
            {id: 4, name: "Única opción"},
            {id: 5, name: "Redireccionar"}
        ];
        let url = ref(null);
        let id_url = ref(null);
        let keyword = ref(null);
        let url_redirect = ref(null);
        let msg = ref([]);
        let items = ref([]);
        let awaiting = false;
        let is_open = ref(false);
        let arrow_counter = ref(-1);
        let search_url;
        let loading_course = ref(false);
        let reserve_url;
        let first_visit = false;
        let url_watch;


        onBeforeMount(async () => {
            await controlStore()
        })

        async function controlStore() {
            await router.isReady()
            if (Object.entries(route.query).length !== 0) {
                reserve_url = route.query.url
                url.value = route.query.url
                portal_id = route.query.portal
                language = route.query.language
                ctx.emit('getURLData', url.value)
                let path = route.fullPath.split('?')[0];
                await router.push(path)
            } else {
                portal_id = computed(() => store.getters.getPortalID).value;
                language = computed(() => store.getters.getLanguageISO).value;
            }
        }

        onMounted(() => {
            document.addEventListener('click', handleClickOutside)
        })
        onUnmounted(() => {
            document.removeEventListener('click', handleClickOutside)
        })

        watch(portal_id, async () => {
            if (!first_visit) {
                items.value.url = {};
                url.value = ""
                msg.value['url'] = ""
                keyword.value = null;
                selected.value = "";
                $('.column-received-data').hide()
            }
        });

        // Watchers para ir verificando los cambios de los valores
        watch(items, async (newItems, oldItem) => {
            items.value = newItems;
            is_open.value = items.value.length > 1;
        });

        watch(url, async (newUrl, oldUrl) => {
            url_watch = newUrl;
            if (!url_watch || url_watch.length < 5) {
                items.value = [];
            }
            if (url_watch.length >= 5) {
                debounce(url_watch);
            }
        });


        watch(url_redirect, async (newUrl, oldUrl) => {
            url_redirect = newUrl;
            // urlState(url_redirect,'url_redirect');
        });

        // Esconde el autocomplete cuando el usuario hace un click exterior
        function handleClickOutside(event) {
            if (!el.contains(event.target)) {
                arrow_counter.value = -1;
                is_open.value = false;
            }
        }

        function submitForm() {
            let id_url_final = null;
            if (isInteger(id_url.value)){
                id_url_final = id_url.value
            }else if(isInteger(id_url)){
                id_url_final=id_url
            }
            if (id_url_final !== null) {
                let info = {
                    'id_url': id_url_final,
                    'kw_objetivo_final': keyword.value,
                    'estado': selected.value.name
                }
                if (selected.value.name === "Redireccionar") {
                    info['url_futura_redireccion'] = url_redirect
                }
                ctx.emit('submit', info);
            } else {
                msg.value['url'] = 'Debes seleccionar una URL del listado proporcionado'
            }

        }

        // Actualizar el valor de keyword desde fuera
        function updateKey(value) {
            keyword.value = value;
        }

        // Actualizar el valor de la url de redirección desde fuera
        function updateUrl_redirect(value) {
            url_redirect = value;
        }


        function updateUrl() {
            if (items.value.length >= 1) {
                this.url = items.value[arrow_counter.value].url;
                this.id_url = items.value[arrow_counter.value].id_url;
                ctx.emit('getURLData', this.url);
                msg.value['url'] = "";
            } else {
                msg.value['url'] = "Url inválida";
            }
            is_open.value = false;
        }


        // Actualizar url desde un objeto (click del ratón por ejemplo)
        function setUrl(value) {
            this.url = value.url;
            id_url = value.id_url;
            if (items.value.length >= 1) {
                ctx.emit('getURLData', value.url);
                msg.value['url'] = "";
            } else {
                msg.value['url'] = "Url inválida(1)";
            }
            is_open.value = false;
        }


        function keydownAutocomplete(key) {
            switch (key) {
                case 'down':
                    if (arrow_counter.value < items.value.length) {
                        arrow_counter.value = arrow_counter.value + 1;
                    }
                    break;
                case 'up':
                    if (arrow_counter.value > 0) {
                        arrow_counter.value = arrow_counter.value - 1;
                    }
                    break;
            }
        }

        // Función de esperar para hacer el query de autocompletado
        function debounce(value, delay = 300) {
            if (!awaiting) {
                setTimeout(async () => {
                    await getAutoComplete();
                    awaiting = false;
                }, delay);
            }
            awaiting = true;
        }

        // Hacer petición asíncrona de los posibles urls a utilizar
        async function getAutoComplete() {
            await controlStore()
            search_url = {}
            items.value = [];
            msg.value['url'] = ""
            try {
                loading_course.value = true
                let response = await aiseoBackendPetition('GET', "buscador-urls-cursos/" + url_watch + '/' + portal_id + '/' + language + '?usuario_logueado=' + username, {}, {})
                if (response.result) {
                    items.value = response.data;
                    if (items.value.length === 1) {
                        msg.value['url'] = ""
                        response.data.forEach(course => {
                            search_url = course;
                            url.value = search_url.url
                            id_url.value = search_url.id_url
                            ctx.emit('getURLData', search_url.url);
                        })
                    }
                    if (items.value.length === 0) {
                        msg.value['url'] = "No hemos encontrado datos referentes a la búsqueda";
                    }
                } else {
                    await refactorUrl()
                    showNotification('error', response.errors)
                }
            } catch (e) {
                showNotification('error', 'Se ha producido un error al lanzar la petición')
            }
            loading_course.value = false
        }

        async function refactorUrl() {
            if (portal_id !== store.getters.getPortalID || language !== store.getters.getLanguageISO) {
                url = reserve_url
                portal_id = store.getters.getPortalID
                language = store.getters.getLanguageISO
                await getAutoComplete()
            }
        }

        return {
            submitForm,
            updateUrl,
            updateKey,
            updateUrl_redirect,
            debounce,
            setUrl,
            keydownAutocomplete,
            selected,
            dropdown,
            url,
            id_url,
            keyword,
            url_redirect,
            msg,
            items,
            is_open,
            arrow_counter,
            loading_course,
            url_watch,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/keyword.scss";
</style>
