<template>
    <BaseModalTemplate
        :title="title_form"
        :open-close-dialog="displayModalCreate"
        :hide_actions="!show_buttons"
        :loading="loading"
    >
        <template #body-modal-content>
            <v-form class="form-config">

                <!--               DESCRIPCIÓN -->
                <v-row>
                    <v-col>
                        <span class="input-title">Seleccionar una empresa</span>
                    </v-col>
                </v-row>
                <v-row>
                    <!--                EMPRESA-->
                    <v-col cols="12" lg="9" md="8">
                        <v-select class="input-error select-primary"
                                  :class="{error :form_error.empresa}"
                                  placeholder="Pulse para buscar una empresa"
                                  :items="business"
                                  v-model="form_config.empresa"
                                  variant="underlined"
                                  hide-details
                                  required
                                  no-data-text="No hay datos disponibles"
                        >
                        </v-select>
                        <span v-if="form_error.empresa" class="input-error msg-error">{{
                                form_error.empresa
                            }}</span>
                    </v-col>
                    <!--                BTN SELECCIONAR EMPRESA-->
                    <v-col cols="12" lg="3" md="4" class="box-selection-bussines">
                        <v-btn class="btn button-primary"
                               @click="selectionBusiness">
                            Seleccionar empresa
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-if="state_form">
                    <v-row>
                        <v-col>
                            <span class="input-title">Nombre de configuración*</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--                        NOMBRE CONFIGURACIÓN-->
                        <v-col cols="12">
                            <v-text-field
                                v-model="form_name_description.name"
                                :class="{error :form_error.name}"
                                class="input-error text-field-primary"
                                placeholder="Nombre Configuración*"
                                variant="underlined"
                                hide-details
                            ></v-text-field>
                            <span v-if="form_error.name" class="input-error msg-error">{{
                                    form_error.name
                                }}</span>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="input-title">Introduzca el identificador del curso</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--                        ID CURSO-->
                        <v-col cols="12">
                            <v-text-field variant="underlined"
                                          placeholder="ID Curso"
                                          :class="{error :form_error.ids_cursos}"
                                          class="input-error text-field-primary"
                                          v-model="form_config.ids_cursos"
                                          hide-details
                            ></v-text-field>
                            <span v-if="form_error.ids_cursos"
                                  class="input-error msg-error">{{ form_error.ids_cursos }}</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <span class="input-title">Introduce el número de matrícula</span>
                            <!--                    NUMERO MATRICULA-->
                            <v-text-field
                                class="input-error text-field-primary personalize-text-field"
                                placeholder="Número Matrícula"
                                variant="underlined"
                                v-model="form_config.n_matriculas"
                                :class="{error :form_error.n_matriculas}"
                                hide-details
                            ></v-text-field>
                            <span v-if="form_error.n_matriculas"
                                  class="input-error msg-error">{{ form_error.n_matriculas }}</span>

                        </v-col>
                        <v-col cols="12" md="6">
                            <span class="input-title">Seleccionar una campaña</span>
                            <!--                CAMPAÑA-->
                            <v-autocomplete
                                class="select-primary"
                                :items="all_campaign"
                                placeholder="Pulse para buscar una campaña"
                                v-model="form_config.campanya"
                                variant="underlined"
                                hide-details
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p class="input-title mt-5">Seleccionar un rango de precio (Mínimo-Máximo)</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!--                SLIDER PRECIO-->
                        <v-col cols="12">
                            <v-range-slider
                                class="slider-modal mt-5"
                                v-model="half_price"
                                step="1"
                                :model-value="half_price"
                                thumb-label="always"
                                min=0
                                max=1000
                                strict
                                hide-details
                            ></v-range-slider>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span class="input-title">Descripción</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form_name_description.description"
                                placeholder="Añade una descripción para esta configuración."
                                variant="outlined"
                                class="text-area-primary personalize-scroll"
                                outline
                                bg-color="white"
                                density="compact"
                                rows="3"
                                hide-details
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </template>
        <template #actions-modal>
            <v-row v-if="show_buttons">
                <v-col cols="12" class="atrack-col">
                    <v-btn class="btn button-primary" @click="exportTestConfiguration"
                           :disabled="disabled_btn_export">
                        <span class="download-option-text me-1">Descargar</span>Excel de prueba
                        <v-progress-circular v-if="state_loading"
                                             class="icons-loading ms-4"
                                             indeterminate
                                             :size="18"
                                             :width="2"
                                             color="white"
                        ></v-progress-circular>
                    </v-btn>
                    <v-btn class="btn button-bg-blue me-xl-4 me-lg-3 me-md-3" @click="saveConfig"
                           :disabled="disabled_btn_save">
                        Guardar configuración
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>

</template>
<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import store from "@/store";
import * as Yup from "yup"
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ConfigurationGADS",
    components: {BaseModalTemplate, Loading},
    props: {
        displayModalCreate: Function,
        title_form: String,
        id: Number,
        getAllConfig: Function,
    },
    setup(props) {
        const all_campaign = ref([]);
        const business = ref([]);
        const disabled_btn_export = ref(false);
        const disabled_btn_save = ref(false);
        const form_config = ref({});
        const form_error = ref({});
        const form_name_description = ref({});
        const half_price = ref([0, 0]);
        const loading = ref(false);
        const show_buttons = ref(false)
        const state_form = ref(false);
        const state_loading = ref(false);
        let format_all_id_course;
        let id_config = props.id
        let status_url_export;

        onBeforeMount(async () => {
            loading.value = true;
            if (id_config !== 0) {
                await getConfig(id_config)
                show_buttons.value = true
            }

            await getCompanies()
            await getCampayas()
            loading.value = false
        })

        async function getCompanies() {
            let response_companies = await aicoreBackendPetition('GET', "/feeds/get_empresas", {})
            if (response_companies['result']) {
                response_companies = response_companies['data']
                response_companies.forEach(company => {
                    business.value.push(company)
                })
            }
        }

        async function getCampayas() {
            let response_campayas = await aicoreBackendPetition('GET', "/feeds/get_campanyas", {})
            if (response_campayas['result']) {
                response_campayas = response_campayas['data']
                response_campayas.forEach(campayas => {
                    all_campaign.value.push(campayas)
                })
            }
        }

        async function selectionBusiness() {
            await validateSelectionBusiness()
            if (Object.keys(form_error.value).length === 0) {
                state_form.value = true
                show_buttons.value = true

            }
        }

        async function validateSelectionBusiness() {
            form_error.value = {}
            const schemaForm = Yup.object().shape({
                empresa: Yup.string().required(true),
            })
            try {
                await schemaForm.validate(form_config.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "Es obligatorio seleccionar una empresa"
                })
            }
        }


        async function validateConfig() {
            form_error.value = {}
            let regex_enrollment = /^\d*$/;
            let regex_id_course = /^[0-9,\s]*$/;
            const schemaForm = Yup.object().shape({
                name: Yup.string().required(true)
            })
            try {
                await schemaForm.validate(form_name_description.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "Es obligatorio asignar un nombre."
                })
            }
            let response_campanya = variableIsDefined(form_config.value.campanya);
            if (response_campanya === false) {
                form_config.value.campanya = null
            }

            let response_n_matriculas = variableIsDefined(form_config.value.n_matriculas);
            if (response_n_matriculas === true) {
                if (!regex_enrollment.test(form_config.value.n_matriculas)) {
                    form_error.value.n_matriculas = "Introduce un dato válido"
                } else {
                    form_config.value.n_matriculas = form_config.value.n_matriculas.toString()
                    form_config.value.n_matriculas = parseInt(form_config.value.n_matriculas)
                }
            } else {
                form_config.value.n_matriculas = null;
            }

            let response_id_courses = variableIsDefined(form_config.value.ids_cursos);
            if (response_id_courses === true) {
                if (!regex_id_course.test(form_config.value.ids_cursos)) {
                    form_error.value.ids_cursos = "Introduce un dato válido"
                } else {
                    format_all_id_course = []
                    form_config.value.ids_cursos = form_config.value.ids_cursos.toString()
                    form_config.value.ids_cursos = form_config.value.ids_cursos.split(',');
                    form_config.value.ids_cursos.forEach(id_course => {
                        if (id_course !== "") {
                            if (!format_all_id_course.includes(parseInt(id_course))) {
                                format_all_id_course.push(parseInt(id_course))
                                form_config.value.ids_cursos = format_all_id_course
                            }
                        }
                    })
                }
            } else {
                form_config.value.ids_cursos = null;
            }
            let response_description = variableIsDefined(form_name_description.value.description);
            if (response_description === false) {
                form_name_description.value.description = null
            }
        }

        async function getConfig(id_config) {
            let params = {
                'config_id': id_config
            }
            let response = await aicoreBackendPetition('GET', "/feeds/get_config", params)
            if (response['result']) {
                response = response['data']
                state_form.value = true;

                half_price.value = [response.json_configuracion.precio_min || 0, response.json_configuracion.precio_max || 0]
                form_config.value = {
                    campanya: response.json_configuracion.campanya,
                    empresa: response.json_configuracion.empresa,
                    n_matriculas: response.json_configuracion.n_matriculas,
                    ids_cursos: response.json_configuracion.ids_cursos,
                    precio_min: half_price.value[0],
                    precio_max: half_price.value[1],
                }
                form_name_description.value = {
                    name: response.nombre,
                    description: response.descripcion,
                }

            } else {
                showNotification("error", response['errors'])
            }
        }

        async function exportTestConfiguration() {
            await validateConfig()
            state_loading.value = true;
            disabled_btn_export.value = true;
            form_config.value.precio_min = half_price.value[0]
            form_config.value.precio_max = half_price.value[1]
            let form_config_json = JSON.stringify(form_config.value)
            await exportTestConfigWS(form_config_json, form_name_description.value.name, form_name_description.value.description)
            if (status_url_export === 200) {
                showNotification('success', "La configuración de prueba se ha descargado")
            } else {
                showNotification('error', "No se pudo generar la configuración de prueba.")
            }
            disabled_btn_export.value = false;
            state_loading.value = false
        }

        async function exportTestConfigWS(form_config_json, name, description) {
            let url = process.env.VUE_APP_AICORE_URL_API + "feeds/test_configuration"
            return await $.ajax({
                type: "GET",
                url: url,
                data: {
                    'config_json': form_config_json,
                    'name': name,
                    'description': description,
                },
                headers: {
                    'Authorization': 'Bearer ' + store.getters.getToken,
                    'username': store.getters.getUsername,
                },
                success: function (data, textStatus, xhr) {
                    status_url_export = xhr.status
                    let disposition = xhr.getResponseHeader("content-disposition")
                    let title = disposition.split("=")[1]
                    let blob = new Blob([data], {type: 'text/csv'});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = title;
                    link.click();
                },
                async: true,
            })
        }

        async function saveConfig() {
            await validateConfig()
            if (Object.keys(form_error.value).length === 0) {
                disabled_btn_save.value = true
                form_config.value.precio_min = half_price.value[0]
                form_config.value.precio_max = half_price.value[1]

                let form_config_json = JSON.stringify(form_config.value)
                let params = {
                    "user": store.getters.getUsername,
                    'config_json': form_config_json,
                    'name': form_name_description.value.name,
                    'description': form_name_description.value.description,
                }
                if (id_config !== 0) {
                    params.config_id = id_config
                    let response = await aicoreBackendPetition('GET', "/feeds/update_feed_config", params)
                    if (response['result']) {
                        showNotification('success', "Se ha guardado correctamente la configuración.")
                    } else {
                        showNotification('error', response['errors'])
                    }
                } else {
                    let response = await aicoreBackendPetition('GET', "/feeds/create_feed_config", params)
                    if (response['result']) {
                        showNotification('success', "Configuración CREADA.")
                    } else {
                        showNotification('error', response['errors'])
                    }
                }
                disabled_btn_save.value = true
                props.displayModalCreate()
                props.getAllConfig()
            }
        }


        return {
            exportTestConfiguration,
            saveConfig,
            selectionBusiness,

            all_campaign,
            business,
            disabled_btn_export,
            disabled_btn_save,
            form_config,
            form_error,
            form_name_description,
            half_price,
            loading,
            show_buttons,
            state_form,
            state_loading,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/form-configuration-gads.scss";
</style>