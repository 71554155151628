import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getAllDegreesUrlWS(id_url_course){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'id_portal' : store.getters.getPortalID.toString(),
        'language' : store.getters.getLanguageISO,
        'url' : id_url_course,

    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/getAllDegreesURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' degrees/getAllDegreesURL')
    }

}


export async function assignDegreeUrlWS(id_url, img_select){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'id_portal' : store.getters.getPortalID.toString(),
        'language' : store.getters.getLanguageISO,
        'url' : id_url,
        'degree_data' : img_select,

    }

    try {
        return await aiseoBackendPetition('POST', 'degrees/assignDegreeURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' degrees/assignDegreeURL')
    }
}

export async function getAllType(url, type, portal, idioma){
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': portal,
        'language': idioma,
        'type': type,
    }

    try {
        return await aiseoBackendPetition('POST', url, params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + url);
    }
}


export async function getCourseDegreesFinalListWS(id_url){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'url': id_url
    }

    try {
        return await aiseoBackendPetition('POST', 'degrees/getCourseDegreesFinalList', params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/getCourseDegreesFinalList');
    }
}