<template>
    <BaseModalTemplate
        title="Selección de categorías"
        :card_id='"selection-category-file-"+id_file'
        :open-close-dialog="displayModal"
        :send-form="saveCategoriesSelected"
    >
        <template #body-modal-content>
            <div class="show-categories">
                <ul class="box-ul">
                    <li :class="main_category.id" class="click-category">
                        <img src="@/assets/gifs/example_loading.gif" alt="loading" class="gif-loading-file">
                        <img src="@/assets/icons/plus_circle.svg" alt="Icono" class="icon-plus-file"
                             @click="openSubcategories(main_category.id)">
                        <img src="@/assets/icons/folder-close.svg" alt="Icono" class="icon-file">
                        <span class="primary-category">Library</span>
                    </li>
                </ul>
            </div>
            <div class="show-category-selected" v-if="see_name_category.length!==0">
                <v-row>
                    <v-col><span><b>Categoría seleccionada:</b></span></v-col>
                </v-row>
                <v-row v-for="name_category in see_name_category" no-gutters>
                    <v-col>
                        <span class="ms-5">{{ name_category }} </span>
                    </v-col>
                </v-row>
            </div>
        </template>
    </BaseModalTemplate>
</template>
<script>
import {closeChildren, recoverCategoryGeneral} from "@/utils/media_valet";
import {onBeforeMount, ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: 'ModalCategoryFile',
    components: {BaseModalTemplate},
    methods: {variableIsDefined},
    props: {
        root_category: Object,
        displayModal: Function,
        id_file: String,
        saveCategory: Function,
        date_file: Array,
    },
    setup(props) {
        let main_category = ref(props.root_category)
        let active = false
        let disable = false
        let fields = ref(new Map());
        let selection_category = []
        let see_name_category = ref([])
        let categories = []
        let active_btn = ref(false)
        let block_btn_close = ref(false)

        async function openSubcategories(id_category) {
            if (!disable) {
                $('.icon-plus-file').hide()
                $('.gif-loading-file').show()
                disable = true
                if (!active) {
                    active = true
                    active_btn.value = true
                    block_btn_close.value = true
                    await recoverCategoryGeneral(id_category, main_category.value, fields.value, clickNameCategory, document.getElementById('selection-category-file-' + props.id_file), 1, null,  null, document.getElementById('btn-save-file-category'))
                    block_btn_close.value = false
                } else {
                    closeChildren(main_category.value.id, fields.value, 1)
                    active = false
                }
                active_btn.value = false
                disable = false
                $('.gif-loading-file').hide()
                $('.icon-plus-file').show()
            }
        }

        function clickNameCategory(id_category, ruta_path, map, parent_id_selected, name_category) {
            if (!categories.includes(id_category)) {
                categories.push(id_category)
                see_name_category.value.push(name_category)
            } else {
                let index = categories.indexOf(id_category);
                if (index > -1) {
                    categories.splice(index, 1);
                    see_name_category.value.splice(index, 1);
                }
            }
        }

        function saveCategoriesSelected() {
            let category_selected = {
                'name_category': see_name_category.value,
                'id_file': props.id_file,
                'id_category': categories,
            }
            selection_category.push(category_selected)
            props.saveCategory(category_selected)
        }


        return {
            openSubcategories,
            saveCategoriesSelected,

            fields,
            main_category,
            see_name_category,
            active_btn,
            block_btn_close

        }
    }
}
</script>

<style scoped lang="scss">
.show-categories {
    min-height: 30vh;
    .gif-loading-file{
        display: none;
        width: 16px;
        margin-right: 5px;
    }
}

.show-category-selected {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    min-height: 10vh;
}

.icon-plus-file {
    cursor: pointer;
    width: 15px;
    height: auto;
    margin-right: 6px;
}
.icon-file{
    cursor: auto;
    width: 15px;
    height: auto;
    margin-right: 6px;
}
</style>