<template>
    <v-row justify="end">
        <v-col cols="12" md="6">
                    <span class="input-title mb-3">
                                    Pregunta </span>
            <v-textarea
                    v-model="question"
                    placeholder="Pregunta"
                    required
                    variant="outlined"
                    bg-color="white"
                    color="transparent"
                    hide-details
                    class="input-error text-area-primary"
                    :error="variableIsDefined(questions_error.question)"
            ></v-textarea>
            <span v-if="questions_error.question" class="input-error msg-error">
                        Tiene que definirse una pregunta
                    </span>
        </v-col>
        <v-col cols="12" md="6">
                    <span class="input-title mb-3">
                                    Respuesta </span>
            <v-textarea
                    v-model="answer"
                    placeholder="Respuesta"
                    required
                    variant="outlined"
                    bg-color="white"
                    color="transparent"
                    hide-details
                    class="input-error text-area-primary"
                    :error="variableIsDefined(questions_error.answer)"
            ></v-textarea>
            <span v-if="questions_error.answer" class="input-error msg-error">
                        La respuesta no puede estar vacía
                    </span>
        </v-col>
        <v-col cols="12" md="6" class="col-btn-question">
            <v-row class="btn-row">
                <v-col cols="6"  sm="3" md="3" lg="2" class="btn-col" v-if="disabled_data || question_created">
                    <v-checkbox
                            label="Eliminar"
                            v-model="delete_question"
                            :true-value="true"
                            :false-value="false"
                            :disabled="loading_btn"
                            color="blue"
                            hide-details
                    ></v-checkbox>
                </v-col>
                <v-col  cols="12"  sm="3" class="btn-col" v-if="!question_created ">
                    <v-btn  class="btn button-bg-white"
                            hide-details
                            @click="addQuestionToMap"
                            :loading="loading_btn"
                    >
                        Añadir
                    </v-btn>
                </v-col>
                <v-col cols="6"  sm="3" md="5" lg="3" class="btn-col" v-else-if="disabled_data || question_created">
                    <v-btn
                            class="btn button-bg-white"
                            hide-details
                            @click="manageQuestion()"
                            :loading="loading_btn"
                    >
                        <span v-if="delete_question"> Eliminar</span>
                        <span v-else>Actualizar </span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {deleteFaqWS, updateFaqWS} from "@/utils/faqs";

export default {
    name: "RowQuestion",
    methods: {variableIsDefined},
    props: {
        questions_map: Map,
        increaseMapID: Function,
        addQuestion: Function,
        id: Number,
    },
    setup(props) {
        let question = ref('');
        let answer = ref('');
        let questions_error = ref({});
        let disabled_data = ref(false);
        let question_created = ref(false);
        let delete_question = ref(false);

        let loading_btn = ref(false);

        onBeforeMount(() => {
            question_created.value = props.questions_map.get(props.id) !== '';
            autocompleteValue();
        })

        /*
        *
        * RELLENA LOS DATOS DE LA PREGUNTA AL MONTAR EL COMPONENTE, PARA EVITAR PROBLEMAS AL BORRAR
        * FILAS EN LAS PRIMERAS POSICIONES
        *
        * */
        function autocompleteValue() {
            if (question_created.value) {
                question.value = props.questions_map.get(props.id).question;
                answer.value = props.questions_map.get(props.id).answer;
                disabled_data.value = true;
            }
        }

        /*AÑADE EL OBJETO PREGUNTA-RESPUESTA AL MAP DE PREGUNTAS*/
        function addQuestionToMap() {
            if (validateQuestions()) {
                loading_btn.value = true;
                let question_data = {
                    'question': question.value,
                    'answer': answer.value,
                }

                let id = props.increaseMapID();
                let next_id = id + 1;

                //AÑADIR LA PREGUNTA EN LA BBDD
                props.addQuestion(id, question_data);
                props.questions_map.set(next_id, '');

                disabled_data.value = true;
                question_created.value = true;
                loading_btn.value = false;
            }
        }

        async function updateQuestion() {
            if (validateQuestions()) {
                loading_btn.value = true;
                let id_faq = props.questions_map.get(props.id).id_faq;

                let question_data = {
                    'question': question.value,
                    'answer': answer.value,
                    'id_faq' : id_faq,
                }

                //ACTUALIZAR LA PREGUNTA EN LA BBDD
                let response = await updateFaqWS(id_faq, question_data);

                if (response['result']){
                    props.questions_map.set(props.id, question_data);
                    showNotification('success', 'Se ha actualizado la pregunta correctamente')
                }else{
                    showNotification('error', response['errors'])
                }
                loading_btn.value = false;
            }
        }

        /*VALIDA LOS DATOS DEL FORMULARIO ANTES DE GUARDAR LA PREGUNTA*/
        function validateQuestions() {
            let correct = true;
            questions_error.value = {};

            question.value = question.value.trim();
            answer.value = answer.value.trim();

            if (!variableIsDefined(question.value)) {
                correct = false;
                questions_error.value['question'] = true;
            }

            if (!variableIsDefined(answer.value)) {
                correct = false;
                questions_error.value['answer'] = true;
            }

            return correct;
        }

        //ELIMINA EL OBJETO DEL MAP DE PREGUNTAS
        async function eraseElement() {
            loading_btn.value = true;
            let response = await deleteFaqWS(props.questions_map.get(props.id).id_faq);

            if (response['result']) {
                props.questions_map.delete(props.id);
                showNotification('success', 'Pregunta eliminada correctamente');
            } else {
                showNotification('error', response['errors'])
            }
            loading_btn.value = false;
        }

        function manageQuestion(){
            return delete_question.value ? eraseElement() : updateQuestion();
        }

        return {
            addQuestionToMap,
            manageQuestion,

            question,
            answer,
            questions_error,
            disabled_data,
            question_created,
            delete_question,
            loading_btn,
        }
    },
}
</script>

<style lang="scss">
.btn-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn-col {
        text-align: end;
    }
}
</style>