<template>
    <div class="sui-layout">
        <div v-if="searchState.wasSearched" class="sui-layout-body edit-sui-layout-body">
            <v-row>
                <v-col cols="12" class="col-search-bar">
                    <SearchHeader
                            ref="header"
                            v-model="searchInputValue" @submit="handleFormSubmit"
                            :suggest="suggest"
                            :suggestions="searchState.autocompletedSuggestions.documents"
                            :completions="searchState.autocompletedResults"
                            @autoComplete="debounceFilter(() => {handleAutoCompleteSelection()})"
                    />
                </v-col>
            </v-row>
            <v-row>
                <div class="sui-layout-body__inner edit-sui-layout-body__inner">
                    <v-col cols="2" class="col-filters" style="min-width: 150px">
                        <div class="sui-layout-sidebar edit-sui-layout-sidebar">
                          <SearchSort v-show="thereAreResults()" v-model="sortBy"
                            :options="sortOptions"
                          />
                            <SearchFacet
                                nombre="Url Portal"
                                :checked="url_portal"
                                :facet="searchState.facets.url_portal[0]"
                                @change="$event => handleFacetChange($event, 'url_portal')"
                            />
                            <SearchFacet
                                nombre="Nombre Facultad"
                                :checked="nombre_facultad"
                                :facet="searchState.facets.nombre_facultad[0]"
                                @change="$event => handleFacetChange($event, 'nombre_facultad')"
                            />
                            <SearchFacet
                                nombre="Nombre Area"
                                :checked="nombre_area"
                                :facet="searchState.facets.nombre_area[0]"
                                @change="$event => handleFacetChange($event, 'nombre_area')"
                            />
                            <SearchFacet
                                nombre="Author"
                                :checked="author"
                                :facet="searchState.facets.author[0]"
                                @change="$event => handleFacetChange($event, 'author')"
                            />
                            <SearchFacet
                                nombre="Author SEO"
                                :checked="author_seo"
                                :facet="searchState.facets.author_seo[0]"
                                @change="$event => handleFacetChange($event, 'author_seo')"
                            />
                            <SearchFacet
                                nombre="Idioma"
                                :checked="idioma"
                                :facet="searchState.facets.idioma[0]"
                                @change="$event => handleFacetChange($event, 'idioma')"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="10" class="min-vw-10 min-vh-10">
                        <div class="sui-layout-main edit-sui-layout-main" >
                            <div class="sui-layout-main-header">
                                <div class="sui-layout-main-header__inner">
                                    <SearchPagingInfo :search-state="searchState"/>
                                    <SearchResultsPerPage
                                            v-show="thereAreResults"
                                            v-model.number="resultsPerPage"
                                    />
                                </div>
                            </div>

                            <div class="sui-layout-main-body mt-5">
                                <SearchResults
                                        v-show="thereAreResults"
                                        :results="searchState.results"
                                />
                            </div>

                            <div class="sui-layout-main-footer">
                                <SearchPagination
                                        v-show="thereAreResults"
                                        :total-pages="Math.min(searchState.totalPages, 100)"
                                        :click-handler="setCurrentPage"
                                />
                            </div>
                        </div>
                    </v-col>

                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue"
import {SearchDriver} from "@elastic/search-ui";
import {getAppSearchConfig, createDebounce} from "@/utils/search_config";
import SearchResults from "./SearchResults.vue";
import SearchFacet from "./SearchFacet.vue";
import SearchHeader from "./SearchHeader.vue";
import SearchPagingInfo from "./SearchPagingInfo.vue";
import SearchPagination from "./SearchPagination.vue";
import SearchSort from "./SearchSort.vue";
import SearchResultsPerPage from "./SearchResultsPerPage.vue";

export default {
    components: {
        SearchResults,
        SearchFacet,
        SearchHeader,
        SearchPagingInfo,
        SearchPagination,
        SearchSort,
        SearchResultsPerPage
    },

    props : {
        engineSuffix : {
            type: String,
            request: true
        },
        engineType: {
            type: String,
            request: true
        }
    },

    setup(props) {
        let searchInputValue = ref("");
        let searchState = ref({});
        let idioma = [];
        let author = [];
        let author_seo = [];
        let nombre_facultad = [];
        let nombre_area = [];
        let driver = new SearchDriver(getAppSearchConfig(props.engineType, props.engineSuffix));
        driver.addFilter('url_portal', 'euroinnova.com', "any");
        let url_portal = ['euroinnova.com'];
        // Referencia necesaria
        let resultsPerPage = ref(10);
        let sortBy = ref("relevancia");
        const sortOptions = [
          {"id": 1, "value": "", "name": "Relevancia"},
          {"id": 2, "value": "fecha_actualizacion", "name": "Fecha Última Actualización"},
          {"id": 3, "value": "fecha_creacion", "name": "Fecha de Creación"},
        ];
        let suggest = ref(false);
        // Reference to the SearchHeader component;
        const header = ref(null);

        function handleFormSubmit() {
          // Ask Elastic to change the query term and refresh the results list.
          suggest.value = false;
          driver.getActions().setSearchTerm(searchInputValue.value);
        }

        function handleAutoCompleteAction() {
            /*
            Requests to Elastic to change the query term without refreshing the result list but rather to populate
            Suggestions and Completions Lists
             */
            suggest.value = true;
            if (driver.getState().searchTerm != searchInputValue.value) {
              driver.getActions().setSearchTerm(searchInputValue.value, {
                  autocompleteResults: true,
                  refresh: false,
                  autocompleteSuggestions: true
              })
            }
        }

        function handleAutoCompleteSelection(value) {
            if (value){
                // We refer to the SearchHeader component with the ref variable "header" in order to call it's function.
                searchInputValue.value = header.value.stripHTML(value);
            }
            if (value != driver.getState().searchTerm){
              suggest.value = false;
              driver.getActions().setSearchTerm(searchInputValue.value);
            }
        }

        function handleFacetChange(event, facet) {
            /*
            Reads the event that causes a facet(filter) change in order to update Elastic Search Connection.
            In here we must either update and activate a filter or eliminate such.
             */
            const {value, checked} = event.target;
            const facetFromDriver = driver.getState().facets[facet][0];
            const valueforApi =
                facetFromDriver.type === "range"
                    ? facetFromDriver.data.find((item) => item.value.name === value).value
                    : value;
            if (checked && this[facet].indexOf(value)===-1) {
                // Range filter: {to: upper_bound, from: lower_bound, name: ""}
                driver.removeFilter(facet, {'name': 'dynamic'});
                this[facet].push(value);
                driver.addFilter(facet, valueforApi, "any");
            } else if (!checked) {
                const index = this[facet].indexOf(value);
                if (index > -1) {
                    this[facet].splice(index, 1);
                }
                driver.removeFilter(facet, valueforApi, "any");
            }

            // Si la faceta existe en version slider
            if (this["sl"+facet]) {
                // Restablecemos los valores de los sliders al valor por defecto
                this["sl" + facet] = this["sl" + facet + "default"]
            }
        }

        function setCurrentPage(page) {
            driver.setCurrent(page);
        }

        function thereAreResults() {
            return searchState.value.totalResults && searchState.value.totalResults > 0;
        }

        watch(resultsPerPage, async (newResultsPerPage, oldResultsPerPage) => {
            resultsPerPage.value = newResultsPerPage;
            driver.setResultsPerPage(newResultsPerPage);
        });

        watch(sortBy, async (newSortBy, oldSortBy) => {
            sortBy.value = newSortBy;
            driver.setSort(newSortBy, "asc");
        });

        function setResultsPerPageValue(value) {
            resultsPerPage.value = value;
        }

        onMounted(() => {
            // Ask Elastic about the actual state of the connection and update our internal variables.
            const {searchTerm, sortField, resultsPerPage, filters, facets} =
                driver.getState();

            // restoring UI from url query
            searchInputValue.value = searchTerm;
            sortBy.value = sortField;
            setResultsPerPageValue(resultsPerPage)

            // Ask ES about all the active facets and read our internal variables (with the same name) to update their values.
            // More information about the active facets in the current connection can be found at search_config.js
            filters.forEach((filter) => {
                if (facets[filter.field] && facets[filter.field][0].type === "range") {
                    this[filter.field] = filter.values.map((value) => value.name);
                } else if (facets[filter.field]) {
                    this[filter.field] = filter.values;
                }
            });

            // Tell Elastic to send State Updates callbacks
            driver.subscribeToStateChanges((state) => {
                searchState.value = state;
            });


        })

        const testValue = ref("");

        return {
          searchInputValue,
          searchState,
          url_portal,
          idioma,
          author,
          author_seo,
          nombre_facultad,
          nombre_area,
          resultsPerPage,
          sortBy,
          sortOptions,
          header,
          suggest,
          handleFormSubmit,
          handleAutoCompleteAction,
          handleAutoCompleteSelection,
          handleFacetChange,
          setCurrentPage,
          thereAreResults,
          debounceFilter: createDebounce(),
          testValue
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/search-engine/search-section.scss";
</style>
