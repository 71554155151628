<template>
    <v-container class="container-list-comment">
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/chat.svg')" alt="icono de typeform" width="28" height="28">
                    Listado comentarios
                </span>
            </v-col>
        </v-row>
        <v-row v-if="pending_language">
            <v-col cols="12">
                <span>Seleccione un idioma para continuar.</span>
            </v-col>
        </v-row>
        <template v-else>
            <template v-if="general_loading">
                <Loading/>
            </template>
            <template v-else>
                <!--FILTROS-->
                <v-row>
                    <v-col>
                        <CommentsFilters
                            :all_status="all_status"
                            :getFilters="getFilters"
                            :deleteFilters="deleteFilters"
                        ></CommentsFilters>
                    </v-col>
                </v-row>

                <!--SELECCIÓN MASIVA ESTADOS-->
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                            :disabled="check_comments_table.length<1"
                            label="Cambiar estado"
                            class="input-error select-primary field-with-label"
                            v-model="form_data.id_status_masive"
                            :items="all_status"
                            item-title="name"
                            item-value="id_status"
                            hide-details
                            variant="underlined"
                            @update:model-value="showConfirmationMessage(form_data.id_status_masive, null)"
                            no-data-text="No hay datos disponibles"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" class="text-end">
                        <v-btn class="btn button-bg-blue" @click="showForm(null, 'create')">Crear comentario genérico</v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <Vue3EasyDataTable
                            class="primary-table"
                            :headers="table_header"
                            :items="result_comments"
                            buttons-pagination
                            rowsPerPageOptions="10"
                            v-model:server-options="server_options"
                            v-model:items-selected="check_comments_table"
                            :server-items-length="serverItemsLength"
                            theme-color="#36A3FF"
                            :loading="loading_table"
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-estado="{status_name, id_comentario}">
                                <v-select
                                    :disabled="check_comments_table.length>=1"
                                    class="input-error select-primary selector-column"
                                    :items="all_status"
                                    v-model="form_data.id_status_item"
                                    :model-value="status_name"
                                    item-title="name"
                                    item-value="id_status"
                                    hide-details
                                    variant="underlined"
                                    @update:modelValue="showConfirmationMessage(form_data.id_status_item, id_comentario)"
                                    no-data-text="No hay datos disponibles"
                                >
                                </v-select>
                            </template>
                            <template #item-url="{url}">
                                <span v-if="variableIsDefined(url)">{{url}}</span>
                                <span v-else>Comentario genérico</span>
                            </template>
                            <template #item-include_opinions="{include_opinions}">
                                <span v-if="include_opinions === 1">SÍ</span>
                                <span v-else>NO</span>
                            </template>
                            <template #item-create_date="{create_date}">
                                {{ formatDate(create_date) }}
                            </template>
                            <template #item-update_date="{update_date}">
                                {{ formatDate(update_date) }}
                            </template>
                            <template #item-update_comment="result_comments">
                                <img :src="require('@/assets/icons/edit.svg')"
                                     alt="Modificar comentario"
                                     class="icon-edit-table"
                                     title="Modificar comentario"
                                     id="copy"
                                     @click="showForm(result_comments, 'update')"
                                >
                            </template>
                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>

    <v-dialog v-model="open_dialog" scrollable persistent>
        <FormComment :title_modal="title_modal"
                     :displayDialog="displayDialog"
                     :selected_comment="selected_item_comment"
                     :getAllComments="getAllComments"
        >
        </FormComment>
    </v-dialog>

</template>
<script>
import FormComment from "@/components/Comments/FormComment.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {computed, onBeforeMount, ref, watch} from "vue";
import {formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import store from "@/store";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {bulkChangeStatusWS, getAllCommentsWS, getAllStatusCommentsWS} from "@/utils/comments";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";
import CommentsFilters from "@/components/Comments/CommentsFilters.vue";


export default {
    name: "ListComments",
    methods: {variableIsDefined, formatDate},
    components: {CommentsFilters, Loading, FormComment, Vue3EasyDataTable},
    setup() {
        let route = useRoute();
        let language = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        let table_header = ref([
            {text: 'CURSO', value: 'url'},
            {text: 'USUARIO', value: 'nickname'},
            {text: 'PUNTUACIÓN', value: 'score'},
            {text: 'ESTADO', value: 'estado'},
            {text: 'MOSTRAR OPINIÓN', value: 'include_opinions'},
            {text: 'FECHA CREACIÓN', value: 'create_date'},
            {text: 'FECHA MODIFICACIÓN', value: 'update_date'},
            {text: 'CREADO POR', value: 'username'},
            {text: 'ACCIONES', value: 'update_comment'},
        ]);
        let serverItemsLength = ref(0);
        let server_options = ref({
            page: 1,
            rowsPerPage: 10
        });
        let general_loading = ref(false)
        let pending_language = ref(false);
        let loading_table = ref(false);

        let all_status = ref([]);
        let form_data = ref({});
        let check_comments_table = ref([]);
        let selected_item_comment = ref()
        let open_dialog = ref(false);
        let title_modal = ref()
        let result_comments = ref([]);

        let ids_comment;
        let accumulation_id_comment;
        let change_status;

        let options_filter = {}
        let filter_name_course = ''
        let filter_generic_comment = null


        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route)
            initSweetAlert()
            if (language.value !== 0) {
                await getAllComments();
                await getAllStatusComments();
            } else {
                pending_language.value = true;
            }
            general_loading.value = false
        })

        watch(portal, async () => {
            await getAllComments();
            await getAllStatusComments();
        });

        watch(server_options, value => {
            getAllComments();
        });

        function showForm(item_comment, type_action) {
            if(type_action==='create'){
                selected_item_comment.value = item_comment
                title_modal.value = "Crear comentario genérico"
            }else{
                selected_item_comment.value = item_comment
                title_modal.value = "Modificar comentario"
            }

            displayDialog()
        }

        async function showConfirmationMessage(id_status, id_comment) {
            change_status = id_status
            accumulation_id_comment = id_comment
            await fireSweetAlert("¿Está seguro de que quieres cambiar el estado?", await bulkChangeStatus, 'Podrá deshacer el cambio más adelante')
        }

        function displayDialog() {
            open_dialog.value = !open_dialog.value
        }

        async function getFilters(filter_search, name_filter, generic_comment_filter) {
            server_options.value.page = 1
            options_filter = filter_search
            filter_name_course = name_filter
            let filter_options = {
                'all': null,
                'generic': 1,
                'not_generic': 0
            };
            filter_generic_comment = filter_options[generic_comment_filter];
            await getAllComments()
        }

        async function deleteFilters() {
            options_filter = {}
            filter_name_course = ''
            filter_generic_comment = null
            await getAllComments()
        }

        async function getAllComments() {
            loading_table.value = true;
            result_comments.value = []
            let response = await getAllCommentsWS(JSON.stringify(options_filter), filter_name_course, filter_generic_comment, server_options.value)
            if (response['result']) {
                if (response['data']['data'].length > 0) {
                    result_comments.value = response['data']['data']
                    serverItemsLength.value = response['data'].total;
                }
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false;
        }

        async function getAllStatusComments() {
            let response = await getAllStatusCommentsWS();
            if (response['result']) {
                all_status.value = response['data']
            } else {
                showNotification("error", response['errors'])
            }
        }

        async function bulkChangeStatus() {
            ids_comment = [];

            if (check_comments_table.value.length >= 1) {
                check_comments_table.value.forEach(comment => {
                    ids_comment.push(comment.id_comentario)
                })
            } else {
                ids_comment.push(accumulation_id_comment)
            }

            let object_status = {
                'comments': ids_comment
            }

            let response = await bulkChangeStatusWS(change_status, JSON.stringify(object_status));
            if (response['data'] && response['result']) {
                showNotification("success", response['data'])
                form_data.value.id_status_masive = null
                await getAllComments();
            } else {
                showNotification("error", response['errors'])
            }
            check_comments_table.value = []
        }


        return {
            displayDialog,
            getAllComments,
            getAllStatusComments,
            showConfirmationMessage,
            getFilters,
            deleteFilters,
            showForm,

            pending_language,
            general_loading,
            loading_table,
            table_header,
            form_data,
            result_comments,
            serverItemsLength,
            server_options,
            all_status,
            title_modal,
            open_dialog,
            selected_item_comment,
            check_comments_table,
        }
    }
}

</script>
<style lang="scss">
@import "@/styles/views/list-comments.scss";
</style>