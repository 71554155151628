function formViewHtml(all_data, type){
    let view_html = '';
    all_data.forEach((data)=>{
        if(data[type]){
            view_html +=  `<div style="text-align: left">
                                <span style="font-size: 14px">- ${data[type]}</span><br>
                            </div>`;
        }
    })
    return view_html
}

export function viewHtml(data_response){
    let notification_html = '';

    /*SUCCESS*/
    if (data_response.some(resp => resp.success)) {
        notification_html += `
                <div style="text-align: left">
                    <p style="color: #21E228; font-size: 16px">Éxito</p>
                    <div style="text-align: left; margin-left: 20px;">
                       ${formViewHtml(data_response, 'success')}
                    </div>
                </div>
            <hr>`;
    }

    /*WARNING*/
    if (data_response.some(resp => resp.warning)) {
        notification_html += `
                <div style="text-align: left">
                    <p style="color: #01afea; font-size: 16px">Advertencia</p>
                    <div style="text-align: left; margin-left: 20px;">
                          ${formViewHtml(data_response, 'warning')}
                    </div>
                </div>
            <hr>`;
    }

    /*ERROR*/
    if (data_response.some(resp => resp.error)) {
        notification_html += `
                <div style="text-align: left">
                    <p style="color: #f83850; font-size: 16px">Error</p>
                    <div style="text-align: left; margin-left: 20px;">
                         ${formViewHtml(data_response, 'error')}
                    </div>
                </div>
            <hr>`;
    }

    return notification_html;
}