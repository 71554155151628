import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function getAllLanguagesWS(form_filter) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'id_idioma' : form_filter.id_language ?? null,
        'activo' : form_filter.active ?? null,
        'nombre_idioma' : form_filter.name ?? null,
        'id_language_base' : form_filter.id_language_base ?? null,
        'iso_2' : form_filter.iso_2 ?? null,
        'iso_6391' : form_filter.iso_6391 ?? null,
        'exact_search' : form_filter.exact_search ?? 0,
    }

    try {
        return await aiseoBackendPetition('POST', 'language/getLanguages', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/getLanguages');
    }
}


export async function insertLanguageWS(data) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'nombre': data.nombre,
        'iso_6391': data.iso_6391,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'activo': data.activo !== null ? data.activo : 0,
        'id_idioma_base': data.id_idioma_base,
        'iso_2': data.iso_2,
        'iso_6391': data.iso_6391,
        'nombre': data.nombre,
    }

    try {
        return await aiseoBackendPetition('POST', 'language/insertLanguage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/insertLanguage');
    }
}

export async function updateLanguageWS(data) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_idioma': data.id_idioma,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_idioma': data.id_idioma,
        'activo': data.activo !== null ? data.activo : 0,
        'id_idioma_base': data.id_idioma_base,
        'iso_2': data.iso_2,
        'iso_6391': data.iso_6391,
        'nombre': data.nombre,
    }

    try {
        return await aiseoBackendPetition('POST', 'language/updateLanguage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/updateLanguage');
    }
}


export async function getAllLanguagesIso6391WS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'language/getAllLanguagesISO6391', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/getAllLanguagesISO6391');
    }
}

export async function disableLanguageWS(id_language, state) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_idioma': id_language,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_idioma': id_language,
        'activo' : state
    }

    try {
        return await aiseoBackendPetition('POST', 'language/disableLanguage', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/disableLanguage');
    }
}






