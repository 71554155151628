import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import axios from "axios";
import {createTokenAISEO, showNotification, variableIsDefined} from "@/utils/aux_functions";


/*
*
* OBTIENE TODAS LAS
* REDIRECCIONES DE UNA URL
* */

export async function getRedirectionsByURLWS(form_data, page = 1, rows_per_page = 20,) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'URL': form_data.redirection_name,
        'strict': form_data.strict ? 1 : 0,
        'page_size': rows_per_page,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/getRedireccionesByURL?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/getRedirecciones');
    }
}

/*
*
* ELIMINA LAS URLS
* SELECCIONADAS
* */
export async function removeRedireccionesByURLWS(ids_redirections) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'redirectionsIDs': ids_redirections,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/removeRedireccionesByURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/removeRedireccionesByURL');
    }
}




/*
*
* OBTIENE TODAS LAS
* REDIRECCIONES DE UN PORTAL
* E IDIOMA
* */

export async function getPortalRedirectionsWS(page, rows_per_page, url_origin, strict = true) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'usuario_logueado': store.getters.getUsername,
        'originPortal': store.getters.getPortalID,
        'originLanguageID': store.getters.getLanguageID,
        'strict': strict ? 1 : 0,
        'page_size': rows_per_page
    };

    if (variableIsDefined(url_origin)){
        params['URL'] = url_origin
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/getRedirecciones?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/getRedirecciones');
    }
}


/*
*
* LLAMA AL WS PARA ACTUALIZAR
* LA REDIRECCIÓN DE UNA URL
*
* */

export async function updateRedirectURLWS(data) {
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    let params = {
        'id_redireccion': data.id_redireccion,
        'codigoHTTP': parseInt(data.redirection_type),
        'activo': 1,
        'destinyURL': (data.redirection_type === 301 ? data.redirection_url : ''),
        'force' : data['force'],
        'URLabsoluta' : data['absolute_url'],
    };

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/updateRedireccionURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/seoPanel/updateRedireccionURL');
    }
}


/*
*
* LLAMA AL WS PARA ELIMINAR
* LA REDIRECCIÓN DE UNA URL
*
* */

export async function removeRedirectURLWS(id_redireccion) {
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    let params = {
        id_redirecciones: id_redireccion,
    };

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/removeRedireccionURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/seoPanel/removeRedireccionURL');
    }
}

/*
*
* LLAMA AL WS PARA AÑADIR
* UNA REDIRECCIÓN A UNA URL
*
* */
export async function redirectURLWS(data, url_origin) {
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    let params = {
        'originPortal': store.getters.getPortalID,
        'originLanguageID': store.getters.getLanguageID,
        'originURL': url_origin,
        'codigoHTTP': parseInt(data.redirection_type),
        'destinyURL': (data.redirection_type === 301 ? data.redirection_url : ''),
        'activo': 1,
        'force' : data['force'],
        'URLabsoluta' : data['absolute_url'],
    };

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/createRedireccionURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/seoPanel/createRedireccionURL');
    }

}

export async function importRedirectionsWS(imported_file){
    let formData = new FormData();
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    formData.append('file', imported_file['imported_file'])
    formData.append('usuario_logueado', store.getters.getUsername)
    formData.append('token', createTokenAISEO(params_token))

    try {
        return $.ajax({
            url: process.env.VUE_APP_AISEO_BACKEND_URL + 'importRedirections/import',
            type: 'post',
            contentType: false,
            processData: false,
            data: formData
        })
    }catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'importRedirections/import');
    }
}

/*OBTIENE TODOS LOS ELEMENTOS EN LA COLA DE REDIRECCIONES*/

export async function getAllElementsRedirectionsWS(page, rows_per_page, {origin_url, destiny_url, state, tries, orderBy, orderDirection}) {
    const params_token = {
        usuario_logueado: store.getters.getUsername,
    };

    const params = {
        'pageSize': rows_per_page,
        'originURL': origin_url,
        'destinyURL' : destiny_url,
        'estadoID' : state,
        'reintentos' : tries,
        'orderBy': orderBy,
        'orderDirection': orderDirection,
    };

    try {
        return await aiseoBackendPetition('POST', 'queueRedirections/getAllElementsRedirections?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueRedirections/getAllElementsRedirections');
    }
}

export async function changeStateElementRedirectionsWS (id_redirection, id_state){
    const params_token= {
        usuario_logueado: store.getters.getUsername,
    }
    const params = {
        id_state: id_state
    }
    
    try {
        return await aiseoBackendPetition('POST', 'queueRedirections/changeStateElementRedirections/'+id_redirection, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueRedirections/changeStateElementRedirections/');
    }
}

export async function getLogElementRedirectionWS(id, file){
    let params_token= {
        usuario_logueado: store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'queueRedirections/getLogElementRedirections/'+id, {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueRedirections/getLogElementRedirections/');
    }
}


/*LLAMA AL WS PARA AÑADIR UNA REDIRECCIÓN MASIVA*/
export async function createRedireccionesByURLWS(data, portals_language) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'originPortalLanguageID': portals_language,
        'originURL': data.origin_url ,
        'codigoHTTP': data.redirection_type,
        'destinyURL': data.destiny_url,
        'activo': 1 ,
        'force': data.force,
    };

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/createRedireccionesByURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/createRedireccionesByURL');
    }

}

/*LLAMA AL WS PARA AÑADIR A COLA LA CREACIÓN DEL FICHERO DE REDIRECCIONES*/
export async function queueExportRedirectionsFileWS(filter_origin_url, filter_exact_search) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let filters = {
        'originPortal': store.getters.getPortalID,
        'originLanguageID': store.getters.getLanguageID,
        'strict' : filter_exact_search ? 1 : 0,
    }

    if (variableIsDefined(filter_origin_url)){
        filters['URL'] = filter_origin_url
    }

    let params = {
        'screen': "redirecciones",
        "params": filters
    };

    try {
        return await aiseoBackendPetition('POST', 'queueExportFile/setElement', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'queueExportFile/setElement');
    }

}