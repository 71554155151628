<template>
    <nav class="navbar navbar-expand-lg w-100 mt-1">
        <v-container
            fluid
            class="container-menu"
        >
            <div class="d-flex justify-content-start ms-4 align-items-center">
                <router-link class="navbar-brand" to="/" @click="backInicio">
                    <img src="@/assets/logos/logotipo-eduseo.ai-full-color.svg" alt="EDUSEO" width="181" height="32"
                         class="d-inline-block align-text-top"
                         title="Inicio"
                         id="logo-aiseo-menu">
                </router-link>
            </div>

            <div class="d-flex icon-set" :class="`${!is_mobile ?? 'justify-content-end'}`">
                <LanguageSelector :renderApp="renderApp" :is_mobile="is_mobile"></LanguageSelector>

                <div v-if="!is_mobile" class="docs-btn-container" :class="{ 'active-link': this.$route.path === '/documentacion' }">
                    <router-link to="/documentacion">
                        <img id="doc-icon" src="@/assets/icons/navbar/new-doc-icon.svg" alt="icono documentación" width="32" height="32">
                    </router-link>
                </div>
                <!--
                <router-link to="/alertas">
                    <img src="@/assets/icons/bell.svg" class="admin-link" alt="icono campana">
                </router-link>
                -->
                <v-menu v-if="!is_mobile" class="select-menu">
                    <template v-slot:activator="{ props }">
                        <div id="btn-user" role="button" :class="{ 'open-profile': props['aria-expanded'] === 'true'}">
                            <div id="logo-user" v-bind="props">
                                <v-img v-if="variableIsDefined(current_image)" id="icon-user" :src="current_image" width="48" height="48" @error="handleImageError" ></v-img>
                                <span v-else id="icon-user">{{ user_initial }}</span>
                            </div>
                        </div>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <span class="dropdown-item-text" id="full-name">{{ username }}</span>
                            </v-list-item-title>
                        </v-list-item>

                        <router-link class="dropdown-item" to="/admin" v-if="admin_access">
                            <v-list-item>
                                <v-list-item-title>
                                    <span class="dropdown-item-text">Panel admin</span>
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>
                        <router-link class="dropdown-item" id="log-out" to="" @click="logout">
                            <v-list-item>
                                <v-list-item-title>
                                    <span class="dropdown-item-text">Cerrar sesión</span>
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </v-menu>
            </div>
        </v-container>
    </nav>
</template>


<script>
import AuthView from "@/views/Auth/AuthView.vue";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {logout, userHaveAllowedRol} from "@/utils/auth";
import LanguageSelector from "@/components/Navigation/LanguageSelector.vue";
import {useRoute} from "vue-router";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "NavBar",
    methods: {variableIsDefined},
    components: {
        LanguageSelector,
        AuthView,
        Sidebar
    },
    props: {
        renderApp: Function,
        is_mobile: Boolean,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        let username = computed(() => store.getters.getUsername).value
        let user_initial = (username.substring(0, 1).charAt(0).toUpperCase());
        let admin_access = ref(false);
        let img_src = computed(() => store.getters.getUserPic).value
        let current_image = ref(img_src)

        onBeforeMount( () => {
            admin_access.value = userHaveAllowedRol("ROOT");
        })

        const backInicio = () => {
            store.commit('setChildrenMenu', false);
            localStorage.setItem('children_menu', JSON.stringify(false))
        }

        const handleImageError = () => {
            current_image.value = null;
        }

        return {
            handleImageError,
            backInicio,
            username,
            user_initial,
            admin_access,
            route,
            current_image,
            logout
        }
    }

}
</script>
<style lang="scss">

@media (max-width: 990px) {
    .navbar-expand-lg{
        padding: 0;
        margin: 0;
        height: 100%;
    }
}

//@import "@/styles/components/navbar.scss";
</style>

