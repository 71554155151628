<template>
    <v-card class="card-home">
        <v-card-text :class="{'disabled-card': disabled}">
            <v-row class="flex-column">
                <v-col class="col-card-img">
                    <img class="img-home" :src="route_img" :alt="route_alt" width="32" height="32">
                </v-col>
                <v-col class="col-card-title">
                    <span class="title-home">{{ route_name }}</span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "HomeCard",
    props: {
        route_name: String,
        route_img: String,
        route_alt: String,
        disabled: {
            type: Boolean,
            default: false,
        }
    },
}

</script>

<style scoped lang="scss">
@import "@/styles/components/home-card.scss";
</style>

