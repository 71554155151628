<template>
    <BaseModalTemplate
        :title="options_modal.title_modal"
        :open-close-dialog="displayModal"
        :loading="general_loading"
        :hide_actions="options_modal.see_btn_actions"
    >

        <template #body-modal-content>
            <v-row>
                <!--NOMBRE-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Nombre *</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.name}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.name"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.name"
                          class="input-error msg-error">{{ form_error.name }}</span>
                </v-col>

                <!--URL-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Url *</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.url}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.url"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.url"
                          class="input-error msg-error">{{ form_error.url }}</span>
                </v-col>

                <!--URL CANONICA-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Url Canónica *</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.url_canonica}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.url_canonica"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.url_canonica"
                          class="input-error msg-error">{{ form_error.url_canonica }}</span>
                </v-col>

                <!--LOGO-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Logo *</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.logo}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.logo"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.logo"
                          class="input-error msg-error">{{ form_error.logo }}</span>
                </v-col>

                <!--PORTAL INNOTUTOR-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Portal Difusión Innotutor *</span>
                    <v-autocomplete
                        v-model="portal_diff_innotutor.id"
                        :title="portal_diff_innotutor.name"
                        :items="items_portals_diffusion_innotutor"
                        item-title="nombre"
                        item-value="id"
                        variant="underlined"
                        :class="{error :form_error.portal_diff_innotutor}"
                        class="select-primary"
                        :disabled="options_modal.disabled_field"
                        :menu-props="{width: '200px'}"
                        no-data-text="No hay datos disponibles"
                        hide-details
                    ></v-autocomplete>
                    <span v-if="form_error.portal_diff_innotutor"
                          class="input-error msg-error">{{ form_error.portal_diff_innotutor }}</span>
                </v-col>

                <!--PAIS-->
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">País </span>
                    <v-autocomplete
                        v-model="form_data.id_country"
                        :items="item_countries"
                        item-title="nombre"
                        item-value="id_pais"
                        variant="underlined"
                        class="select-primary"
                        :disabled="options_modal.disabled_field"
                        hide-details
                        :menu-props="{width: '200px'}"
                        no-data-text="No hay datos disponibles"
                    ></v-autocomplete>
                </v-col>

                <!--SCORE-->
                <v-col cols="12" sm="6" md="2">
                    <span class="input-title">Score</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.score}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.score"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.score"
                          class="input-error msg-error">{{ form_error.score }}</span>
                </v-col>

                <!--WS PUSH-->
                <v-col cols="12" sm="6" md="2">
                    <span class="input-title">WS Push</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.ws_push}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.ws_push"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.ws_push"
                          class="input-error msg-error">{{ form_error.ws_push }}</span>
                </v-col>

                <!--ID ACCURANKER-->
                <v-col cols="12" sm="6" md="3">
                    <span class="input-title">ID AccuRanker</span>
                    <v-text-field variant="underlined"
                                  :class="{error :form_error.id_accuranker}"
                                  class="input-error text-field-primary"
                                  v-model.trim="form_data.id_accuranker"
                                  :disabled="options_modal.disabled_field"
                                  hide-details
                    ></v-text-field>
                    <span v-if="form_error.id_accuranker"
                          class="input-error msg-error">{{ form_error.id_accuranker }}</span>
                </v-col>

                <!--TIPO PORTAL-->
                <v-col cols="12" sm="6" md="3">
                    <span class="input-title">Tipo portal</span>
                    <v-select
                        v-model="form_data.portal_type"
                        :items="['Portal','Vertical']"
                        class="select-primary"
                        variant="underlined"
                        hide-details
                        :disabled="options_modal.disabled_field"
                        no-data-text="No hay datos disponibles"
                    >
                    </v-select>
                </v-col>

                <!--ACTIVE-->
                <v-col v-if="options_modal.option ==='details'" cols="12" sm="6" md="2">
                    <span class="input-title">Estado: </span>
                    <v-text-field
                        v-model="form_data.active"
                        :value="form_data.active === 1 ? 'Activo' : (form_data.active === 0 ? 'Inactivo' : '')"
                        variant="underlined"
                        class="select-primary"
                        :disabled="options_modal.disabled_field"
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-else>
                    <v-checkbox
                        label="ACTIVAR PORTAL"
                        v-model="form_data.active"
                        :true-value="1"
                        :false-value="0"
                        color="blue"
                        :disabled="options_modal.disabled_field"
                        hide-details
                    ></v-checkbox>
                </v-col>


                <v-col cols="12" sm="8" md="4" v-if="options_modal.add_language && options_modal.see_language">
                    <v-row>
                        <v-col cols="12">
                            <span class="input-title">Añadir idioma al portal</span>
                        </v-col>
                        <v-col cols="12" :md="options_modal.option === 'create' ? '8' : '12'">
                            <v-autocomplete
                                placeholder="Seleccione un idioma"
                                :items="all_languages"
                                item-title="nombre"
                                item-value="id_idioma"
                                v-model="id_language_selected"
                                :class="{error :form_error.id_language_selected}"
                                variant="underlined"
                                class="input-error select-primary"
                                @update:modelValue="displayDialogInsertLanguage"
                                hide-details
                                no-data-text="No hay datos disponibles"
                                :menu-props="{width: '200px'}"
                            ></v-autocomplete>
                            <span v-if="form_error.id_language_selected"
                                  class="input-error msg-error">{{ form_error.id_language_selected }}</span>
                        </v-col>
                        <v-col cols="12" :md="options_modal.option === 'create' ? '4' : '12'" class="mt-2"
                               v-if="options_modal.option === 'create'">
                            <v-btn class="btn button-primary" @click="addLanguage">Añadir</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" v-if="!loading_language || options_modal.see_language">
                    <v-row>
                        <v-col cols="12">
                            <span class="input-title">{{ msg_language }}</span>
                        </v-col>
                        <v-col v-for="language in data_language_portal" :key="language.id_idioma" cols="12"
                               xs="12"
                               v-if="data_language_portal.length>0"
                               sm="auto"
                               class="boxes-languages">
                            <v-card class="box_language">
                                <v-radio-group v-model="main_language" inline hide-details>
                                    <v-radio :value="language.id_idioma"
                                             v-if="options_modal.add_language && options_modal.see_language"
                                             @click.prevent="displayDialogMainLanguage(language.id_idioma)">
                                        <v-tooltip
                                            activator="parent"
                                            location="top"
                                        >Idioma principal
                                        </v-tooltip>
                                    </v-radio>
                                    <v-icon
                                        v-if="!options_modal.add_language && options_modal.see_language && main_language === language.id_idioma"
                                        end size="small" class="align-self-center"
                                        icon="mdi-checkbox-marked-circle" title="Idioma principal"
                                    ></v-icon>
                                    <v-card-text class="text-area align-self-center">
                                        {{ language.nombre }}
                                    </v-card-text>
                                    <div class="align-self-center p-2"
                                         v-if="options_modal.add_language && options_modal.see_language && data_language_portal.length > 1 ">
                                        <v-icon
                                            class="icons-close"
                                            @click="displayDialogDeleteLanguage(language.id_idioma)"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </div>
                                </v-radio-group>
                            </v-card>
                        </v-col>
                        <v-col v-else>
                             <span
                                 :style="{ 'font-style': 'italic', 'color': options_modal.option === 'details' ? '#cccccc' : '' }">
                                No hay ningún idioma para este portal
                             </span>
                        </v-col>
                        <v-col cols="12">
                            <span v-if="form_error.main_language"
                                  class="input-error msg-error">{{ form_error.main_language }}</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-else cols="12" md="8">
                    <v-row>
                        <v-col cols="12">
                            <span class="input-title">{{ msg_language }}</span>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12" class="text-end">
                    <v-btn class="btn button-primary" @click="savePortal" :loading="disabled_save">
                        {{ options_modal.title_btn }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {
    createPortalWS,
    getPortalsDiffusionInnotutorWS,
    updatePortalWS
} from "@/utils/portal";
import {objectsHasSameInfo, showNotification, variableIsDefined} from "@/utils/aux_functions";
import * as Yup from "yup";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {
    deletePortalLanguageWS,
    getLanguagesPortalWS,
    insertPortalLanguageWS,
    updateMainLanguageOfPortalWS
} from "@/utils/portal_language";
import {getAllLanguagesWS} from "@/utils/language";
import {getCharacterCount} from "@/utils/editor_course_blog";


export default {
    name: "ModalPortal",
    components: {BaseModalTemplate, Loading},
    props: {
        displayModal: Function,
        getPortals: Function,
        data_obtained_ws: Object,
        item_countries: Array,
        options_modal: Object,
    },
    setup(props) {
        let general_loading = ref(false)
        let form_data = ref({})
        let form_error = ref({})
        let portal_diff_innotutor = ref({
            name: null,
            id: null
        })
        let status = ref([
            {name: 'Activo', value: 1,},
            {name: 'Inactivo', value: 0,}
        ])
        let items_portals_diffusion_innotutor = ref([])
        let errors_ws = ref([])
        let disabled_save = ref(false)
        let all_languages = ref([])
        let data_language_portal = ref([])
        let msg_language = ref("Idioma asociado")
        let id_language_selected = ref([])
        let id_language_delete;
        let loading_language = ref(false)
        let main_language = ref('')
        let language_selected_main = ''
        let control_language_changes = false
        let reserve_main_language = ''

        onBeforeMount(async () => {
            general_loading.value = true
            await getPortalsDiffusionInnotutor()
            await getAllLanguages()
            if (variableIsDefined(props.data_obtained_ws)) {
                form_data.value = _.cloneDeep(props.data_obtained_ws);
                items_portals_diffusion_innotutor.value.forEach((portal_diff) => {
                    if (portal_diff.id === form_data.value.id_portal_diffusion_innotutor) {
                        portal_diff_innotutor.value = {
                            'name': portal_diff.nombre,
                            'id': portal_diff.id
                        }
                    }
                })
                await getLanguagePortal()
            }
            initSweetAlert()
            general_loading.value = false
        })


        watch(data_language_portal, (new_value, old_value) => {
            if (new_value.length !== old_value.length) {
                control_language_changes = true
            }
        });

        watch(main_language, async () => {
            if (main_language.value !== reserve_main_language) {
                control_language_changes = true
            }
        });


        async function getAllLanguages() {
            let response = await getAllLanguagesWS({'active': 1})
            if (response['result']) {
                all_languages.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function getLanguagePortal() {
            data_language_portal.value = []
            let response = await getLanguagesPortalWS(props.data_obtained_ws.id_portal)
            if (response['result']) {
                if (response['data'].length > 0) {
                    msg_language.value = response['data'].length === 1 ? 'Idioma asociado:' : 'Idiomas asociados:';
                    all_languages.value.forEach((language) => {
                        response['data'].forEach((language_portal) => {
                            if (language_portal.id_idioma === language.id_idioma) {
                                data_language_portal.value.push(language)
                                if (language_portal.peso === 0) {
                                    main_language.value = language.id_idioma
                                    reserve_main_language = language.id_idioma
                                }
                            }
                        })
                    })
                }
            } else {
                showNotification('error', response['errors'])
            }
        }


        async function getPortalsDiffusionInnotutor() {
            let response = await getPortalsDiffusionInnotutorWS()
            if (response['result']) {
                items_portals_diffusion_innotutor.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function validateForm() {
            let correct = true;
            form_error.value = {};

            const schemaForm =
                Yup.object().shape({
                    name: Yup.string().required(true),
                    url: Yup.string().required(true),
                    url_canonica: Yup.string().required(true),
                    logo: Yup.string().required(true),
                })

            if (!variableIsDefined(portal_diff_innotutor.value.id)) {
                form_error.value.portal_diff_innotutor = 'El campo es obligatorio'
                correct = false
            }

            if (data_language_portal.value.length === 0) {
                form_error.value.id_language_selected = 'Debes añadir un idioma para este portal'
                correct = false
            }

            if (!variableIsDefined(main_language.value)) {
                form_error.value.main_language = 'Debes asignar un idioma como principal'
                correct = false
            }

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "El campo es obligatorio"
                })
                correct = false
            }
            if (correct) {
                let validate_number = await validateNumber()
                let validate_url = await validateUrls()
                correct = validate_number && validate_url;
            }
            return correct
        }

        async function validateNumber() {
            let correct
            const schemaForm = Yup.object().shape({
                score: Yup.number().typeError("El campo debe ser un número").oneOf([0, 1], "El campo debe ser 0 o 1"),
                ws_push: Yup.number().typeError("El campo debe ser un número").oneOf([0, 1], "El campo debe ser 0 o 1"),
                id_accuranker: Yup.number().typeError("El campo debe ser un número"),
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                correct = true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                correct = false
            }
            return correct
        }

        async function validateUrls() {
            let correct_url = true
            const regexURL = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(\/\S*)?$/i;
            if (!regexURL.test(form_data.value.url)) {
                form_error.value.url = 'Error en el formato de la url'
                correct_url = false
            }
            if (!regexURL.test(form_data.value.url_canonica)) {
                form_error.value.url_canonica = 'Error en el formato de la url'
                correct_url = false
            }
            return correct_url
        }

        async function savePortal() {
            if (props.options_modal.option === 'create' || props.options_modal.option === 'update') {
                if (await validateForm()) {
                    disabled_save.value = true
                    errors_ws.value = []
                    if (variableIsDefined(props.data_obtained_ws)) {
                        await updatePortal()
                    } else {
                        await createPortal()
                    }
                    disabled_save.value = false
                }
            } else {
                props.displayModal()
            }
        }

        async function createPortal() {
            form_data.value.id_portal_diffusion_innotutor = portal_diff_innotutor.value.id
            let response = await createPortalWS(form_data.value)
            if (response['result']) {
                if (variableIsDefined(response['data']['id_portal'])) {
                    await addLanguageNewPortal(response['data']['id_portal'])
                    await mainLanguageAssignment(response['data']['id_portal'])
                }
                showNotification('success', response['data']['message'])
                await props.getPortals()
                props.displayModal()
            } else {
                response['errors'].forEach((error) => {
                    errors_ws.value.push(error.title)
                    showNotification('error', errors_ws.value, true)
                })
            }
        }

        async function addLanguageNewPortal(new_portal) {
            for (const lenguage of data_language_portal.value) {
                let response = await insertPortalLanguageWS(new_portal, lenguage.id_idioma)
                if (!response['result']) {
                    showNotification('error', response['errors'])
                }
            }
        }

        async function updatePortal() {
            form_data.value.id_portal_diffusion_innotutor = portal_diff_innotutor.value.id
            if (!objectsHasSameInfo(props.data_obtained_ws, form_data.value, true)) {
                let response = await updatePortalWS(form_data.value)
                if (response['result']) {
                    showNotification('success', response['data']['message'])
                    await props.getPortals()

                } else {
                    response['errors'].forEach((error) => {
                        errors_ws.value.push(error.title)
                        showNotification('error', errors_ws.value, true)
                    })
                }
            } else {
                if (control_language_changes === false) {
                    showNotification('info', 'No se han registrado cambios')
                }
            }
            props.displayModal()
        }

        function addLanguage() {
            form_error.value.id_language_selected = ''
            form_error.value.main_language = ''
            const addedLanguage = data_language_portal.value.some(language => language.id_idioma === id_language_selected.value);
            if (!addedLanguage) {
                all_languages.value.forEach((language) => {
                    if (language.id_idioma === id_language_selected.value) {
                        data_language_portal.value.push(language)
                    }
                })
                id_language_selected.value = []
            } else {
                form_error.value.id_language_selected = 'El idioma ya ha sido añadido'
            }
        }

        function getLanguageName(id_language) {
            let name_language
            all_languages.value.forEach((language) => {
                if (language.id_idioma === id_language) {
                    name_language = language.nombre
                }
            })
            return name_language
        }

        function displayDialogInsertLanguage() {
            if (props.options_modal.option === 'update') {
                if (variableIsDefined(id_language_selected.value)) {
                    fireSweetAlert('Añadir Idioma', insertLanguagePortal, `¿Quieres añadir el idioma ${getLanguageName(id_language_selected.value)} para este portal?`)
                }
            }
        }

        async function insertLanguagePortal() {
            loading_language.value = true
            let can_insert = true;
            data_language_portal.value.forEach((language) => {
                if (language.id_idioma === id_language_selected.value) {
                    can_insert = false
                }
            })
            if (can_insert) {
                let response = await insertPortalLanguageWS(props.data_obtained_ws.id_portal, id_language_selected.value)
                if (response['result']) {
                    showNotification('success', response['data']['message'])
                    await getLanguagePortal()
                    id_language_selected.value = []

                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                showNotification('info', 'El idioma ya está insertado en este portal')
            }

            loading_language.value = false
        }

        function displayDialogDeleteLanguage(id_language) {
            if (props.options_modal.option === 'create') {
                const index = data_language_portal.value.findIndex(element => element.id_idioma === id_language);
                if (index !== -1) {
                    if (id_language === main_language.value) {
                        main_language.value = ''
                    }
                    data_language_portal.value.splice(index, 1);
                }
            } else if (props.options_modal.option === 'update') {
                id_language_delete = id_language

                fireSweetAlert('Eliminar idioma', deleteLanguage, `¿Quieres eliminar el idioma ${getLanguageName(id_language_delete)} para este portal?`)
            }
        }

        async function deleteLanguage() {
            loading_language.value = true
            let response = await deletePortalLanguageWS(props.data_obtained_ws.id_portal, id_language_delete)
            if (response['result']) {
                showNotification('success', response['data']['message'])
                await getLanguagePortal()
            } else {
                showNotification('error', response['errors'])
            }
            loading_language.value = false
        }

        function displayDialogMainLanguage(id_language) {
            language_selected_main = id_language
            if (props.options_modal.option === 'create') {
                main_language.value = id_language
            } else if (props.options_modal.option === 'update') {
                if (id_language !== main_language.value) {
                    fireSweetAlert('Idioma principal', mainLanguageAssignment, `¿Quieres marcar como principal el idioma ${getLanguageName(id_language)}?`)
                }
            }
        }


        async function mainLanguageAssignment(new_id_portal) {
            loading_language.value = true
            if (variableIsDefined(language_selected_main)) {
                if (props.options_modal.option === 'create') {
                    await updateMainLanguage(new_id_portal, language_selected_main)
                } else if (props.options_modal.option === 'update') {
                    await updateMainLanguage(props.data_obtained_ws.id_portal, language_selected_main)
                }
            }
            loading_language.value = false
        }

        async function updateMainLanguage(portal_id, language_id) {
            let response = await updateMainLanguageOfPortalWS(portal_id, language_id)
            if (response['result']) {
                showNotification('success', response['data']['message'])
                main_language.value = language_id
            } else {
                showNotification('error', response['errors'], true)
            }
        }


        return {
            savePortal,
            displayDialogInsertLanguage,
            displayDialogDeleteLanguage,
            displayDialogMainLanguage,
            addLanguage,

            general_loading,
            loading_language,
            form_data,
            form_error,
            items_portals_diffusion_innotutor,
            portal_diff_innotutor,
            errors_ws,
            disabled_save,
            all_languages,
            data_language_portal,
            msg_language,
            id_language_selected,
            main_language,
            status,

        }
    }
}
</script>


<style scoped lang="scss">
.card-modal .body-modal .content-modal {
    min-height: 30vh
}

.boxes-languages {
    .box_language {
        box-shadow: none;
        height: auto;
        width: fit-content;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $blue-medium;
        border: solid $blue-medium;
        border-width: 1px 1px;

        .text-area {
            padding: 3px 15px;
        }

        .icons-close {
            filter: brightness(0) saturate(100%) invert(90%) sepia(17%) saturate(3681%) hue-rotate(321deg) brightness(96%) contrast(98%);
        }

        .icons-loading {
            display: none;
        }
    }
}
</style>
