<template>
    <v-col v-if="loading">
        <div class="general-loading">
            <Loading class_loading="loading-gif-s" />
        </div>
    </v-col>
    <v-col v-else v-for="titulation in all_titulation" cols="12" md="4" lg="3">
        <v-checkbox v-model="ranking_actives_course" class="check-active"
                    :value="checkTitulation(titulation)"
                    :disabled="disableCheckBoxs(titulation)"
                    @update:modelValue="updateNewCheckBox(titulation)">
            <template v-slot:label>
                <div class="div-check">
                    <div class="box-title">
                        <span class="title-checkboxs"> {{ titulation.title }}</span>
                    </div>
                    <v-tooltip location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img class="image-checkbox" :src=titulation.img_url></v-img>
                        </template>
                    </v-tooltip>
                </div>
            </template>
        </v-checkbox>
    </v-col>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "CheckBoxesImage",
    components: {Loading},
    props: {
        /*ranking*/
        all_ranking: Object,
        ranking_actives_course: Array,
        checkIdActive: Function,
        selectCheckBox: Function,
        ranking: Boolean,

        /*diplomas y logos*/
        check_boxes_active: Object,
        type_degree: String,
        view_select_type: Object,
        addSelection: Function,
        select_type: String,

    },
    setup(props) {
        let ranking_actives_course = ref({})
        let all_titulation = ref({})
        let loading = ref(false)


        onBeforeMount(async () => {
            loading.value = true
            ranking_actives_course.value = {}
            all_titulation.value = {}
            if (props.ranking) {
                all_titulation.value = props.all_ranking
                ranking_actives_course.value = props.ranking_actives_course
            } else {
                all_titulation.value = props.view_select_type
                if(variableIsDefined(props.check_boxes_active[props.type_degree])){
                    ranking_actives_course.value = props.check_boxes_active[props.type_degree]
                }
            }
            loading.value = false
        })

        watch(
            () => props.view_select_type,
            () => {
                all_titulation.value = props.view_select_type
                if(variableIsDefined(props.check_boxes_active[props.type_degree])){
                    ranking_actives_course.value = props.check_boxes_active[props.type_degree]
                }
            }
        )


        function checkTitulation(titulation) {
            if (props.ranking) {
                return titulation.id_ranking
            } else {
                return titulation[props.type_degree]
            }
        }

        function disableCheckBoxs(titulation) {
            if (props.ranking) {
                return props.checkIdActive(titulation.id_ranking)
            } else {
                return false
            }
        }

        function updateNewCheckBox(check_titulation) {
            if (props.ranking) {
                props.selectCheckBox(check_titulation.img_url, ranking_actives_course.value)
            } else {
                props.addSelection(props.select_type, check_titulation[props.type_degree])
            }

        }

        return {
            updateNewCheckBox,
            checkTitulation,
            disableCheckBoxs,
            ranking_actives_course,
            all_titulation,
            loading,
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/styles/components/check-boxs-image";
</style>