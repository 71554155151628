import {showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import store from "@/store";

export async function createURLWS(urls_data, related, id_portal = 0, id_idioma = 0, url = 0) {
    //Enviar los datos
    let params = {
        'urlsData': JSON.stringify(urls_data),
    }
    if (related) {
        params['originPortal'] = id_portal;
        params['originLanguageID'] = id_idioma;
        params['originURL'] = url;
    }

    let params_token = {
        'urlsData': JSON.stringify(urls_data),
        'usuario_logueado': store.getters.getUsername
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/createURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/createURL');
    }

}

export async function getURLVersionsWS(id_portal, id_language, url) {
    let params = {
        portal: id_portal,
        languageID: id_language,
        url: url,
    }
    try {
        return await aiseoBackendPetition('POST', 'getURLVersions', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getURLVersions');
    }
}


export async function getCompaniesWS () {
    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'getCompanies', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getCompanies');
    }
}

export async function getProgramsWS () {
    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'getPrograms', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getPrograms');
    }
}

export async function getTypesWS () {
    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'getTypes', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getTypes');
    }
}

export async function getSubTypesWS () {
    let params = {
        portal: store.getters.getPortalID,
        languageISO: store.getters.getLanguageISO,
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'getSubTypes', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getSubTypes');
    }
}

export async function getDegreeTypesWS () {
    let params = {
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'getDegreeTypes', params, params)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getDegreeTypes');
    }
}