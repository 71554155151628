import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function getFaqsWS(url_course = ''){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'url_course' : url_course,
        'languageID' : store.getters.getLanguageID,
        'portalID' : store.getters.getPortalID,
    }

    try {
        return await aiseoBackendPetition('POST', 'faqs/getFAQs', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faqs/getFAQs');
    }
}

export async function addFaqWS(faq, url = '') {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'portalID': store.getters.getPortalID,
        'languageID': store.getters.getLanguageID,
        'url': url,
        'question': faq.question,
        'answer': faq.answer,
    }

    try {
        return await aiseoBackendPetition('POST', 'faqs/insertFAQ', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faqs/insertFAQ');
    }
}

export async function deleteFaqWS(faq_id) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'id_faq': faq_id,
    }

    try {
        return await aiseoBackendPetition('DELETE', 'faqs/removeFAQ/'+faq_id, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faqs/removeFAQ/');
    }
}

export async function updateFaqWS(faq_id, faq) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'FAQData': JSON.stringify({
            'pregunta': faq.question,
            'respuesta': faq.answer,
            "id_faq":faq_id,
            "id_portal":store.getters.getPortalID,
            "id_idioma": store.getters.getLanguageID
        }),
    }

    try {
        return await aiseoBackendPetition('PUT', 'faqs/updateFAQ', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'faqs/updateFAQ');
    }
}