<template>
    <v-container>
        <template v-if="general_loading">
            <Loading/>
        </template>

        <template v-else>
            <v-row>
                <v-col cols="12" sm="9" md="9" lg="9">
                    <span class="main-title-page">{{ form_data.id_url_course }}</span>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" class="col-btn-title"
                       v-if="Object.keys(language_selected).length > 0">
                    <v-btn class="btn button-bg-blue" @click="associatedImages" :loading="loading_associated_images">ASIGNAR</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Portal: {{ portal_specific.nombre }}</span>
                </v-col>
            </v-row>
            <v-row v-if="portal_specific.languages.length>1">
                <v-col cols="12" md="6" lg="4" class="align-self-center">
                    <v-select
                        placeholder="Seleccione un idioma"
                        v-model="language_selected"
                        :items="portal_specific.languages"
                        item-title="nombre"
                        return-object
                        :error=form_error.language
                        class="input-error select-secondary"
                        bg-color="white"
                        hide-details
                        :menu-props="{width:400}"
                        @update:modelValue="getLogosDiplomasTrainingProgram"
                        no-data-text="No hay datos disponibles">
                    </v-select>
                    <span v-if="form_error.language"
                          class="input-error msg-error">Debe seleccionar un idioma
                        </span>
                </v-col>
            </v-row>
            <template v-if="Object.keys(language_selected).length > 0">
                <div v-if="loading_get_images" class="general-loading">
                    <Loading class_loading="loading-gif-s"/>
                </div>
                <template v-else>
                    <div v-if="variableIsDefined(form_data.id_training_program)">
                        <DraggableQualificationsTemplate
                            :degree_data="degree_data"
                            :logos_list="list_logos"
                            :diploma_list="list_diplomas"
                            :id_element="form_data.id_training_program"
                            :id_portal="form_data.id_portal"
                            :iso_language="language_selected.iso_6391"
                            type_component="training_program"
                            :getAllDegreesUrl="getAllDegrees"
                            txt_not_data_diplomas="No hay diplomas asociados a este programa formativo"
                            txt_not_data_logos="No hay logos asociados a este programa formativo"
                        ></DraggableQualificationsTemplate>
                    </div>
                </template>
            </template>
            <v-dialog v-model="display" scrollable persistent>
                <ModalDegreesCourse
                    :id_portal="form_data.id_portal"
                    :iso_language="language_selected.iso_6391"
                    :id_url_course="form_data.id_training_program"
                    :displayDialog="displayDialogAddImages"
                    :degree_data="degree_data"
                    :changeDegreeData="changeDegreeData"
                    type_component="training_program"
                    :getAllDegreesUrl="getLogosDiplomasTrainingProgram"
                    title_dialog="Asignación de logos y diplomas"
                ></ModalDegreesCourse>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {onBeforeMount, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {
    getSpecificPortal
} from "@/utils/portal";
import {variableIsDefined} from "@/utils/aux_functions";
import DraggableQualificationsTemplate from "@/components/DegreesCourse/DraggableQualificationsTemplate.vue";
import useQualifications from "@/utils/composables/use_qualifications.js";
import ModalDegreesCourse from "@/components/DegreesCourse/ModalDegreesCourse.vue";

export default {
    name: "AssignDegreesView",
    methods: {variableIsDefined},
    components: {ModalDegreesCourse, DraggableQualificationsTemplate, Loading},

    setup() {
        let router = useRouter();
        let route = useRoute();

        let loading_get_images = ref(false)
        let loading_associated_images = ref(false)

        let portal_specific = ref({})
        let form_data = ref({})
        let form_error = ref({})
        let general_loading = ref(false)
        let display = ref(false);
        let language_selected = ref([])

        const {
            list_diplomas,
            list_logos,
            degree_data,
            getAllDegrees,
            changeDegreeData,
            fillDegreeData,
        } = useQualifications()

        onBeforeMount(async () => {
            general_loading.value = true
            await getUrlRoute()
            portal_specific.value = getSpecificPortal(form_data.value.id_portal)
            if (portal_specific.value.languages.length === 1) {
                language_selected.value = portal_specific.value.languages[0]
                await getAllDegrees('campaign', form_data.value.id_training_program, language_selected.value.iso_6391, form_data.value.id_portal)
            }
            await fillDegreeData()

            general_loading.value = false
        })

        /*OBTIENE LOS DATOS DE LA URL*/
        async function getUrlRoute() {
            await router.isReady()
            if (Object.entries(route.query).length !== 0) {
                form_data.value.id_url_course = route.query['programa-formativo'];
                form_data.value.id_training_program = route.query['id-pf'];
                form_data.value.id_portal = route.query['portal']
            }
        }

        /*OBTIENE LOS LOGOS Y DIPLOMAS DEL PROGRAMA FORMATIVO ACTUAL, PASANDO EL IDIOMA SELECCIONADO*/
        async function getLogosDiplomasTrainingProgram() {
            loading_get_images.value = true
            if (validateForm()) {
                await getAllDegrees('campaign', form_data.value.id_training_program, language_selected.value.iso_6391, form_data.value.id_portal)
            }
            loading_get_images.value = false
        }

        function validateForm() {
            form_error.value = {}
            if (language_selected.value.length === 0) {
                form_error.value.language = 'Debe seleccionar un idioma'
            }
            return Object.keys(form_error.value).length <= 0;
        }


        /*MODAL PARA AÑADIR LOGOS Y DIPLOMAS AL PROGRAMA FORMATIVO*/
        async function associatedImages() {
            loading_associated_images.value = true
            displayDialogAddImages()
            loading_associated_images.value = false
        }

        function displayDialogAddImages() {
            display.value = !display.value;
        }


        return {
            associatedImages,
            getLogosDiplomasTrainingProgram,
            displayDialogAddImages,
            changeDegreeData,
            getAllDegrees,

            general_loading,
            loading_associated_images,
            loading_get_images,
            form_data,
            degree_data,
            form_error,
            list_logos,
            list_diplomas,
            portal_specific,
            language_selected,
            display,
        }
    }
}
</script>


<style scoped lang="scss">
.boxes-languages {
    .box_language {
        box-shadow: none;
        height: auto;
        width: fit-content;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $blue-medium;
        border: 1px solid $blue-medium;


        .text-area {
            padding: 3px 15px;
        }

        .icons-close {
            filter: brightness(0) saturate(100%) invert(90%) sepia(17%) saturate(3681%) hue-rotate(321deg) brightness(96%) contrast(98%);
        }

        .icons-loading {
            display: none;
        }
    }
}
</style>