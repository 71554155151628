<template>
    <v-row>
        <v-col>
            <v-expansion-panels>
                <v-expansion-panel class="expansion-personalized">
                    <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.url"
                                    placeholder="URL"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-autocomplete
                                    v-model="form_filter.rol"
                                    :items="items_roles"
                                    item-title="name"
                                    item-value="id_rol"
                                    variant="underlined"
                                    class="select-primary"
                                    placeholder="Rol"
                                    hide-details
                                    :menu-props="{width: '300px'}"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-autocomplete
                                    v-model="form_filter.type"
                                    :items="items_types"
                                    item-title="type"
                                    item-value="id"
                                    variant="underlined"
                                    class="select-primary"
                                    placeholder="Tipo"
                                    hide-details
                                    :menu-props="{width: '300px'}"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.location_of_code"
                                    placeholder="Fichero"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.individual_description"
                                    placeholder="Descripción del Rol"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="justify-end mt-3">
                            <v-col cols="auto">
                                <v-btn class="btn button-bg-blue" @click="validateFilter">
                                    Filtrar
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                     title="Eliminar filtros" class="delete-filter ms-4" @click="clearFilters">
                            </v-col>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import {ref} from "vue";

export default {
    name: "FilterGetRolesByUrl",
    props: {
        items_roles: Array,
        items_types: Array,
        getFilters: Function,
        getRolesByUrl: Function,
    },
    setup(props) {
        let form_filter = ref({})
        let form_error = ref({})

        async function validateFilter() {
            let correct = true
            form_error.value = {};
            Object.keys(form_filter.value).forEach(key => {
                if (form_filter.value[key] === "") {
                    delete form_filter.value[key];
                }
            });

            if (correct) {
                await props.getFilters(form_filter.value)
            }
        }

        async function clearFilters() {
            form_filter.value = {}
            form_error.value = {}
            await props.getRolesByUrl()
        }

        return {
            validateFilter,
            clearFilters,

            form_filter,
            form_error,
        }
    }
}
</script>

<style scoped lang="scss">
.expansion-personalized {
    border: 1px solid $blue-medium;

    .delete-filter {
        filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
        width: 30px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
        }
    }
}
</style>
