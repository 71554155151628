<template>
    <div v-show="facet.data.length" class="sui-multi-checkbox-facet ">
        <div v-if="!nombre" class="sui-sorting__label">
            {{ facet.field }}
        </div>
        <div v-else class="sui-sorting__label">
            {{ nombre }}
        </div>
        <div class="sui-multi-checkbox-facet__options-list">
            <label
                    v-for="facetItem in facet.data"
                    :key="getValue(facetItem, facet.type)"
                    class="sui-multi-checkbox-facet__option-label"
            >
                <div class="sui-multi-checkbox-facet__option-input-wrapper">
                    <input
                            class="sui-multi-checkbox-facet__checkbox"
                            type="checkbox"
                            :value="getValue(facetItem, facet.type)"
                            :checked="isChecked(getValue(facetItem, facet.type))"
                            @change="$emit('change', $event)"
                    />
                    <span class="sui-multi-checkbox-facet__input-text">{{
                            getValue(facetItem, facet.type)
                        }}</span>
                </div>
                <span class="sui-multi-checkbox-facet__option-count">{{
                        facetItem.count
                    }}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        facet: {
            type: Object,
            required: true
        },
        checked: {
            type: Array,
            required: true
        },
        nombre: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        function isChecked(value) {
            return props.checked && props.checked.includes(value);
        }

        function getValue(facetItem, type) {
            return type === "range" ? facetItem.value.name : facetItem.value;
        }

        return {
            isChecked,
            getValue
        }
    }
};
</script>

