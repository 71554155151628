<template>

    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/translate.svg')" alt="icono de traducción" width="28" height="28">
                    Traductor
                </span>
            </v-col>

            <v-col cols="12" md="12" sm="12" xs="12" class="col-translator">
                <FormTranslator></FormTranslator>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import FormTranslator from "@/components/Forms/FormTranslator.vue";
import {onBeforeMount} from "vue";
import {hasPermission} from "@/utils/auth";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "TranslatorView",
    components: {FormTranslator},
    setup() {
        const route = useRoute();

        onBeforeMount(async () => {
            await hasPermission(route);
        })
    }
}
</script>
<style scoped lang="scss">

.col-translator {
    //padding: 0;
}

</style>