import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function getAllDegreesWS(related_entity, name_filter) {
    let params_token = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'type': related_entity.related_entity,
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'type': related_entity.related_entity,
        'name_filter': name_filter,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/getAllDegrees', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/getAllDegrees');
    }
}

export async function insertDegreeWS(form_data, customization_degree, type_entity) {
    let params = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'degree_data': form_data,
        'type': type_entity,
        'bulkData': customization_degree,
        'usuario_logueado': store.getters.getUsername,
    }
    let params_token = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'type': type_entity,
        'degree_data': form_data,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/insertDegree', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/insertDegree');
    }
}


export async function updateDegreeWS(id_degree, form_data, customization_degree, type_entity) {
    let params = {
        'id_degree': id_degree,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'degree_data': form_data,
        'type': type_entity,
        'bulkData': customization_degree,
        'usuario_logueado': store.getters.getUsername,
    }
    let params_token = {
        'id_degree': id_degree,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'type': type_entity,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/updateDegree', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/updateDegree');
    }
}

export async function getDegreeDataWS(id_degree, type) {
    let params = {
        'id_degree': id_degree,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'type': type,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/getDegreeData', params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/getDegreeData');
    }
}

export async function deleteDegreeWS(id_degree, portal, language, type){
    let params = {
        'id_degree': id_degree,
        'id_portal': portal,
        'language': language,
        'type': type,
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'degrees/deleteDegree', params, params);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' ' + 'degrees/deleteDegree');
    }
}