<template>
    <div :class="{ 'error-quill-editor': show_error }" >
        <quill-editor
            v-model:value="state.content"
            :options="state.editorOption"
            :disabled="state.disabled"
            @change="onEditorChange($event)"
            ref="quill_editor"
        />
    </div>
</template>

<script>
import 'quill/dist/quill.snow.css';
import {ref, onMounted, reactive} from 'vue';
import {quillEditor} from "vue-quill-editor/src";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: 'SimpleTextEditor',
    methods: {variableIsDefined},
    props: {
        content: String,
        min_height: String,
        max_height: String,
        default_text: String,
        show_error: Boolean,
    },
    components: {
        quillEditor
    },
    setup(props) {
        let quill_editor = ref(null)
        let received_content = ref('');
        const state = reactive({
            content: props.content,
            editorOption: {
                placeholder: props.default_text,
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{'header': 1}, {'header': 2}],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{'script': 'sub'}, {'script': 'super'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'direction': 'rtl'}],
                        [{'size': ['small', false, 'large', 'huge']}],
                        [{'color': []}, {'background': []}],
                        [{'font': []}],
                        [{'align': []}],
                        ['clean'],
                        ['link'],
                    ],
                },
                theme: 'snow',
            },
            disabled: false
        })

        onMounted(async () => {
            received_content.value = props.content
            const quill_editor_element = quill_editor.value.quill.root;
            quill_editor_element.classList.add('personalize-scroll');
            quill_editor_element.style.minHeight = props.min_height;
            quill_editor_element.style.maxHeight = props.max_height;
            quill_editor_element.style.fontFamily = 'Lato, sans-serif'
            quill_editor_element.style.fontSize = '16px'
        });

        const onEditorChange = ({html, text}) => {
            received_content.value = removeSpaces(text) ? html : '';
        }
        function removeSpaces(text) {
            let text_remove_space = text.replace(/^\s+|\s+$/g, '');
            return !!text_remove_space;
        }

        return {
            onEditorChange,
            received_content,
            state,
            quill_editor,
        };
    },
};
</script>

<style scoped lang="scss">
.error-quill-editor{
    border: 1px solid $error;
}
</style>
