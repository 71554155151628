<template>
    <v-locale-provider locale="es" class="text-field-secondary box-data-input" :class="{ 'input-error': !error_date }">
        <v-date-input
            prepend-icon=""
            v-model="input_date"
            :max="date_today"
            :rules="validate_rules"
            :error-messages="error_rules"
            :error="!error_date"
            @keydown.enter.prevent="handleKeydown"
            variant="solo"
            :label="label_input"
            placeholder="dd/mm/aaaa"
            ok-text="ACEPTAR"
            cancel-text="CANCELAR"
            :messages="msg_hint"
            border="sm"
            color="rgba(54, 163, 255, 1)"
            error-color="rgba(248, 56, 80, 1)"
        ></v-date-input>
    </v-locale-provider>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {VDateInput} from 'vuetify/labs/VDateInput'
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "InputCalendar",
    methods: {variableIsDefined},
    components: {
        VDateInput

    },
    props: {
        msg_hint: {
            type: String,
            default: ''
        },
        label_input: {
            type: String,
            default: ''
        },
        /*FECHA PREDEFINIDA*/
        filled_date: {
            type: String,
            default: null
        },
        /*SELECCIONAR FECHA SOLO HASTA LA ACTUAL*/
        date_today: {
            type: Boolean,
            default: true
        },
        /*COMPARAR DOS FECHAS*/
        compare_dates: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        let input_date = ref()
        let error_date = ref(true)
        let date_today = ref()
        let error_rules = ref('')
        let validate_rules = ref([
            (value) => {
                if (value === '' || value === null) return error_date.value = true;
                let date_regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

                if (!date_regex.test(value)) {
                    error_date.value = false
                    return 'Formato de fecha inválido';
                }

                return error_date.value = true;
            }
        ]);

        onBeforeMount(() => {
            if (variableIsDefined(props.filled_date)) {
                input_date.value = _.cloneDeep(new Date(props.filled_date));
            }
            if (props.date_today) {
                date_today.value = getCurrentDate()
            } else {
                date_today.value = null
            }
        })

        /*OBTENER FECHA ACTUAL PARA ASIGNAR AL CALENDARIO EL MÁXIMO, 'mm/dd/aaaa'*/
        function getCurrentDate() {
            let today = new Date();
            let day = String(today.getDate()).padStart(2, '0');
            let month = String(today.getMonth() + 1).padStart(2, '0');
            let year = today.getFullYear();

            return month + '/' + day + '/' + year;
        }


        /*MANEJA ENTER (CALENDARIO)*/
        function handleKeydown(event) {
            let is_entry_key = event.key === 'Enter';
            if (is_entry_key) {
                event.stopPropagation();
                input_date.value = null
            }
        }

        function compareDateMsg(type, msg_error) {
            if(type){
                error_date.value = true;
                error_rules.value = ''

                return true
            }else{
                error_date.value = false;
                error_rules.value = msg_error
                return false;
            }
        }


        function deleteDate() {
            input_date.value = null
            error_date.value = true;
            error_rules.value = ''
        }

        return {
            handleKeydown,
            compareDateMsg,
            deleteDate,
            validate_rules,
            input_date,
            error_date,
            date_today,
            error_rules
        }
    }
}
</script>


<style lang="scss">
@import "@/styles/components/input-calendar.scss";
@import "@/styles/general.scss";
</style>