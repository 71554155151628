import "regenerator-runtime/runtime";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {showNotification} from "@/utils/aux_functions";

export function getAppSearchConfig(tipo, idioma) {
    let engineNameValue = `${tipo}-${idioma}`;

    const connector = new AppSearchAPIConnector({
        searchKey:process.env.VUE_APP_SEARCHKEY,
        engineName: engineNameValue,
        endpointBase: process.env.VUE_APP_SEARCHUIBASE
    });

    let config = null;
    if (tipo == 'eduseo-cursos') {
    config = {
        debug: false,
        apiConnector: connector,
        searchQuery: {
            disjunctiveFacets: [
                "id_idioma", "id_campanya", "tipo_de_curso","nombre_vertical"
            ],
            disjunctiveFacetsAnalyticsTags: ["ignore"],
            facets: {
                precio: {
                    type: "range",
                    ranges: [
                        {"from":0,"to":500, "name":"0-500"},
                        {"from":500,"to":1000, "name":"500-1000"},
                        {"from":1000,"to":1500, "name":"1000-1500"},
                        {"from":1500,"to":2000, "name":"1500-2000"},
                        {"from":2000,"name":"+2000"}
                    ]
                },
                // Check language of the course
                idioma: {
                    type: "value"
                },
                // Filter id_campanya
                campanya: {
                    type: "value"
                },
                // Type of the course
                tipo_de_curso: {
                    type: "value"
                },
                nombre_vertical: {
                    type: "value"
                },
                nombre_facultad: {
                    type: "value"
                },
                nombre_area: {
                    type: "value"
                }
            }
        },
        filters: [
            {field: "convocatoria_cerrada", values: [0]},
            {field: "activa", values: [1]},
            {field: "status", values: [1]},
        ],
        autocompleteQuery: {
            // performs a prefix search on the query
            results: {
                resultsPerPage: 5, // number of results to display. Default is 5.
                result_fields: {
                    // Add snippet highlighting within autocomplete suggestions
                    keyword_objetivo: {raw: {}},
                }
            },
            // performs a query to suggest for values that partially match the incomplete query
            suggestions: {
                types: {
                    // Limit query to only suggest based on "title" field
                    documents: {fields: ["titulo_curso", "h1", "keyword_objetivo"]},
                },
                // Limit the number of suggestions returned from the server
                size: 5
            }
        }
    };
    }else {
    config = {
        debug: false,
        apiConnector: connector,
        searchQuery: {
            disjunctiveFacets: [],
            disjunctiveFacetsAnalyticsTags: ["ignore"],
            facets: {
                url_portal: {
                    type: "value"
                },
                // Check language of the course
                idioma: {
                    type: "value"
                },
                // Filter id_campanya
                author_seo: {
                    type: "value"
                },
                author: {
                    type: "value"
                },
                nombre_facultad: {
                    type: "value"
                },
                nombre_area: {
                    type: "value"
                }
            }
        },
        filters: [
        ],
        autocompleteQuery: {
            // performs a prefix search on the query
            results: {
                resultsPerPage: 5, // number of results to display. Default is 5.
                result_fields: {
                    // Add snippet highlighting within autocomplete suggestions
                    h1: {raw: {}},
                }
            },
            // performs a query to suggest for values that partially match the incomplete query
            suggestions: {
                types: {
                    // Limit query to only suggest based on "title" field
                    documents: {fields: ["title", "h1", "keyword_objetivo"]},
                },
                // Limit the number of suggestions returned from the server
                size: 5
            }
        }
    };
    }

    try {
        return config;
    } catch (e) {
        showNotification('error', 'Se ha producido un error al intentar producir \l' +
            'a configuración de conexión a Elastic.');
    }
}


export function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

