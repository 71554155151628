<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/queue_redirection.svg')" alt="icono de eliminar redirecciones" width="28" height="28">
                    Eliminación de redirecciones
                </span>
            </v-col>
        </v-row>
        <template v-if="loading.general">
            <Loading/>
        </template>

        <template v-else>
            <v-row>
                <v-col cols="12" sm="7" md="8">
                    <v-text-field
                        v-model.trim="form_data.redirection_name"
                        @keyup.enter="searchRedirection"
                        class="text-field-secondary"
                        placeholder="Identificador texto url"
                        bg-color="white"
                        color="transparent"
                        append-inner-icon="mdi-magnify"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.redirection_name"
                          class="input-error msg-error">{{ form_error.redirection_name }}</span>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="mt-sm-3 text-end text-sm-start">
                    <v-btn class="btn button-primary" @click="searchRedirection" :loading="loading.get_redirections"
                           :disabled="loading.btn_delete || loading.table">Buscar redirección
                    </v-btn>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="12">
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Búsqueda exacta"
                            v-model="form_data.strict"
                            :true-value="1"
                            :false-value="0"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="show_table">
                <v-col cols="12" class="text-end">
                    <v-btn class="btn button-bg-blue"
                           :disabled="loading.table || items_selected.length===0 || loading.btn_delete"
                           @click="displayDeleteConfirmation">Borrar redirecciones seleccionadas
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table"
                        theme-color="#36A3FF"
                        :headers="headers"
                        :items="table_items"
                        :loading="loading.table"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        rowsPerPageOptions="10"
                        v-model:items-selected="items_selected"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                        buttons-pagination
                    >
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {getRedirectionsByURLWS, removeRedireccionesByURLWS} from "@/utils/url_redirections";
import Vue3EasyDataTable from "vue3-easy-data-table";
import * as Yup from "yup";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {useStore} from "vuex";

export default {
    name: "DeleteRedirectionsView",
    components: {Vue3EasyDataTable, Loading},
    setup() {
        let store = useStore();
        let all_portals = computed(() => store.getters.getStoredPortals).value
        let all_languages = computed(() => store.getters.getStoredLanguages).value
        let loading = ref({
            'general': false,
            'table': false,
            'btn_delete': false,
            'get_redirections': false
        })
        let show_table = ref(false)
        let form_data = ref({})
        let form_error = ref({})
        let items_selected = ref([])
        let ids_redirections_selected = []

        const headers = ref([
            {text: 'ID REDIRECCIÓN', value: 'id_redireccion'},
            {text: 'COD. HTTP', value: 'codigo_http'},
            {text: 'URL ORIGEN', value: 'url_origen'},
            {text: 'URL DESTINO', value: 'url_destino'},
            {text: 'Portal', value: 'name_portal'},
            {text: 'Idioma', value: 'name_language'},
        ])
        let table_items = ref([])
        let server_options = ref({
            page: 1,
            rowsPerPage: 20
        });
        let server_items_length = ref(0);

        onBeforeMount(async () => {
            loading.value.general = true
            initSweetAlert()
            loading.value.general = false
        })
        watch(server_options, async () => {
                await getRedirectionsByURL();
            },
            {deep: true}
        );


        const validateForm = async () => {
            const schemaForm = Yup.object().shape({
                redirection_name: Yup.string().required(true),
            })
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                return true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "Debe introducir una redirección"
                })
                return false
            }
        }

        async function searchRedirection() {
            server_options.value.page = 1;
            loading.value.get_redirections = true
            await getRedirectionsByURL()
            loading.value.get_redirections = false
        }

        async function getRedirectionsByURL() {
            items_selected.value = []
            loading.value.table = true
            if (await validateForm()) {
                let response = await getRedirectionsByURLWS(form_data.value, server_options.value.page, server_options.value.rowsPerPage)
                if (response['result']) {
                    show_table.value = true
                    server_items_length.value = response['data'].total;
                    response['data']['data'].forEach(redirection => {
                        const portal = all_portals.find(port => port.id_portal === redirection.id_portal);
                        const language = all_languages.find(lang => lang.id_idioma === redirection.id_idioma);
                        if (portal) {
                            redirection.name_portal = portal.nombre;
                        }
                        if (language) {
                            redirection.name_language = language.nombre;
                        }
                    });
                    table_items.value = response['data']['data']
                } else {
                    showNotification('error', response['errors'])
                }
            }
            loading.value.table = false

        }

        function displayDeleteConfirmation() {
            fireSweetAlert('¿Quieres eliminar las redirecciones marcadas?', deleteMassiveRedirections)
        }

        async function deleteMassiveRedirections() {
            ids_redirections_selected = []
            let msg_success = []
            if (variableIsDefined(items_selected.value)) {
                items_selected.value.forEach((redirection) => {
                    ids_redirections_selected.push(redirection.id_redireccion)
                })
                loading.value.btn_delete = true
                let response = await removeRedireccionesByURLWS(ids_redirections_selected)
                if (response['result']) {
                    response['data'].forEach((redirections) => {
                        msg_success.push(redirections.message)
                    })
                    showNotification('success', msg_success, true)
                    loading.value.btn_delete = false
                    await getRedirectionsByURL()
                } else {
                    showNotification('error', response['errors'])
                    loading.value.btn_delete = false
                }
            }
        }

        return {
            searchRedirection,
            displayDeleteConfirmation,

            loading,
            headers,
            table_items,
            form_data,
            form_error,
            show_table,
            items_selected,
            server_options,
            server_items_length,
        }
    }
}
</script>

<style scoped>
</style>