<template>
    <!--    todo añadir linkchecker a plugins una vez adquirida versión PRO -->
    <editor
            :initial-value="''"
            v-model="model_copy"
            :id="id"
            :init="{
            setup: function (editor){
                editor.on('init', (e) => {
                  getCharacterCountFromEditor(editor)
                  getWordCountFromEditor(editor)
                });
                editor.on('keyup',function (evt){
                   getCharacterCountFromEditor(editor)
                   getWordCountFromEditor(editor)
                });
            },
            entity_encoding : 'raw',
            selector: 'textarea',
            plugins:
            'advlist lists link image table code  media'+
            ' searchreplace spellchecker paste wordcount textcolor',
            toolbar: 'undo redo | formatselect | formatpainter casechange blocks | bold italic forecolor backcolor | ' +
                      'alignleft aligncenter alignright alignjustify | ' +
                      'checklist outdent indent | removeformat | a11ycheck code '+
                       'pagebreak | searchreplace spellchecker paste | bullist numlist | image link unlink blocks |',

            height: height,
            content_css: [],
            content_style :'',

            statusbar: false,
            convert_urls: false,
            relative_urls : true,

            table_class_list: [
                {title: 'Default', value: 'default'},
            ],
            object_resizing: false,
            table_appearance_options: false,
            link_rel_list: [
                {title: 'Por defecto', value: ''},
                {title: 'No follow', value: 'nofollow'},
                {title: 'Follow', value: 'follow'},
            ]
        }"
    />
    <div class="char-count">
        <span class="count">Palabras: {{editor_word_count}} - Caracteres: {{ editor_character_count }}</span>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import {getCharacterCount} from "@/utils/editor_course_blog";
import {computed, ref, watch} from "vue";

export default {
    name: "TinyEditor",
    components: {
        Editor
    },
    props: {
        height: Number,
        model: String,
        id: String,
    },
    emits: ["customChange"],

    setup(props, context) {
        let height = props.height
        let editor_character_count = ref();
        let editor_word_count = ref();

        let model_copy = computed({
                get() {
                    return props.model
                },
                set(value) {
                    context.emit('customChange', value)
                }
            }
        );

        watch(model_copy, async () => {
            let editor = await tinymce.get(props.id);
            let text = getCharacterCount(editor);
            editor_character_count.value = text.length;
        });

        /*
        *
        * A partir de un editor, obtiene su contenido
        * y la longitud del texto que contiene
        *
        * */
        function getCharacterCountFromEditor(editor) {
            let text = getCharacterCount(editor);
            editor_character_count.value = text.length;
        }

        /*
        *
        * A partir de un editor, obtiene su contenido
        * y el número de palabras que contiene
        *
        * */
        function getWordCountFromEditor(editor){
            editor_word_count.value = editor.plugins.wordcount.getCount();
        }

        return {
            getCharacterCountFromEditor,
            getWordCountFromEditor,

            editor_character_count,
            editor_word_count,
            height,
            model_copy,
        }
    }
}
</script>

<style lang="scss">
</style>