<template>
    <v-row>
        <v-col cols="12">
            <span class="title-type">{{ title }}</span>
        </v-col>
        <template v-if="list.length>0">
            <v-col cols="12" md="6" lg="4" class="box-degree" v-for="degree in list">
                <v-row>
                    <v-col cols="12" class="text-center">
                        <span class="title-img">{{ degree.titulo }}</span>
                    </v-col>
                    <v-col cols="12" class="box-img">
                        <img class="img-degree" :src="degree.img_url" :alt="degree.titulo">
                    </v-col>
                </v-row>
            </v-col>
        </template>
        <template v-else>
            <v-col cols="12"><span style="font-style: italic">Actualmente no contiene datos.</span></v-col>

        </template>
    </v-row>
</template>

<script>
export default {
    name: "DegreeBoxBase",
    props: {
        title: String,
        list: Array,

    },
}
</script>


<style scoped lang="scss">
@import "@/styles/components/degree-box-base.scss";
</style>