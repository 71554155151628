<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/priority-course.svg')" alt="icono de cursos prioritarios" width="28" height="28">
                    Cursos prioritarios para docentes
                </span>
            </v-col>
        </v-row>
        <template v-if="!pending_language">
            <template v-if="loading">
                <Loading/>
            </template>
            <template v-else>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-text-field
                            class="text-field-secondary"
                            placeholder="Buscar por nombre del curso..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                            v-model="filter_course"
                            @keyup.enter="getFilterCourses()"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="10" sm="4">
                        <v-select
                            placeholder="Buscar por facultad"
                            class="input-error select-secondary"
                            auto-select-first
                            bg-color="white"
                            hide-details
                            :items="all_categories_web"
                            item-title="nombre"
                            item-value="clave"
                            v-model="filter_faculty"
                            @update:modelValue="getFilterCourses()"
                            :menu-props="{ width: '200px', height: '200px'}"
                            no-data-text="No hay datos disponibles"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="2" sm="1" class="align-self-center">
                        <img src="@/assets/icons/delete-left-solid.svg" alt="Icono" class="clean-filter"
                             @click="clean_filters">
                    </v-col>
                    <v-col cols="12" sm="3" class="text-end mt-2">
                        <v-btn
                            class="btn button-bg-blue"
                            @click="selectOptions('create')"
                        >Agregar curso
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <Vue3EasyDataTable
                            class="primary-table table_priority_courses"
                            theme-color="#F4A649"
                            :headers="columns_table"
                            :items="dates_courses"
                            :loading="loading_table"
                            :rows-per-page="50"
                            buttons-pagination
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-icon_edit="item">
                                <img :src="require('@/assets/icons/edit.svg')"
                                     alt="Editar"
                                     class="icon-data-table icon-edit"
                                     title="Editar"
                                     @click="selectOptions('edit', item)"
                                >
                            </template>

                            <template #item-icon_delete="item">
                                <img :src=" require('@/assets/icons/trash.svg')"
                                     alt="Borrar"
                                     class="icon-data-table icon-delete"
                                     title="Borrar"
                                     @click="selectOptions('delete', item)"
                                >
                            </template>

                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>
            </template>
            <v-dialog v-model="open_dialog">
                <ModalPriorityCourses
                    :displayDialogCourse="displayDialogCourse"
                    :msg_title_modal="msg_title_modal"
                    :date_course_selected="date_course_selected"
                    :getAllCoursesPriority="getAllCoursesPriority"
                ></ModalPriorityCourses>
            </v-dialog>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <span>Selecciona un idioma para continuar.</span>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import ModalPriorityCourses from "@/components/Course/ModalPriorityCourses.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {
    getFacultyFeaturedCourses,
    getFacultyFeaturedCoursesWS, getPortalCategorizationWS, removeFacultyFeaturedCourseWS
} from "@/utils/courses";
import Loading from "@/components/Generic/Loading.vue";
import {showNotification} from "@/utils/aux_functions";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
    name: "PriorityCoursesView",
    components: {Loading, ModalPriorityCourses, Vue3EasyDataTable},

    setup() {
        const store = useStore();
        const route = useRoute();
        let loading = ref(false)
        let language = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        let pending_language = ref(false);
        let portals = ref([]);
        let filter_course = ref("")
        let filter_faculty = ref([])
        let columns_table = [
            {text: 'Url Curso', value: 'url'},
            {text: 'Titulo Curso', value: 'titulo_curso'},
            {text: 'Orden', value: 'weight'},
            {text: 'Facultad', value: 'nombre_categoria'},
            {text: '', value: 'icon_edit'},
            {text: '', value: 'icon_delete'},
        ]
        let dates_courses = ref([])
        let loading_table = ref(false)
        let open_dialog = ref(false)
        let msg_title_modal = ref("")
        let date_course_selected = ref({})
        let all_categories_web = ref([])

        onBeforeMount(async () => {
            loading.value = true
            portals.value = computed(() => store.getters.getStoredPortals).value
            await hasPermission(route);
            language.value !== 0 ? await generateCoursesPriority() : pending_language.value = true
            loading.value = false


        })

        watch(portal, async () => {
            dates_courses.value = [];
            await generateCoursesPriority();
        });

        async function generateCoursesPriority() {
            await getAllCoursesPriority()
            await getAllCategorization()
            initSweetAlert()
        }

        async function getAllCoursesPriority() {
            let response = await getFacultyFeaturedCoursesWS()
            if (response['result']) {
                dates_courses.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function getAllCategorization() {
            let response = await getPortalCategorizationWS()
            if (response['result']) {
                let data = response['data']
                for (const idFacultad in data.categorias_web) {
                    if (Object.prototype.hasOwnProperty.call(data.categorias_web, idFacultad)) {
                        const objetoOriginal = data.categorias_web[idFacultad];
                        const objetoNuevo = {...objetoOriginal, clave: parseInt(idFacultad)};
                        all_categories_web.value.push(objetoNuevo);
                    }
                }
            } else {
                showNotification('error', response['errors'])
            }
        }

        function displayDialogCourse() {
            open_dialog.value = !open_dialog.value;
        }

        function displayDialogDelete() {
            fireSweetAlert("Eliminar curso de la lista prioritarios", deleteCourse)
        }

        async function deleteCourse() {
            let response = await removeFacultyFeaturedCourseWS(date_course_selected.value.url)
            if (response['result']) {
                showNotification('success', 'Se ha eliminado el curso como prioritario')
                await getAllCoursesPriority()
            } else {
                showNotification('error', response['errors'])
            }
        }

        function selectOptions(actions, course) {
            date_course_selected.value = course
            switch (actions) {
                case 'create':
                    msg_title_modal.value = "Agregar curso prioritario"
                    displayDialogCourse()
                    break;
                case 'edit':
                    msg_title_modal.value = "Modificar orden del curso"
                    displayDialogCourse()
                    break;
                case 'delete':
                    displayDialogDelete()
                    break;
            }
        }

        async function getFilterCourses() {
            let response = await getFacultyFeaturedCourses(filter_course.value, filter_faculty.value)
            if (response['result']) {
                dates_courses.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function clean_filters() {
            filter_faculty.value = []
            filter_course.value = ""
            await getAllCoursesPriority()
        }


        return {
            selectOptions,
            displayDialogCourse,
            getFilterCourses,
            getAllCoursesPriority,
            clean_filters,

            language,
            pending_language,
            portals,

            loading,
            filter_course,
            filter_faculty,
            columns_table,
            dates_courses,
            loading_table,
            open_dialog,
            msg_title_modal,
            date_course_selected,
            all_categories_web,

        }
    }
}
</script>


<style scoped lang="scss">
.table_priority_courses {
    .icon-data-table {
        width: 25px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: none;
        }
    }

    .icon-edit {
        &:hover {
            content: url('@/assets/icons/hover-edit.svg');
        }
    }

    .icon-delete {
        &:hover {
            content: url('@/assets/icons/hover-trash.svg');
        }
    }

}

.clean-filter {
    width: 30px;
    cursor: pointer;
}

</style>