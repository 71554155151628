<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">Panel de alertas</span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row v-if="alerts.length > 0">
                <v-col cols="12">
                    <p>A continuación se muestra un listado de alertas con acciones pendientes de revisión</p>
                </v-col>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        class="primary-table"
                        :headers="headers"
                        :items="alerts"
                        :loading="loading"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >

                        <template #item-aceptar="{id_alerta, alert_text}">
                            <img :src=" require('@/assets/icons/tick.svg')"
                                 class="action-icons"
                                 alt="Icono asignación"
                                 id="icon-check"
                                 title="Aceptar modificación"
                                 @click="preExecuteAlert('accept', id_alerta, alert_text)"
                            >
                        </template>
                        <template #item-denegar="{id_alerta, alert_text}">
                            <img :src=" require('@/assets/icons/close-circle.svg')"
                                 class="action-icons"
                                 alt="Icono asignación"
                                 id="icon-delete"
                                 title="Declinar modificación"
                                 @click="preExecuteAlert('decline', id_alerta, alert_text)"
                            >
                        </template>

                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12">
                    <p>No hay alertas pendientes</p>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>


import Vue3EasyDataTable from "vue3-easy-data-table";
import {onBeforeMount, ref} from "vue";
import {executeActionAlertWS, getAlertsWS} from "@/utils/alerts";
import {showNotification} from "@/utils/aux_functions";
import FormTeacherPortal from "@/components/Forms/FormTeacherPortal.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";

export default {
    name: "AlertsView",
    components: {Loading, FormTeacherPortal, Vue3EasyDataTable},
    setup() {
        const route = useRoute();
        let general_loading = ref(false)
        let alerts = ref([]);
        let loading = ref(true);
        let action = ref("");
        let title_modal = ref("");
        let id_alert_selected;
        let text_alert_selected = ref('');

        let headers = [
            {text: 'ID', value: 'id_alerta', sortable: true},
            {text: 'Creación', value: 'created_at', sortable: true},
            {text: 'Asunto', value: 'alert_text', sortable: true},
            {text: 'Aceptar', value: 'aceptar'},
            {text: 'Denegar', value: 'denegar'},
        ]

        onBeforeMount(async () => {
            await hasPermission(route)
            general_loading.value = true
            await getAllAlerts()
            initSweetAlert()
            general_loading.value = false
        })

        async function getAllAlerts() {
            loading.value = true;
            let response = await getAlertsWS();
            if (response['result']) {
                alerts.value = response['data'];
            } else {
                showNotification('error', response['errors'])
            }
            loading.value = false;
        }

        function preExecuteAlert(mode, id_alert, alert_text) {
            id_alert_selected = id_alert
            text_alert_selected.value = alert_text
            action.value = mode
            let action_text;
            if (mode === 'accept') {
                action_text = '¿Ejecutar la acción?'
            } else {
                action_text = '¿Descartar la acción?'
            }
            title_modal.value = action_text
            displayDialogConfirmationMessage();
        }

        async function executeActionAlert() {
            let response = await executeActionAlertWS(id_alert_selected, action.value)
            if (response['result']) {
                showNotification('error', 'Alerta resuelta satisfactoriamente')
            } else {
                showNotification('error', response['errors'])
            }

            await getAllAlerts();

        }

        function displayDialogConfirmationMessage() {
            fireSweetAlert(title_modal.value, executeActionAlert, text_alert_selected.value)
        }

        return {
            preExecuteAlert,
            headers,
            alerts,
            loading,
            general_loading
        }
    }
}
</script>

<style scoped lang="scss">
.action-icons {
    max-width: 28px;

    &:hover {
        cursor: pointer;
    }
}
</style>