<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="8" md="8" lg="9">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/library.svg')" alt="icono de imagenes" width="28" height="28">
                    Biblioteca de imágenes
                </span>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="3" class="text-end" v-if="!general_loading">
                <v-btn class="btn button-bg-blue" @click="insertImage()">
                    Añadir
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" xs="12" md="4" lg="4">
                    <v-text-field
                        class="text-field-secondary"
                        label="Buscar por nombre"
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        hide-details
                        v-model="name_filter"
                        @keyup.enter="getImagesByPortal()"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" md="4" lg="4">
                    <v-select
                        :items="select_options"
                        v-model="img_entity"
                        item-title="title"
                        return-object
                        placeholder="Selecciona a un tipo de imagen"
                        class="select-secondary"
                        bg-color="white"
                        @update:modelValue="getImagesByPortal()"
                        hide-details
                        no-data-text="No hay datos disponibles"
                    >
                    </v-select>
                </v-col>
            </v-row>
            <template v-if="loading_portals || loading_images">
                <Loading></Loading>
            </template>
            <v-row v-else>
                <v-col cols="12">
                    <span id="title_imagen_type">Imágenes de: <i>{{ img_entity.title }}</i></span>
                </v-col>
                <v-col cols="12" sm="4" lg="3" v-for="image in images_portal" v-if="images_portal.length > 0">
                    <ImageCard
                        :image="image"
                        :show_btns="true"
                        :selectImage="selectImage"
                        :display-dialog-confirmation-message="displayDialogConfirmationMessage"
                        :random="random"
                    >
                        <template #title-imagen-card>
                            <v-row>
                                <v-col cols="12" class="title-column">{{ image.fileName }}</v-col>
                            </v-row>
                        </template>
                        <template #text-imagen-card>
                            <v-img class="image" :src="`${image.public_route}?reset=${random}`"></v-img>
                        </template>
                        <template #actions-imagen-card>
                            <v-row>
                                <v-col cols="12" class="btn-col">
                                    <v-btn class="btn-edit"
                                           @click="selectImage(image, 'update')">
                                    </v-btn>
                                    <v-btn :class="image.active === 0 ? 'btn-enable' : 'btn-disable'"
                                           @click="selectImage(image, 'changeStatus')">
                                    </v-btn>
                                    <v-btn class="btn-delete"
                                           @click="selectImage(image, 'deleteFile')">
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </ImageCard>

                </v-col>
                <v-col cols="12" xs="12" sm="4" lg="3" v-else>
                    <span>No hay imágenes disponibles</span>
                </v-col>
            </v-row>

            <v-dialog v-model="display_dialog_image" scrollable>
                <FormImage
                    :display-dialog="displayDialogImage"
                    :img_entity="img_entity"
                    :getImagesByPortal="getImagesByPortal"
                    :title_modal="title_modal"
                    :image="selected_image"
                    :portal_data="portal_filter"
                    :clear-data="clearDataSelected"
                ></FormImage>
            </v-dialog>

            <v-dialog v-model="display_dialog_update" scrollable>
                <ModalUpdateImage
                    :display-dialog="displayDialogUpdate"
                    :image="selected_image"
                ></ModalUpdateImage>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import FormImage from "@/components/Forms/FormImage.vue";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import {manageWSResponseMultiple, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {changeStatusFileWS, getFilesByEntityWS, deleteFileWS} from "@/utils/image_library";
import {useStore} from "vuex";
import {fireInfoAlert, fireSweetAlert, initInfoAlert, initSweetAlert} from "@/utils/sweetalert";
import {viewHtml} from "@/utils/full_sreen_notification";
import ModalUpdateImage from "@/components/ImagesLibrary/ModalUpdateImage.vue";


export default {
    name: "ImagesLibraryView",
    components: {
        ModalUpdateImage,
        FormImage,
        ImageCard,
        Loading
    },

    setup() {
        const store = useStore();
        let general_loading = ref(false)
        let images_portal = ref([]);
        let selected_image = ref({});

        let portal_filter = ref({});
        let name_filter = ref('');

        let display_dialog_image = ref(false);
        let display_dialog_update = ref(false)
        let portals = computed(() => store.getters.getStoredPortals).value
        let loading_portals = ref(false);
        let loading_images = ref(false);

        let title_delete = '';
        let txt_delete = ''
        let title_modal = ref('');
        let img_entity = ref({title: 'Logos', related_entity: 'logos', singular: 'logo'});
        let select_options = ref(
            [
                {title: 'Logos', related_entity: 'logos', singular: 'logo'},
                {title: 'Diplomas', related_entity: 'diplomas', singular: 'diploma'}
            ])

        let random = ref(Math.floor(Math.random() * 5000));

        onBeforeMount(async () => {
            general_loading.value = true
            await initData();
            initSweetAlert()
            initInfoAlert()
            general_loading.value = false
        });


        //INICIALIZACIÓN DE LOS DATOS NECESARIOS
        async function initData() {
            loading_portals.value = true;
            portal_filter.value = portals[0];
            await getImagesByPortal();
            loading_portals.value = false;
        }

        //OBTENCIÓN DE LAS IMAGENES A PARTIR DEL PORTAL SELECCIONADO EN EL FILTRO
        async function getImagesByPortal() {
            loading_images.value = true;
            let response = await getFilesByEntityWS(img_entity.value.related_entity, name_filter.value);
            if (response['result']) {
                images_portal.value = response['data'];
            } else {
                showNotification('error', response['errors']);
            }
            loading_images.value = false;
        }

        function displayDialogImage() {
            display_dialog_image.value = !display_dialog_image.value;
        }

        function displayDialogUpdate() {
            display_dialog_update.value = !display_dialog_update.value;
        }

        function insertImage() {
            title_modal.value = 'Insertar imagen en: ' + img_entity.value.title;
            displayDialogImage();
        }

        //OBTIENE LOS DATOS DE LA IMAGEN CON LA QUE SE VA A TRABAJAR
        function selectImage(image, action) {
            let txt_status = '';
            selected_image.value = image;
            txt_delete = '';

            switch (action) {
                case 'changeStatus':
                    if (image.active === 1) {
                        txt_status = 'desactivar';
                        txt_delete = `Este cambio puede afectar a los ${img_entity.value.related_entity} que contienen la imagen desactivando su estado.`;
                    } else {
                        txt_status = 'activar';
                        txt_delete = `Este cambio puede afectar a los ${img_entity.value.related_entity} que contienen la imagen activando su estado.`;
                    }
                    title_delete = `¿Desea ${txt_status} la imagen ` + selected_image.value.fileName + '?';
                    displayDialogConfirmationMessage(action);
                    break;
                case 'deleteFile':
                    txt_status = 'borrar';
                    txt_delete = `Esta acción borrará la imagen seleccionada.`;
                    title_delete = `¿Desea ${txt_status} la imagen ` + selected_image.value.fileName + '?';
                    displayDialogConfirmationMessage(action);
                    break;
                case 'update':
                    displayDialogUpdate()
                    break;
            }
        }

        //FUNCIÓN PARA MOSTRAR CONFIRMACIÓN DE ACCIÓN
        function displayDialogConfirmationMessage(action) {

            let response = fireSweetAlert(title_delete, () => executeAction(action), txt_delete);

            response.then((res) => {
                if (res.isConfirmed) {
                    clearDataSelected();
                }
            });

        }

        async function executeAction(action) {
            let status_preview = selected_image.value.active === 1 ? 0 : 1;
            let response;

            if (action === 'changeStatus') {
                response = await changeStatusFileWS(selected_image.value.id_file, status_preview);
            } else if (action === 'deleteFile') {
                response = await deleteFileWS(selected_image.value.id_file);

                if (response.result) {
                    showNotification('success', 'Imagen borrada correctamente');
                } else {
                    showNotification('error', response['errors']);
                }

            }

            let managed_response = await manageWSResponseMultiple(response);

            if (managed_response.length > 0) {
                await fireInfoAlert('Aviso', viewHtml(managed_response));

                if (variableIsDefined(managed_response.success) && action === 'changeStatus') {
                    selected_image.value.active = status_preview;
                }
            }

            await getImagesByPortal();
            clearDataSelected();
        }

        function clearDataSelected() {
            selected_image.value = {};
        }

        return {
            displayDialogConfirmationMessage,
            displayDialogImage,
            getImagesByPortal,
            insertImage,
            selectImage,
            clearDataSelected,
            displayDialogUpdate,
            random,
            general_loading,
            display_dialog_image,
            loading_portals,
            loading_images,
            portal_filter,
            images_portal,
            name_filter,
            selected_image,
            title_modal,
            img_entity,
            select_options,
            portals,
            display_dialog_update,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/images-library.scss";
</style>