import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getAllCountriesWS(){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try{
        return await aiseoBackendPetition('POST', 'country/getCountries', {}, params_token)
    }catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'country/getCountries');
    }
}