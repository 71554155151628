<template>
    <BaseModalTemplate
            :title="`${data_to_show} asociadas al elemento en cola con ID ${element_id}`"
            :open-close-dialog="openModalURLs"
            :hide_actions="true"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" class="mt-2">
                    <Vue3EasyDataTable
                        table-class-name="primary-table modal-table"
                        theme-color="#F4A649"
                        buttons-pagination
                        :headers="headers"
                        :items="table_items"
                        :hide-footer="table_items.length<=25"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-id_portal="item">
                            {{ getNamePortal(parseInt(item.id_portal)) }}
                        </template>

                        <template #item-id_idioma="item">
                            {{ getNameLanguage(parseInt(item.id_idioma)) }}
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {ref} from "vue";

export default {
    name: "ModalElementData",
    methods: {variableIsDefined, getNameLanguage, getNamePortal},
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        openModalURLs: Function,
        element_id : Number,
        element_urls : Array,
        element_config: Array,
        data_to_show: String
    },
    setup(props){
        const headers =
            props.data_to_show === 'URLs' ?
        [
            {text: 'URL', value: 'id_url'},
            {text: 'Portal', value: 'id_portal'},
            {text: 'Idioma', value: 'id_idioma'},
        ]
                :
        [
            {text: 'TABLA', value: 'tabla'},
            {text: 'CAMPO', value: 'campo'},
        ]

        const table_items = ref(props.data_to_show === 'URLs' ? props.element_urls : props.element_config)

        return{
            table_items,
            headers,
        }
    }
}
</script>

<style lang="scss" scoped>
</style>