<template>
<BaseModalTemplate
    :open-close-dialog="openModalURLs"
    :title="title_url"
    card_extra_classes="modal-width-xs"
    :hide_actions="true"
>
    <template #body-modal-content>
        <v-table>
            <thead>
            <tr>
                <th class="text-left">
                    ID
                </th>
                <th class="text-left">
                    NOMBRE
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="statu in element_urls"
                :key="statu.id_url"
            >
                <td>{{ statu.id_url }}</td>
                <td>{{ statu.url }}</td>
            </tr>
            </tbody>
        </v-table>
    </template>
</BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ModalUrls",
    components: {Loading, BaseModalTemplate},
    props:{
        openModalURLs: Function,
        element_urls: Object,
        title_url: String,
    },
    setup(){


        return{

        }
    }
}
</script>


<style scoped lang="scss">

</style>