<template>
    <v-expansion-panels>
        <v-expansion-panel class="expansion-personalized">
            <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-row>
                    <slot name="filters"></slot>
                    <!--   FILTROS COMUNES -->
                    <v-col cols="12" md="4" lg="4"
                           class="align-self-start">
                        <v-select v-model="filters.category_type"
                                  class="select-primary personalize-text-field field-with-label"
                                  label="Seleccione el tipo de categoría"
                                  :items="categories_types"
                                  item-title="type_name"
                                  item-value="type_id"
                                  variant="underlined"
                                  @update:modelValue="searchCategories()"
                                  :menu-props="{width: '250px'}"
                                  hide-details>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="8"
                           :lg="type_order_col_template ? '8' : '5'"
                           :xl="type_order_col_template ? '8' : '6'"
                           class="align-self-start">
                        <v-autocomplete
                            :disabled="!type_selected"
                            v-model="filters.category_id"
                            class="input-error select-primary personalize-text-field field-with-label"
                            label="Seleccione la categoría"
                            :items="categories"
                            item-title="nombre"
                            item-value="id_categoria"
                            variant="underlined"
                            :error="category_error"
                            hide-details
                            :menu-props="{width: '250px'}"
                        ></v-autocomplete>
                        <span v-if="category_error" class="input-error msg-error">
                            Debe rellenar la categoría para aplicar los filtros
                        </span>
                    </v-col>
                </v-row>
                <!--   BOTÓN DE FILTRAR Y DE LIMPIAR FILTROS   -->
                <v-row justify="end" no-gutters class="pt-2 sm:pt-0">
                    <v-col cols="4" sm="2" md="2" class="text-end align-self-center p-1 sm:p-0">
                        <v-btn class="btn button-bg-blue" @click="applyCommonFilters" :loading="loading_filters">
                            Filtrar
                        </v-btn>
                    </v-col>
                    <v-col cols="4" sm="2" md="1" class="text-end align-self-center p-1 sm:p-0">
                        <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                             class="delete-filter" @click="deleteCommonFilters">
                    </v-col>
                </v-row>
                <!--                    <v-col cols="2" xs="3">
                                        <v-btn variant="outlined" class="btn button-primary" @click="exportData"
                                               :disabled="disabled_export">
                                            Exportar
                                        </v-btn>
                                    </v-col>-->
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

import {variableIsDefined} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";
import {getAreasWS, getCategoriesWS, getSubcategoriesWS} from "@/utils/web_categorization";

export default {
    name: "BaseFilterUrl",
    props: {
        getApplyBaseFilters: Function,
        getDeleteFilter: Function,
        image_screen: Boolean,
        type_order_col_template: {
            type: Boolean,
            default: true,
        }
    },
    setup(props) {
        let loading_filters = ref(false)
        let filters = ref({})
        let categories_types = ref([
            {type_name: 'Áreas web', type_id: 1},
            {type_name: 'Categorías web', type_id: 2},
            {type_name: 'Subcategorías web', type_id: 3},
        ])
        let categories = ref()
        let type_selected = ref(false)
        let category_error = ref(false)

        onBeforeMount(async () => {
            if (props.image_screen) {
                categories_types.value.splice(0, 1)
            }
        })

        async function searchCategories() {
            let search_type = filters.value.category_type
            let response

            filters.value.category_id = null
            type_selected.value = false

            switch (search_type) {
                case 1:
                    response = await getAreasWS()
                    break;
                case 2:
                    response = await getCategoriesWS()
                    break;
                case 3:
                    response = await getSubcategoriesWS()
                    break;
            }

            if (response.result) {
                categories.value = response.data
                type_selected.value = true
            }
        }

        function checkCategoryFilter() {
            let correct = true
            category_error.value = false

            if (!variableIsDefined(filters.value.category_id)) {
                category_error.value = true
                correct = false
            }

            return correct
        }

        function applyCommonFilters() {
            /*CATEGORIA*/
            loading_filters.value = true
            if (variableIsDefined(filters.value.category_type)) {
                if (checkCategoryFilter()) {
                    filters.value.category = JSON.stringify({
                        type: filters.value.category_type,
                        id: filters.value.category_id
                    })
                }
            } else {
                filters.value.category = null
            }

            if ((variableIsDefined(filters.value.category_type) && variableIsDefined(filters.value.category))
                || (!variableIsDefined(filters.value.category_type) && !variableIsDefined(filters.value.category))) {
                props.getApplyBaseFilters(filters.value)
            }
            loading_filters.value = false

        }

        let deleteCommonFilters = () => {
            filters.value = {}
            category_error.value = false
            props.getDeleteFilter()
        }

        return {
            applyCommonFilters,
            deleteCommonFilters,
            searchCategories,

            loading_filters,
            filters,
            category_error,
            categories,
            categories_types,
            type_selected,
        }
    }
}
</script>


<style scoped lang="scss">

.expansion-personalized {
    border: 1px solid $blue-medium;

    .delete-filter {
        filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
        width: 30px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
        }
    }
}

.v-expansion-panel--active > .v-expansion-panel-title {
    min-height: 55px !important;
}


</style>