<template>
    <BaseModalTemplate
        :title="'Importar desde CSV'"
        :open-close-dialog="displayDialogImports"
        :card_extra_classes="'personalize-card modal-width-xs'"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span>Importe el documento: </span>
                    <v-file-input
                        v-model="form_data.imported_file"
                        hint="Solo se admiten ficheros .csv, .xls, .xlsx"
                        persistent-hint
                        accept=".csv,.xls,.xlsx"
                        class="text-field-primary field-with-label"
                        variant="underlined"
                        prepend-icon="mdi-paperclip"
                        :class="{error :form_error.imported_file}"
                        @click:clear='clearInput()'
                    ></v-file-input>
                    <span v-if="form_error.imported_file" class="input-error msg-error">
                            {{ form_error.imported_file }}
                    </span>
                </v-col>
                <v-col class="align-self-center" cols="12">
                    <span>El fichero que debe adjuntarse debe seguir la estructura de la plantilla que podrás encontrar
                        <a style="color:#36A3FF;" :href=href_template
                           :download=download_template
                        >
                            aquí
                        </a>
                    </span>

                </v-col>
                <template v-if="search_replace_regex.length > 0">
                    <v-col class="align-self-center" cols="12" v-if="search_replace_regex.length > 0">
                    <span class="input-title">
                        Tipo de búsqueda
                    </span>
                    <p class="my-3">
                        ¡Usar expresiones regulares para buscar y reemplazar puede producir cambios inesperados. Utiliza
                            el previsualizador para asegurarte de que los cambios que has indicado en el excel son los correctos!
                    </p>
                        <v-select
                            :items="search_replace_regex"
                            v-model="form_data.selected_search_type"
                            item-title="name"
                            item-value="id"
                            placeholder="Seleccione un tipo de búsqueda"
                            class="input-error select-secondary"
                            bg-color="white"
                            hide-details
                            return-object
                            no-data-text="No hay datos disponibles"
                            @update:modelValue="setSearchType()"
                        >
                        </v-select>
                    </v-col>
                    <v-col class="align-self-center" cols="12" v-if="form_data.selected_search_type.id !== 1">
                        <p>
                            Todos los campos search del fichero serán sustituidos por la siguiente expresión:
                        </p>
                        <span v-if="form_data.selected_search_type.id >2">
                        Eligiendo esta opción se ignorarán los campos replace del fichero
                    </span>
                        <v-text-field
                            v-model.trim="form_data.selected_regex"
                            placeholder="Expresión regular"
                            class="input-error text-field-primary"
                            variant="underlined"
                            :error="variableIsDefined(form_error.regex)"
                            :disabled="form_data.selected_search_type.id > 2"
                            hide-details
                        ></v-text-field>
                        <span class="input-error msg-error" v-if="variableIsDefined(form_error['regex'])">
                        {{form_error['regex']}}
                    </span>
                    </v-col>
                </template>

                <v-col cols="12" v-if="file_uploaded_successfully || variableIsDefined(file_warning)">
                    <p v-if="variableIsDefined(file_warning)" v-for="warning in file_warning" class="msg-info" style="font-weight: bold">
                        {{ warning }}</p>
                    <span v-else class="msg-info">Su fichero está siendo procesado. Consulte el progreso en la siguiente url:
                        <router-link to="/cola-redirecciones" class="msg-router-link">
                            Cola de redirecciones
                        </router-link>
                    </span>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col class="align-self-center">
                    <v-btn class="btn button-bg-blue"
                           @click="sendFile"
                           :loading="loading_btn"
                    >Importar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import * as Yup from "yup";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalImportDocument",
    components: {BaseModalTemplate},
    methods: {variableIsDefined},
    props: {
        displayDialogImports: Function,
        href_template: String,
        download_template: String,
        search_replace_regex: {
            type: Array,
            default: []
        }
    },

    setup(props, {emit}) {
        let form_data = ref({})
        let form_error = ref({})
        let loading_btn = ref(false)
        let file_uploaded_successfully = ref(false)
        let file_warning = ref([])

        onBeforeMount(() => {
            if (props.search_replace_regex.length > 0){
                form_data.value.selected_search_type = props.search_replace_regex[0]
                setSearchType()
            }
        })

        function setSearchType(){
            form_data.value.selected_regex = form_data.value.selected_search_type.regex
        }

        const sendFile = async () => {
            file_uploaded_successfully.value = false
            file_warning.value = []
            loading_btn.value = true
            
            if (await validateData()) {
                try {
                    await awaitEmit(form_data.value)
                }catch (e) {
                    console.log(e)
                }
            }

            loading_btn.value = false
        }


        async function awaitEmit(payload){
            return new Promise((resolve, reject) =>{
                emit('send', payload, (response) => {
                    if (response['result']) {
                        file_uploaded_successfully.value = true
                        if (variableIsDefined(response['data']['warning'])) {
                            file_warning.value = response['data']['warning']
                        }
                        form_data.value.imported_file = null
                        showNotification('success', 'Archivo subido correctamente')
                        resolve()
                    } else {
                        showNotification('error', response['errors'])
                        reject('Error en la consulta')
                    }
                });
            })
        }

        const validateFile = async () => {
            const schemaForm = Yup.object().shape({
                imported_file: Yup.mixed()
                    .nullable()
                    .required('El campo es obligatorio')
                    .test(
                        'fileType',
                        'El archivo debe ser de tipo .csv, .xlsx o .xls',
                        (value) => {
                            if (!value) return true;
                            const allowedFileTypes = [
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                                'application/vnd.ms-excel', // .xls
                                'text/csv', // .csv
                            ];
                            return allowedFileTypes.includes(value.type);
                        }),
            });

            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})
                return true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                return false
            }
        }

        async function validateData(){
            let is_valid_file = await validateFile()
            let correct = true
            form_error.value = {}

            if (props.search_replace_regex.length > 0){

                if (form_data.value.selected_search_type.id !== 1 && !variableIsDefined(form_data.value.selected_regex)){
                    correct = false
                    form_error.value['regex'] = 'La expresión no puede estar vacía'
                }
            }

            return is_valid_file && correct
        }

        const clearInput = () => {
            form_data.value.imported_file = null
        }


        return {
            clearInput,
            sendFile,
            setSearchType,

            file_uploaded_successfully,
            file_warning,
            form_error,
            form_data,
            loading_btn,
        }
    }
}
</script>


<style scoped lang="scss">
.personalize-card {
    .content-modal {
        min-height: 15vh !important;

        .msg-info {
            font-size: 14px;
            font-style: italic;
            color: $gray-medium-bold;
        }

        .msg-router-link {
            color: $blue-medium;
        }
    }
}


</style>