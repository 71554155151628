import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function getPortalFormativeProgramsWS(id_portal, id_training_program = null, show_degrees = null, iso_lanugage = null) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'portal': id_portal,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'portal': id_portal,
        'formativeProgramID': id_training_program,
        'showDegrees': show_degrees,
        'languageISO': iso_lanugage,
    }

    try {
        return await aiseoBackendPetition('POST', 'sites/getPortalFormativePrograms', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/getPortalFormativePrograms');
    }
}

export async function getSitesWS(){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'sites/getSites', {}, {})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/getSites');
    }
}

export async function assignDegreeToFormativeProgramWS(id_portal, id_language, id_program, degree){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': id_portal,
        'language': id_language,
        'formative_program': id_program,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': id_portal,
        'language': id_language,
        'formative_program': id_program,
        'degree_data': degree
    }

    try {
        return await aiseoBackendPetition('POST', 'degrees/assignDegreeToFormativeProgram', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'degrees/assignDegreeToFormativeProgram');
    }
}