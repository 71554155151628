<template xmlns="http://www.w3.org/1999/html">
    <v-container>
        <div class="container-upload-sem">
            <v-row>
                <v-col cols="12" class="main_col_title">
                    <span class="main-title-page">
                        <img :src="require('@/assets/icons/navbar/upload.svg')" alt="icono de volcado SEMrush" width="28" height="28">
                        Subir volcado SEMrush
                    </span>
                </v-col>
                <div class="box-upload-sem">
                    <v-col cols="12">
                        <div class="input-group">
                            <button class="btn-selection-archivo" type="button" id="inputAddon">
                                Selecciona archivo
                            </button>
                            <v-file-input
                                show-size
                                label="Selecciona un archivo"
                                type="file" class="form-control file-input-primary" id="input"
                                aria-describedby="inputAddon"
                                aria-label="Upload"
                                hide-details
                                :prepend-icon="null"
                                @change="handleFileUpload( $event )"
                            ></v-file-input>
                            <!--                            <input type="file" class="form-control" id="input"-->
                            <!--                                   aria-describedby="inputAddon"-->
                            <!--                                   aria-label="Upload"-->
                            <!--                                   @change="handleFileUpload( $event )"/>-->
                            <span v-if="file">({{ file.name }}</span>
                            <button class="btn button-primary with-border-radius btn-upload-archivo" type="button"
                                    id="inputAddon"
                                    @click="uploadFileSEMRUSH">
                                Subir
                            </button>
                        </div>
                    </v-col>
                </div>
            </v-row>
            <v-row>
                <v-col>
                    <div v-if="loading" class="box-wait">
                        <img class="loading-gif-s" :src="require('@/assets/img/loading.gif')"
                             alt="Loading Gif">
                        <span class="msg-wait">Este proceso puede durar unos minutos, puede cerrar la página</span>
                    </div>
                    <span class="personalize-error" v-if="errored">Se ha producido un error al cargar el archivo</span>
                    <div v-if="finished">
                    <span class="personalize-correct">Se han cargado los nuevos datos Puedes verlos en el dashboard de Semrush a través de <a
                        href="https://aicore.euroinnova.edu.es" target="_blank">Aicore</a></span>
                    </div>

                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>

import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import {getAicoreToken} from "@/utils/aux_functions";

export default {
    name: "UploadSEMrush",

    setup() {
        const store = useStore();
        const route = useRoute();
        let aicore_token;
        let username = computed(() => store.getters.getUsername).value;
        let file = null;
        let loading = ref();
        let errored = ref();
        let finished = ref();

        onBeforeMount(async () => {
            await hasPermission(route);
        })

        const handleFileUpload = (event) => {
            file = event.target.files[0]
        }

        const uploadFileSEMRUSH = async () => {
            loading.value = true;
            errored.value = false;
            finished.value = false;

            let response = await getAicoreToken({})
            aicore_token = response['access_token']

            let today = new Date();
            let date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
            let name = 'semrush_' + date + '.' + file.name.split('.').pop();

            let formData = new FormData();
            formData.append('file', file, name);
            formData.append('username', username);

            axios.post(process.env.VUE_APP_AICORE_URL_API + "AISEO/semrush/informe-semrush-looker",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + aicore_token
                    }
                }
            ).then(function (semrushResult) {
                if (semrushResult.data['result']) {
                    finished.value = true;
                } else {
                    errored.value = true;
                }
            })
                .catch(function (semrushResult) {
                    errored.value = true;
                });

            loading.value = false;
        }

        return {
            handleFileUpload,
            uploadFileSEMRUSH,
            loading,
            file,
            errored,
            finished
        };
    },
};
</script>

<style lang="scss">
@import "@/styles/views/upload-sem-rush-view.scss";
</style>
