<template xmlns="http://www.w3.org/1999/html">
    <template v-if="username && roles && user_roles">
        <v-app>
            <v-app-bar flat class="top-menu" :height="is_mobile ? '100': '64'">
                <NavBar :is_mobile="is_mobile" :key=roles :renderApp="renderApp"/>
            </v-app-bar>

            <v-navigation-drawer
                v-if="false && !is_mobile && this.$route.path !== '/'"
                class="custom-drawer"
                v-model="show_drawer"
                :rail="mini"
                permanent
                floating
            >
                <v-btn :ripple="false" id="open-sidebar-btn" class="toggle-btn" @click.stop="openCloseSidebar" density="compact" :icon="mini ?  'mdi-chevron-right' : 'mdi-chevron-left'"></v-btn>

                <Sidebar :openCloseSidebar="openCloseSidebar" :is_closed="mini"/>

                <v-list class="eduseo-logo-container">
                    <v-list-item>
                        <template v-slot:prepend>
                            <img :src="require('@/assets/icons/sidebar/edtech-logo-icon.svg')" alt="Logo Educa Edtech" height="56" width="56">
                        </template>
                        <img :src="require('@/assets/icons/sidebar/letras-logo-educa.svg')" alt="Logo Educa Edtech" height="58" width="130">

                    </v-list-item>
                </v-list>

            </v-navigation-drawer>

            <v-main id="main-app">
                <v-container fluid>
                    <BreadCrumb v-if="render && this.$route.path !== '/bridge'"/>
                    <router-view v-if="render"/>
                </v-container>
            </v-main>

            <v-bottom-navigation
                v-if="false && is_mobile && this.$route.path !== '/'"
                elevation="0"
                class="bottom-navigation"
                horizontal
                grow

            >
                <MobileBottomMenu></MobileBottomMenu>
            </v-bottom-navigation>
        </v-app>

    </template>
    <AuthView v-else></AuthView>
</template>

<script>
import NavBar from "@/components/Navigation/Navbar.vue";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import MobileBottomMenu from "@/components/Navigation/MobileBottomMenu.vue";
import {useStore} from "vuex";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import {logout} from "@/utils/auth";
import AuthView from "@/views/Auth/AuthView";
import {initSweetNotification} from "@/utils/sweetalert";
import BreadCrumb from "@/components/Navigation/BreadCrumb.vue";
import {useDisplay} from "vuetify";
import {activateRipple} from "@/utils/aux_functions";

export default {
    components: {
        BreadCrumb,
        NavBar,
        Sidebar,
        AuthView,
        MobileBottomMenu,
    },
    setup() {
        const store = useStore();
        const display_properties = useDisplay();

        let username = computed(() => store.getters.getUsername);
        let roles = computed(() => store.getters.getRoles);
        let user_roles = computed(() => store.getters.getRolesUser);
        let render = ref(false);

        let show_drawer = ref(true);
        let mini = ref(true);

        let is_mobile = ref(display_properties.smAndDown.value)
        let maintenance_mode = ref(process.env.MAINTENANCE === "1")

        onBeforeMount(() => {
            existsLoggedUser();
            initSweetNotification()
        })

        watch(display_properties.smAndDown, (value) => {
            is_mobile.value = value;
        });

        const renderApp = () => {
            render.value = true;
        }

        const existsLoggedUser = () => {
            let hours = 4
            let saved = localStorage.getItem('saved')
            if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
                logout()
            }
            if (username.value) {
                store.commit('setUser', username.value)
            }
        }

        const openCloseSidebar = () => {
            mini.value = !mini.value
        }

        /* TODO
        * Parche para que el botón del sidebar cambie de color al pulsarlo
        * intentar cambiar
        * */
        $(document).ready(function () {
            let $open_sidebar_btn = $("#open-sidebar-btn")

            $open_sidebar_btn.on('mousedown', function (e) {
                if (e.button === 0) {
                    $open_sidebar_btn.addClass('is-pressed')
                }
            })

            document.addEventListener('mouseup', (event) => {
                if ($open_sidebar_btn.hasClass('is-pressed')) {
                    $open_sidebar_btn.removeClass('is-pressed')
                }
            });
        });

        return {
            openCloseSidebar,

            render,
            username,
            roles,
            user_roles,
            renderApp,
            show_drawer,
            mini,
            is_mobile,
            maintenance_mode,
        }
    }
}
</script>


<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/components/navbar.scss";
@import "@/styles/components/sweetalert/notification-toast.scss";

.eduseo-logo-container {
    margin-bottom: 3vh;

    .v-list-item {
        padding-inline: 0 !important;

        &:hover{
            background: transparent;
        }
    }
}


.toggle-btn {
    position: absolute;
    bottom: 2%;
    right: -12px;
    transform: translateY(0);
    z-index: 10;
    box-shadow: none;
    color: $sidebar-icon;
    border: 1px solid $general-elements-hover;

    &:active, &:focus {
        box-shadow: none;
    }

    &:hover {
        border-color: $blue-darker;
        color: $blue-darker;
        box-shadow: none;
    }
}

.is-pressed {
    color: $white;
    background: $blue-medium;

    &:hover {
        border-color: transparent;
        color: $white;
        box-shadow: none;
    }
}

.bottom-navigation {
    background: $background;
    height: 90px !important;
}

</style>