<template>
    <v-autocomplete
        v-model="internal_selected"
        v-model:menu="menu_model_value"
        :menu-icon="null"
        :placeholder="placeholder"
        class="select-secondary-multiple chips-selector select-menu"
        :items="flatList"
        :item-title="itemTitle"
        :item-value="itemValue"
        multiple
        hide-details
        bg-color="white"
        no-data-text="No hay datos disponibles"
        @update:modelValue="updateModelValue"
        :menu-props="{
            maxWidth: '300px',
        }"
        :disabled="disabled"
        chips
        closable-chips
        :custom-filter="customFilter"
    >
        <template v-slot:prepend-inner="image" v-if="!variableIsDefined(internal_selected)">
            <slot name="prepend_image" v-bind="image">
                <v-img :src="require('@/assets/icons/navbar/icon-institutions.svg')" height="24" width="24"></v-img>
            </slot>
        </template>
        <template v-slot:append-inner>
            <v-icon>{{ menu_model_value ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
        </template>
        <template v-slot:chip="{props, item}">
            <v-chip
                v-bind="props"
                :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/'+item?.raw?.logo"
                class="custom-chip"
            >
                <template #close="">
                    <v-icon icon="mdi-close" ></v-icon>
                </template>
                {{`${item.raw.nombre}`}}
            </v-chip>
        </template>

        <template #item="{props, item, index}">
            <div>
                <v-divider
                    v-if="item.raw.is_divider"
                    :key="'divider-' + index"
                ></v-divider>
                <v-list-item
                    class="group-name-item"
                    v-else-if="item.raw.is_group_name"
                >
                    <v-list-item-title class="group-name">
                        <span style="cursor: pointer">{{item?.raw?.group_name}}</span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-else
                    v-bind="props"
                    :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/'+item?.raw?.logo"
                    :title="item?.raw?.nombre"
                >
                    <template #append v-if="itemIsSelected(item)">
                        <v-icon color="blue" icon="mdi-check"></v-icon>
                    </template>
                </v-list-item>
            </div>
        </template>
    </v-autocomplete>

</template>

<script>
import {variableIsDefined} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";

export default {
    methods: {variableIsDefined},
    emits: ['changeValue',"update:modelValue"],
    props:{
        placeholder: String,
        itemTitle: String,
        itemValue: String,
        select_menu_props: Object,
        items: Array,
        prepend_image: String,
        modelValue: {
            type: Array,
            default: []
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        default_values: {
            type: Array,
            default: []
        }
    },
    setup(props, {emit}){
        let menu_model_value = ref(false)
        let model_value = ref()
        let grouped_portals = ref(props.items.reduce((acc, item) => {
            if (!acc[item.tipo_portal]) {
                acc[item.tipo_portal] = [];
            }
            acc[item.tipo_portal].push(item);
            return acc;
        }, {}));

        let flatList = ref([])
        let internal_selected = ref([...props.default_values]);

        onBeforeMount(() => {
            Object.keys(grouped_portals.value).forEach((group, index) => {
                if (index > 0) {
                    flatList.value.push({ is_divider: true });
                }

                flatList.value.push({ is_group_name: true, group_name: group },...grouped_portals.value[group]);
            });
        })


        const changeSelection = () => {
            emit('changeValue', model_value.value)
        }

        function updateModelValue(event) {
            emit('update:modelValue', event)
        }

        const itemIsSelected = (item) => {
            return internal_selected.value.includes(item.value)
        }

        const customFilter = (item_text, query_text, item) => {
            const query = query_text.toLowerCase();
            const text = item_text.toLowerCase();

            if (item.value.is_group_name || item.value.is_divider){
                return true
            }

            return text.includes(query)
        }

        return{
            customFilter,
            itemIsSelected,
            updateModelValue,
            changeSelection,
            model_value,
            menu_model_value,
            flatList,
            internal_selected,
        }
    }
}

</script>

<style lang="scss">

.chips-selector {
    .custom-chip {
        border: 1px solid $general-elements-hover;
        .v-chip__underlay {
            background: transparent !important;
        }

        .v-chip__close {
            .v-icon {
                transform: none !important;
                color: $general-elements-hover !important;
            }
        }
    }
}

.group-name-item {
    &:hover{
        background: transparent;
    }
    .group-name{
        font-weight: bold;
    }
}
</style>