<template>
    <div class="auth">
        <Login v-if="showLogin" :changeForm="changeForm"/>
    </div>
</template>

<script>

import {ref} from "vue";
import Login from "@/components/Auth/Login";

export default {
    name: 'AuthView',
    components: {
        Login,
    },
    setup() {
        let showLogin = ref(true)

        const changeForm = () => {
            showLogin.value = !showLogin.value
        }
        return {
            showLogin,
            changeForm
        }
    },

}
</script>

<style lang="scss" scoped>
</style>