<template>
    <DiscardDrafts v-if="draft"
                   :url="form_data.url"
                   :draft-type="draftType"
    ></DiscardDrafts>

    <template v-if="form_data.tipo_url === 'ficha_producto'">
        <GenerateImage
            :data_url="data_url"
        ></GenerateImage>
        <BrokenLinks
            :seo_description="form_data.content"
        ></BrokenLinks>
    </template>

    <template v-else-if="form_data.tipo_url === 'contenido_seo'">
        <DeletePage
            :form_data_url="form_data.url"
        ></DeletePage>
        <LinkBoxesEditor
            :data_url="data_url"
        ></LinkBoxesEditor>
    </template>
    <Redirects
        :data_url="data_url"
    ></Redirects>

    <template v-if="(form_data.tipo_url === 'ficha_producto' || form_data.urlType === 'ficha_producto') && draftType === 'course' ">
        <InvertDegrees
            :data_url="data_url"
            :form_data="form_data">
        </InvertDegrees>
    </template>

</template>

<script>
import BrokenLinks from "@/components/Editor/Buttons/BrokenLinks.vue";
import DeletePage from "@/components/Editor/Buttons/DeletePage.vue";
import GenerateImage from "@/components/Editor/Buttons/GenerateImage.vue";
import Redirects from "@/components/Editor/Buttons/Redirects.vue";
import LinkBoxesEditor from "@/components/Editor/Buttons/LinkBoxesEditor.vue";
import {onBeforeMount, ref} from "vue";
import {userHaveAllowedRol} from "@/utils/auth";
import DiscardDrafts from "@/components/Editor/Buttons/DiscardDrafts.vue";
import InvertDegrees from "@/components/Editor/Buttons/InvertDegrees.vue";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "Actions",
    components: {InvertDegrees, DiscardDrafts, LinkBoxesEditor, Redirects, GenerateImage, DeletePage, BrokenLinks},
    props: {
        data_url: String,
        draftType: String,
        draft: Boolean,
        form_data: Object
    },
}
</script>

<style scoped lang="scss">

</style>
