<template>
    <v-container>
        <!--  USUARIOS EXTERNOS      -->

        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/external-user.svg')" alt="icono de docente externo" width="28" height="28">
                    Listado docentes externos
                </span>
            </v-col>
            <v-col cols="12" sm="5" md="4" lg="3" xl="2" class="align-self-center text-end" v-if="!general_loading">
                <v-btn
                    class="btn button-primary"
                    @click="selectUserTo('create')"
                >
                    Añadir usuario externo
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        class="primary-table"
                        :headers="external_user_headers"
                        :items="external_users"
                        :loading="loading_pending"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-linkedin="{linkedin}">
                            <span v-if="variableIsDefined(linkedin)"> {{ linkedin }} </span>
                            <span v-else> Sin datos</span>
                        </template>

                        <template #item-icon_edit="item">

                            <img :src="require('@/assets/icons/edit.svg')"
                                 alt="Editar"
                                 class="icon-data-table icon-edit"
                                 title="Editar"
                                 id="copy"
                                 @click="selectUserTo('update',item)"
                            >
                        </template>

                        <template #item-icon_delete="item">
                            <img :src=" require('@/assets/icons/trash.svg')"
                                 alt="Borrar"
                                 class="icon-data-table icon-delete"
                                 title="Borrar"
                                 id="delete"
                                 @click="selectUserTo('delete', item)"
                            >
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
            <!--   FIN USUARIOS EXTERNOS     -->
            <v-dialog v-model="open_external_user_modal">
                <FormCreateExternalUser
                    :title="title_modal_external_user"
                    :btn_action="btn_action_external_user"
                    :showDialog="showDialogExternalUser"
                    :user_to_update="user_to_update"
                    :updateTable="getAllExternalUsers"
                >
                </FormCreateExternalUser>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import FormCreateExternalUser from "@/components/Forms/FormCreateExternalUser.vue";
import {onBeforeMount, ref} from "vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getExternalUsersWS, removeExternalUserWS} from "@/utils/external_users";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ExternalTeachersView",
    methods: {variableIsDefined},
    components: {Loading, FormCreateExternalUser, Vue3EasyDataTable},
    setup() {
        let general_loading = ref(false)
        const loading_pending = ref(true)
        const external_users = ref([])
        const open_external_user_modal = ref(false)
        const external_user_headers = ref([
            {text: 'Nombre de usuario', value: 'username'},
            {text: 'Nombre', value: 'firstname'},
            {text: 'Apellidos', value: 'secondname'},
            {text: 'Correo', value: 'email'},
            {text: 'Linkedin', value: 'linkedin'},
            {text: '', value: 'icon_edit'},
            {text: '', value: 'icon_delete'},
        ])

        const title_modal_external_user = ref('Crear usuario externo')
        const btn_action_external_user = ref('Crear')
        const user_to_update = ref({})


        onBeforeMount(async () => {
            general_loading.value = true
            await getAllExternalUsers()
            initSweetAlert()
            general_loading.value = false
        })

        const getAllExternalUsers = async () => {
            loading_pending.value = true
            const response = await getExternalUsersWS();
            if (response['result']) {
                external_users.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_pending.value = false
        }

        const showDialogExternalUser = () => {
            open_external_user_modal.value = !open_external_user_modal.value
        }

        const selectUserTo = (action, user = {}) => {
            user_to_update.value = {}

            switch (action) {
                case 'create':
                    btn_action_external_user.value = 'Crear'
                    title_modal_external_user.value = 'Crear usuario externo'
                    showDialogExternalUser()
                    break;
                case 'update':
                    btn_action_external_user.value = 'Actualizar'
                    title_modal_external_user.value = 'Actualiza usuario'
                    user_to_update.value = user
                    showDialogExternalUser()
                    break;
                case 'delete':
                    user_to_update.value = user
                    fireSweetAlert("Eliminar usuario externo " + user.username, deleteExternalUser)
                    break;
            }
        }

        const deleteExternalUser = async () => {
            if (variableIsDefined(user_to_update.value.username)) {
                const response = await removeExternalUserWS(user_to_update.value)

                if (response['result']) {
                    showNotification('success', response['data'].message)
                    await getAllExternalUsers()
                } else {
                    showNotification('error', response['errors'])
                }
            }
        }

        return {
            deleteExternalUser,
            getAllExternalUsers,
            selectUserTo,
            showDialogExternalUser,

            btn_action_external_user,
            external_user_headers,
            external_users,
            loading_pending,
            general_loading,
            open_external_user_modal,
            title_modal_external_user,
            user_to_update,
        }
    }
}
</script>

<style scoped>

</style>