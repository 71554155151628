<template>
    <v-table fixed-header height="42vh">
        <thead>
        <tr>
            <th class="text-left">Imagen
            </th>
            <th class="text-left">
                Nombre
            </th>
            <th class="text-left">
                Propiedades del archivo
            </th>
        </tr>
        </thead>
        <tbody>
        <tr class="td-general"
            v-for="item in all_files"
            :key="item.id_file">
            <td v-if="item.type === 'image'" class="td-img-video">
                <template v-if="item.name.split(['.'])[1]==='svg'">
                    <img
                        :src="require('@/assets/icons/icon_svg.svg')"
                        class="icon-video">
                </template>
                <template v-else><img
                    :src="item.upload_url" alt="Image" type="img/svg"
                    class="img-file"></template>
            </td>
            <td v-else-if="item.type === 'video'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-video.svg')" alt="Video"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'audio'" class="td-img-video"><img
                :src="require('@/assets/icons/icon-audio.svg')"
                alt="Audio"
                class="icon-video">
            </td>
            <td v-else-if="item.type === 'application'" class="td-img-video"><img
                :src="require('@/assets/icons/file.svg')"
                alt="Archivo"
                class="icon-video">
            </td>
            <td v-else class="td-img-video"><img
                :src="require('@/assets/icons/icon-push.svg')"
                class="icon-video">
            </td>
            <td> {{ item.name }}</td>
            <td>
                <v-btn class="btn button-primary" @click="getProperties(item.id_file)">AÑADIR</v-btn>
            </td>
        </tr>
        </tbody>
    </v-table>
    <v-dialog persistent
              v-model="open_modal">
        <ModalPropertiesFile
            :displayModal="displayModal"
            :saveAllProperties="saveAllProperties"
            :obj_file_selected="obj_file_selected"
        />
    </v-dialog>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import ModalPropertiesFile from "@/components/MediaValet/ModalPropertiesFile.vue";

export default {
    name: 'TableAddFilesProperties',
    components: {ModalPropertiesFile},
    props: {
        date_file: Object,
        savePropertiesFiles: Function,
    },
    setup(props) {
        let open_modal = ref(false)
        let form_data_file = {}
        let all_files = ref();
        let obj_file_selected = ref()
        let new_title_file;

        onBeforeMount(() => {
            all_files.value = []
            props.date_file.forEach((file) => {
                new_title_file = file.name_upload.split('.')
                form_data_file = {
                    'img': file.upload_url,
                    'id_file': file.id_upload,
                    'name': file.name_upload,
                    'title': new_title_file[0],
                    'description': "",
                    'size': file.size,
                    'name_category': file.nameCategory,
                    'type': file.type
                }
                all_files.value.push(form_data_file)
            })
            props.savePropertiesFiles(all_files.value)
        })

        function saveAllProperties(id_file, name, title, description) {
            all_files.value.forEach((file) => {
                if (file.id_upload === id_file) {
                    file.name = name
                    file.title = title
                    file.description = description
                }
            })
            props.savePropertiesFiles(all_files.value)
        }

        function getProperties(id_file) {
            all_files.value.forEach((file) => {
                if (file.id_file === id_file) {
                    obj_file_selected.value = file
                }
            })
            displayModal()
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }

        return {
            open_modal,
            all_files,
            obj_file_selected,

            saveAllProperties,
            getProperties,
            displayModal,

        }
    }
}
</script>


<style scoped lang="scss">
.td-general {
    height: 10vh;

    .td-img-video {
        width: 10vw;
        text-align: left;
    }

    .icon-video {
        filter: $filter-primary-color-1;
        width: 30px;
        height: auto;
    }

    .img-file {
        width: auto;
        max-height: 7vh;
    }
}

@media (max-width: 800px) {
    .td-general {
        .icon-video {
            width: 20px;
            height: auto;
        }

        .img-file {
            width: auto;
            max-height: 5vh;
        }
    }
}

</style>