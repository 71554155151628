import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";


export async function getAllRankingWS(){
    let params = {
        id_portal: store.getters.getPortalID,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
        id_portal: store.getters.getPortalID,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/getAllRankings', params, params_token)

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/getAllRankings')
    }
}

export async function getAllRankingsUrlWS(id_url) {
    let params = {
        id_portal: store.getters.getPortalID,
        language: store.getters.getLanguageISO,
        url: id_url,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/getAllRankingsURL', params, params_token)

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/getAllRankingsURL')
    }
}

export async function insertURLRankingWS(json_form_data, id_url) {
    let params = {
        logo_data: json_form_data,
        id_portal: store.getters.getPortalID,
        language: store.getters.getLanguageISO,
        url: id_url,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/insertRanking', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/insertRanking')
    }
}



export async function getUrlRankingWS(id_url_ranking) {
    let params = {
        id_url_ranking: id_url_ranking
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/getUrlRanking', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/getUrlRanking')
    }

}

export async function updateUrlRankingWS(id_url_ranking, json_ranking_data) {
    let params = {
        id_url_ranking: id_url_ranking,
        ranking_data: json_ranking_data,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/updateRanking', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/updateRanking')
    }

}

export async function deleteUrlRankingWS(id_url_ranking) {
    let params = {
        id_url_ranking: id_url_ranking,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/deleteRanking', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/deleteRanking')
    }

}

export async function getHtmlRankingWS(id_url_ranking) {
    let params = {
        id_url_ranking: id_url_ranking,
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/getHTMLRanking', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/getHTMLRanking')
    }
}

export async function assignRankingUrlWS(ranking_data, url) {
    let params = {
        id_portal: store.getters.getPortalID,
        language: store.getters.getLanguageISO,
        url : url,
        ranking_data: ranking_data
    }
    let params_token = {
        usuario_logueado: store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'rankings/assignRankingURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' rankings/assignRankingURL')
    }
}
