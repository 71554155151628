<template>
    <v-card class="mx-auto box-profile">
        <div class="box-img">
            <v-img class="img-profile" v-if="user_image" :src="`${user_image}?`+new Date()"
                   alt="Imagen usuario" cover
            ></v-img>
            <v-img class="img-profile img-standard" v-else
                   :src="require('@/assets/img/standard-teacher.svg')" alt="Imagen usuario"
            ></v-img>
        </div>
        <v-card-text class="box-name-profile">
            <span>{{ user_name }}</span>
        </v-card-text>
        <v-card-actions class="box-actions">
            <v-btn v-if="can_edit" class="btn-edit"
                   @click="showForm('update',profile)">
            </v-btn>

            <v-btn v-if="can_delete" class="btn-delete"
                   @click="displayDialogConfirmationMessage(profile.id_tecnico)">
            </v-btn>

            <v-btn v-if="can_view" :class="{'btn-disable': (user_active === 1), 'btn-enable': (user_active === 0)}"
                   @click="displayDialogConfirmationMessage(profile)">
            </v-btn>

            <v-btn v-if="can_view" class="btn-view"
                   @click="showForm('view', profile)">
            </v-btn>
        </v-card-actions>

    </v-card>
</template>
<script>

export default {
    name: "GenericUserProfile",
    components: {},
    props: {
        profile: Object,
        showForm: Function,
        displayDialogConfirmationMessage: Function,
        user_image: String,
        user_name: String,
        user_active: Number,
        can_delete: Boolean,
        can_edit: Boolean,
        can_activate: Boolean,
        can_view: Boolean,
    },
}
</script>
<style scoped lang="scss">
@import "@/styles/components/generic-profile.scss";
</style>