<template>
    <v-container>
        <v-row>
            <v-col cols='12'>
                <span class='main-title-page'>
                    <img :src="require('@/assets/icons/navbar/search.svg')" alt="icono de buscador" width="28" height="28">
                    Buscador
                </span>
            </v-col>
        </v-row>
          <v-row>
            <v-col>
            <v-select
                  class='select-primary'
                  :items='engineTypeSelector'
                  v-model='selectedEngineType'
                  variant='underlined'
                  hide-details
              >
                  <template v-slot:selection='{ item }'>
                      <span >{{ item.value.front }}</span>
                  </template>
                  <template v-slot:item='{ props, item }'>
                      <v-list-item
                              v-bind='props'
                              :title='item?.raw?.front'
                      >
                      </v-list-item>
                  </template>
              </v-select>
          </v-col>
            <v-col>
            <v-select
                  class='select-primary'
                  :items='engineSuffixSelector'
                  v-model='selectedEngineSuffix'
                  variant='underlined'
                  hide-details
              >
                  <template v-slot:selection='{ item }'>
                      <span >{{ item.value.front }}</span>
                  </template>
                  <template v-slot:item='{ props, item }'>
                      <v-list-item
                              v-bind='props'
                              :title='item?.raw?.front'
                      >
                      </v-list-item>
                  </template>
              </v-select>
          </v-col>
          </v-row>
        <v-row>
            <v-col class='col-search'>
              <SearchSection v-if="engineType == 'eduseo-cursos'"
                :engineSuffix=engineSuffix
                :engineType=engineType
              ></SearchSection>
              <SearchSectionBlogs v-else
                :engineSuffix=engineSuffix
                :engineType=engineType
              >
              </SearchSectionBlogs>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import SearchSection from '@/components/SearchEngine/SearchSection';
import SearchSectionBlogs from '@/components/SearchEngine/SearchSectionBlogs';
import {onBeforeMount, ref, watch} from 'vue';
import {hasPermission} from '@/utils/auth';
import {useRoute, useRouter} from 'vue-router';

export default {
  components: {
    SearchSection,
    SearchSectionBlogs,
  },
  props: {
    engineSuffix: {
      type: String,
      required: true,
    },
    engineType: {
      type: String,
      required: true,
    },
  },


  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const engineSuffixSelector = [
      {id: 1, name: 'es-se', front: 'Español'},
      {id: 2, name: 'it-se', front: 'Italiano'},
    ];
    const engineTypeSelector = [
      {id: 1, name: 'eduseo-cursos', front: 'Cursos'},
      {id: 2, name: 'contenidos-seo', front: 'Blogs'},
    ];

    const selectedEngineSuffix = ref(
        engineSuffixSelector.find((option) => option.name === props.engineSuffix) || null);
    const selectedEngineType = ref(
        engineTypeSelector.find((option)=> option.name === props.engineType) || null);

    onBeforeMount(async () => {
      await hasPermission(route);
    });

    watch(selectedEngineSuffix, async () => {
      if (props.engineSuffix !== selectedEngineSuffix.value.name) {
        const path = route.fullPath.replace(
            '/'+ props.engineSuffix, '/' + selectedEngineSuffix.value.name);
        await router.push(path);
        await router.go();
      }
    });

    watch(selectedEngineType, async () => {
      if (props.engineType !== selectedEngineType.value.name) {
        const path = route.fullPath.replace(
            '/'+ props.engineType, '/' + selectedEngineType.value.name);
        await router.push(path)
        await router.go();
      }
    });

    return {
      engineSuffixSelector,
      engineTypeSelector,
      selectedEngineType,
      selectedEngineSuffix,
    };
  }
};
</script>

<style>
@import '@elastic/react-search-ui-views/lib/styles/styles.css';
@import '@/styles/components/search-engine/search-engine.scss';
</style>
