<template>
    <v-container>

        <v-row v-if="not_url_course">
            <v-col class="mt-4">
                <span>No se ha indicado la URL del curso.</span>
            </v-col>
        </v-row>
        <v-row v-if="not_find_url">
            <v-col class="mt-4">
                <span>La URL especificada del curso, no existe.</span>
            </v-col>
        </v-row>
        <v-row class="row-title-btn-add" v-if="!not_find_url && !not_url_course">
            <v-col cols="12" sm="5" md="5" lg="8">
                <span class="main-title-page">Ranking curso: {{ id_url_list }}</span>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="2" class="row-btn-create-ranking" v-if="!general_loading">
                <v-btn @click="createRanking" class="btn button-primary">Crear Ranking
                </v-btn>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="2" class="row-btn-to-image" v-if="!general_loading">
                <router-link to='/imagenes' target="_blank">
                    <v-btn class="btn button-primary">Insertar imagen
                    </v-btn>
                </router-link>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" md="6" v-if="form_ranking">
                    <InsertRanking
                        :saveRanking="saveRanking"
                        :loading_save="loading_save"
                        :id_url=id_url_list
                    ></InsertRanking>
                </v-col>
            </v-row>
            <v-row v-if="not_ranking_course">
                <v-col>
                    <span>No hay ranking para este curso</span>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="6" v-for="ranking in ranking_course">
                    <CardRanking
                        :ranking_course="ranking_course"
                        :id_ranking="ranking.id_ranking"
                        :img_ranking="ranking.img_url"
                        :title_ranking="ranking.title"
                        :text_alt_img="ranking.alt_text"
                        :text_ranking_course="ranking.text"
                        :displayDelete="displayDialogConfirmationMessage"
                        :saveRanking="saveRanking"
                        :id_url=id_url_list
                    ></CardRanking>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {
    assignRankingUrlWS,
    deleteUrlRankingWS,
    getAllRankingsUrlWS,
    insertURLRankingWS, updateUrlRankingWS,
    uploadImgWS
} from "@/utils/ranking";
import {onBeforeMount, ref} from "vue";
import {showNotification} from "@/utils/aux_functions";
import {useRoute, useRouter} from "vue-router";
import CardRanking from "@/components/Ranking/CardRanking.vue";
import InsertRanking from "@/components/Ranking/InsertRanking.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "FormRanking",
    components: {Loading, InsertRanking, CardRanking},
    setup() {
        let general_loading = ref()
        const router = useRouter();
        let route = useRoute();
        let id_url_list = ref();
        let ranking_course = ref([])
        let form_ranking = ref(false)
        let id_ranking_url;
        let loading_save = ref(false)
        let all_ranking = {}
        let not_url_course = ref(false)
        let not_ranking_course = ref(false)
        let not_find_url = ref(false)


        onBeforeMount(async () => {
            general_loading.value = true
            await getUrlRoute()
            initSweetAlert()
            general_loading.value = false
        })

        async function getUrlRoute() {
            await router.isReady()
            not_url_course.value = false
            if (Object.entries(route.query).length !== 0) {
                id_url_list.value = route.query.url
                await getAllRankingURL()
            } else {
                not_url_course.value = true
            }
        }


        async function getAllRankingURL() {
            not_ranking_course.value = false
            let response = await getAllRankingsUrlWS(id_url_list.value);
            if (response['result']) {
                let response_data = response['data']
                ranking_course.value = response_data.rankings_list
                if (ranking_course.value.length === 0) {
                    not_ranking_course.value = true
                }
            } else {
                not_find_url.value = true
                showNotification('error', response['errors'])
            }
        }


        async function createRanking() {
            form_ranking.value = !form_ranking.value
        }

        function displayDialogConfirmationMessage(id_ranking) {
            id_ranking_url = id_ranking
            fireSweetAlert("¿Estas seguro de eliminar el ranking?", deleteRanking)
        }

        async function deleteRanking() {
            await saveRanking('eliminado', id_ranking_url)
        }


        async function saveRanking(type_save, id_ranking, form_data) {
            all_ranking = {"ranking_list": []}
            ranking_course.value.forEach((ranking) => {
                if (id_ranking !== ranking.id_ranking) {
                    all_ranking["ranking_list"].push({
                        id_ranking: ranking.id_ranking,
                        text: ranking.text
                    })
                }
            })
            if (type_save !== 'eliminado') {
                all_ranking["ranking_list"].push({
                    id_ranking: id_ranking,
                    text: form_data
                })
            }

            let json_form_data = JSON.stringify(all_ranking);
            let response = await assignRankingUrlWS(json_form_data, id_url_list.value)
            if (response['result']) {
                form_ranking.value = false
                showNotification('success', 'Ranking ' + type_save + ' correctamente')
            } else {
                showNotification('error', response['errors'])
            }
            $(".btn-save-loading-" + id_ranking).hide()
            $(".btn-save-" + id_ranking).show()
            $(".div-ranking-" + id_ranking + " .writing").hide();
            $(".div-ranking-" + id_ranking + " .reading").show();
            $(".disabled_btn").show();
            await getAllRankingURL()
        }

        return {
            createRanking,
            displayDialogConfirmationMessage,
            saveRanking,

            id_url_list,
            ranking_course,
            form_ranking,
            loading_save,
            not_url_course,
            not_ranking_course,
            not_find_url,
            general_loading,
        }
    }
}

</script>

<style scoped lang="scss">


.row-btn-create-ranking {
    display: flex;
    align-items: center;
}

.row-btn-to-image {
    display: flex;
    align-items: center;
}

@media (max-width: 600px) {
    .button-primary {
        margin-bottom: 20px;
    }

    .row-btn-to-image {
        justify-content: flex-start;
    }
}
</style>