<template>
    <v-container id="keyword-objective">
        <div class="container-keyword">
            <v-row>
                <v-col cols="12" md="6">
                    <span class="main-title-page">
                        <img :src="require('@/assets/icons/navbar/tag.svg')" alt="icono de keyword" width="28" height="28">
                        Propuestas keywords
                    </span>
                </v-col>
                <v-col cols="12" md="6" class="text-end align-self-center">
                    <v-btn class="btn button-primary personalize-btn"
                           @click="displayDialogImports"
                    >Importar desde Excel
                        <img :src="require('@/assets/icons/import-document.svg')"
                             alt="Editar"
                             class="import-icon"
                             title="Importar desde Excel"
                             id="copy">
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="pending_language">
                <v-col cols="12">
                    <span>Selecciona un idioma para continuar.</span>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" md="6">
                    <div>
                        <span id="proposal-text">Propuesta</span>
                    </div>
                    <div class="card proposal-fields">
                        <div class="card-body proposal-fields">
                            <Keyword v-model="data" @getURLData="getURLData"
                                     @submit="submit"
                                     ref="key_obj">
                            </Keyword>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="data-box">
                        <div class="column column-received-data" v-if="data_recovered">
                            <span id="data-text">Datos Recibidos</span>
                            <div class="card global-field-data">
                                <div class="card-body global-field-data">
                                    <KeywordData
                                            :data=filteredData()
                                    ></KeywordData>
                                </div>
                            </div>
                        </div>
                        <div class="column" v-else></div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
    <v-dialog v-model="display_modal_import" persistent>
        <ModalImportDocument
            :displayDialogImports="displayDialogImports"
            @send="importProposals"
            :href_template="'/Plantilla_Propuesta_Keyword.csv'"
            :download_template="'Plantilla_Propuesta_Keyword.csv'"
        ></ModalImportDocument>
    </v-dialog>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import Keyword from "@/components/Keyword/Keyword";
import KeywordData from "@/components/Keyword/KeywordData";
import {useStore} from "vuex";
import {showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import ModalImportDocument from "@/components/Redirections/ModalImportDocument.vue";
import {importProposalKeyword} from "@/utils/proposal_keyword";

export default {
    name: "ProposalKeywordView",
    components: {ModalImportDocument, Keyword, KeywordData},
    setup() {
        const store = useStore();
        const route = useRoute();

        let data = ref({});
        let data_recovered = ref(false);
        // Crear referencia a nuestra instancia hija
        let key_obj = ref(null);
        let username = computed(() => store.getters.getUsername).value
        let pending_language = ref(true);
        let language = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        let display_modal_import = ref(false);

        onBeforeMount(async () => {
            await hasPermission(route)
            if (language.value !== 0) {
                pending_language.value = false;
            }
        })

        watch(portal, () => {
            pending_language.value = true;
            data.value = {}
            data_recovered.value = false;
            if (language.value !== 0) {
                pending_language.value = false;
            }
        });

        async function getURLData(value) {
            let valores = [];
            if (value) {
                let url = "propuestas-keywords/" + value + "/" + store.getters.getPortalID + "/"
                    + store.getters.getLanguageISO + "?usuario_logueado=" + username;

                let response = await aiseoBackendPetition('GET', url, {}, {})
                if (response.result) {
                    valores = response.data;
                    data_recovered.value = response.result;
                    $('.column-received-data').show()

                } else {
                    showNotification('error', response.errors)
                }
                data.value = valores;
                if (valores['keyword_propuesta'])
                    key_obj.value.updateKey(valores['keyword_propuesta']);
                else
                    key_obj.value.updateKey('');
                if (valores['url_futura_redireccion'])
                    key_obj.value.updateUrl_redirect(valores['url_futura_redireccion']);
            }

        }

        async function submit(form_data) {
            form_data['id_keyword_inicial'] = data.value['id_keyword_inicial'];
            form_data['usuario_logueado'] = username;
            form_data['id_portal'] = store.getters.getPortalID;
            form_data['iso_idioma'] = store.getters.getLanguageISO;
            let response = await aiseoBackendPetition('POST', "savePropuestaKeyword", form_data, {})
            if (response.result) {
                showNotification('success', 'Url actualizada con éxito')
            } else {
                showNotification('error', response.errors)
            }
        }

        function filteredData() {
            let valores = {};
            for (const [key, value] of Object.entries(data.value)) {
                if (!key.includes("id") && !key.includes("keyword_propuesta") && !key.includes("url_futura_redireccion") && !key.includes("estados")) {
                    valores[key] = value;
                }
            }
            return valores;
        }

        function displayDialogImports(){
            display_modal_import.value = !display_modal_import.value;
        }

        const importProposals = async (file, callback) => {
            callback(await importProposalKeyword(file));
        }

        return {
            getURLData,
            filteredData,
            submit,
            displayDialogImports,
            importProposals,

            data,
            data_recovered,
            key_obj,
            pending_language,
            display_modal_import,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/views/proposal-keyword.scss";
</style>
