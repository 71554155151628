<template>
        <v-btn
            :href="'http://geseupruebas.euroinnova.edu.es/import/createWebpFromUrl/'+data_url"
            target="_blank"
            class="btn button-bg-blue button-action"
        >
            Generar Imagen
        </v-btn>
</template>

<script>
export default {
    name: "GenerateImage",
    props: {
        data_url: String,
    },
    setup() {


        return {}
    }
}
</script>


<style scoped>

</style>