<template>
    <v-row>
        <v-col cols="12" sm="3" md="3">
            <v-select
                :items="portals"
                item-title="nombre"
                item-value="id_portal"
                v-model="form_data.id_portal"
                variant="underlined"
                placeholder="PORTAL"
                class="input-error select-primary"
                :error="errors.portal_error"
                :disabled="disable_portals || disabled_general"
                @update:modelValue="changePortalSelection()"
                hide-details
                no-data-text="No hay datos disponibles"
                :menu-props="{width: '200px'}"
            >
            </v-select>
            <span v-if="errors.portal_error" class="input-error msg-error">El campo no puede estar vacio</span>
        </v-col>

        <v-col cols="12" sm="3" md="3">
            <v-select
                :items="languages"
                item-title="nombre"
                item-value="id_idioma"
                v-model="form_data.id_language"
                variant="underlined"
                placeholder="IDIOMA"
                class="input-error select-primary"
                :error="errors.language_error"
                :disabled="disable_languages || disabled_general"
                @update:modelValue="translateURL()"
                hide-details
                no-data-text="No hay datos disponibles"
                :menu-props="{width: '200px'}"

            >
            </v-select>
            <span v-if="errors.language_error" class="input-error msg-error">El campo no puede estar vacio</span>
        </v-col>

        <v-col cols="12" sm="4" md="4">
            <v-text-field
                v-model.trim="form_data.url"
                placeholder="URL"
                required
                variant="underlined"
                hide-details
                class="input-error text-field-primary"
                :error="errors.url_error"
                :disabled="disable_url || disabled_general"
                :loading="url_loading"
            ></v-text-field>
            <span v-if="errors.url_error" class="input-error msg-error">{{ msg_error_url }}</span>
        </v-col>
        <v-col cols="12" sm="2" v-if="!disable && !disabled_general" class="text-center">
            <v-btn class="btn button-primary"
                   hide-details
                   type="button"
                   :disabled="disable || disabled_general"
                   @click="checkUrlData()"
            >
                Añadir
            </v-btn>
        </v-col>
        <v-col cols="12" sm="2" v-if="disable && !disabled_general"
               class="align-self-center text-center">
            <img class="btn-erase" @click="eraseElement" src="@/assets/icons/close-circle.svg" alt="eliminar url"/>
        </v-col>


        <!--                <v-col v-if="copy_seo" cols="12" sm="2" md="2">
                            <v-checkbox
                                    class="cbox-trad"
                                    label="Traducir"
                                    v-model="cbox_value"
                                    :true-value="1"
                                    :false-value="0"
                                    :disabled="disable || disabled_general"
                                    hide-details
                                    color="blue"
                            ></v-checkbox>
                        </v-col>-->
        <v-col cols="12" sm="7" v-if="copy_seo && url_contains_data">
            <div class="override-message-container">
                <span v-if="!override_url"
                      :class="(disable || disabled_general)"
                >La URL de destino contiene datos, ¿quiere sobreescribirlos?</span>
                <span v-else class="msg_error_override">La url de origen y destino, tienen el mismo portal e idioma seleccionado</span>
            </div>
        </v-col>
        <v-col v-if="show_msg_error_override">
            <span
                class="msg_error_override">Ya existe una url con este nombre en el portal e idioma seleccionados</span>
        </v-col>


        <!--PARA CUANDO VAS A SOBRESCRIBIR EL CONTENIDO-->
        <v-col cols="12" sm="3" md="3" v-if="copy_seo && url_contains_data">
            <v-radio-group v-if="!override_url"
                           inline
                           v-model="override"
                           mandotory
                           hide-details
                           :disabled="disable || disabled_general"
            >
                <v-radio
                    label="SI"
                    :value="true"
                    class="radio-button-primary"
                ></v-radio>
                <v-radio
                    label="NO"
                    :value="false"
                    class="radio-button-primary"
                ></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" class="error-repeat">
            <span v-if="errors.repeated_value" class="error-repeat">El valor introducido esta repetido</span>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {useStore} from "vuex";
import {checkURLWS} from "@/utils/editor_course_blog";

export default {
    name: "RowFormUrl",
    props: {
        portals: Array,
        fields: Map,
        increaseID: Function,
        id: Number,
        inherit: Boolean,
        id_portal: Number,
        can_repeat: Boolean,
        id_language: Number,
        related_url: Array,
        copy_seo: Boolean,
        url: String,
        url_type: String,
    },
    setup(props) {
        let disabled_general = ref(false)
        let disable_portals = ref(false)
        let disable_languages = ref(true)
        let disable_url = ref(false)


        let form_data = ref({});
        let errors = ref({});
        let disable = ref(false);
        let languages = ref([]);

        /*
        * VARIABLES RELACIONADAS CON LA COPIA DE CONTENIDO SEO
        * */
        let store = useStore();
        let url_loading = ref(false);
        let cbox_value = ref(0);
        let override = ref(true);
        let url_contains_data = ref(false);
        let language_id
        let url_origin = _.cloneDeep(props.url)
        let msg_error_url = ref("")
        let show_msg_error_override = ref(false)
        let override_url = ref(false)
        let state_btn_cancel = ref(false)

        onBeforeMount(() => {
            autocompleteValues();
        })

        /*
        * FUNCIONES RELACIONADAS CON LA COPIA DE CONTENIDO SEO
        */

        async function translateURL() {
            if (props.copy_seo) {
                url_loading.value = true;
                disabled_general.value = true;
                disable.value = false
                clearErrors()
                let language_index = languages.value.findIndex((element) => element.id_idioma === form_data.value.id_language);
                language_id = languages.value[language_index].id_idioma;
                let response = await checkURLWS(store.getters.getPortalID, store.getters.getLanguageID, props.url, form_data.value.id_portal, form_data.value.id_language);
                /*COMPROBAR SI TIENE URL RELACIONADA*/
                if (response.result) {
                    if (response['data'][0].related_url) { //SI LA TIENE: SE RELLENA EL INPUT CON ELLA Y SE BLOQUEA
                        form_data.value.url = response['data'][0].url;
                        disable_url.value = true;
                        url_contains_data.value = true;
                        if (form_data.value.id_portal !== store.getters.getPortalID || form_data.value.id_language !== store.getters.getLanguageID) {
                            show_msg_error_override.value = false
                        } else {
                            disable.value = true
                            disabled_general.value = false
                            override_url.value = true
                        }
                    } else { // NO LA TIENE: SE RELLENA EL INPUT CON LA TRADUCCIÓN Y SE PERMITE AL USUARIO EDITARLA
                        //Temporal. No se traduce la URL, se crea en el portal-idioma indicado con la misma url.
                        /*let translated_url = await translateTextWS('texto', props.url, store.getters.getLanguageISO, language_id);
                        form_data.value.url = translated_url['data'];*/
                        form_data.value.url = url_origin
                        disable_url.value = false;
                        url_contains_data.value = false;
                        override.value = true;
                    }
                } else {
                    showNotification('error', 'Se ha producido un error al comprobar la url')
                }

                url_loading.value = false;
                disabled_general.value = false;
            }
        }

        /*
        *   UNA VEZ PULSADO EL BOTÓN DE AÑADIR:
        *   SI NO ESTÁ COPIANDO EL CONTENIDO SEO, AÑADE LA URL DIRECTAMENTE.
        *
        *   SI LO ESTÁ COPIANDO:
        *       SI LA URL ORIGEN TIENE UNA RELACIONADA, LO AÑADE DIRECTAMENTE.
        *
        *       SI NO TIENE, LA HA TENIDO QUE TRADUCIR, COMPRUEBA SI LA URL TRADUCIDA EXISTE
        *       Y SI EXISTE MUESTRA UN ERROR, SI NO EXISTE LA AÑADE.
        * */


        /*COMPROBAR SI LA URL TIENE RELACIÓN CON OTRA EN EL PORTAL E IDIOMA (DEVUELVE FALSE NO TIENE RELACIÓN)*/

        async function checkUrlData() {
            clearErrors();
            if (!validarDatos()) {
                if (props.copy_seo) {
                    if (url_contains_data.value) {
                        addElement();
                    } else {
                        let url_exist = await checkURLWS(store.getters.getPortalID, store.getters.getLanguageID, props.url, form_data.value.id_portal, form_data.value.id_language, form_data.value.url);
                        if (url_exist['result']) {
                            if (url_exist['data'][0].related_url) {
                                disabled_general.value = false
                                show_msg_error_override.value = true
                            } else {
                                show_msg_error_override.value = false
                                addElement();
                            }
                        }
                    }
                } else {
                    addElement();
                }
            }
        }

        /******************************************************/

        function autocompleteValues() {
            if (variableIsDefined(props.id_portal) && variableIsDefined(props.id_language) && variableIsDefined(props.url)) {
                form_data.value.id_portal = props.id_portal
                form_data.value.url = props.url
                form_data.value.id_language = props.id_language
                form_data.value.url_type = props.url_type
                showLanguages();
            }

            if (props.inherit) {
                disabled_general.value = true;
            }

            if (variableIsDefined(props.fields)) {
                if (props.fields.get(props.id) !== "") {
                    if (!props.copy_seo) {
                        form_data.value.id_portal = props.fields.get(props.id).destinyPortal;
                        form_data.value.url = props.fields.get(props.id).newURL;
                        showLanguages();
                        let language_index = languages.value.findIndex((element) => element.id_idioma === props.fields.get(props.id).destinyLanguageID);
                        form_data.value.id_language = languages.value[language_index].id_idioma;
                    } else {
                        form_data.value.id_portal = props.fields.get(props.id).portal;
                        form_data.value.url = props.fields.get(props.id).url;
                        showLanguages();
                        let language_index = languages.value.findIndex((element) => element.iso_6391 === props.fields.get(props.id).languageISO);
                        form_data.value.id_language = languages.value[language_index].id_idioma;
                        cbox_value.value = props.fields.get(props.id).translate_content;

                        /*COMPROBAR SI HAY QUE SOBREESCRIBIR LOS DATOS*/
                        url_contains_data.value = true;
                        override.value = props.fields.get(props.id).sobreescribir;
                    }
                    disableBtn();
                }
            }
        }

        function disableBtn() {
            disable_languages.value = true;
            disable_portals.value = true;
            disable_url.value = true;
            return disable.value = !disable.value;
        }

        function eraseElement() {
            if (form_data.value.id_portal !== store.getters.getPortalID || form_data.value.id_language !== store.getters.getLanguageID) {
                props.fields.delete(props.id);
            }

        }

        /*
        *
        * AÑADE UN NUEVO ELEMENTO AL MAP, SEGÚN SI ESTÁ
        * AÑADIENDO UNA URL DONDE COPIAR EL CONTENIDO SEO
        * O UNA URL RELACIONADA AÑADE UN OBJETO U OTRO
        *
        * */
        function addElement() {
            let data;
            if (props.copy_seo) {
                data = {
                    'portal': form_data.value.id_portal,
                    'url': form_data.value.url,
                    'languageISO': language_id,
                    'usuario_logueado': store.getters.getUsername,
                    'sobreescribir': override.value,
                    'translate_content': cbox_value.value,
                };
            } else {
                data = {
                    'destinyPortal': form_data.value.id_portal,
                    'destinyLanguageID': form_data.value.id_language,
                    'newURL': form_data.value.url,
                    'urlType': form_data.value.url_type,
                }
            }
            let id = props.increaseID();
            let next_id = id + 1;
            props.fields.set(id, data);
            props.fields.set(next_id, '');
            disableBtn();

        }

        function validarDatos() {
            let regex_url
            msg_error_url.value = ""
            errors.value.url_error = false
            errors.value.portal_error = !variableIsDefined(form_data.value.id_portal);
            errors.value.language_error = !variableIsDefined(form_data.value.id_language);

            if (variableIsDefined(form_data.value.url)) {
                msg_error_url.value = ""
                errors.value.url_error = false
                if (props.url_type === 'blogs' || !variableIsDefined(props.url_type)) {
                    regex_url = /^[a-z0-9\/\-_.]+$/
                } else if (props.url_type === 'courses') {
                    regex_url = /^[a-zA-Z0-9\/\-_]+$/i
                }
                if (!regex_url.test(form_data.value.url)) {
                    msg_error_url.value = 'La url introducida no es válida'
                    errors.value.url_error = true
                }
            } else {
                msg_error_url.value = 'El campo no puede estar vacío'
                errors.value.url_error = true
            }

            let data_error = errors.value.url_error || errors.value.language_error || errors.value.portal_error;
            if (!data_error && variableIsDefined(props.related_url)) {
                props.related_url.forEach(store_url => {
                    if (store_url.id_portal === form_data.value.id_portal &&
                        store_url.id_idioma === form_data.value.id_language
                    ) {
                        errors.value.repeated_value = true;
                        data_error = errors.value.repeated_value;
                    }
                })
            }

            if (!data_error && !props.can_repeat) {
                for (let [key, value] of props.fields.entries()) {
                    if (value !== '') {
                        if (props.copy_seo) {
                            if (value['portal'] === form_data.value.id_portal &&
                                value['languageISO'] === language_id
                            ) {
                                errors.value.repeated_value = true;
                                data_error = errors.value.repeated_value;
                            }
                        } else {
                            if (value['destinyPortal'] === form_data.value.id_portal &&
                                value['destinyLanguageID'] === form_data.value.id_language
                            ) {
                                errors.value.repeated_value = true;
                                data_error = errors.value.repeated_value;
                            }
                        }
                    }
                }
            }
            return data_error;
        }

        function clearErrors() {
            errors.value = {
                portal_error: false,
                language_error: false,
                url_error: false,
            }
        }

        function showLanguages() {
            /*form_data.value.id_language = null;*/
            disable_languages.value = false;
            props.portals.forEach(portal => {
                if (portal.id_portal === form_data.value.id_portal) {
                    languages.value = portal.languages;
                }
            })
        }

        function changePortalSelection() {
            form_data.value.id_language = null;
            show_msg_error_override.value = false
            url_contains_data.value = false
            msg_error_url.value = ""
            override_url.value = false
            showLanguages();
        }


        return {
            eraseElement,
            changePortalSelection,
            translateURL,
            checkUrlData,

            disable_portals,
            disable_languages,
            disable_url,
            disabled_general,
            form_data,
            errors,
            disable,
            languages,
            url_loading,
            cbox_value,
            override,
            url_contains_data,
            msg_error_url,
            show_msg_error_override,
            override_url,
            state_btn_cancel,

        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/url-list/modal-url.scss";
</style>