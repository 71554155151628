<template>
    <v-table
        class="personalize-scroll table-style"
        fixed-header
        height="510px"
    >
        <thead class="position-head">
        <tr>
            <th class="text-left">
                Avance
            </th>
            <th class="text-left">
                Titulo
            </th>
            <th class="text-left">
                Status
            </th>
            <th class="text-left">
                Tamaño Archivo
            </th>
            <th class="text-left">
                Dimensiones
            </th>
            <th class="text-left">
                Tipo
            </th>
            <th class="text-left">
                Fecha de carga
            </th>
            <th class="text-left">
                Fecha Modificación
            </th>
            <th class="text-left">
                Duración
            </th>
        </tr>
        </thead>
        <tbody v-for="file in all_files_select" class="todos">
        <tr class="row-list">
            <td class="td-img">
                <v-img :src="file.front" alt="img" class="image-file" @click="displayModal(file)">
                    <div class="icons-video" v-if="file.type==='Video'">
                        <img class="img-play" :src="require('@/assets/icons/play.svg')" alt="">
                    </div>
                </v-img>
            </td>
            <td class="title-table">
                <v-row no-gutters>
                    <v-col cols="12 my-2">
                        {{ file.title }}
                    </v-col>
                    <v-col class="mb-2">
                        <a :href="file.video">
                            <img :src="require('@/assets/icons/download.svg')" alt="Descargar" class="icon-download">
                        </a>
                        <img :src="require('@/assets/icons/icon-code.svg')" alt="Url CDN" class="icon-download ms-5"
                             @click="displayModalUrlCdn(file)">
                    </v-col>
                </v-row>
            </td>
            <td class="status-table" v-if="file.status"><img :src="require('@/assets/icons/spot.svg')" alt=""
                                                             class="icons-spot-true">Aprobado
            </td>
            <td class="status-table" v-else><img :src="require('@/assets/icons/spot.svg')" class="icons-spot-false"
                                                 alt="">Rechazado
            </td>
            <td class="size-table">{{ convertSize(file.size) }}</td>
            <td class="dimensions-table">{{ file.img_width }} x {{ file.img_height }}</td>
            <td class="type-table">{{ file.type }}</td>
            <td class="upload-date-table">{{ convertDate(file.date_uploaded) }}</td>
            <td class="modify-date-table">{{ convertDate(file.date_modify) }}</td>
            <td class="duration-table" v-if="file.type==='Image'">-</td>
            <td class="duration-table" v-else>{{ file.duration }}</td>
        </tr>
        </tbody>
    </v-table>
    <v-dialog class=""
              v-model="open_modal">
        <ModalFile
            :displayModal="displayModal"
            :file_select="file_select"
            :getFileActiveWS="getFileActiveWS"
            :identifier_category="identifier_category"
        />
    </v-dialog>

</template>

<script>
import {ref} from "vue";
import ModalFile from "@/components/MediaValet/ModalFile.vue";

export default {
    name: 'ListFiles',
    components: {ModalFile},
    props: {
        all_files_select: Object,
        getFileActiveWS: Function,
        identifier_category: String,
        displayModalUrlCdn: Function,
    },
    setup(props) {
        let open_modal = ref(false)
        let file_select = ref({})

        function displayModal(file) {
            open_modal.value = !open_modal.value
            file_select.value = file
        }

        function convertDate(date) {
            let date_only = date.split('T')
            let date_division = date_only[0].split('-')
            return date_division[2] + '/' + date_division[1] + '/' + date_division[0]
        }

        function convertSize(size) {
            if (size < 1000000) {
                return (size / 1000).toFixed(2) + ' KB'
            } else {
                return (size / 1000000).toFixed(2) + ' MB'
            }
        }

        return {
            displayModal,
            convertDate,
            convertSize,

            open_modal,
            file_select,
        }
    }
}
</script>

<style lang="scss">
.todos {
    font-size: 14px;
}

@import "@/styles/components/media-valet/list-files.scss";
</style>