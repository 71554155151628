<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="8" md="9">
                <span class="main-title-page">Preguntas frecuentes: {{ url_name }}</span>
                <span v-if="!general_loading">¡Ojo! La información sólo queda guardada en AISEO a falta del desarrollo necesario en el portal</span>
            </v-col>
            <v-col cols="8" sm="4" md="3" class="text-end align-self-center" v-if="!general_loading">
                <v-btn
                    class="btn button-bg-blue w-100"
                    hide-details
                    type="button"
                    @click="displayDialogFaq"
                >
                    Gestionar FAQs
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row justify="end" v-if="questions_map.size > 1">
                <v-col cols="6" sm="3" md="3" lg="2">
                    <v-btn
                        class="btn button-bg-white w-100"
                        hide-details
                        type="button"
                        @click="openCloseAll"
                    >
                        <span v-if="open_all"> Cerrar todos</span>
                        <span v-else>Abrir todos</span>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row align="center" v-if="questions_map.size > 1">
                <v-col>
                    <v-expansion-panels
                        v-model="panels"
                        multiple
                    >
                        <template v-for="question in questions_map.values()">
                            <v-expansion-panel
                                class="expansion"
                                :value="question.question"
                                v-if="question !== ''"
                            >
                                <template v-if="question !== ''">
                                    <v-expansion-panel-title  class="faq-container">
                                        {{ question.question }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        {{ question.answer }}
                                    </v-expansion-panel-text>
                                </template>

                            </v-expansion-panel>
                        </template>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <span> Actualmente no hay ninguna pregunta creada.</span>
                </v-col>
            </v-row>


            <v-dialog v-model="display_dialog_faq" scrollable persistent>
                <ModalCreateFaq
                    :displayDialog="displayDialogFaq"
                    :faq_list="questions_map"
                    :url="url_name"
                    :increaseID="increaseID"
                ></ModalCreateFaq>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import ModalCreateFaq from "@/components/CourseFAQ/ModalCreateFaq.vue";
import {useRoute, useRouter} from "vue-router";
import {getFaqsWS} from "@/utils/faqs";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "CourseFaqView",
    components: {Loading, ModalCreateFaq},
    setup() {
        const router = useRouter();
        let route = useRoute();

        let general_loading = ref(false)
        let display_dialog_faq = ref(false);
        let url_name = ref('');
        let questions_map = ref(new Map());
        let count = ref(0);
        let open_all = ref(false);
        let panels = ref([]);

        onBeforeMount(async () => {
            general_loading.value = true
            await getUrlRoute();
            await fillQuestions();
            count.value = questions_map.value.size - 1;
            general_loading.value = false
        })

        watch(
            () => panels.value,
            () => {
                if (panels.value.length === questions_map.value.size - 1) {
                    open_all.value = true;
                } else if (panels.value.length === 0) {
                    open_all.value = false;
                }
            }
        )


        async function fillQuestions() {
            let response = await getFaqsWS(url_name.value);
            let current_index = 0;

            if (response['result']) {
                response['data'].forEach((faq, index) => {
                    questions_map.value.set(index, {
                        'id_faq': faq.id_faq,
                        'question': faq.pregunta,
                        'answer': faq.respuesta
                    });
                    current_index = index;
                });
                questions_map.value.set(current_index + 1, '');
            }
        }

        async function getUrlRoute() {
            await router.isReady()
            if (Object.entries(route.query).length !== 0) {
                url_name.value = route.query['url'];
            }
        }

        function displayDialogFaq() {
            display_dialog_faq.value = !display_dialog_faq.value;
        }

        /*
        *
        * AUMENTA EL CONTADOR CADA VEZ QUE SE GUARDA UNA NUEVA PREGUNTA EN EL MAP
        *
        * */
        function increaseID() {
            let count_before = count.value;
            count.value++;

            return count_before;
        }


        /*
        *
        * RELLENA O VACÍA EL ARRAY QUE SERVIRÁ
        * COMO CONTROLADOR PARA LAS PREGUNTAS
        * QUE HAY ABIERTAS, SI EL VALUE DE LA
        * PREGUNTA ESTÁ EN EL ARRAY ES PORQUE ESTÁ ABIERTA
        *
        * */
        function openCloseAll() {
            if (open_all.value) {
                panels.value = [];
            } else {
                Array.from(questions_map.value.values()).forEach((question) => {
                    panels.value.push(question.question);
                });
            }
            open_all.value = !open_all.value;
        }

        return {
            displayDialogFaq,
            openCloseAll,
            increaseID,

            general_loading,
            display_dialog_faq,
            questions_map,
            url_name,
            open_all,
            panels,
        }
    }
}
</script>

<style lang="scss">
.v-expansion-panel.expansion {
    background: $background;

    .faq-container:hover {
        box-shadow: none !important;
        background: $blue-medium;
        color: $white;
        font-weight: bold;
    }
}

.v-expansion-panel-title--active {
    background: $blue-medium;
    opacity: 1;
    color: $white !important;
    font-weight: bold;
}

.v-expansion-panel-text {
    border: 1px solid $blue-medium !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.v-expansion-panel__shadow {
    box-shadow: none !important;
}
</style>