<template>
    <BaseModalTemplate
        title="Gestión de preguntas"
        :open-close-dialog="displayDialog"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <div v-for="question_key in faq_list.keys()">
                <RowQuestion
                    :questions_map="faq_list" :increaseMapID="increaseID" :id="question_key"
                    :key="question_key" :addQuestion="addQuestion"
                ></RowQuestion>
            </div>
        </template>
    </BaseModalTemplate>
</template>

<script>

import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import RowQuestion from "@/components/CourseFAQ/RowQuestion.vue";
import {addFaqWS} from "@/utils/faqs";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalCreateFaq",
    components: {BaseModalTemplate, RowQuestion},
    methods: {variableIsDefined},
    props: {
        displayDialog: Function,
        saveNewFaqs: Function,
        increaseID: Function,
        faq_list: Map,
        url: String,
    },
    setup(props){

        /*
        *
        * AÑADE UNA PREGUNTA AL MAP
        *
        * */
        async function addQuestion(id, question) {
            let response = await addFaqWS(question, props.url)

            if (response['result']){
                question.id_faq = response['data']['id_faq'];
                props.faq_list.set(id, question);

                showNotification('success', 'Nueva pregunta añadida correctamente')
            }else{
                showNotification('error', response['errors'])
            }
        }

        return{
            addQuestion,
        }
    },
}
</script>

<style scoped>

</style>