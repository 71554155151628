<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="9" lg="10">
                <span class="main-title-page">Grupos asignados a un usuario</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" sm="7" md="6" lg="4">
                <v-text-field
                    v-model.trim="identifier_user"
                    @keyup.enter="getAllGroupsByUser"
                    class="text-field-secondary input-error"
                    :error="error_id"
                    placeholder="Identificador del usuario"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>

            </v-col>
            <v-col cols="2" sm="1" md="1" class="align-self-center">
                <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                     class="delete-filter" @click="deleteFilter(identifier_user)">
            </v-col>
        </v-row>
        <v-row no-gutters v-if="error_id">
            <v-col cols="10" sm="7" md="6" lg="4">
                 <span class="input-error msg-error">
                    {{ msg_error }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="list-title">
                <span>Grupos LDAP</span>
            </v-col>
        </v-row>
        <Loading v-if="loading"></Loading>
        <template v-else>
            <v-row no-gutters>
                <v-col cols="12" v-if="see_info">
                    <Vue3EasyDataTable v-if="group_user.length>0"
                                       :hide-footer="group_user.length<=50"
                                       class="primary-table user-groups-table"
                                       :headers="columns_table"
                                       :items="group_user"
                                       :loading="loading"
                                       :rows-per-page="50"
                                       buttons-pagination
                                       rows-per-page-message="Elementos por página: "
                                       rows-of-page-separator-message="de"
                                       empty-message="No hay datos disponibles"

                    ></Vue3EasyDataTable>
                </v-col>
                <v-col class="default-text" v-else>
                    <span>{{ msg_info || "Debe introducir un usuario para mostrar los grupos asociados" }}</span>
                </v-col>

            </v-row>
        </template>
    </v-container>
</template>

<script>
import {ref} from "vue";
import {getAllGroupsByUserWS} from "@/utils/admin_functions";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupUserView",
    components: {Loading, Vue3EasyDataTable},

    setup() {
        let identifier_user = ref("")
        const columns_table = ref([
            {text: 'ID', value: 'id'},
            {text: 'Nombre grupo LDAP', value: 'cn'},
            {text: 'GidNumber', value: 'gidNumber'},
        ]);
        let group_user = ref([])
        let loading = ref(false)
        let see_info = ref(false)
        let msg_info = ref("")
        let error_id = ref(false)
        let msg_error = ref("")

        async function getAllGroupsByUser() {
            see_info.value = false
            error_id.value = false
            msg_info.value = ""
            if (variableIsDefined(identifier_user.value)) {
                if (validate_user(identifier_user.value)) {
                    loading.value = true
                    let response = await getAllGroupsByUserWS(identifier_user.value)
                    if (response['result']) {
                        group_user.value = response['data']
                        if (group_user.value.length !== 0) {
                            see_info.value = true
                        } else {
                            see_info.value = false
                            msg_info.value = "No hay grupos para el usuario introducido"
                        }
                    } else {
                        showNotification('error', response['error'])
                        see_info.value = true
                    }
                    loading.value = false
                } else {
                    msg_error.value = "El usuario introducido no es válido"
                    error_id.value = true
                }
            }
        }

        function validate_user(username) {
            const regex = /^[a-z]+\.{1}[a-z]+$/i;
            return ((regex.exec(username)) !== null);
        }

        function deleteFilter() {
            identifier_user.value = ""
            see_info.value = false
            msg_info.value = ""
        }

        return {
            getAllGroupsByUser,
            deleteFilter,
            
            identifier_user,
            columns_table,
            group_user,
            error_id,
            msg_error,
            see_info,
            msg_info,
            loading,
        }
    }
}
</script>

<style lang="scss">
.user-groups-table {
    .vue3-easy-data-table__main {
        border: solid 2px $blue-medium !important;
        border-radius: 8px;
    }
}
</style>