<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <slot name="custom-title">
                    <span class="main-title-page">{{ page_title }}</span>
                </slot>
            </v-col>
        </v-row>
        <div v-if="loading_general">
            <Loading/>
        </div>
        <template v-else>
            <template v-if="pending_language && active_portal_language_general">
                <p>Seleccione un idioma para continuar.</p>-->
            </template>
            <template v-else>
                <v-row>
                    <v-col cols="12">
                        <v-expansion-panels>
                            <v-expansion-panel class="expansion-personalized">
                                <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-text-field
                                                class="text-field-secondary"
                                                v-model.number="filter_search.tries"
                                                type="number"
                                                placeholder="Número de reintentos"
                                                append-inner-icon="mdi-magnify"
                                                single-line
                                                bg-color="white"
                                                color="transparent"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                            <v-select v-model="filter_search.state"
                                                      class="input-error select-secondary"
                                                      placeholder="Seleccione un estado"
                                                      :items="options_state"
                                                      item-title="nombre"
                                                      item-value="id_estado"
                                                      bg-color="white"
                                                      hide-details
                                                      no-data-text="No hay datos disponibles"
                                            >
                                            </v-select>
                                        </v-col>
                                        <!-- DOCUMENTACIÓN
                                            SLOT QUE PERMITE AÑADIR FILTROS
                                            SIMPLEMENTE EN EL COMPONENTE PADRE CREAR LOS ELEMENTOS
                                            Y USAR COMO SU v-model EL OBJETO form_data QUE SE PASA
                                            EN EL SLOT, SE DEBERÍA USAR LA ESTRUCTURA
                                            <v-col>elemento</v-col> PARA MANTENER LA MAQUETACIÓN
                                            Ejemplo:
                                            <template #queue-filters="{form_data}">
                                              <v-col cols="12" lg="6">
                                                    <v-text-field
                                                        v-model.trim="form_data.origin_url"
                                                        ....
                                                    ></v-text-field>
                                                </v-col>
                                                ....
                                        -->
                                        <slot name="queue-filters" :form_data="filter_search"></slot>
                                    </v-row>

                                    <v-row justify="end" no-gutters class="pt-2 sm:pt-0">
                                        <v-col cols="12" class="text-end align-self-center p-1 sm:p-0">
                                            <v-btn
                                                class="btn button-bg-blue"
                                                @click="applyFilter"
                                            >
                                                Filtrar
                                            </v-btn>
                                            <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                                 class="delete-filter ms-4" @click="deleteFilters">
                                        </v-col>
                                        <!--                                    <v-col cols="4" sm="2" md="1" class="text-start align-self-center p-1 sm:p-0 ms-2" >-->

                                        <!--                                    </v-col>-->
                                    </v-row>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <Vue3EasyDataTable
                            table-class-name="primary-table queue-table-especific"
                            theme-color="#F4A649"
                            buttons-pagination
                            :headers="headers"
                            :items="items"
                            v-model:server-options="server_options"
                            :server-items-length="server_items_length"
                            :loading="loading"
                            must-sort
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-url_destino="item">
                                <span>{{ item.url_destino || 'N/C' }}</span>
                            </template>
                            <template #item-id_estado="item">
                                <v-tooltip text="Pendiente" v-if="item.id_estado===1" location="top">
                                    <template v-slot:activator="{ props }">
                                        <img src="@/assets/icons/administration/pending.svg" v-bind="props" alt="Icono"
                                             class="icons-state">
                                    </template>
                                </v-tooltip>
                                <v-tooltip text="En proceso" v-if="item.id_estado===2" location="top">
                                    <template v-slot:activator="{ props }">
                                        <img src="@/assets/icons/administration/process.svg" v-bind="props" alt="Icono"
                                             class="icons-state">
                                    </template>
                                </v-tooltip>
                                <v-tooltip text="Terminado" v-if="item.id_estado===3" location="top">
                                    <template v-slot:activator="{ props }">
                                        <img src="@/assets/icons/administration/check-green.svg" v-bind="props"
                                             alt="Icono"
                                             class="icons-state">
                                    </template>
                                </v-tooltip>
                                <v-tooltip text="Cancelado" v-if="item.id_estado===4" location="top" class="color-tool">
                                    <template v-slot:activator="{ props }">
                                        <img src="@/assets/icons/administration/cancel-red.svg" v-bind="props"
                                             alt="Icono"
                                             class="icons-state">
                                    </template>
                                </v-tooltip>
                            </template>
                            <template #item-strict="{strict}">
                                <span v-if="strict === 1">SÍ</span>
                                <span v-else>NO</span>
                            </template>
                            <template #item-created_at="{created_at}">
                                {{ formatDate(created_at) }}
                            </template>

                            <template v-slot:item-updated_at="{updated_at}">
                                {{ formatDate(updated_at) }}
                            </template>

                            <!-- DOCUMENTACIÓN
                                CONJUNTO DE SLOTS POR SI HAY QUE PERSONALIZAR ALGÚN HEADER EN ESPECIAL
                                O POR SI HAY QUE CAMBIAR ALGUNO DE LOS QUE HAY POR DEFECTO.
                                USA LOS PROPS custom_item_slots Y manage-custom-slots
                                name INDICA EL NOMBRE QUE SE VA A USAR PARA REFERIR AL SLOT,
                                EN ESTE CASO COMO ESTÁ EN LA TABLA SIEMPRE DEBERÍA SER DEL ESTILO
                                'item-(header.value)' Ej: 'item-updated_at' cambiaría lo que muestra en la columna updated_at
                                según lo que devuelva la función manageCustomSlots
                             -->
                            <template v-for="(name) in custom_item_slots" :key="name" #[name]="item">
                                <slot :name="name" :item="item"></slot>
                            </template>

                            <template #item-btn_actions="item">
                                <v-menu class="table-blog" v-if="item.id_estado !== 2">
                                    <template v-slot:activator="{ props }">
                                        <img :src="require('@/assets/icons/more-options.svg')"
                                             class="btn button-bg-white icon-actions"
                                             alt="Icono puntos"
                                             v-bind="props">
                                    </template>
                                    <v-list>
                                        <v-list-item v-if="item.id_estado !== 1 && item.id_estado !== 2">
                                            <v-list-item-title @click="openElementLog(item)" class="text-center">
                                                <span style="cursor: pointer">Ver log completo</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <slot name="btn-change-state">
                                            <v-list-item v-if="item.id_estado===1">
                                                <v-list-item-title @click="displayDialogConfirmationMessage(item, 4)"
                                                                   class="text-center">
                                                    <span style="cursor: pointer">Cancelar proceso</span>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="item.id_estado===4">
                                                <v-list-item-title @click="displayDialogConfirmationMessage(item, 1)"
                                                                   class="text-center">
                                                    <span style="cursor: pointer">Reanudar proceso</span>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </slot>
                                        <!-- DOCUMENTACIÓN
                                            SLOT PARA AÑADIR BOTONES AL MENÚ FINAL DE btn_actions
                                            Ejemplo:
                                             <template #extra-btn-actions="{item_data}">
                                                <v-list-item>
                                                    <v-list-item-title @click="fn(item_data)">
                                                        <span style="cursor: pointer">ELEMENTO EXTRA</span>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template>

                                            En item_data están todos los datos del objeto seleccionado
                                            fn es una función del componente padre que hará algo con los datos,
                                            abrir un modal, hacer una llamada a ws...
                                        -->
                                        <slot name="extra-btn-actions" :item_data="item"></slot>
                                    </v-list>
                                </v-menu>
                            </template>
                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>

    <!-- DOCUMENTACIÓN
        MODAL PARA EL LOG Y PARA LO QUE HAGA FALTA EN UN FUTURO
        SIMPLEMENTE IR AÑADIENDO v-dialog EN EL COMPONENTE PADRE
    -->
    <slot name="modal-element-log">
    </slot>

</template>

<script>
import {formatDate, showNotification} from "@/utils/aux_functions";
import ModalLogRedirection from "@/components/Redirections/ModalLogRedirection.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {computed, onBeforeMount, ref, watch} from "vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {getCatalogQueueStatesWS} from "@/utils/queue_seo";
import Loading from "@/components/Generic/Loading.vue";
import {useStore} from "vuex";
import store from "@/store";

export default {
    name: "QueueTemplate",
    methods: {formatDate},
    components: {Loading, ModalLogRedirection, Vue3EasyDataTable},
    props: {
        page_title: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        getAllQueueElements: {
            type: Function,
            required: true,
        },
        changeElementState: {
            type: Function,
            required: true,
        },

        manageCustomSlots: Function,
        custom_item_slots: {
            type: Array,
            default: [],
        },
        process_key_name: {
            type: String,
            default: 'id',
        },
        active_portal_language_general: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['sendElementData'],
    setup(props, {emit}) {
        let loading = ref(false)

        let store = useStore();
        let pending_language = ref(false);
        let language = computed(() => store.getters.getLanguageISO);
        let items = ref([])
        let server_items_length = ref(0)
        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: 'updated_at',
            sortType: 'desc',
        })

        let options_state = ref([])
        let filter_search = ref({})

        let selected_log_id = null
        let selected_log_next_state = null
        let loading_general = ref(false)

        onBeforeMount(async () => {
            loading_general.value = true
            if (language.value !== 0) {
                initSweetAlert()
                await loadQueueElements()
                await fillStatus()
                loading_general.value = false
            }else{
                pending_language.value = true;
            }
        })

        watch(language, async () => {
            pending_language.value = true;
            if (language.value !== 0) {
                pending_language.value = false;
                await loadQueueElements()
            }
        });

        watch(server_options, async () => {
                await loadQueueElements()
            },
            {deep: true}
        );

        /*Rellena el selector con todos los estados posibles del elemento en cola*/
        const fillStatus = async () => {
            loading.value = true
            const response = await getCatalogQueueStatesWS()
            if (response['result']) {
                response['data'].forEach((state) => {
                    options_state.value.push({id_estado: state.id_estado, nombre: state.nombre})
                })
            } else {
                showNotification('error', response['errors'])
            }

            loading.value = false
        }

        /*Carga la tabla con los elementos encolados*/
        const loadQueueElements = async () => {
            loading.value = true
            const {sortBy, sortType} = server_options.value

            filter_search.value['orderBy'] = sortBy
            filter_search.value['orderDirection'] = sortType
            const response = await props.getAllQueueElements(server_options.value.page, server_options.value.rowsPerPage, filter_search.value)

            if (response['result'] && response['data']) {
                items.value = response['data'].data;
                server_items_length.value = response['data'].total;
            } else {
                showNotification("error", response['errors'])
            }
            loading.value = false
        }

        /*Muestra un aviso sobre si se desea cancelar el proceso en cola*/
        function displayDialogConfirmationMessage(item, state) {
            selected_log_id = item[props.process_key_name]
            selected_log_next_state = state
            const msg = selected_log_next_state === 1 ? '¿Deseas reanudar el proceso en cola?' : '¿Deseas cancelar el proceso en cola?'

            fireSweetAlert(msg, cancel_url, '')
        }

        /*Cambia el estado del elemento seleccionado a cancelado*/
        const cancel_url = async () => {
            let response = await props.changeElementState(selected_log_id, selected_log_next_state)
            if (response['result']) {
                const msg = selected_log_next_state === 1 ? 'Se ha reanudado el elemento seleccionado.' : 'Se ha cancelado el elemento seleccionado.'
                showNotification('success', msg)
            } else {
                showNotification('error', response['errors'])
            }
            await loadQueueElements()
        }


        /*Abre un modal para ver el log del elemento seleccionado*/
        const openElementLog = (element_data) => {
            emit('sendElementData', element_data)
        }

        /*Funciones para aplicar los filtros*/
        async function applyFilter() {
            server_options.value.page = 1
            await loadQueueElements()
        }

        async function deleteFilters() {
            filter_search.value = {};
            server_options.value.page = 1
            await loadQueueElements()
        }


        return {
            applyFilter,
            deleteFilters,
            displayDialogConfirmationMessage,
            openElementLog,
            loadQueueElements,

            loading_general,
            pending_language,

            filter_search,
            items,
            loading,
            options_state,
            server_items_length,
            server_options,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/queue-template.scss";
</style>