<template>
    <BaseModalTemplate
        :title="title"
        :open-close-dialog="showDialog"
        card_extra_classes="modal-width-xs"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                        <span class="input-title">
                            Nombre de usuario*
                        </span>
                    <v-text-field
                        v-model.trim="form_data.username"
                        :error="form_error.username"
                        class="input-error text-field-secondary"
                        placeholder="Nombre de usuario"
                        bg-color="white"
                        color="transparent"
                        hide-details
                        :disabled="btn_action === 'Actualizar'"
                    >
                    </v-text-field>
                    <span v-if="form_error.username"
                          class="input-error msg-error">Inserte un nombre de usuario válido</span>
                </v-col>
                <v-col cols="12" md="6">
                        <span class="input-title">
                            Nombre*
                        </span>
                    <v-text-field
                        v-model.trim="form_data.firstname"
                        :error="form_error.firstname"
                        class="input-error text-field-secondary"
                        placeholder="Nombre"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.firstname"
                          class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12" md="6">
                        <span class="input-title">
                            Apellidos*
                        </span>
                    <v-text-field
                        v-model.trim="form_data.secondname"
                        :error="form_error.secondname"
                        class="input-error text-field-secondary"
                        placeholder="Apellidos"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.secondname"
                          class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12">
                        <span class="input-title">
                            Email*
                        </span>
                    <v-text-field
                        v-model="form_data.email"
                        :error="form_error.email"
                        class="input-error text-field-secondary"
                        placeholder="Email"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.email"
                          class="input-error msg-error">Inserte una dirección de correo válida</span>
                </v-col>
                <v-col cols="12">
                        <span class="input-title">
                            Linkedin
                        </span>
                    <v-text-field
                        v-model="form_data.linkedin"
                        :error="form_error.linkedin"
                        class="input-error text-field-secondary"
                        placeholder="Linkedin (opcional)"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    >
                    </v-text-field>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12">
                    <v-btn
                        variant="outlined"
                        class="btn button-primary"
                        :loading="loading_btn"
                        @click="sendForm"
                    >
                        {{btn_action}}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {onBeforeMount, ref} from "vue";
import {insertExternalUserWS, updateExternalUserWS} from "@/utils/external_users";
import {showNotification, validateEmail, variableIsDefined} from "@/utils/aux_functions";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormCreateExternalUser",
    components: {BaseModalTemplate, Loading},
    props: {
        title: String,
        showDialog: Function,
        btn_action: String,
        user_to_update: Object,
        updateTable: Function,
    },
    setup(props) {
        const loading_btn = ref(false)

        const form_data = ref({})
        const form_error = ref({})

        onBeforeMount(async () => {
            if (variableIsDefined(props.user_to_update)){
                form_data.value = props.user_to_update
            }
        })

        const sendForm = async () => {
            if (validateData()){
                loading_btn.value = true
                let response

                switch (props.btn_action){
                    case 'Crear':
                        response = await insertExternalUserWS(form_data.value)
                        break;
                    case 'Actualizar':
                        response = await updateExternalUserWS(form_data.value)
                        break;
                }

                if (response['result']){
                    showNotification('success', response['data'].message)
                    loading_btn.value = false
                    await props.updateTable()
                    props.showDialog()
                }else {
                    loading_btn.value = false
                    showNotification('error', response['errors'])
                }
            }
        }

        const validateData = () => {
            let correct = true

            form_error.value = {}

            if (!validate_user(form_data.value.username)){
                form_error.value.username = true
                correct = false
            }

            if (!variableIsDefined(form_data.value.firstname)){
                form_error.value.firstname = true
                correct = false
            }

            if (!variableIsDefined(form_data.value.secondname)){
                form_error.value.secondname = true
                correct = false
            }

            if (!validateEmail(form_data.value.email)){
                form_error.value.email = true
                correct = false
            }

            return correct
        }

        const validate_user = (username) => {
            const regex = /^[a-z]+\.{1}[a-z]+$/i;
            return ((regex.exec(username)) !== null);
        }

        return {
            sendForm,

            form_data,
            form_error,
            loading_btn,
        }
    },
}
</script>

<style scoped>

</style>