<template>
    <v-container>
        <v-row class="row-title-btn-add" align="center">
            <v-col cols="12" md="8">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/chat-settings.svg')" alt="icono de estados de comentarios" width="28" height="28">
                    Gestión de estados para comentarios
                </span>
            </v-col>
            <v-col cols="12" md="4" class="text-end" v-if="!general_loading">
                <v-btn
                    class="btn button-bg-blue"
                    @click=" showForm('create', null)"
                >Crear Estado
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col>
                    <v-table>
                        <thead>
                        <tr>
                            <th class="text-left">ID</th>
                            <th class="text-left">NOMBRE</th>
                            <th class="text-left">DESCRIPCIÓN</th>
                            <th class="text-center">ACCIONES</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="statu in all_status"
                            :key="statu.id_status"
                        >
                            <td>{{ statu.id_status }}</td>
                            <td>{{ statu.name }}</td>
                            <td>{{ statu.description }}</td>

                            <td class="text-center">
                                <img :src="require('@/assets/icons/edit.svg')"
                                     alt="Modificar estado"
                                     class="icon-edit"
                                     title="Modificar estado"
                                     id="copy"
                                     @click=" showForm('update', statu)"
                                ></td>
                        </tr>
                        </tbody>
                    </v-table>
                </v-col>
            </v-row>
        </template>
        <v-dialog class="modal-create-edit"
                  v-model="open_modal" persistent>
            <FormStatus
                :getAllStatusComments="getAllStatusComments"
                :displayModal="displayModal"
                :title_form_status="title_form_status"
                :status_selected="status_selected"
            />
        </v-dialog>

    </v-container>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import FormStatus from "@/components/Forms/FormStatus.vue";
import {getAllStatusCommentsWS} from "@/utils/comment_status";
import Loading from "@/components/Generic/Loading.vue";


export default {
    name: "StatusManagementView",
    components: {Loading, FormStatus},
    setup() {
        let general_loading = ref(false)
        let all_status = ref();
        let open_modal = ref(false)
        let title_form_status = ref()
        let status_selected = ref()

        onBeforeMount(async () => {
            general_loading.value = true
            await getAllStatusComments()
            general_loading.value = false
        })

        async function getAllStatusComments() {
            let response = await getAllStatusCommentsWS()
            if (response['data'] && response['result']) {
                all_status.value = response['data']
            } else {
                showNotification("error", response['errors'])
            }
        }

        function showForm(method, status = {}) {
            if (method === 'create' && !variableIsDefined(status)) {
                title_form_status.value = "Crear estado"
            } else {
                title_form_status.value = "Modificar estado"
            }
            status_selected.value = status
            displayModal()
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }

        return {
            getAllStatusComments,
            displayModal,
            showForm,
            general_loading,
            open_modal,
            all_status,
            title_form_status,
            status_selected,
        }
    }
}


</script>

<style scoped lang="scss">
.icon-edit {
    width: 25px;
    height: auto;
    cursor: pointer;

    &:hover {
        content: url("@/assets/icons/hover-edit.svg");
    }
}

</style>