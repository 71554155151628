import {formatOnlyDate, variableIsDefined} from "@/utils/aux_functions";


/*VALIDACIÓN PARA HACER UNA BÚSQUEDA ENTRE DOS FECHAS*/
export function validateDate(init_date, final_date) {
    let correct_date = true
    let final_dates = null


    let initial = formatOnlyDate(init_date.input_date, true)
    let final = formatOnlyDate(final_date.input_date, true)


    /*NINGUN CAMPO RELLENO*/
    if (!variableIsDefined(initial) && !variableIsDefined(final)) {
        correct_date = true;
    }

    /*DEBE RELLENAR LOS DOS CAMPOS*/
    else if ((variableIsDefined(initial) && !variableIsDefined(final))
        || (!variableIsDefined(initial) && variableIsDefined(final))) {
        /*CAMPO INICIAL*/
        if (!variableIsDefined(initial)) {
            correct_date = false
            init_date.compareDateMsg(false, 'Debe rellenar la fecha de inicio')
        } else {
            correct_date = true
            init_date.compareDateMsg(true, '')
        }

        /*CAMPO FINAL*/
        if (!variableIsDefined(final)) {
            correct_date = false
            final_date.compareDateMsg(false, 'Debe rellenar la fecha final')
        } else {
            correct_date = true
            final_date.compareDateMsg(true, '')
        }
    }

    /*LOS DOS CAMPOS RELLENOS*/
    else if (variableIsDefined(initial) && variableIsDefined(final)) {
        /*COMPARAMOS LA FECHA FORMATEADA*/
        if (!compareDates(initial, final)) {
            correct_date = false
            init_date.compareDateMsg(false, 'La fecha de inicio no puede ser mayor a la final')
            final_date.compareDateMsg(true, '')
        } else {
            init_date.compareDateMsg(true, '')
            final_date.compareDateMsg(true, '')
            final_dates = {
                'initial': formatOnlyDate(initial, false, 'aaaa-mm-dd'),
                'final': formatOnlyDate(final, false, 'aaaa-mm-dd')
            }
        }
    }
    return correct_date ? [true, final_dates] : [false]
}


/*COMPARAR 2 FECHAS (INICIO MENOS A FINAL) 'dd/mm/aaaa*/
export function compareDates(init_date, final_date) {
    if (variableIsDefined(init_date) && variableIsDefined(final_date)) {
        let init_date_split = init_date.split(/[-\/T]/)
        let final_date_split = final_date.split(/[-\/T]/)
        /*AÑO INICIAL MENOS O IGUAL*/
        if (init_date_split[2] <= final_date_split[2]) {
            if (init_date_split[1] <= final_date_split[1]) {
                return init_date_split[0] <= final_date_split[0];
            } else {
                return false
            }
        } else {
            return false
        }

    }
}