<template>
    <BaseModalTemplate
        title="Previsualización de titulaciones"
        :loading="general_loading"
        :open-close-dialog="displayDialog"
    >
        <template #body-modal-content>
            <v-row>
                <v-col>
                    <span class="input-title">Orden en la web: {{ title_order_type }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <span class="input-title" style="color: #36A3FF">LOGOS</span>
                </v-col>
            </v-row>
            <v-row class="row-base-degree">
                <v-col cols="12" sm="6" class="col-base-degree">
                    <DegreeBoxBase
                        :title="order_type === 0 ? 'Programa formativo' : 'Curso'"
                        :list="order_type === 0 ? all_logos.program_formative : all_logos.course"
                    ></DegreeBoxBase>
                </v-col>
                <v-col cols="12" sm="6" class="col-base-degree">
                    <DegreeBoxBase
                        :title="order_type === 0 ? 'Curso' : 'Programa formativo'"
                        :list="order_type === 0 ? all_logos.course : all_logos.program_formative"
                    ></DegreeBoxBase>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <span class="input-title" style="color: #36A3FF">DIPLOMAS</span>
                </v-col>
            </v-row>
            <v-row class="row-base-degree">
                <v-col cols="12" sm="6" class="col-base-degree">
                    <DegreeBoxBase
                        :title="order_type === 0 ? 'Programa formativo' : 'Curso'"
                        :list="order_type === 0 ? all_diplomas.program_formative : all_diplomas.course"
                    ></DegreeBoxBase>
                </v-col>
                <v-col cols="12" sm="6" class="col-base-degree">
                    <DegreeBoxBase
                        :title="order_type === 0 ? 'Curso' : 'Programa formativo'"
                        :list="order_type === 0 ? all_diplomas.course : all_diplomas.program_formative"
                    ></DegreeBoxBase>
                </v-col>
            </v-row>
        </template>

        <template #actions-modal>
            <v-row>
                <v-col>
                    <v-btn variant="outlined" @click="displayDialog" class="btn button-primary">CERRAR</v-btn>
                </v-col>
            </v-row>
        </template>


    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {getCourseDegreesFinalListWS} from "@/utils/degrees_course";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import DegreeBoxBase from "@/components/DegreesCourse/DegreeBoxBase.vue";

export default {
    name: "ModalAllDegrees",
    components: {DegreeBoxBase, ImageCard, BaseModalTemplate},
    props: {
        displayDialog: Function,
        id_url_course: String
    },

    setup(props) {
        let general_loading = ref(false)
        let order_type = ref()
        let title_order_type = ref('')
        let list_final
        let all_logos = ref({course: [], program_formative: []})
        let all_diplomas = ref({course: [], program_formative: []})


        onBeforeMount(async () => {
            general_loading.value = true
            await getFinalListCourseDegree()
            general_loading.value = false
        })

        async function getFinalListCourseDegree() {
            let response = await getCourseDegreesFinalListWS(props.id_url_course)
            if (response['result']) {
                list_final = response['data']
                getDataListing()

            } else {
                showNotification('error', response['errors'])
            }
        }

        function getDataListing() {
            title_order_type.value = list_final['invert'] === 0 ? 'Logos y diplomas por programa formativo - Logos y diplomas por curso' : 'Logos y diplomas por curso - Logos y diplomas por programa formativo';
            order_type.value = list_final['invert'];

            /*LISTA DE LOGOS*/
            if (variableIsDefined(list_final.logos)) {
                processData(list_final.logos, all_logos);
            }

            /*LISTA DE DIPLOMAS*/
            if (variableIsDefined(list_final.diplomas)) {
                processData(list_final.diplomas, all_diplomas);
            }
        }


        /*LISTA TENIENDO EN CUENTA EL PESO*/
        function processData(data, list_degree) {
            let weight_course = 0;
            let weight_program = 0;

            data.forEach((degree) => {
                if (variableIsDefined(degree.pivot.id_url)) {
                    if (degree.pivot.peso === weight_course) {
                        list_degree.value.course.push(degree);
                        weight_course++;
                    }
                } else {
                    if (degree.pivot.peso === weight_program) {
                        list_degree.value.program_formative.push(degree);
                        weight_program++;
                    }
                }
            });
        }

        return {
            general_loading,
            title_order_type,
            order_type,
            all_diplomas,
            all_logos,
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/degree-box-base.scss";
</style>