<template>
    <BaseModalTemplate
        :title="title_dialog"
        :open-close-dialog="displayDialog"
        :loading="general_loading"
        card_extra_classes="card-modal-checkboxs"
        :send-form="saveDegree"
    >
        <template #body-modal-content>
            <v-row>
                <v-col>
                    <v-radio-group
                        v-model="select_type"
                        @update:modelValue="degreeSelection(select_type)"
                        inline
                    >
                        <v-radio
                            label="Diplomas"
                            value="Diplomas"
                            class="radio-button-primary"
                            :disabled="lock_options"
                        ></v-radio>
                        <v-radio
                            label="Logos"
                            value="Logos"
                            class="radio-button-primary"
                            :disabled="lock_options"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <div v-if="loading_diplomas_logos" class="general-loading">
                <Loading class_loading="loading-gif-s"/>
            </div>
            <template v-else>
                <v-row v-if='select_type!=="" && view_select_type.length!==0'>
                    <CheckBoxesImage
                        :view_select_type="view_select_type"
                        :check_boxes_active="check_boxes_active"
                        :type_degree="type_degree"
                        :addSelection="addSelection"
                        :select_type="select_type"
                    ></CheckBoxesImage>
                </v-row>
                <v-row v-else>
                    <v-col>
                        <span>La selección no contiene información</span>
                    </v-col>
                </v-row>
            </template>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12" md="2" xl="2">
                    <v-btn
                        variant="outlined"
                        @click="saveDegree"
                        class="btn button-bg-blue"
                        :loading="loading_save"
                        :disabled="loading_diplomas_logos"
                    >
                        Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {assignDegreeUrlWS, getAllDegreesUrlWS} from "@/utils/degrees_course";
import {getAllType} from "@/utils/degrees_course";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import CheckBoxesImage from "@/components/Generic/CheckBoxesImage.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import Loading from "@/components/Generic/Loading.vue";
import {assignDegreeToFormativeProgramWS, getPortalFormativeProgramsWS} from "@/utils/training_program";
import store from "@/store";

export default {
    name: "ModalDegreesCourse",
    components: {Loading, BaseModalTemplate, CheckBoxesImage, ImageCard},
    props: {
        displayDialog: Function,
        getAllDegreesUrl: Function,
        changeDegreeData: Function,
        degree_data: Object,
        id_url_course: String,
        type_component: String,
        title_dialog: String,

        id_portal: {
            type: String,
            default: store.getters.getPortalID
        },
        iso_language: {
            type: String,
            default: store.getters.getLanguageISO
        }
    },
    setup(props) {
        let general_loading = ref(false)
        let check_boxes_active = ref({});
        let loading_btn = ref(false);
        let selection_title = ref(['Diplomas', 'Logos']);
        let select_type = ref("");
        let view_select_type = ref([]);
        let type_degree = ref();
        let all_titulation_url = {diplomas_list: [], logos_list: []}
        let selection_diplomas = []
        let selection_logos = []
        let lock_options = ref(false)

        let loading_diplomas_logos = ref(false)
        let loading_save = ref(false)

        let degree_data = ref({});
        let json_degree_data;


        onBeforeMount(async () => {
            general_loading.value = true
            await getAllDegreesUrl()
            await loadTitulationActive()
            degree_data.value = _.cloneDeep(props.degree_data)
            general_loading.value = false
        })

        /*GUARDA LOS DIPLOMAS Y LOGOS ---------- UPDATE*/
        async function saveDegree() {
            loading_save.value = true
            let response
            json_degree_data = JSON.stringify(degree_data.value)
            if (props.type_component === 'qualifications') {
                response = await assignDegreeUrlWS(props.id_url_course, json_degree_data)
            } else if (props.type_component === 'training_program') {
                response = await assignDegreeToFormativeProgramWS(props.id_portal, props.iso_language, props.id_url_course, json_degree_data)
            }

            if (response['result']) {
                if (response['data']['success']) {
                    showNotification('success', response['data']['message'])
                } else {
                    showNotification('info', response['data']['message'])
                }
                await props.getAllDegreesUrl()
            } else {
                showNotification('error', response['errors'])
            }

            props.displayDialog()
            loading_save.value = false
        }

        /*OBTIENE TODOS LOS LOGOS Y DIPLOMAS ACTIVOS*/
        async function degreeSelection(select_type) {
            loading_diplomas_logos.value = true
            let url;
            let type;
            view_select_type.value = []
            lock_options.value = true
            switch (select_type) {
                case 'Diplomas':
                    type_degree.value = 'id_diploma'
                    url = 'degrees/getAllDegrees';
                    type = 'diplomas';
                    check_boxes_active.value['id_diploma'] = selection_diplomas
                    break;
                case 'Logos':
                    type_degree.value = 'id_logo'
                    url = 'degrees/getAllDegrees';
                    type = 'logos';
                    check_boxes_active.value['id_logo'] = selection_logos
                    break;
            }
            let response = await getAllType(url, type, props.id_portal, props.iso_language)
            if (response['result']) {
                if (variableIsDefined(response['data'])) {
                    response['data'].forEach((item) => {
                        if (item.activo === 1) {
                            view_select_type.value.push(item)
                        }
                    })
                } else {
                    view_select_type.value = []
                }

            } else {
                showNotification('error', response['errors'])
            }
            lock_options.value = false
            loading_diplomas_logos.value = false
        }


        /*OBTENER DATOS PARA TITULACIONES URL*/
        async function getAllDegreesUrl() {
            all_titulation_url = {diplomas_list: [], logos_list: []}
            let response
            if (props.type_component === 'qualifications') {
                response = await getAllDegreesUrlWS(props.id_url_course)
            } else if (props.type_component === 'training_program') {
                response = await getPortalFormativeProgramsWS(props.id_portal, props.id_url_course, 1, props.iso_language)
            }
            if (response['result']) {
                if(variableIsDefined(response['data'])){
                    all_titulation_url = response['data']
                }
            } else {
                showNotification('error', response['errors'])
            }
        }


        /*AÑADIR O DESMARCA LOS DATOS SELECCIONADOS PARA ENVIARLOS*/
        function addSelection(type, new_selection) {
            if (type === 'Diplomas') {
                let index = selection_diplomas.findIndex(element => element === new_selection)
                if (index >= 0) {
                    selection_diplomas.splice(index, 1);
                } else {
                    selection_diplomas.push(new_selection)
                }

                /*Una vez modificada la lista de diplomas se reasignan los pesos*/
                degree_data.value['diplomas_weight'] = [];

                // Recalcular los pesos de los diplomas empezando desde 0
                selection_diplomas.forEach((diploma, index) => {
                    degree_data.value['diplomas_weight'][diploma] = selection_diplomas.length - 1 - index;
                });

                // Asignar la nueva selección de diplomas a degree_data.value
                degree_data.value['diplomas_list'] = selection_diplomas;
            }

            if (type === 'Logos') {
                let index = selection_logos.findIndex(element => element === new_selection)
                if (index >= 0) {
                    selection_logos.splice(index, 1)
                } else {
                    selection_logos.push(new_selection)
                }

                /*Una vez modificada la lista de logos se reasignan los pesos*/
                degree_data.value['logos_weight'] = [];

                selection_logos.forEach((logo, index) => {
                    degree_data.value['logos_weight'][logo] = selection_logos.length - 1 - index;
                });
                degree_data.value['logos_list'] = selection_logos;
            }

            /*Se actualiza el degreeData general para esté sincronizado a la hora de reordenar*/
            props.changeDegreeData(degree_data.value);
        }

        /*OBTIENE LOS DATOS ACTUALES PARA MARCAR LOS ACTIVADOS*/
        async function loadTitulationActive() {
            if(variableIsDefined(all_titulation_url)){
                if(variableIsDefined(all_titulation_url.diplomas_list)){
                    all_titulation_url.diplomas_list.forEach((diploma) => {
                        selection_diplomas.push(diploma.id_diploma)
                    })
                }
                if(variableIsDefined(all_titulation_url.logos_list)){
                    all_titulation_url.logos_list.forEach((logo) => {
                        selection_logos.push(logo.id_logo)
                    })
                }

            }
        }


        return {
            degreeSelection,
            saveDegree,
            addSelection,

            general_loading,
            check_boxes_active,
            loading_btn,
            selection_title,
            select_type,
            view_select_type,
            type_degree,
            loading_diplomas_logos,
            loading_save,
            lock_options,


        }
    }

}
</script>

<style lang="scss">
@import "@/styles/components/modal-image-degree";
</style>