<template>
    <BaseModalTemplate
        card_extra_classes="modal-file"
        :title="file_select.title"
        :open-close-dialog="displayModal"
        :loading="loading"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" md="7" lg="7" class="view-file">
                    <img class="size-img" v-if="file_select.type==='Image'" :src="file_select.img" alt="">
                    <video class="size-video" controls
                           v-if="file_select.type==='Video' || file_select.type==='Audio'">
                        <source :src="file_select.video">
                    </video>
                    <img class="size-img" v-if="file_select.type==='Image' && file_select.fileType==='SVG'"
                         :src="file_select.front" alt="">
                </v-col>
                <v-col cols="12" md="5" lg="5">
                    <v-row no-gutters class="row-info-modal mt-2">
                        <v-col cols="12" md="5">
                            <span class="title-info">Estado</span>
                        </v-col>
                        <v-col cols="12" md="7">
                                    <span v-if="file_select.status">
                                        <img :src="require('@/assets/icons/spot.svg')"
                                             class="icons-spot-true" alt="">Aprobado
                                    </span>
                            <span v-else>
                                        <img :src="require('@/assets/icons/spot.svg')" class="icons-spot-false" alt="">Rechazado
                                    </span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Formato</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ file_select.fileType }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Tamaño del archivo</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ new_size }}</span>

                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Fecha de carga</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ new_date_uploaded }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Subido por</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ identify_user }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Descripción</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ file_select.description }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Fecha Modificación</span>
                        </v-col>
                        <v-col cols="12" md="7">
                            <span>{{ new_date_modify }}</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="row-info-modal">
                        <v-col cols="12" md="5">
                            <span class="title-info">Url del archivo: </span>
                        </v-col>
                        <v-col cols="10" md="5" class="crop-text align-self-center">
                            <span> {{ url_cdn }}</span>
                        </v-col>
                        <v-col cols="2" class="align-self-center">
                            <img class="icon-copy"
                                 :src="require('@/assets/icons/clipboard.svg')"
                                 alt="Clipboard icon" @click="copyUrl(url_cdn)"/>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row class="">
                <v-col cols="6" class="text-start">
                    <v-btn class="btn button-bg-white" @click="displayDeleteConfirmation">Eliminar Archivo
                    </v-btn>
                </v-col>
                <v-col cols="6" class="text-end">
                    <a :href="file_select.video">
                        <v-btn class="btn button-bg-blue">Descargar</v-btn>
                    </a>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import {createDirectLinkFile, deleteFile, getDirectLinkFile} from "@/utils/media_valet";
import {copyClipboard, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalVideo",
    components: {BaseModalTemplate, Loading},
    methods: {copyClipboard},
    props: {
        displayModal: Function,
        file_select: Object,
        getFileActiveWS: Function,
        identifier_category: String
    },
    setup(props) {
        let loading = ref(false)
        let new_date_modify = ref()
        let new_date_uploaded = ref()
        let new_size = ref()
        let identify_user = ref("")
        let state_loading = ref(false);
        let block_btn = ref(false)
        let url_cdn = ref("")


        onBeforeMount(async () => {
            loading.value = true
            convertDateUploaded()
            convertDateModify()
            convertSize()
            getIdentify()
            initSweetAlert()

            let response = await getDirectLinkFile(props.file_select.id_file)
            if (variableIsDefined(response.payload.length)) {
                url_cdn.value = response.payload[0].cdnLink
            } else {
                let response = await createDirectLinkFile(props.file_select.id_file, props.file_select.type)
                url_cdn.value = response.payload.cdnLink
            }
            loading.value = false

        })

        function displayDeleteConfirmation() {
            fireSweetAlert('¿Quieres eliminar el archivo?', deleteFileWS)
        }

        async function deleteFileWS() {
            block_btn.value = true
            state_loading.value = true;
            let response = await deleteFile(props.file_select.id_file)
            if (response) {
                await sleep(3000);
                props.getFileActiveWS()
                showNotification('success', 'Se ha eliminado correctamente el archivo')
            } else {
                showNotification('error', 'Error al eliminar el archivo')
            }
            state_loading.value = false;
            block_btn.value = false
        }

        let sleep = function (ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        };

        function getIdentify() {
            identify_user.value = props.file_select.identifier_create.username
        }

        function convertDateUploaded() {
            let date_only = props.file_select.date_uploaded.split('T')
            let date_division = date_only[0].split('-')
            new_date_uploaded.value = date_division[2] + '/' + date_division[1] + '/' + date_division[0]
        }

        function convertDateModify() {
            let date_only = props.file_select.date_modify.split('T')
            let date_division = date_only[0].split('-')
            new_date_modify.value = date_division[2] + '/' + date_division[1] + '/' + date_division[0]
        }

        function convertSize() {
            if (props.file_select.size < 1000000) {
                new_size.value = (props.file_select.size / 1000).toFixed(2) + ' KB'
            } else {
                new_size.value = (props.file_select.size / 1000000).toFixed(2) + ' MB'
            }
        }

        const copyUrl = async (valor) => {
            await copyClipboard(valor)
        }

        return {
            displayDeleteConfirmation,
            copyUrl,

            new_date_modify,
            new_date_uploaded,
            new_size,
            identify_user,
            state_loading,
            block_btn,
            url_cdn,
            loading,
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/styles/components/media-valet/modal-file";
</style>