<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" class="align-self-center">
                <span class="main-title-page ">{{ title }}</span>
            </v-col>
            <!--    CREAR NUEVO PORTAL-->
            <v-col cols="12" sm="6" v-if="!general_loading && can_edit_portals" class="align-self-center text-end">
                <v-btn class="btn button-primary " @click="degreeSelection('create')">Crear Portal</v-btn>
            </v-col>
        </v-row>
        <!--    EXPANSION PANEL - FILTROS-->
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <FiltersGetPortal
                :getFilters="getFilters"
                :item_countries="item_countries"
                :loading_filter="loading_filter"
                :dropFilter="dropFilter"/>
            <v-row>
                <v-col>
                    <Vue3EasyDataTable
                        :key="key_table"
                        table-class-name="primary-table"
                        theme-color="#F4A649"
                        :headers="headers"
                        :items="items_all_portals"
                        :loading="loading_table"
                        :rowsPerPage="10"
                        buttons-pagination
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                        must-sort
                    >
                        <template #item-languages="{languages}">
                            <template v-for="language in languages" v-if="languages.length !== 0">
                                <span>
                                    {{ language.nombre }}
                                    <strong>ID:</strong> {{ language.id_idioma }}
                                </span>
                                <br v-if="languages.length > 1">
                            </template>
                            <span v-else> Sin datos </span>
                        </template>
                        <template #item-activo="items_all_portals">
                            <span v-if="items_all_portals.activo===1">Activo</span>
                            <span v-else>Inactivo</span>
                        </template>
                        <template #item-btn_actions="items_all_portals">
                            <v-menu class="table-blog">
                                <template v-slot:activator="{ props }">
                                    <img :src="require('@/assets/icons/more-options.svg')"
                                         class="btn button-bg-white icon-actions"
                                         alt="Icono puntos"
                                         v-bind="props">
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title class="text-center"
                                                           @click="degreeSelection('details', items_all_portals)">
                                            <span style="cursor: pointer">Detalles</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="can_edit_portals">
                                        <v-list-item-title class="text-center"
                                                           @click="degreeSelection('update', items_all_portals)">
                                            <span style="cursor: pointer">Editar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <!--Activar/Desactivar url-->
                                    <v-list-item @click="displayStatusPortal(items_all_portals, items_all_portals.active)">
                                        <v-list-item-title>
                                            <span v-if="items_all_portals.activo===1">Desactivar</span>
                                            <span v-else>Activar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </v-container>
    <v-dialog class="modal-create-edit"
              v-model="open_modal" persistent>
        <ModalPortal
            :displayModal="displayModalPortal"
            :data_obtained_ws="form_data"
            :getPortals="getPortals"
            :item_countries="item_countries"
            :options_modal="options_modal"
        />
    </v-dialog>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {getAllPortalsWS, updatePortalWS} from "@/utils/portal";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {showNotification} from "@/utils/aux_functions";
import ConfigurationGADS from "@/components/ConfigurationGads/FormConfigurationGADS.vue";
import ModalPortal from "@/components/Admin/ManagerPortal/ModalPortal.vue";
import {getAllCountriesWS} from "@/utils/countries";
import FiltersGetPortal from "@/components/Admin/ManagerPortal/FiltersGetPortal.vue";
import {fireSweetAlert} from "@/utils/sweetalert";

export default {
    name: "ManagerPortalsView",
    components: {FiltersGetPortal, ModalPortal, ConfigurationGADS, Vue3EasyDataTable, Loading},
    props: {
        title: {
            type: String,
            required: true,
        },
        can_edit_portals: {
            type: Boolean,
            default: true,
        },
        get_languages: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        let general_loading = ref(false)
        let form_data = ref({})
        const headers = ref()
        const items_all_portals = ref([])
        let loading_table = ref(false)
        let open_modal = ref(false)
        let item_countries = ref([])
        let options_modal = ref({})
        let loading_filter = ref({
            btn_save: false,
            btn_delete: false,
        })
        let data_filters = {}
        const key_table = ref(0);
        let status_portal_selected

        onBeforeMount(async () => {
            general_loading.value = true
            if (props.get_languages) {
                headers.value = [
                    {text: 'ID Portal', value: 'id_portal', sortable: true},
                    {text: 'ID Pais', value: 'id_pais', sortable: true},
                    {text: 'Nombre', value: 'nombre', sortable: true},
                    {text: 'Idiomas', value: 'languages'},
                    {text: 'URL', value: 'url', sortable: true},
                    {text: 'Estado', value: 'activo', sortable: true},
                    {text: 'Tipo Portal', value: 'tipo_portal', sortable: true},
                    {text: 'Acciones', value: 'btn_actions'},
                ]
            } else {
                headers.value = [
                    {text: 'ID Portal', value: 'id_portal', sortable: true},
                    {text: 'ID Pais', value: 'id_pais', sortable: true},
                    {text: 'Nombre', value: 'nombre', sortable: true},
                    {text: 'URL', value: 'url', sortable: true},
                    {text: 'Estado', value: 'activo', sortable: true},
                    {text: 'Tipo Portal', value: 'tipo_portal', sortable: true},
                    {text: 'Acciones', value: 'btn_actions'},
                ]
            }
            await getPortals()
            await getAllCountries()
            general_loading.value = false
        })

        async function getPortals() {
            loading_table.value = true

            data_filters['languages'] = props.get_languages
            let response = await getAllPortalsWS(data_filters)
            if (response['result']) {
                items_all_portals.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }

        async function getFilters(filters) {
            loading_filter.value.btn_save = true
            key_table.value++;
            data_filters = filters
            await getPortals()
            loading_filter.value.btn_save = false
        }

        async function dropFilter() {
            loading_filter.value.btn_delete = true
            data_filters = {}
            await getPortals()
            loading_filter.value.btn_delete = false
        }

        async function getAllCountries() {
            let response = await getAllCountriesWS()
            if (response['result']) {
                item_countries.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        function displayModalPortal() {
            open_modal.value = !open_modal.value
        }

        async function degreeSelection(select_type, data) {
            switch (select_type) {
                case 'create':
                    form_data.value = null
                    options_modal.value = {
                        'title_modal': 'Crear un portal',
                        'title_btn': 'Guardar',
                        'disabled_field': false,
                        'add_language': true,
                        'see_language': true,
                        'option': 'create',
                        'see_btn_actions': false
                    }
                    displayModalPortal()
                    break;
                case 'update':
                    await getSelectedPortalData(data)
                    options_modal.value = {
                        'title_modal': 'Modificar un portal',
                        'title_btn': 'Guardar',
                        'disabled_field': false,
                        'add_language': true,
                        'see_language': true,
                        'option': 'update',
                        'see_btn_actions': false
                    }
                    displayModalPortal()
                    break;
                case 'details':
                    await getSelectedPortalData(data)
                    options_modal.value = {
                        'title_modal': 'Detalles del portal',
                        'disabled_field': true,
                        'add_language': false,
                        'see_language': true,
                        'option': 'details',
                        'see_btn_actions': true
                    }
                    displayModalPortal()
                    break;
            }
        }

        async function getSelectedPortalData(data) {
            form_data.value = {
                'active': data.activo,
                'create_date': data.fecha_creacion,
                'modification_date': data.fecha_creacion,
                'id_accuranker': data.id_accuranker,
                'id_country': data.id_pais,
                'id_portal': data.id_portal,
                'id_portal_diffusion_innotutor': data.id_portal_difusion_innotutor,
                'key': data.key,
                'logo': data.logo,
                'name': data.nombre,
                'score': data.score,
                'portal_type': data.tipo_portal,
                'url': data.url,
                'url_canonica': data.url_canonica,
                'ws_push': data.ws_push,
            }
        }




        async function displayStatusPortal(item){
            await getSelectedPortalData(item)
            status_portal_selected = item.activo
            let state = item.activo === 1 ? 'desactivar' : 'activar';
            await fireSweetAlert(`¿Quieres ${state} el estado del portal?`, disableStatusPortal)

        }

        async function disableStatusPortal (){
            form_data.value.active = status_portal_selected === 1 ? 0 : 1;
            let response = await updatePortalWS(form_data.value)
            if(response['result']){
                showNotification('success', response['data']['message'])
            }else{
                showNotification('error', response['errors'])
            }
            await getPortals()

        }


        return {
            displayModalPortal,
            displayStatusPortal,
            degreeSelection,
            getPortals,
            getFilters,
            dropFilter,

            general_loading,
            loading_filter,
            loading_table,
            headers,
            items_all_portals,
            item_countries,
            form_data,
            open_modal,
            options_modal,
            key_table,

        }
    }
}
</script>


<style scoped lang="scss">
</style>