<template>
    <BaseModalTemplate
        :title="`Detalles de la cola con ID ${log_id}`"
        :open-close-dialog="open_modal_log"
        :loading="loading"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <template v-if="logs_array.length > 0" v-for="info in logs_array">
                <v-row>
                    <v-col cols="6">
                        <span><b>Fecha de creación:</b> {{ info.created_at }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Creado por:</b> {{ info.id_empleado }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Portal e idioma:</b> {{
                                getNamePortal(parseInt(info.id_portal))
                            }} - {{ getNameLanguage(parseInt(info.id_idioma)) }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>URL: </b>{{ info.url }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Keyword actual: </b>{{ info.keyword_actual }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Keyword propuesta: </b>{{ info.keyword_propuesta }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Estado keyword: </b>{{ info.estado_kw }}</span>
                    </v-col>
                    <v-col cols="6">
                        <span><b>Respuesta del servidor: </b>{{ info.response }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <span><b>Datos del servidor: </b></span>
                    </v-col>
                    <template v-for="(value, key) in JSON.parse(info.data)">
                        <v-col cols="12">
                            <span><b>{{ key }}:</b> {{ value }}</span>
                        </v-col>
                    </template>


                </v-row>

            </template>
            <template v-else>
                <span>No hay datos disponibles para este elemento</span>
            </template>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {getLogElementProposalWS} from "@/utils/proposal_keyword";

export default {
    name: "ModalLogProposalQueue",
    methods: {variableIsDefined, formatDate, getNameLanguage, getNamePortal},
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        open_modal_log: Function,
        log_id: Number,
    },
    setup(props) {
        let loading = ref(false)
        let logs_array = ref([])

        onBeforeMount(async () => {
            loading.value = true
            const response = await getLogElementProposalWS(props.log_id)

            if (response['result']) {
                logs_array.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }

            loading.value = false
        })


        return {
            loading,
            logs_array,
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/modal-log.scss";
</style>