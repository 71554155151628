<template>
    <BaseModalTemplate
        title="Creación de URLs"
        :loading="loading"
        :open-close-dialog="showDialog"
        :send-form="sendForm"
    >
        <template #body-modal-content>
            <p v-if="loading_related">Buscando urls relacionadas...</p>
            <template v-if="stored_related_urls">
                <div id="created_urls" v-for="related_url in stored_related_urls">
                    <RowFormUrl :portals="portals" :id_portal="related_url.id_portal"
                                :id_language="related_url.id_idioma" :url="related_url.urlStr"
                                :inherit="true" :url_type="url_type"></RowFormUrl>
                </div>
            </template>

            <div id="new_urls" v-for="field in fields.keys()">
                <RowFormUrl :portals="portals" :fields="fields" :increaseID="increaseID"
                            :id="field" :inherit="false" :related_url="stored_related_urls"
                            :key="field" :can_repeat="can_repeat" :url_type="url_type"></RowFormUrl>
            </div>

            <v-row justify="end">
                <v-col cols="12">
                        <span v-if="error_empty_url" class="input-error msg-error">
                            Se debe añadir como mínimo una URL
                        </span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import RowFormUrl from "@/components/UrlList/RowFormUrl.vue";
import {createURLWS, getURLVersionsWS} from "@/utils/urls";
import {getSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalUrl",
    components: {
        BaseModalTemplate,
        RowFormUrl,
    },
    props: {
        showDialog: Function,
        url_data: Object,
        url_type: String,
        portals: Array
    },
    setup(props) {
        const loading = ref(false)
        const fields = ref(new Map());
        const count = ref(0);
        const data = ref([]);
        const stored_related_urls = ref([]);

        const id_portal = ref();
        const id_language = ref();
        const url = ref();
        const loading_related = ref(false);
        const error_empty_url = ref(false)

        const can_repeat = ref(false)

        onBeforeMount(async () => {
            loading.value = true
            fields.value.set(0, '');
            can_repeat.value = !variableIsDefined(Object.keys(props.url_data).length)

            if (props.url_data.url && props.url_data.id_language && props.url_data.url && props.url_type) {
                loading_related.value = true;
                await getStoredRelatedURLs();
            }
            initSweetAlert()
            loading.value = false
        })

        async function getStoredRelatedURLs() {
            let response = await getURLVersionsWS(props.url_data.id_portal, props.url_data.id_language, props.url_data.url)
            if (response['result']) {
                stored_related_urls.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_related.value = false;
        }

        async function sendForm() {
            //Enviar los datos
            let temporary_urls = ''

            for (let [key, value] of fields.value.entries()) {
                if (value !== '') {
                    let fields_value = fields.value.get(key);
                    fields_value['urlType'] = props.url_type === 'courses' ? 'ficha_producto' : 'contenido_seo'
                    fields_value.urlType = props.url_type === 'courses' ? 'ficha_producto' : 'contenido_seo';
                    data.value.push(fields_value);
                    temporary_urls += `<li>${fields_value.newURL}</li>`
                }
            }
            if (data.value.length > 0) {
                let response;

                error_empty_url.value = false

                await getSweetAlert().fire({
                    title: 'Se van a crear las siguientes URL ¿Son correctas?',
                    html: `<ul>${temporary_urls}</ul>`,
                    preConfirm: async () => {
                        if (props.url_data.url) {
                            return await createURLWS(data.value, true, props.url_data.id_portal, props.url_data.id_language, props.url_data.url)
                        } else {
                            return await createURLWS(data.value, false)
                        }
                    },
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            response = result.value

                            if (response['result']) {
                                showNotification('success', 'URLs creadas con éxito')
                                props.showDialog();
                            } else {
                                showNotification('error', response['errors'])
                            }
                        }
                    })

            } else {
                error_empty_url.value = true
            }
            data.value = [];
        }

        function increaseID() {
            let count_before = count.value;
            count.value++;

            return count_before;
        }

        return {
            increaseID,
            sendForm,

            loading,
            can_repeat,
            count,
            data,
            error_empty_url,
            fields,
            id_language,
            id_portal,
            loading_related,
            stored_related_urls,
            url,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/url-list/modal-url.scss";
</style>