<template>
    <BaseModalTemplate
        :open-close-dialog="openModalLogs"
        :title="title_log"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <div v-if="loading_log" class="general-loading">
                <Loading class_loading="loading-gif-s"/>
            </div>
            <template v-else>
                <v-row>
                    <v-col v-if="element_log.length===0">
                        <span class="text-log">No hay datos para el log seleccionado.</span>
                    </v-col>
                    <v-col v-else>
                        <template v-for="item in element_log">
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <span class="title-log">Log número:</span>
                                    <span class="text-log">{{ item.id_log }}</span>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <span class="title-log">Lote:</span>
                                    <span class="text-log">{{ item.id_lote }}</span>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <span class="title-log">Fecha creación:</span>
                                    <span class="text-log">{{ formatDate(item.created_at) }}</span>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <span class="title-log">Usuario:</span>
                                    <span class="text-log">{{ item.tecnico }}</span>
                                </v-col>


                                <v-col cols="12" md="6">
                                    <span class="title-log">Portal:</span>
                                    <span class="text-log"> {{ getNamePortal(parseInt(item.id_portal)) }}</span>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span class="title-log">Idioma:</span>
                                    <span class="text-log">{{ getNameLanguage(parseInt(item.id_idioma)) }}</span>
                                </v-col>

                                <v-col cols="12">
                                    <span class="title-log">Urls:</span>
                                    <div v-if="item.urls">
                                        <v-table density="compact"
                                                 class="personalize-scroll personalize-table-simple">
                                            <thead>
                                            <tr>
                                                <th class="text-left" style="font-weight: bold">
                                                    ID
                                                </th>
                                                <th class="text-left" style="font-weight: bold">
                                                    URL
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                v-for="(value, key) in JSON.parse(item.urls)"
                                                :key="key">
                                                <td>{{ key }}</td>
                                                <td>{{ value }}</td>
                                            </tr>
                                            </tbody>
                                        </v-table>
                                    </div>

                                </v-col>
                                <v-col cols="12">
                                    <span class="title-log">Respuesta:</span>
                                    <span class="text-log ms-2" v-if="JSON.parse(item.response).updateStatus">UpdateStatus: {{ JSON.parse(item.response).updateStatus }}</span>
                                    <span class="text-log ms-2" v-if="JSON.parse(item.response).attach">Attach: {{ JSON.parse(item.response).attach }}</span>
                                    <span class="text-log ms-2">{{ JSON.parse(item.response).errors }}</span>
                                </v-col>
                                <v-divider/>
                            </v-row>
                        </template>

                    </v-col>
                </v-row>
            </template>

        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import Loading from "@/components/Generic/Loading.vue";
import {getNameLanguage, getNamePortal} from "../../utils/portal";
import {formatDate} from "../../utils/aux_functions";

export default {
    name: "ModalLogImages",
    methods: {formatDate, getNamePortal, getNameLanguage},
    components: {Loading, BaseModalTemplate},
    props: {
        openModalLogs: Function,
        element_log: Object,
        loading_log: Boolean,
        title_log: String,
    }
}
</script>


<style scoped lang="scss">
.title-log {
    font-size: 14px;
    color: $blue-medium;
    font-weight: bold;
}

.text-log {
    font-size: 14px;
    margin-left: 5px;
}

.personalize-table-simple {
    max-height: 20vh;
    overflow-y: auto;
}

@media (max-width: 700px) {
    .personalize-table-simple {
        max-height: 30vh;
    }
}
</style>