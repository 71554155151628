<template>
    <v-container fluid>
        <div class="position-info-page personalize-scroll">
            <v-row>
                <v-col>
                    <v-breadcrumbs :items="items_upload" v-model="option_menu">
                        <template v-slot:divider>
                            <v-icon class="icon-next-options" color="blue" icon="mdi-chevron-right"></v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[0].disabled===false">
                <v-col cols="12">
                    <span class="title_add_files">Añadir archivos</span>
                </v-col>
                <v-col cols="12" sm="8" md="9" lg="10">
                    <v-file-input
                        multiple
                        chips
                        v-model="validate_files_uploads"
                        @update:model-value="getFiles"
                        class="select-primary personalize-text-field add-files"
                        bg-color="white"
                        variant="underlined"
                        @click:clear="clear_files_update"
                        hide-details
                        counter
                        :show-size="1000"
                    >
                        <template v-slot:selection="{ fileNames }">
                            <template v-for="(fileName, index) in fileNames" :key="fileName">
                                <v-chip
                                    v-if="index < 2"
                                    color="blue"
                                    label
                                    size="small"
                                    class="me-2"
                                >
                                    {{ fileName }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="text-overline text-grey-darken-3 mx-2"
                                >
          +{{ validate_files_uploads.length - 2 }} ARCHIVO/OS
        </span>
                            </template>
                        </template>
                    </v-file-input>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2">
                    <v-btn class="btn button-bg-white btn_add_files" @click="uploadFiles"
                           :disabled="btn_upload_files"
                    >Subir Archivo
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-table v-if="active_table" fixed-header height="30vh">
                        <thead>
                        <tr>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Tamaño
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in validate_files_uploads"
                            :key="item.name"
                        >
                            <td>{{ item.name }}</td>
                            <td>{{ item.size }} bytes</td>
                            <td>{{ item.type }}</td>
                        </tr>
                        </tbody>
                    </v-table>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[1].disabled===false">
                <v-col cols="12">
                    <TableAddFilesCategories
                        :root_category="root_category"
                        :date_file="date_files_update"
                        :saveFilesCategories="saveFilesCategories">
                    </TableAddFilesCategories>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[2].disabled===false">
                <v-col cols="12">
                    <TableAddFilesAttributes
                        :date_file="date_files_update"
                        :date_attribute="date_attribute"
                        :addAttributesFiles="saveDateAttribute">
                    </TableAddFilesAttributes>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[3].disabled===false">
                <v-col cols="12">
                    <TableAddFilesProperties
                        :date_file="date_files_update"
                        :savePropertiesFiles="savePropertiesFiles"
                    ></TableAddFilesProperties>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[4].disabled===false">
                <v-col cols="12">
                    <TableFinal
                        :properties_files="properties_files"
                    ></TableFinal>
                </v-col>

            </v-row>
        </div>
        <div class="position-next-page">
            <v-row v-if="items_upload[0].disabled===false">
                <v-col>
                    <v-btn class="btn button-primary" @click="nextFileCategory"
                           :disabled="!disable_next_page">
                        Siguiente
                        <v-progress-circular
                            v-if="state_loading"
                            class="icons-loading ms-4"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[1].disabled===false">
                <v-col cols="12" class="position-btn">
                    <v-btn @click="returnFileCategory" class="btn button-primary btn-category btn-next-category"
                           :disabled="!disable_next_page">Volver
                    </v-btn>
                    <v-btn @click="nextCategoryAttributes" class="btn button-primary btn-category btn-next-category"
                           :disabled="!disable_next_page">
                        Siguiente
                        <v-progress-circular
                            v-if="state_loading"
                            class="icons-loading ms-4"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                    <span v-if="msg_wait_update_files" class="msg-wait"> <v-progress-circular
                        v-if="msg_wait_update_files"
                        class="icons-loading ms-4"
                        indeterminate
                        :size="25"
                        :width="2"
                        color="red"
                    ></v-progress-circular>
                    El siguiente paso está bloqueado hasta que se suban los archivos. <b>Espere, por favor</b></span>
                </v-col>

            </v-row>

            <v-row v-if="items_upload[2].disabled===false">
                <v-col cols="12" class="position-btn">
                    <v-btn @click="returnCategoryAttributes" class="btn button-primary"
                           :disabled="!disable_next_page">Volver
                    </v-btn>
                    <v-btn @click="nextAttributesKeywords" class="btn button-primary" :disabled="!disable_next_page">
                        Siguiente
                        <v-progress-circular
                            v-if="state_loading"
                            class="icons-loading ms-4"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[3].disabled===false">
                <v-col cols="12" class="position-btn">
                    <v-btn @click="returnAttributesKeywords" :disabled="!disable_next_page"
                           class="btn button-primary">Volver
                    </v-btn>
                    <v-btn @click="nextDescriptionFinish" :disabled="!disable_next_page" class="btn button-primary">
                        Siguiente
                        <v-progress-circular
                            v-if="state_loading"
                            class="icons-loading ms-4"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-if="items_upload[4].disabled===false">
                <v-col cols="12" class="position-btn">
                    <v-btn @click="returnDescriptionFinish" :disabled="!disable_next_page" class="btn button-primary">
                        Volver
                    </v-btn>
                    <v-btn @click="finalizeUpload" class="btn button-primary" :disabled="!disable_next_page">Finalizar
                        <v-progress-circular
                            v-if="state_loading"
                            class="icons-loading ms-4"
                            indeterminate
                            :size="20"
                            :width="2"
                            color="white"
                        ></v-progress-circular>
                    </v-btn>
                </v-col>

            </v-row>
        </div>
    </v-container>
</template>

<script>
import {computed, ref, watch} from "vue";
import {
    addPropertyFile,
    createAttributeActive,
    fileInCategories, finalizeUploadWS, getAttributes,
    uploadFile, uploadFilePut, uploadFileValidate,
} from "@/utils/media_valet";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import TableAddFilesCategories from "@/components/MediaValet/TableAddFilesCategories.vue";
import TableAddFilesAttributes from "@/components/MediaValet/TableAddFilesAttributes.vue";
import TableAddFilesProperties from "@/components/MediaValet/TableAddFilesProperties.vue";
import Loading from "@/components/Generic/Loading.vue";
import TableFinal from "@/components/MediaValet/TableFinal.vue";

export default {
    name: "Upload",
    components: {TableFinal, Loading, TableAddFilesProperties, TableAddFilesAttributes, TableAddFilesCategories},
    props: {
        root_category: Object
    },
    setup() {

        let option_menu = ref("Subir Archivos")
        let items_upload = ref([
            {
                title: 'Subir Archivos',
                disabled: false,
            },
            {
                title: 'Añadir categorías',
                disabled: true,
            },
            {
                title: 'Añadir atributos',
                disabled: true,
            },
            {
                title: 'Añadir propiedades',
                disabled: true,
            },
            {
                title: 'Finalizar',
                disabled: true
            },
        ])
        let validate_files_uploads = ref([])
        let active_table = ref(false)
        let all_files_uploads = []
        /*TODO CAMBIAR A TRUE*/
        let disable_next_page = ref(false)
        let date_attribute = ref([])
        let state_loading = ref(false)
        let active_btn_upload_file = ref(true)
        let date_files_update = ref([])
        let file_category_selected = ref([])
        let dataAtributtes;
        let properties_files = ref([])
        let response_upload_files = ref([])
        let btn_upload_files = ref(false)
        let msg_wait_update_files = ref(false)
        let verification_upload_all_files = ref(0)

        watch(verification_upload_all_files, async () => {
            if (verification_upload_all_files.value === validate_files_uploads.value.length) {
                $('.btn-category').show()
                msg_wait_update_files.value = false
            }
        });
        let id_atributte_date

        async function getAtributos() {
            date_attribute.value = []
            let list_required_attribute = []
            let response = await getAttributes()
            if (response.result) {
                response.response.payload.forEach((attribute) => {
                    if (attribute.isRequired) {
                        id_atributte_date = attribute.id
                        list_required_attribute.push(attribute)
                    }
                })
                list_required_attribute.forEach((list) => {
                    list.optionsList.forEach((option) => {
                        date_attribute.value.push(option.optionValue)
                    })
                })
            } else {
                showNotification('error', 'Error al obtener los atributos')
            }

        }

        async function uploadFiles() {
            all_files_uploads = []
            if (validate_files_uploads.value.length !== 0) {
                validate_files_uploads.value.forEach((file) => {
                    let files_option = {
                        'filename': file.name,
                        'fileSizeInBytes': file.size,
                        'type': file.type
                    }
                    all_files_uploads.push(files_option)
                })
                disable_next_page.value = true
                active_table.value = true
            } else {
                showNotification('error', 'Debes seleccionar un archivo')
            }
        }

        async function saveFilesCategories(category_files) {
            if (file_category_selected.value.length === 0) {
                file_category_selected.value.push(category_files)
            } else {
                let index = file_category_selected.value.findIndex((category) => {
                    return category.id_file === category_files.id_file
                })
                if (index !== -1) {
                    file_category_selected.value.forEach((category) => {
                        if (category.id_file === category_files.id_file) {
                            category.id_category = category_files.id_category
                            category.name_category = category_files.name_category
                        }
                    })
                } else {
                    file_category_selected.value.push(category_files)
                }
            }
        }

        async function finalizeUpload() {
            disable_next_page.value = false
            state_loading.value = true
            for (const file of properties_files.value) {
                let response = await finalizeUploadWS(file.id_file)
                if (response.result) {
                    showNotification('success', 'Archivo subido correctamente')
                } else {
                    showNotification('error', 'Error al subir los archivos')
                }
            }
            items_upload.value[4].disabled = true
            file_category_selected.value = []
            btn_upload_files.value = false
            validate_files_uploads.value = []
            state_loading.value = false
            active_table.value = false
            items_upload.value[0].disabled = false

        }

        async function nextFileCategory() {
            btn_upload_files.value = true
            response_upload_files.value = []
            date_files_update.value = []
            if (validate_files_uploads.value.length !== 0) {
                state_loading.value = true
                disable_next_page.value = false
                let response_validate = await uploadFileValidate(all_files_uploads)
                if (response_validate.result) {
                    for (const file_uploads of all_files_uploads) {
                        let response = await uploadFile(file_uploads)
                        if (response.result) {
                            let type = file_uploads.type.split('/')
                            let response_upload_file = {
                                'name_upload': response.response.payload.uploadFileName,
                                'id_upload': response.response.payload.id,
                                'upload_url': response.response.payload.uploadUrl,
                                'size': file_uploads.fileSizeInBytes,
                                'type': type[0]
                            }
                            date_files_update.value.push(response_upload_file)
                            disable_next_page.value = true
                            state_loading.value = false
                            items_upload.value[0].disabled = true
                            items_upload.value[1].disabled = false
                        } else {
                            showNotification('error', 'Error al subir el archivo')
                        }
                    }
                    for (const file of validate_files_uploads.value) {
                        for (const upload_file of date_files_update.value) {
                            if (file.name === upload_file.name_upload) {
                                let response_upload = await uploadFilePut(upload_file.upload_url, file)
                                if (response_upload.result === '201') {
                                    response_upload_files.value.push(response_upload)
                                    verification_upload_all_files.value++
                                }
                                if (response_upload.result === 'error') {
                                    showNotification('error', 'Hay un error al subir los archivos')
                                }
                                if (response_upload.result === 'wait') {
                                    showNotification('error', 'La solicitud esta pendiente')
                                }
                            }

                        }
                    }
                } else {
                    showNotification('error', 'Error al validar los archivos')
                }

            }
        }

        function returnFileCategory() {
            state_loading.value = false
            disable_next_page.value = false
            btn_upload_files.value = false
            file_category_selected.value = []
            items_upload.value[0].disabled = false
            items_upload.value[1].disabled = true
        }


        async function nextCategoryAttributes() {
            msg_wait_update_files.value = false
            state_loading.value = true
            disable_next_page.value = false
            let verification = []
            if (file_category_selected.value.length === date_files_update.value.length) {
                if (date_files_update.value.length === response_upload_files.value.length) {
                    for (const category of file_category_selected.value) {
                        if (category.hasOwnProperty('id_category') && category.id_category.length > 0) {
                            verification.push(category)
                        } else {
                            showNotification('error', 'Todos los archivos deben tener una categoría asignada')
                        }
                    }
                } else {
                    msg_wait_update_files.value = true
                    $('.btn-next-category').hide()
                }

            } else {
                showNotification('error', 'Todos los archivos deben tener una categoría asignada')
            }

            if (verification.length === date_files_update.value.length) {
                for (const category of verification) {
                    await fileInCategories(category.id_file, category.id_category)
                }
                await getAtributos()
                items_upload.value[1].disabled = true
                items_upload.value[2].disabled = false
            }


            state_loading.value = false
            disable_next_page.value = true
        }

        function returnCategoryAttributes() {
            state_loading.value = false
            items_upload.value[1].disabled = false
            items_upload.value[2].disabled = true
        }


        async function nextAttributesKeywords() {
            state_loading.value = true
            disable_next_page.value = false
            if (variableIsDefined(dataAtributtes)) {
                for (const file of dataAtributtes) {
                    let response = await createAttributeActive(id_atributte_date, file.id_upload, file.dateAttribute)
                    if (response.result) {
                        items_upload.value[2].disabled = true
                        items_upload.value[3].disabled = false
                    } else {
                        showNotification('error', 'Error al crear los atributos')
                    }
                }
            } else {
                showNotification('error', 'Debes rellenar el campo de fecha')
            }
            state_loading.value = false
            disable_next_page.value = true

        }

        function returnAttributesKeywords() {
            items_upload.value[2].disabled = false
            items_upload.value[3].disabled = true
        }

        async function nextDescriptionFinish() {
            disable_next_page.value = false
            state_loading.value = true
            for (const file of properties_files.value) {
                let response = await addPropertyFile(
                    file.id_file, file.name, file.title,
                    file.description, file.size)
                if (!response.result) {
                    showNotification('error', 'Error al añadir las propiedades')
                }
            }
            items_upload.value[3].disabled = true
            items_upload.value[4].disabled = false
            state_loading.value = false
            disable_next_page.value = true

        }

        function returnDescriptionFinish() {
            items_upload.value[3].disabled = false
            items_upload.value[4].disabled = true
        }


        function clear_files_update() {
            disable_next_page.value = false
            active_btn_upload_file.value = true
            active_table.value = false
        }

        function getFiles() {
            active_btn_upload_file.value = false
        }

        async function saveDateAttribute(file) {
            dataAtributtes = file
        }

        function savePropertiesFiles(files) {
            properties_files.value = files
        }

        return {
            returnFileCategory,
            uploadFiles,
            nextFileCategory,
            saveFilesCategories,
            finalizeUpload,
            clear_files_update,
            getFiles,
            saveDateAttribute,
            savePropertiesFiles,

            properties_files,
            items_upload,
            option_menu,
            validate_files_uploads,
            active_table,
            date_attribute,
            disable_next_page,
            state_loading,
            active_btn_upload_file,
            date_files_update,
            file_category_selected,
            msg_wait_update_files,
            btn_upload_files,
            verification_upload_all_files,

            /*BUTTON NEXT AND RETURN PAGE MENU*/
            nextCategoryAttributes,
            returnCategoryAttributes,
            nextAttributesKeywords,
            returnAttributesKeywords,
            nextDescriptionFinish,
            returnDescriptionFinish,
        }
    }
}

</script>
<style scoped lang="scss">
@import "@/styles/components/media-valet/upload.scss";
</style>