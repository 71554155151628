<template>
    <BaseModalTemplate
        :title="title_modal"
        :open-close-dialog="displayDialogPortalRedirection"
    >
        <template #body-modal-content>
            <v-row v-if="not_show_data_redirection">
                <v-col cols="12" sm="4">
                    <v-select
                        placeholder="Tipo de redirección"
                        variant="underlined"
                        class="input-error select-primary"
                        v-model="form_data.redirection_type"
                        :items="[301,410]"
                        :error="form_error.redirection_type"
                        hide-details
                        no-data-text="No hay datos disponibles"
                        :disabled="disabled_exits_redirection"
                    ></v-select>
                    <span v-if="form_error.redirection_type"
                          class="input-error msg-error">Tiene que elegir un tipo de redirección.</span>
                </v-col>

                <!--                URL ORIGEN                    -->
                <v-col cols="12" sm="4">
                    <v-text-field
                        class="input-error text-field-primary"
                        placeholder="URL de origen"
                        v-model.trim="form_data.origin_url"
                        variant="underlined"
                        :error="form_error.origin_url"
                        hide-details
                        :disabled="disabled_exits_redirection"
                    >
                    </v-text-field>
                    <span v-if="form_error.origin_url"
                          class="input-error msg-error">{{ error_url_origin }}</span>

                </v-col>
                <!--                URL DESTINO                    -->
                <v-col cols="12" sm="4" v-if="form_data.redirection_type === 301">
                    <v-text-field
                        class="input-error text-field-primary"
                        placeholder="URL de destino"
                        v-model.trim="form_data.destiny_url"
                        variant="underlined"
                        :error="form_error.destiny_url"
                        hide-details
                        :disabled="disabled_exits_redirection"
                    >
                    </v-text-field>
                    <span v-if="form_error.destiny_url"
                          class="input-error msg-error">{{ error_url }}</span>
                </v-col>
            </v-row>
            <v-row>
                <template v-if="form_data.redirection_type === 301">
                    <v-col class="align-self-center" cols="6">
                        <div style="display: inline-block; width: auto ">
                            <v-checkbox
                                label="Redirección a URL absoluta"
                                v-model="is_url_absolute"
                                :true-value="true"
                                :false-value="false"
                                color="blue"
                                hide-details
                            >
                            </v-checkbox>
                        </div>
                    </v-col>
                    <v-col v-if="can_check_force" class="align-self-center" cols="6">
                        <div style="display: inline-block; width: auto ">
                            <v-checkbox
                                label="Aplicar incluso si no existe la URL"
                                v-model="is_selected_force"
                                :true-value="true"
                                :false-value="false"
                                color="blue"
                                hide-details
                            >
                            </v-checkbox>
                        </div>
                    </v-col>
                </template>
                <v-col v-if="!disabled_exits_redirection" class="align-self-center" cols="12">
                    <div style="display: inline-block; width: auto ">
                        <v-checkbox
                            label="Aplicar a multiples portales (se deben seleccionar todos los idiomas y portales para los que se quiere la redirección)"
                            v-model="is_selected_multiportal"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                        >
                        </v-checkbox>
                    </div>

                </v-col>
            </v-row>

            <v-row v-if="not_show_data_redirection && is_selected_multiportal">
                <v-col>
                    <p class="input-title">Selección de portal e idioma</p>
                </v-col>
            </v-row>

            <template v-if="is_selected_multiportal">
                <div id="new_urls" v-for="field in fields.keys()">
                    <RowFormRedirection :portals="portals" :fields="fields" :increaseID="increaseID"
                                        :id="field"
                                        :key="field"
                                        :not_portal_selected="not_portal_selected"></RowFormRedirection>
                </div>
                <span v-if="not_portal_selected" class="input-error msg-error">Debes añadir un portal e idioma para poder crear la redirección </span>
            </template>
            <v-col cols="12" v-if="redirection_warning.length > 0">
                <p class="msg-info" style="font-weight: bold"> AVISO:</p>
                <p v-for="warning in redirection_warning" class="msg-info" style="font-weight: bold">
                    {{ warning }}
                </p>
            </v-col>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col class="text-end" v-if="not_show_data_redirection">
                    <v-btn
                        class="btn button-bg-blue"
                        v-if="!is_selected_multiportal"
                        @click="createRedirection"
                        :loading="loading_create_redirection"
                    >
                        Guardar
                    </v-btn>

                    <v-btn
                        v-else
                        class="btn button-bg-blue"
                        :loading="loading_create_redirection"
                        @click="createRedirectionMassive"
                    >
                        Guardar redirección masiva
                    </v-btn>
                </v-col>
                <v-col v-else>
                    <v-btn class="btn button-bg-blue"
                           @click="displayDialogPortalRedirection"
                    >ACEPTAR
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import store from "@/store";
import {isURLAbsolute, showNotification, variableIsDefined} from "@/utils/aux_functions";
import RowFormRedirection from "@/components/Redirections/RowFormRedirection.vue";
import RowFormUrl from "@/components/UrlList/RowFormUrl.vue";
import {createRedireccionesByURLWS, redirectURLWS} from "@/utils/url_redirections";
import {userHaveAllowedRol} from "@/utils/auth";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {checkURLWS} from "@/utils/editor_course_blog";

export default {
    name: "ModalNewRedirection",
    methods: {variableIsDefined},
    components: {BaseModalTemplate, RowFormUrl, RowFormRedirection},
    props: {
        displayDialogPortalRedirection: Function,
        getPortalRedirections: Function,
        all_redirections: Array,
        redirection_selected: Object,
    },

    setup(props) {
        let fields = ref(new Map())
        let count = 0;

        const portals = computed(() => store.getters.getStoredPortals).value
        let is_selected_multiportal = ref(false)

        let form_data = ref({})
        let json_portals_languages = ""
        let not_show_data_redirection = ref(true)

        let form_error = ref({})
        let error_url = ref('La url de destino no puede estar vacía.')
        let error_url_origin = ref('La url de origen no puede estar vacía.')
        let not_portal_selected = ref(false)
        let redirection_warning = ref([])

        let loading_create_redirection = ref(false)
        let disabled_exits_redirection = ref(false)
        let title_modal = ref('Añadir redirección')

        let can_check_force = ref(false)
        let is_selected_force = ref(false)

        let is_url_absolute = ref(false)


        onBeforeMount(async () => {
            can_check_force.value = userHaveAllowedRol("CAN_FORCE_REDIRECT_URLS")

            fields.value.set(0, '')

            if (variableIsDefined(props.redirection_selected)) {
                disabled_exits_redirection.value = true
                is_selected_multiportal.value = true
                form_data.value.redirection_type = props.redirection_selected.codigo_http
                form_data.value.origin_url = props.redirection_selected.url_origen
                form_data.value.destiny_url = props.redirection_selected.url_destino
                title_modal.value = props.redirection_selected.title_modal
            }
        })

        watch(is_selected_multiportal, async () => {

                if (is_selected_multiportal.value === false) {
                    clearMap()
                } else {
                    redirection_warning.value = []
                }
            },
        );


        /*
       *
       * VALIDA QUE LOS DATOS INTRODUCIDOS
       * EN EL SELECTOR DE TIPO DE REDIRECCIÓN
       *  Y EN LOS DOS INPUT DE URL SEAN CORRECTOS
       *
       * */
        async function validateForm() {
            form_error.value = {};
            let correct = true;
            let destiny_url = form_data.value.destiny_url;

            //La url de origen NUNCA puede estar vacía y tiene que existir en AISEO
            if (!variableIsDefined(form_data.value.origin_url)) {
                form_error.value.origin_url = true;
                correct = false;
                error_url_origin.value = 'La url de origen no puede estar vacía.'
            } else {
                correct = await checkURL('origin_url');
            }

            if (!variableIsDefined(form_data.value.redirection_type)) {
                form_error.value.redirection_type = true;
                correct = false;
            } else {
                if (form_data.value.redirection_type === 301) {

                    if (variableIsDefined(destiny_url)) {
                        if (is_url_absolute.value){  //Si es absoluta
                            correct = isURLAbsolute(destiny_url)
                            if (!correct){
                                form_error.value.destiny_url = true;
                                error_url.value = 'La url de destino no se corresponde con una URL absoluta.'
                            }
                        }else{ //Si es una url relativa
                            if (is_selected_force.value){ //Si el force está marcado
                                //No debe tener estructura de URL absoluta y no tiene que existir en aiseo
                                correct = !isURLAbsolute(destiny_url)
                                if (!correct){
                                    form_error.value.destiny_url = true;
                                    error_url.value = 'La url de destino se corresponde con una URL absoluta.'
                                }
                            }else{//Si el force NO está marcado
                                //Tiene que existir en aiseo (en esta comprobación ya se mira que no tenga estructura de url absoluta)
                                correct = await checkURL('destiny_url');
                                if (!correct){
                                    form_error.value.destiny_url = true;
                                    error_url.value = 'La url de destino no existe en AISEO.'
                                }
                            }
                        }

                    } else {
                        form_error.value.destiny_url = true;
                        error_url.value = 'La url de destino no puede estar vacía.'
                        correct = false;
                    }
                }
            }

            return correct;
        }

        /*
        *
        * COMPRUEBA QUE UNA URL ESTE
        * REGISTRADA EN EL SISTEMA
        *
        * */
        async function checkURL(key) {
            let exist = false;

            if (key === 'origin_url') exist = props.all_redirections.findIndex((element) => element.url_origen === form_data.value[key]) > -1

            if (exist) {
                form_error.value[key] = true;
                error_url_origin.value = 'Ya existe una redirección en este portal para esta url';
                return false
            } else {
                let response = await checkURLWS(store.getters.getPortalID, store.getters.getLanguageID, form_data.value[key], store.getters.getPortalID, store.getters.getLanguageID);

                if (response['result']) {

                    if (response['data'].length === 0) {
                        form_error.value[key] = true;

                        if (key === 'origin_url') {
                            error_url_origin.value = 'La url a redireccionar debe estar registrada en el sistema'
                        } else {
                            error_url.value = 'La url de redirección debe estar registrada en el sistema';
                        }
                        return false
                    } else {
                        return true;
                    }
                } else {
                    showNotification('error', 'Se ha producido un error al comprobar la url')
                }
            }
        }

        /*
        *
        * CREA UNA REDIRECCIÓN A PARTIR DE
        * UNA URL DE ORIGEN, UN COD. HTTP Y
        *  UNA URL DE DESTINO EN CASO DE QUE SEA NECESARIA
        *
        * */
        async function createRedirection() {
            loading_create_redirection.value = true;

            if (await validateForm()) {
                let data = {
                    'redirection_type': form_data.value.redirection_type,
                }
                if (form_data.value.redirection_type === 301) {
                    data['redirection_url'] = form_data.value.destiny_url;
                }

                data['absolute_url'] = is_url_absolute.value ? 1 : 0

                if (is_url_absolute){//Si la url es absoluta, hay que pasar el force siempre a true
                    data['force'] = 1
                }else{
                    data['force'] = is_selected_force.value ? 1 : 0
                }

                let response = await redirectURLWS(data, form_data.value.origin_url);

                if (response['result']) {
                    await props.getPortalRedirections();
                    showNotification('success', 'Redirección creada correctamente');
                    form_data.value = {}
                } else {
                    showNotification('error', response['errors']);
                }

            }
            loading_create_redirection.value = false;
        }

        /*
        *
        * FUNCIONES RELACIONADAS CON LAS REDIRECCIONES MASIVAS
        * */
        async function validateMasiveRedirection() {
            let correct = true
            let portals_languages = []
            not_portal_selected.value = false
            if (!variableIsDefined(props.redirection_selected)) {
                correct = await validateForm()
            }
            if (correct) {
                for (let [key, value] of fields.value.entries()) {
                    if (variableIsDefined(value)) {
                        portals_languages.push(value);
                    }
                }
                if (portals_languages.length > 0) {
                    json_portals_languages = JSON.stringify(portals_languages)
                    return true
                } else {
                    not_portal_selected.value = true
                    return false

                }
            }
        }

        async function createRedirectionMassive() {
            loading_create_redirection.value = true;

            if (await validateMasiveRedirection()) {
                form_data.value['absolute_url'] = is_url_absolute.value ? 1 : 0

                if (is_url_absolute){//Si la url es absoluta, hay que pasar el force siempre a true
                    form_data.value['force'] =  1
                }else{
                    form_data.value['force']  = is_selected_force.value ? 1 : 0
                }

                let response = await createRedireccionesByURLWS(form_data.value, json_portals_languages)
                if (response['result']) {
                    is_selected_multiportal.value = false
                    is_selected_force.value = false
                    form_data.value = {}

                    if (response['data']) {
                        showNotification('success', 'Redirecciones creadas correctamente')
                        if (variableIsDefined(response['debug']['warning'])) {
                            redirection_warning.value = response['debug']['warning']
                        }

                        if (variableIsDefined(props.redirection_selected)) {
                            not_show_data_redirection.value = false
                            props.displayDialogPortalRedirection()
                        }
                    }
                } else {
                    showNotification('error', response['errors'])
                }
            }
            loading_create_redirection.value = false;
        }


        /*FUNCIONES PARA EL MAP USADO CUANDO QUEREMOS HACER REDIRECCIONES EN DIVERSOS PORTALES*/
        const clearMap = () => {
            fields.value.clear()
            fields.value.set(0, '');
            count = 0
        }

        function increaseID() {
            let count_before = count;
            count++;

            return count_before;
        }

        /*
        *
        * FIN FUNCIONES RELACIONADAS CON LAS REDIRECCIONES MASIVAS
        * */
        return {
            createRedirection,
            createRedirectionMassive,
            increaseID,
            can_check_force,
            disabled_exits_redirection,
            error_url,
            error_url_origin,
            fields,
            form_data,
            form_error,
            is_selected_force,
            is_selected_multiportal,
            is_url_absolute,
            loading_create_redirection,
            not_portal_selected,
            not_show_data_redirection,
            portals,
            redirection_warning,
            title_modal,
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/views/redirection.scss";
</style>