<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/folder.svg')" alt="icono de docente no vinculado" width="28" height="28">
                    Docentes sin vincular
                </span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
        <v-row v-if="pending_teachers.length > 0">
            <v-col cols="12">
                <p>A continuación se muestra un listado de docentes importados desde <b>Innotutor</b> y que,
                    actualmente, no se encuentran asignados a ningún portal</p>
            </v-col>
            <v-col cols="12">
                <Vue3EasyDataTable
                    class="primary-table"
                    :headers="header"
                    :items="pending_teachers"
                    :loading="loading_pending"
                    rows-per-page-message="Elementos por página: "
                    rows-of-page-separator-message="de"
                    empty-message="No hay datos disponibles"
                >

                    <template #item-acciones="{id_innotutor, nombre, usuario}">
                        <img :src=" require('@/assets/icons/assign-user.svg')"
                             alt="Icono asignación"
                             id="icon-assing"
                             title="Asignar docente a portal"
                             @click="showForm(nombre, usuario)"
                        >
                    </template>

                </Vue3EasyDataTable>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12">
                <p>No hay docentes pendientes de asignar</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="7" md="8" lg="9" xl="10">
                <span class="main-title-page">Docentes vinculados</span>
            </v-col>
        </v-row>

        <v-row v-if="linked_teachers.length > 0">
            <v-col cols="12">
                <Vue3EasyDataTable
                    class="primary-table"
                    :headers="header"
                    :items="linked_teachers"
                    :loading="loading_linked"
                    rows-per-page-message="Elementos por página: "
                    rows-of-page-separator-message="de"
                    empty-message="No hay datos disponibles"
                >
                    <template #item-acciones="{id_innotutor, nombre}">
                        <img :src=" require('@/assets/icons/eye-simple.svg')"
                             class="w-25"
                             alt="Icono asignación"
                             id="icon-assing"
                             title="Portales vinculados"
                             @click="showPortal(id_innotutor, nombre)"
                        >
                    </template>
                </Vue3EasyDataTable>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12">
                <p>No hay docentes creados</p>
            </v-col>
        </v-row>

        <v-dialog v-model="open_modal">
            <TeachersPortalsModal
                :portals_assigned="portals_assigned"
                :displayDialog="displayDialog"
                :loading="loading_modal"
                :teacher_name="selected_name_teacher"
            >
            </TeachersPortalsModal>
        </v-dialog>

        <v-dialog v-model="open_assing_modal">
            <FormTeacherPortal
                :portals="portals"
                :displayDialog="displayFormDialog"
                :teacher_name="selected_name_teacher"
                :teacher_username="selected_username_teacher"
            >
            </FormTeacherPortal>
        </v-dialog>
        </template>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import {getAllTeachersWS} from "@/utils/teachers";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import TeachersPortalsModal from "@/components/Teachers/TeachersPortalsModal.vue";
import FormTeacherPortal from "@/components/Forms/FormTeacherPortal.vue";
import {useStore} from "vuex";
import FormCreateExternalUser from "@/components/Forms/FormCreateExternalUser.vue";

export default {
    name: "TeachersPendingView",
    methods: {variableIsDefined},
    components: {FormCreateExternalUser, FormTeacherPortal, TeachersPortalsModal, Loading, Vue3EasyDataTable},
    setup() {
        let store = useStore();
        let general_loading = ref(false)
        let loading_pending = ref(false)
        let loading_linked = ref(false)
        let pending_teachers = ref([])
        let linked_teachers = ref([])
        let loading_modal = ref(false);
        let open_modal = ref(false);
        let open_assing_modal = ref(false);
        let linked_teachers_complete = [];
        let portals_assigned = ref([]);
        let selected_name_teacher = ref("");
        let selected_username_teacher = ref("");
        let portals = computed(() => store.getters.getStoredPortals).value

        let header = ref([
            {text: 'ID Innotutor', value: 'id_innotutor', sortable: true},
            {text: 'Nombre', value: 'nombre', sortable: true},
            {text: 'Usuario', value: 'usuario', sortable: true},
            {text: 'Portales', value: 'acciones'},
        ])

        onBeforeMount(async () => {
            general_loading.value = true
            await getAllPendingTeachers();
            await getAllLinkedTeachers();
            general_loading.value = false
        })

        async function getAllPendingTeachers() {
            loading_pending.value = true
            let response = await getAllTeachersWS(0);
            let final_teachers = [];
            if (response['result']) {
                Object.entries(response['data']).forEach(docente => {
                    let teacher_obj = {
                        'nombre': docente[1].datos_innocualificados.nombre_completo,
                        'usuario': docente[1].datos_innocualificados.usuario,
                        'id_innotutor': docente[1].datos_innocualificados.identity_id,
                    }
                    final_teachers.push(teacher_obj)
                })
                pending_teachers.value = final_teachers
            } else {
                showNotification('error', response['errors'])
            }
            loading_pending.value = false;
        }

        async function getAllLinkedTeachers() {
            loading_linked.value = true
            let response = await getAllTeachersWS(1, 0);
            let final_teachers = [];
            if (response['result']) {
                linked_teachers_complete = response['data']
                Object.entries(response['data']).forEach(teacher => {
                    let assigned_portals = [];
                    let teacher_obj = {
                        'nombre': teacher[1].datos_innocualificados.nombre_completo,
                        'usuario': teacher[1].datos_innocualificados.usuario,
                        'id_innotutor': teacher[1].datos_innocualificados.identity_id,
                    }
                    teacher[1].datos_personalizaciones.forEach(personalization => {
                        assigned_portals.push({
                            'portal': personalization.id_portal,
                            'language': personalization.id_idioma
                        })
                    })
                    teacher_obj.portales = assigned_portals
                    final_teachers.push(teacher_obj)
                })
                linked_teachers.value = final_teachers
            } else {
                showNotification('error', response['errors'])
            }
            loading_linked.value = false;
        }

        function showForm(name, username) {
            displayFormDialog();
            selected_name_teacher.value = name
            selected_username_teacher.value = username
        }


        async function showPortal(id_innotutor, teacher_name) {
            selected_name_teacher.value = teacher_name;
            loading_modal.value = true
            portals_assigned.value = [];
            let teacher_portals = [];
            displayDialog();
            linked_teachers.value.forEach(teacher => {
                if (teacher.id_innotutor === id_innotutor) {
                    teacher_portals = teacher.portales
                }
            })
            if (teacher_portals.length > 0) {
                teacher_portals.forEach(portal_ass => {
                    let portal_obj = {}
                    portals.forEach(portal => {
                        if (portal_ass.portal === portal.id_portal) {
                            portal_obj = {
                                'nombre': portal.nombre,
                                'id_portal': portal.id_portal,
                                'idioma': portal.languages[0].nombre
                            }
                        }
                    })
                    if (Object.keys(portal_obj).length > 0) {
                        portals_assigned.value.push(portal_obj)
                    }
                })
            }
            loading_modal.value = false
        }

        function displayDialog() {
            open_modal.value = !open_modal.value
        }

        function displayFormDialog() {
            open_assing_modal.value = !open_assing_modal.value
        }

        return {
            showPortal,
            displayDialog,
            displayFormDialog,
            showForm,

            general_loading,
            selected_name_teacher,
            selected_username_teacher,
            loading_pending,
            open_assing_modal,
            open_modal,
            portals,
            loading_modal,
            loading_linked,
            pending_teachers,
            linked_teachers,
            header,
            portals_assigned,
        }
    }
}
</script>

<style lang="scss">
#icon-assing {
    max-width: 42px;

    &:hover {
        cursor: pointer;
    }
}

.icon-edit{
    &:hover{
        filter: none !important;
        content: url('@/assets/icons/hover-edit.svg');
    }
}

.icon-delete{
    &:hover{
        filter: none !important;
        content: url('@/assets/icons/hover-trash.svg');
    }
}
</style>