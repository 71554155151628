<template>
    <v-row>
        <v-col>
            <v-expansion-panels>
                <v-expansion-panel class="expansion-personalized">
                    <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row>
                            <v-col cols="12" sm="4" md="3" lg="2">
                                <v-text-field
                                    v-model.trim="form_filter.id_portal"
                                    placeholder="ID Portal"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                                <span v-if="form_error.id_portal"
                                      class="input-error msg-error">El ID Portal debe ser un número</span>
                            </v-col>

                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.name"
                                    placeholder="Nombre"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.url"
                                    placeholder="URL"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="2">
                                <v-autocomplete
                                    v-model="form_filter.id_country"
                                    :items="items_countries"
                                    item-title="name_id"
                                    item-value="id_pais"
                                    variant="underlined"
                                    class="select-primary"
                                    placeholder="Pais"
                                    hide-details
                                    :menu-props="{width: '300px', height: '200px'}"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="2">
                                <div style="display: inline-block; width: auto ">
                                    <v-checkbox
                                        label="Activo"
                                        v-model="form_filter.active"
                                        :true-value="1"
                                        :false-value="0"
                                        color="blue"
                                        hide-details
                                    ></v-checkbox>
                                </div>
                            </v-col>

                            <v-col cols="12" sm="4" md="3" class="text-start align-self-center">
                                <div style="display: inline-block; width: auto ">
                                    <v-checkbox label="Búsqueda exacta"
                                                v-model="form_filter.exact_search"
                                                :true-value="true"
                                                :false-value="false"
                                                color="blue"
                                                hide-details></v-checkbox>
                                </div>
                            </v-col>

                            <v-col cols="12" md="6" lg="9" class="text-end align-self-center p-1 sm:p-0">
                                <v-btn class="btn button-bg-blue" :loading="loading_filter.btn_save" @click="applyFilters">
                                    Filtrar
                                </v-btn>

                                <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                     title="Eliminar filtros" class="delete-filter ms-4" @click="clearFilters">
                            </v-col>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "FiltersGetPortal",
    emits: ['applyFilters'],
    props: {
        getFilters: Function,
        item_countries: Array,
        loading_filter: Object,
        dropFilter: Function,
    },

    setup(props) {
        let form_filter = ref({})
        let form_error = ref({})
        let items_countries = ref([])

        onBeforeMount(()=>{
            items_countries.value = props.item_countries.map(country => {
                return {
                    ...country,
                    name_id: `${country.nombre} (${country.id_pais})`
                };
            });
        })

        async function applyFilters() {
            const regex_number = /^\d+$/
            let correct = true
            form_error.value = {}
            if (variableIsDefined(form_filter.value.id_portal)) {
                if (!regex_number.test(form_filter.value.id_portal)) {
                    correct = false
                    form_error.value.id_portal = true
                }
            }
            if (correct) {
                await props.getFilters(form_filter.value)
            }
        }

        async function clearFilters() {
            if (!props.loading_filter.btn_delete){
                form_filter.value = {}
                await props.dropFilter()
            }
        }


        return {
            applyFilters,
            clearFilters,

            form_filter,
            form_error,
            items_countries,

        }
    }
}
</script>


<style scoped lang="scss">
.expansion-personalized {
    border: 1px solid $blue-medium;


    .delete-filter {
        filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
        width: 30px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
        }
    }
}

</style>