<template>
    <BaseModalTemplate
        :title="title_modal"
        :open-close-dialog="displayDialog"
        card_extra_classes="w-75"
        body_extra_classes="new-url-modal"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">URL</span>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        placeholder="Introduce una URL*"
                        v-model="new_url"
                        required
                        hide-details
                        class="input-error text-field-secondary"
                        :error="url_error"
                        bg-color="transparent"
                    ></v-text-field>
                    <span v-if="variableIsDefined(url_error)"
                          class="input-error msg-error">{{ error_message }}</span>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <div class="d-flex">
                <v-btn
                    variant="outlined"
                    @click="displayDialog"
                    class="btn button-secondary"
                    :loading="loading_save"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    variant="outlined"
                    @click="saveNewURL"
                    class="btn button-bg-blue btn-icon"
                    :loading="loading_save"
                    prepend-icon="mdi-check"
                >
                    Aceptar
                </v-btn>
            </div>
        </template>
    </BaseModalTemplate>
</template>

<script>
    import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
    import {variableIsDefined} from "@/utils/aux_functions";
    import {ref} from "vue";

    export default {
        name: "ModalRegisterURL",
        methods: {variableIsDefined},
        components: {BaseModalTemplate},
        props: {
            title_modal: String,
            submitForm: Function,
            displayDialog: Function,
        },
        setup () {
            let new_url = ref()
            let url_error = ref(false)
            let error_message = ref("")
            let loading_save = ref(false)

            const saveNewURL = () => {

                return true;
            }

            return {
                saveNewURL,

                new_url,
                url_error,
                error_message,
                loading_save
            }
        }
    }
</script>

<style lang="scss">
    .new-url-modal {
        min-height: unset !important;
    }

    .title-modal {
        margin-left: 0 !important;
    }
</style>