<template>
    <div class="sui-layout-header edit-sui-layout-header">
        <v-form @submit.prevent="$emit('submit', $event.target.value)">

            <div class="box-search">
                <v-row class="row-text-field">

                    <v-col cols="12" sm=8 md="8" lg="10" xl="10">
                        <v-text-field
                            class="text-field-secondary"
                            placeholder="Te ayudamos a encontrar los mejores cursos, p.e, 'BigData'"
                            @input="$emit('update:modelValue', $event.target.value)"
                            bg-color="white"
                            color="transparent"
                            hide-details

                        ></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="4" lg="2">
                        <v-btn
                            type="submit"
                            class="btn button-primary w-100"
                        >Buscar
                        </v-btn>
                    </v-col>

                </v-row>
            </div>
        </v-form>
        <div class="results-searching">
            <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6"
                       v-if="suggest && modelValue.length >= 3 && suggestions && suggestions.length > 0">
                    <div
                        class="box-related-search">
                        <p class="list list-hide"> Sugerencias: </p>
                        <div class="list list-hide ">
                            <div
                                class="list-items"
                                v-for="suggestion in suggestions"
                                @click="$emit('autoComplete',suggestion.suggestion)"
                            >
                                {{ stripHTML(suggestion.suggestion) }}
                            </div>
                        </div>

                    </div>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="6"
                       v-if="suggest && modelValue.length >= 3 && completions && completions.length > 0">
                    <div
                        class="box-related-search">
                        <p class="list list-hide"> Búsquedas Relacionadas: </p>
                        <div class="list list-hide">
                            <div
                                class="list-items"
                                v-for="suggestion in completions"
                                @click="$emit('autoComplete',suggestion.keyword_objetivo.raw)"
                            >
                                {{ stripHTML(suggestion.keyword_objetivo.raw) }}
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            required: true,
            type: String
        },
        suggestions: {
            required: false,
            type: Array,
            default: () => []
        },
        completions: {
            required: false,
            type: Array,
            default: () => []
        },
        suggest: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue", "autoComplete", "submit"],
    setup(props, ctx) {
        function stripHTML(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        }

        return {
            stripHTML,
        }
    }
};
</script>
<style lang="scss">
@import "@/styles/components/search-engine/search-results.scss";
</style>
