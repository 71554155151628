<template>
    <BaseModalTemplate
        :title="`Detalles del elemento en cola con ID ${log_id}`"
        :open-close-dialog="open_modal_log"
        :loading="loading"
        card_extra_classes="w-100"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <template v-if="redirection_logs.length > 0" v-for="info in redirection_logs">
                <v-row>
                    <v-col cols="12" md="3">
                        <p>
                            <span class="subtitle-secondary">Id Redirección: </span><span>{{ info.id }}</span>
                        </p>
                        <p>
                            <span class="subtitle-secondary">Tipo Redirección: </span><span>{{ redirection_type }}</span>
                        </p>
                        <p>
                            <span class="subtitle-secondary">Id Empleado: </span><span>{{info.id_empleado}}</span>
                        </p>
                        <p>
                            <span class="subtitle-secondary">Fecha: </span><span>{{formatDate(info.created_at)}}</span>
                        </p>
                    </v-col>
                    <v-col cols="12" md="4">
                        <p class="titles">ORIGEN</p>
                        <span class="subtitle-primary">Url: </span> <span>{{ info.url_origen }}</span><br>
                    </v-col>
                    <v-col cols="12" md="5">
                        <p class="titles">DESTINO</p>
                        <span class="subtitle-primary">Url: </span> <span>{{ info.url_destino || "Sin URL" }}</span><br>
                    </v-col>
                    <v-col cols="12" md="5">
                        <p class="titles">Portales en los que se va a aplicar</p>
                        <template v-for="portals in getDestinyPortals(info.originPortalLanguageID)">
                            <p>
                                <span>{{ getNamePortal(portals.originPortal) }} - {{ getNameLanguage(portals.originLangID) }}</span>
                            </p>
                        </template>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                        <span class="subtitle-secondary">Respuesta del servidor: </span><span>{{ info.response }}</span>
                    </v-col>
                </v-row>

                <hr>
            </template>
            <template v-else>
                <span>No hay datos disponibles para este elemento</span>
            </template>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {formatDate, showNotification} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import {getLogElementRedirectionWS} from "@/utils/url_redirections";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalLogRedirection",
    methods: {getNameLanguage, getNamePortal, formatDate},
    components: {BaseModalTemplate},
    props: {
        open_modal_log: Function,
        log_id: Number,
        redirection_type: Number,
    },
    setup(props) {
        const loading = ref(false)
        const redirection_logs = ref([])

        onBeforeMount(async () => {
            loading.value = true
            const response = await getLogElementRedirectionWS(props.log_id)

            if (response['result']){
                redirection_logs.value = response['data']
            }else{
                showNotification('error', response['error'])
            }

            loading.value = false
        })

        const getDestinyPortals = (json_portals) => {
            return JSON.parse(json_portals)
        }

        return {
            getDestinyPortals,

            loading,
            redirection_logs,
        }
    }
}
</script>


<style lang="scss" scoped>
    @import "@/styles/components/modal-log.scss";
</style>
