import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function getLinkBoxesWS(url = ''){
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'originPortal' : store.getters.getPortalID,
        'originLanguageID' : store.getters.getLanguageID,
        'originURL' : url,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/getEnlacesURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/getEnlacesURL');
    }
}

export async function addLinkBoxWS(link_data, url = '') {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'link': link_data.url_enlace,
        'linkText': link_data.texto_enlace,
        'linkImgSrc': link_data.url_imagen,
        'linkStatus': link_data.active,
    }

    let params = {
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'link': link_data.url_enlace,
        'linkText': link_data.texto_enlace,
        'linkImgSrc': link_data.url_imagen,
        'linkStatus': link_data.active,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/createEnlacesURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/createEnlacesURL');
    }
}

export async function deleteLinkBoxWS(box_id, url) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'linkID': box_id,
    }

    let params = {
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'linkID': box_id,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/removeEnlacesURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/removeEnlacesURL');
    }
}

export async function updateLinkBoxWS(link_data, url) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'linkID': link_data.id,
    }

    let params = {
        'portal' : store.getters.getPortalID,
        'languageISO' : store.getters.getLanguageISO,
        'url' : url,
        'linkID': link_data.id,
        'link': link_data.url_enlace ? link_data.url_enlace : null,
        'linkText': link_data.texto_enlace ? link_data.texto_enlace : null,
        'linkImgSrc': link_data.url_imagen ? link_data.url_imagen : null,
        'linkStatus':  link_data.active,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/updateEnlacesURL', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/updateEnlacesURL');
    }
}