<template>
    <v-btn
        @click="confirmationDiscard"
        class="btn button-bg-blue button-action"
    >
        Descartar borrador
    </v-btn>
</template>

<script>
import {discardDraftWS} from "@/utils/courses";
import {showNotification} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {onBeforeMount} from "vue";

export default {
    name: "DiscardDrafts",
    props:{
        url: String,
        draftType: String
    },
    setup(props){
        /*
        *
        * DESCARTAR BORRADORES
        *
        * */

        onBeforeMount(() => {
            initSweetAlert()
        })

        const confirmationDiscard = async () => {
            await fireSweetAlert('Descartar borrador', discardDraft)
        }

        const discardDraft = async () => {
            let response = await discardDraftWS(props.url, props.draftType)
            if (response['result']) {
                location.reload()
                showNotification('success', response['data']['message'])
            } else {
                showNotification('error', response['errors'])
            }
        }

        return{
            confirmationDiscard,
        }
    }
}
</script>

<style scoped>

</style>