<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/course_list_logo.svg')" alt="icono de asignación" width="28" height="28">
                    Asignación de logos y diplomas a cursos
                </span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="text-end">
                <v-btn
                    v-if="show_images"
                    class="btn button-primary"
                    @click="returnToList"
                    :disabled="loading_save"
                >
                    Volver atrás
                </v-btn>
            </v-col>
        </v-row>
        <template v-if="!pending_language">
            <v-row>
                <v-col v-if="!show_images">
                    <FilterCourseListLogo
                        :getDeleteFilter="deleteFilter"
                        :getApplyFilter="getApplyFilter"
                    ></FilterCourseListLogo>
                </v-col>
                <v-col v-else>
                    <span>Elija los logos y diplomas para asignar a los cursos seleccionados</span>
                </v-col>
            </v-row>
            <v-row justify="end" class="mt-2">
              <v-col cols="12" md="9" class="text-end align-self-center">
                <v-btn
                    class="btn button-bg-blue mt-2 mt-sm-0 ms-sm-3"
                    @click="setQueueExcelElement"
                    :disabled="items.length === 0"
                >Exportar listado completo
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!show_images" class="justify-space-between">
                <v-col cols="12" md="3">
                    <v-select
                        class="input-error select-primary"
                        label="Aplicar selección a"
                        :items="available_selections"
                        item-title="type"
                        item-value="id"
                        v-model="selection_type"
                        variant="underlined"
                        return-object
                        hide-details
                        :disabled="loading"
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" md="9" class="align-self-center text-end">
                    <span
                        class="title-count-courses">Cursos seleccionados actualmente: {{ (all_selected && selection_type.id === 'all_pages') ? (server_items_length - excluded_courses.length) : selected_courses.length }} de {{ server_items_length }}</span>
                </v-col>
                <v-col cols="12" class="align-self-center text-end">
                    <v-btn
                        class="btn button-primary large-text"
                        @click="getSelectedUrlToImages"
                        v-if="selected_courses.length > 0"
                    >
                        Asignar logos-diplomas a la selección
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table courses-table"
                        theme-color="#F4A649"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        rowsPerPageOptions="10"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #header-selected_courses="item">
                            <v-checkbox
                                v-model="all_selected"
                                class="select-all-checkbox"
                                color="blue"
                                hide-details
                                @update:modelValue="selectAllCourses"
                                density="compact"
                            ></v-checkbox>
                        </template>
                        <template #item-selected_courses="item">
                            <v-checkbox
                                v-model="selected_courses"
                                :value="item.id_url"
                                @change="selectCourse(item)"
                                color="blue"
                                hide-details
                                density="compact"
                            ></v-checkbox>
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
            <v-row v-else>
                <template v-if="loading_logos_diplomas">
                    <v-row>
                        <v-col class="text-center" cols="12">
                            <div class="general-loading">
                                <Loading class_loading="loading-gif-s" />
                            </div>
                        </v-col>
                    </v-row>
                </template>

                <template v-else>
                    <!-- DIPLOMAS -->
                    <v-col cols="12">
                        <v-expansion-panels>
                            <v-expansion-panel class="expansion-personalized">
                                <v-expansion-panel-title>Diplomas</v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <p><span class="title-section">Disponibles </span><span class="subtitle-section"> (haga click sobre el elemento que desee seleccionar)</span>
                                    </p>
                                    <v-row class="available-image-list personalize-scroll overflow-auto">
                                        <v-col cols="12" sm="6" md="3" v-for="degree in degree_list">
                                            <ImageCard
                                                :disabled_btn_cancel="loading_save"
                                                @click="selectElement(degree, 'degree')"
                                                :image="degree"
                                            ></ImageCard>
                                        </v-col>
                                    </v-row>

                                    <p class="mt-5"><span class="title-section">Seleccionados </span><span
                                        class="subtitle-section"> (arrastre los elementos para ordenarlos)</span></p>
                                    <draggable :list="selected_degrees" group="degrees"
                                               :disabled="loading_save"
                                               class="draggable_list personalize-scroll overflow-auto custom-draggable">
                                        <v-col cols="12" sm="6" md="3" v-for="selected_degree in selected_degrees">
                                            <ImageCard
                                                :show_button_cancel="true"
                                                :discard-element="discardElement"
                                                class="draggable_image"
                                                :image="selected_degree"
                                                :disabled_btn_cancel="loading_save"
                                            ></ImageCard>
                                        </v-col>
                                    </draggable>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>

                    <!-- LOGOS -->
                    <v-col cols="12">
                        <v-expansion-panels>
                            <v-expansion-panel class="expansion-personalized">
                                <v-expansion-panel-title>Logos</v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <p><span class="title-section">Disponibles </span><span class="subtitle-section"> (haga click sobre el elemento que desee seleccionar)</span>
                                    </p>
                                    <v-row class="available-image-list personalize-scroll overflow-auto"
                                           :disabled="loading_save">
                                        <v-col cols="12" sm="6" md="3" v-for="logo in logo_list">
                                            <ImageCard
                                                :disabled_btn_cancel="loading_save"
                                                @click="selectElement(logo, 'logo')"
                                                :image="logo"
                                            ></ImageCard>
                                        </v-col>
                                    </v-row>

                                    <p class="mt-5"><span class="title-section mt-5">Seleccionados </span><span
                                        class="subtitle-section"> (arrastre los elementos para ordenarlos)</span></p>
                                    <draggable :list="selected_logos" group="logos"
                                               :disabled="loading_save"
                                               class="draggable_list personalize-scroll overflow-auto custom-draggable">
                                        <v-col cols="12" sm="6" md="3" v-for="selected_logo in selected_logos">
                                            <ImageCard
                                                :show_button_cancel="true"
                                                :discard-element="discardElement"
                                                class="draggable_image"
                                                :image="selected_logo"
                                                :disabled_btn_cancel="loading_save"
                                            ></ImageCard>
                                        </v-col>
                                    </draggable>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" class="text-center" v-if="empty_list_error">
                        <span class="error-list text-center">El listado de diplomas y logos está vacío</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn
                            class="btn button-bg-blue"
                            @click="changeOrder"
                            :loading="loading_save"
                        >
                            Guardar
                        </v-btn>
                    </v-col>
                </template>

            </v-row>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <span>Selecciona un idioma para continuar.</span>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import FilterUrl from "@/components/UrlList/FilterUrl.vue";
import {useStore} from "vuex";
import {computed, onBeforeMount, ref, watch} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {assignDegreeBulkWS, getCoursesByPortalWS} from "@/utils/courses";
import {VueDraggableNext} from "vue-draggable-next";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import {getImagesByPortalWS} from "@/utils/image_library";
import BaseFilterUrl from "@/components/Generic/BaseFilterUrl.vue";
import FilterCourseListLogo from "@/components/UrlList/FilterCourseListLogo.vue";
import Loading from "@/components/Generic/Loading.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {queueExportCoursesFileWS} from "@/utils/courses";

export default {
    name: "CourseListLogoView",
    methods: {variableIsDefined},
    components: {
        Loading,
        FilterCourseListLogo,
        BaseFilterUrl, ImageCard, draggable: VueDraggableNext, FilterUrl, Vue3EasyDataTable
    },
    setup() {
        const store = useStore()

        let loading = ref(false)
        let loading_save = ref(false)

        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
        })
        let server_items_length = ref(0)

        const headers = ref([
            {text: 'Selección', value: 'selected_courses'},
            {text: 'NOMBRE CURSO', value: 'titulo_curso'},
            {text: 'URL', value: 'identificador_texto_url'},
            {text: 'CÓDIGO INNOTUTOR', value: 'codigo_innotutor'},
            {text: 'ID INNOTUTOR', value: 'id_innotutor'},
            {text: 'ID SIMO', value: 'id_simo'},
            {text: 'TOTAL LOGOS', value: 'count_logos'},
            {text: 'TOTAL DIPLOMAS', value: 'count_diplomas'},

        ])
        let items = ref([])
        let filter_search = ref({})
        let create_redirection_loading = ref(false);

        let language = computed(() => store.getters.getLanguageISO)
        let portals = ref([])
        let pending_language = ref(false)

        let selected_courses = ref([])
        let excluded_courses = ref([])
        let all_selected = ref(false)
        let selection_type = ref({type: 'Todas las páginas', id: 'all_pages'})
        let available_selections = ref([
            {type: 'Todas las páginas', id: 'all_pages'},
            {type: 'Página actual', id: 'selected_page'},
        ])

        /* VARIABLES PARA ASIGNACIÓN DE LOGOS Y DIPLOMAS*/
        let show_images = ref(false)
        let images_data = ref({})

        let available_degree_list = ref()
        let degree_list = ref([])
        let selected_degrees = ref([])

        let available_logo_list = ref()
        let logo_list = ref([])
        let selected_logos = ref([])

        let selected_urls_ids = ref()

        let empty_list_error = ref(false)
        let loading_logos_diplomas = ref(false)

        onBeforeMount(async () => {
          initSweetAlert()
          portals.value = computed(() => store.getters.getStoredPortals).value
            language.value !== 0 ? await getCoursesByPortal() : pending_language.value = true
        });

        watch(server_options, async () => {
                await getCoursesByPortal();
            },
            {deep: true}
        );

        watch(language, async () => {
            items.value = [];
            selected_courses.value = []
            filter_search.value = {}

            pending_language.value = true;
            if (language.value !== 0) {
                pending_language.value = false;
                await getCoursesByPortal();
            }
        });

        watch(selection_type, () => {
            selected_courses.value = []
            all_selected.value = false
        })

        const getCoursesByPortal = async (page = server_options.value.page) => {
            loading.value = true;

            let response_all_urls = await getCoursesByPortalWS(page, server_options.value.rowsPerPage, filter_search.value);
            if (response_all_urls['data'] && response_all_urls['result']) {
                items.value = response_all_urls['data'].data;
                server_items_length.value = response_all_urls['data'].total;

                if (all_selected.value && (selection_type.value.id === 'all_pages')) {
                    /*Si esta puesto el modo select_all en todas las pág,
                     al cambiar de página se activan todos los checkbox
                     por si se quiere excluir algún curso, solo se activan las que
                     no estén en excluidos*/
                    items.value.forEach(course => searchInExcluded(course) === -1 ? selected_courses.value.push(course.id_url) : null)
                } else if (selection_type.value.id === 'selected_page') {
                    /*Si esta puesto el modo select_all pero solo en la pág actual,
                     al cambiar de página se desactiva el checkbox,
                     a no ser que estén todos los elementos de la página seleccionados*/

                    all_selected.value = isAllPageSelected(items.value)
                }
            } else {
                showNotification("error", response_all_urls['errors'])
            }

            loading.value = false
        }

        /*Selecciona todos los cursos*/
        const selectAllCourses = () => {
            if (selection_type.value.id === 'all_pages') {
                selected_courses.value = []
                excluded_courses.value = []
                if (all_selected.value) {
                    items.value.forEach(course => selected_courses.value.push(course.id_url))
                }
            } else if (selection_type.value.id === 'selected_page') {
                if (all_selected.value) {
                    /*CHECK de 0 -> 1: Hay que SELECCIONAR todos los cursos de la página*/
                    items.value.forEach(course => selected_courses.value.push(course.id_url))
                } else {
                    /*CHECK de 1 -> 0: Hay que DESELECCIONAR todos los cursos de la página*/
                    let index = -1
                    items.value.forEach(course => {
                        index = searchInSelected(course)
                        if (index !== -1) {
                            selected_courses.value.splice(index, 1)
                        }
                    })
                }
            }
        }

        /*Deselecciona un curso ya seleccionado para excluirlo cuando
        este marcada la opción de seleccionar todos los cursos de todas las páginas*/
        const selectCourse = (course) => {
            if (all_selected.value && (selection_type.value.id === 'all_pages')) {
                let index = searchInExcluded(course)
                if (index >= 0) {
                    excluded_courses.value.splice(index, 1)
                } else {
                    excluded_courses.value.push(course.id_url)
                }

                if (excluded_courses.value.length === server_items_length.value) {
                    all_selected.value = false
                }
            }
        }

        //Comprueba si todos los elementos de una página están seleccionados
        const isAllPageSelected = (page_elements) => {
            let all_page_selected = true
            page_elements.forEach(course => {
                if (all_page_selected) {
                    if (!selected_courses.value.some(selected_course => selected_course === course.id_url)) {
                        all_page_selected = false
                    }
                }
            })

            return all_page_selected
        }

        /*Busca si un curso esta añadido en el array de excluidos*/
        const searchInExcluded = (course) => {
            return excluded_courses.value.findIndex(course_to_search => course_to_search === course.id_url)
        }
        /*Busca si un curso esta añadido en el array de seleccionados*/
        const searchInSelected = (course) => {
            return selected_courses.value.findIndex(course_to_search => course_to_search === course.id_url)
        }

        /*******************************************************************************************/
        /* FUNCIONES PARA APLICAR LOS FILTROS */

        //HACE UNA BUSQUEDA CON LOS FILTROS SELECCIONADOS
        const getApplyFilter = async (filter) => {
            server_options.value.page = 1
            filter_search.value = filter

            selected_courses.value = []
            all_selected.value = false
            await getCoursesByPortal()
        }

        //BORRA LOS FILTROS Y HACE UNA BUSQUEDA
        const deleteFilter = async (cleanFilter) => {
            server_options.value.page = 1
            selected_courses.value = []
            all_selected.value = false
            filter_search.value = cleanFilter
            await getCoursesByPortal()
        }

        /*******************************************************************************************/
        /*FUNCIONES PARA ASIGNAR LAS IMAGENES*/
        const getSelectedUrlToImages = async () => {
            selected_urls_ids.value = selected_courses.value.map(({id_url}) => id_url)
            loading_logos_diplomas.value = true
            /*CARGAR LOS LOGOS Y DIPLOMAS*/
            show_images.value = true

            if (degree_list.value.length === 0) {
                let response = await getImagesByPortalWS('Diplomas', store.getters.getPortalID)
                if (response['result']) {
                    available_degree_list.value = response['data'].filter(element => element.activo)
                    degree_list.value = Array.from(available_degree_list.value)
                } else {
                    showNotification('error', response['errors'])
                }
            }

            if (logo_list.value.length === 0) {
                let response = await getImagesByPortalWS('Logos', store.getters.getPortalID)
                if (response['result']) {
                    available_logo_list.value = response['data'].filter(element => element.activo)
                    logo_list.value = Array.from(available_logo_list.value)
                } else {
                    showNotification('error', response['errors'])
                }
            }
            loading_logos_diplomas.value = false
        }
        async function setQueueExcelElement() {
          await fireSweetAlert('Exportar listado completo', queueExportFile, 'Se enviará el fichero por correo al finalizar el proceso de creación')
        }

        //funcion para encolar un elemento a la cola de exportacion de ficheros, en la pantalla logos_diplomas
        async function queueExportFile() {

          create_redirection_loading.value = true
          let response = await queueExportCoursesFileWS();

          if (response['result']) {
            showNotification('success', 'Proceso encolado con éxito')
          } else {
            showNotification('error', response['errors'])
          }
          create_redirection_loading.value = false
        }
        /*
        * MODIFICA LA LISTA DE LOS PESOS CORRESPONDIENTE
        * PARA ACTUALIZAR degree_data DESPUÉS DE MOVER
        * UN ELEMENTO
        * */
        const changeOrder = async () => {
            let weight_list = []
            let assign_type = 'urls';
            loading_save.value = true

            if (all_selected.value && (selection_type.value.id === 'all_pages')) {
                assign_type = 'filters'
            }

            images_data.value['diplomas_list'] = [];
            images_data.value['logos_list'] = [];

            selected_degrees.value.forEach((diploma, index) => {
                weight_list[diploma.id_diploma] = (selected_degrees.value.length - 1) - index;
                images_data.value['diplomas_list'].push(diploma.id_diploma)
            });
            images_data.value['degrees_weight'] = weight_list;

            weight_list = []
            selected_logos.value.forEach((logo, index) => {
                weight_list[logo.id_logo] = (selected_logos.value.length - 1) - index
                images_data.value['logos_list'].push(logo.id_logo)
            });

            images_data.value['logos_weight'] = weight_list;

            if ((images_data.value['diplomas_list'].length !== 0) || (images_data.value['logos_list'].length !== 0)) {
                empty_list_error.value = false
                const response = await assignDegreeBulkWS(assign_type, images_data.value, {
                    filters: filter_search.value,
                    excluded_courses: excluded_courses.value,
                    selected_urls: selected_courses.value
                })

                if (response['result']) {
                    /*LIMPIA LA PANTALLA Y VUELVE ATRÁS*/

                    selected_courses.value = []
                    excluded_courses.value = []
                    await deleteFilter({})
                    returnToList()

                    showNotification('success', response['data']['message'])
                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                empty_list_error.value = true
            }
            loading_save.value = false
        }

        /* SELECCIÓN DE IMAGENES HACIENDO CLICK SOBRE ELLAS*/
        const selectElement = (element, type) => {
            let index
            switch (type) {
                case 'degree':
                    index = degree_list.value.findIndex(degree => degree.id_diploma === element.id_diploma)
                    selected_degrees.value.push(degree_list.value[index])
                    degree_list.value.splice(index, 1)
                    break;

                case 'logo':
                    index = logo_list.value.findIndex(logo => logo.id_logo === element.id_logo)
                    selected_logos.value.push(logo_list.value[index])
                    logo_list.value.splice(index, 1)
                    break;
            }
        }

        /*ELIMINA DE SELECCIONADOS UN LOGO O DIPLOMA*/
        const discardElement = (element) => {
            let index

            if (variableIsDefined(element.id_logo)) {
                index = selected_logos.value.findIndex(logo => logo.id_logo === element.id_logo)
                logo_list.value.push(selected_logos.value[index])
                selected_logos.value.splice(index, 1)
            } else if (variableIsDefined(element.id_diploma)) {
                index = selected_degrees.value.findIndex(degree => degree.id_diploma === element.id_diploma)
                degree_list.value.push(selected_degrees.value[index])
                selected_degrees.value.splice(index, 1)
            }
        }

        /* VUELVE DE LA PANTALLA DE AÑADIR LOGOS A LA DEL LISTADO DE URL*/
        const returnToList = () => {
            show_images.value = false

            selected_degrees.value = []
            selected_logos.value = []

            logo_list.value = Array.from(available_logo_list.value)
            degree_list.value = Array.from(available_degree_list.value)
        }


        return {
            changeOrder,
            deleteFilter,
            discardElement,
            getApplyFilter,
            getSelectedUrlToImages,
            returnToList,
            selectAllCourses,
            selectCourse,
            selectElement,
            setQueueExcelElement,

            all_selected,
            available_selections,
            create_redirection_loading,
            degree_list,
            empty_list_error,
            excluded_courses,
            filter_search,
            headers,
            items,
            loading,
            loading_save,
            logo_list,
            pending_language,
            portals,
            selected_courses,
            selected_degrees,
            selected_logos,
            selection_type,
            server_items_length,
            server_options,
            show_images,
            loading_logos_diplomas,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/inputs.scss";
.draggable_list {
    border-top: 1px solid $blue-medium;
    min-height: 10vh;
    max-height: 50vh;
    overflow: scroll;
}

.large-text {
    white-space: unset;
}

.courses-table {
    th:nth-child(2) {
        width: 30vw;
    }
}

.available-image-list {
    max-height: 50vh;
    overflow: scroll;
}

.title-count-courses {
    font-style: italic;
    font-size: 14px;
}

.error-list {
    @extend .input-error;
    @extend .msg-error;
    float: none !important;
}
.custom-draggable {
    display: flex;
    flex-wrap: wrap;
}
</style>