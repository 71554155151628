<template>
    <BaseModalTemplate
        card_extra_classes="modal-file"
        card_id="card-options-category"
        body_extra_classes="view_info_refresh"
        :card_disabled="disabled_btn"
    >
        <template #modal-header>
            <span class="title-modal">Opciones Categorías</span>
            <v-btn class="btn-close-modal block-btn-close" @click="displayModalNewCategory" :disabled="block_btn_close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <template #body-modal-content>
            <v-row>
                <v-col cols="12"><span>Selecciona una categoría:  </span></v-col>
                <v-col cols="12">
                    <ul class="box-ul">
                        <li :class="main_category.id" class="click-category">
                            <img src="@/assets/gifs/example_loading.gif" alt="loading"
                                 class="gif-loading-add-category">
                            <img src="@/assets/icons/plus_circle.svg" alt="Icono" class="icon-plus-add-category"
                                 @click="getSubcategories(main_category.id)">
                            <img src="@/assets/icons/folder-close.svg" alt="Icono" class="icon-file">
                            <span class="primary-category"
                                  @click="createSubcategories">{{ main_category.name }} </span>
                        </li>
                    </ul>
                </v-col>
            </v-row>
            <v-row v-if="option_category_selected">
                <v-col>
                        <span class="title-category-select">Categoria seleccionada: <b
                            class="ms-2">{{ name_category_selected }}</b></span>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="active_ruta">
                    <span>Ruta: <b class="ms-2">{{ ruta }}</b></span>
                </v-col>
            </v-row>
            <v-row v-if="option_category_selected">
                <v-col>
                    <v-select class="select-primary" variant="underlined"
                              :items="options_category"
                              v-model="select_option"
                              @update:model-value="option_selected"
                              hide-details
                              no-data-text="No hay datos disponibles"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="align-items-center" v-if="variableIsDefined(select_option)">
                <v-col cols="3">
                    <span>{{ title_text_name }} </span>
                </v-col>
                <v-col cols="9">
                    <v-text-field class="text-field-primary"
                                  variant="underlined" hide-details
                                  v-model="new_name_category">
                    </v-text-field>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row class="view_info_refresh">
                <v-col>
                    <v-btn class="btn button-bg-blue" @click="createCategoryWS" v-if="select_option==='Crear'">
                        CREAR
                    </v-btn>
                    <v-btn class="btn button-bg-blue" @click="updateCategoryWS" v-if="select_option==='Editar'">
                        EDITAR
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {ref} from "vue";
import {
    createCategory,
    detailsCategory, closeChildren,
    recoverCategoryGeneral,
    updateCategory
} from "@/utils/media_valet";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: 'AddNewCategory',
    methods: {variableIsDefined},
    components: {BaseModalTemplate, Loading},
    props: {
        category_main: Object,
        displayModalNewCategory: Function,
        field_browse: Map
    },
    setup(props) {
        let main_category = ref(props.category_main)
        let fields = ref(new Map());
        let map_close;
        let ruta = ref("");
        let active_ruta = ref(false);
        let title_text_name = ref("");
        let active = false;
        let disable = false
        let options_category = ref()
        let select_option = ref("");
        let option_category_selected = ref(false);
        let name_category_selected = ref();
        let new_name_category = ref("")
        let disabled_btn = ref(false)
        let block_btn = ref(false)
        let block_btn_close = ref(false)
        let id_category_select

        function option_selected() {
            if (select_option.value === 'Editar') {
                title_text_name.value = "Editar nombre de la categoría"
                new_name_category.value = name_category_selected.value;
            } else if (select_option.value === 'Crear') {
                title_text_name.value = "Nombre de la categoría"
                new_name_category.value = ""
            }
        }

        async function getSubcategories(id_category) {
            if (!disable) {
                $('.icon-plus-add-category').hide()
                $('.gif-loading-add-category').show()
                disable = true
                if (!active) {
                    active = true
                    block_btn_close.value = true
                    await recoverCategoryGeneral(id_category, main_category.value, fields.value, nameCategory, document.getElementById("card-options-category"), 1, null, null, document.getElementsByClassName(null))
                    block_btn_close.value = false
                } else {
                    closeChildren(main_category.value.id, fields.value, 1)
                    active = false
                }
                disable = false
                $('.gif-loading-add-category').hide()
                $('.icon-plus-add-category').show()
            }
        }

        async function nameCategory(id_category, ruta_path, map, parent_id_selected, name_category) {
            option_category_selected.value = true
            name_category_selected.value = name_category
            id_category_select = id_category
            map_close = map
            ruta.value = ruta_path
            active_ruta.value = true
            if (id_category_select !== main_category.value.id) {
                options_category.value = ['Crear', 'Editar']
                if (select_option.value==='Editar'){
                    new_name_category.value = name_category_selected.value;
                }else{
                    new_name_category.value = "";
                }
            }
        }


        async function createCategoryWS() {
            $('.view_info_refresh').hide()
            $('.view_loading_refresh').show()

            disabled_btn.value = true;
            let response = await createCategory(id_category_select, new_name_category.value)
            if (response.result) {
                let new_category = response.response.payload.id
                if (id_category_select !== main_category.value.id) {
                    block_btn.value = true
                    await refreshCategories(new_category)
                } else {
                    await getSubcategories(main_category.value.id)
                    await getSubcategories(main_category.value.id)
                }
                showNotification('success', 'Categoría creada correctamente')
            } else {
                showNotification('error', 'Error al crear la categoría')
            }
            disabled_btn.value = false;
            $('.view_loading_refresh').hide()
            $('.view_info_refresh').show()
        }

        async function updateCategoryWS() {
            disabled_btn.value = true;
            if (id_category_select !== main_category.value.id) {
                $('.view_info_refresh').hide()
                $('.view_loading_refresh').show()
                let response = await updateCategory(id_category_select, new_name_category.value)
                if (response.result) {
                    await refreshCategories(id_category_select)
                    showNotification('success', 'Se ha editado la categoría correctamente')
                } else {
                    showNotification('error', 'Error al editar la categoría')
                }
                select_option.value = ""
                title_text_name.value = ""
                $('.view_loading_refresh').hide()
                $('.view_info_refresh').show()
            } else {
                showNotification('error', 'No se puede editar el nombre de la categoría principal')
            }
            disabled_btn.value = false;

        }

        async function refreshCategories(id_category) {
            let first_parent, second_parent, third_parent
            let first_response = await detailsCategory(id_category)
            first_parent = first_response.payload.parentId
            if (first_parent !== main_category.value.id) {
                let second_response = await detailsCategory(first_parent)
                second_parent = second_response.payload.parentId

                if (second_parent !== main_category.value.id) {
                    let third_response = await detailsCategory(second_parent)
                    third_parent = third_response.payload.parentId

                    closeChildren(second_parent, map_close, 1)
                    await recoverCategoryGeneral(second_parent, third_parent, map_close, nameCategory, document.getElementById("card-options-category"), 0, null, null, null, true)
                    await recoverCategoryGeneral(first_parent, second_parent, map_close, nameCategory, document.getElementById("card-options-category"), 0, null, null, null, true)
                }
            } else {
                closeChildren(first_parent, map_close, 1)
                await recoverCategoryGeneral(main_category.value.id, main_category.value, fields.value, nameCategory, document.getElementById("card-options-category"), 1, null, null, document.getElementsByClassName(null))

                // await recoverCategoryGeneral(first_parent,main_category, map_close, nameCategory, document.getElementById("card-options-category"), 0, null, null, null, true)
            }
        }

        async function createSubcategories() {
            options_category.value = ['Crear']
            option_category_selected.value = true
            id_category_select = main_category.value.id
            name_category_selected.value = main_category.value.name
            ruta.value = "\\Library"
            active_ruta.value = true
            select_option.value='Crear'
            new_name_category.value = "";
        }

        return {
            getSubcategories,
            createCategoryWS,
            updateCategoryWS,
            option_selected,
            createSubcategories,

            new_name_category,
            main_category,
            options_category,
            select_option,
            option_category_selected,
            ruta,
            active_ruta,
            title_text_name,
            name_category_selected,
            disabled_btn,
            block_btn,
            block_btn_close,
        }
    }
}
</script>


<style scoped lang="scss">
.gif-loading-add-category {
    display: none;
    width: 16px;
    margin-right: 5px;
}

.title-category-select {
    font-size: 14px;
}

.icon-plus-add-category {
    cursor: pointer;
    width: 15px;
    height: auto;
    margin-right: 6px;
}

.icon-file {
    cursor: auto;
    width: 15px;
    height: auto;
    margin-right: 6px;
}

.primary-category {
    cursor: pointer;
}

.view_loading_refresh {
    display: none;
}

</style>