<template>
    <BaseModalTemplate
        :title="title_modal"
        :open-close-dialog="displayDialog"
        card_extra_classes="w-75"
        :send-form="submitForm"
    >
        <template #body-modal-content>
                <v-row>
                    <v-col cols="12">
                        <span class="input-title">Imagen *</span>
                    </v-col>
                    <v-col cols="12">
                        <v-img class="preview-image" v-if="preview" :src="preview"></v-img>
                        <v-file-input
                            accept="image/*"
                            prepend-icon="mdi-camera"
                            v-model="form_data.new_image"
                            type="file"
                            label="Ingrese nueva imagen"
                            hide-details="auto"
                            variant="underlined"
                            chips
                            show-size
                            :rules="file_rules"
                            class="file-input-primary underlined"
                            :class="{error :form_error.new_image}"
                            @change="previewImage(form_data.new_image)"
                            @click:clear="clearImage"
                        ></v-file-input>
                        <span v-if="variableIsDefined(form_error.new_image)"
                              class="input-error msg-error">Debe añadir una imagen</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <span class="input-title">Nombre *</span>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            placeholder="Nombre de la imagen"
                            v-model="form_data.image_name"
                            required
                            variant="underlined"
                            hide-details
                            class="input-error text-field-primary"
                            :class="{error :form_error.image_name}"
                        ></v-text-field>
                        <span v-if="variableIsDefined(form_error.image_name)"
                              class="input-error msg-error">{{ form_error.image_name }}</span>
                    </v-col>
                </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12" md="2" xl="2">
                    <v-btn
                        variant="outlined"
                        @click="submitForm"
                        class="btn button-bg-blue"
                        :loading="loading_save"
                    >
                        Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeUnmount, ref} from "vue";
import {uploadFileWS} from "@/utils/image_library";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import * as Yup from "yup";

export default {
    name: "FormImage",
    methods: {variableIsDefined},
    components: {BaseModalTemplate},
    props: {
        displayDialog: Function,
        getImagesByPortal: Function,
        clearData: Function,
        image: Object,
        portal_data: Object,
        img_entity: Object,
        title_modal: String,
    },

    setup(props) {
        let image_name = ref('');
        let preview = ref('');

        let form_data = ref({})
        let form_error = ref({});

        let loading_save = ref(false)

        let file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']


        onBeforeUnmount(() => {
            props.clearData();
        });


        //PREVISUALIZACIÓN DE LA IMAGEN
        function previewImage() {
            let reader = new FileReader();
            let file = form_data.value.new_image

            if (variableIsDefined(file)) {
                reader.readAsDataURL(file);

                reader.onload = e => {
                    preview.value = e.target.result;
                }
            }
        }

        function clearImage() {
            preview.value = ""
            form_data.value.new_image = null;
        }

        //ENVIO Y VALIDACIÓN DE FORMULARIOS
        async function submitForm() {
            if (await validateFormData()) {
                loading_save.value = true

                let response = await uploadFileWS(form_data.value, props.img_entity.related_entity);
                if(response['status'] === 200){
                    if(response['data']['data']['success']){
                        showNotification('success', 'Imagen subida correctamente')
                    }else{
                        showNotification('error', 'Error al subir la imagen')
                    }
                }else{
                    showNotification('error', 'Error al hacer la petición: files/uploadFile')
                }

                loading_save.value = false
                props.displayDialog();
                props.getImagesByPortal()
            }
        }

        async function validateFormData() {
            let correct = true;
            form_error.value = {};

            const schemaForm = Yup.object().shape({
                new_image: Yup.mixed().required(),
                image_name: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, {
                    message: 'El nombre no puede contener espacios',
                    nullable: false
                }).required('El nombre de la imagen es obligatorio')
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                correct = true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                    correct = false
                })
            }
            return correct;
        }

        return {
            submitForm,
            previewImage,
            clearImage,

            form_data,
            file_rules,
            image_name,
            form_error,
            preview,
            loading_save
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/images-library.scss";
</style>