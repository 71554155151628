<template>
    <v-btn class="btn btn-danger button-action"
           @click="displayDialogConfirmationMessage">Eliminar
        página
        SEO
    </v-btn>
</template>

<script>
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {onBeforeMount} from "vue";
import {deletePageSEOWS} from "@/utils/editor_course_blog";
import {showNotification} from "@/utils/aux_functions";
import {useRouter} from "vue-router";


export default {
    name: "DeletePage",
    props: {
        form_data_url: String
    },
    setup(props) {
        const router = useRouter();


        onBeforeMount(() => {
            initSweetAlert()
        })

        function displayDialogConfirmationMessage() {
            fireSweetAlert("Eliminar páginas SEO", deletePageSEO)
        }

        async function deletePageSEO() {
            let response = await deletePageSEOWS(props.form_data_url)
            if (response['result']) {
                showNotification('success', 'Página eliminada correctamente. Redirigiendo al listado general...')
                setTimeout(() => {
                    router.push({name: 'listado-urls'})
                }, 2000)
            } else {
                showNotification('error', response['errors'])
                setTimeout(() => {
                    router.push({name: 'listado-urls'})
                }, 2000)
            }
            //Llamar al ws final de delete página seo
        }

        return {
            displayDialogConfirmationMessage,
        }
    }
}
</script>


<style scoped lang="scss">

</style>