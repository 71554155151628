<template>
    <div class="sui-layout">
        <div v-if="searchState.wasSearched" class="sui-layout-body edit-sui-layout-body">
            <v-row>
                <v-col cols="12" class="col-search-bar">
                    <SearchHeader
                            ref="header"
                            v-model="searchInputValue" @submit="handleFormSubmit"
                            :suggest="suggest"
                            :suggestions="searchState.autocompletedSuggestions.documents"
                            :completions="searchState.autocompletedResults"
                            @autoComplete="handleAutoCompleteSelection"
                            v-on:input="debounceFilter(() => {handleAutoCompleteAction()})"
                    />
                </v-col>
            </v-row>
            <v-row>
                <div class="sui-layout-body__inner edit-sui-layout-body__inner">
                    <v-col cols="2" class="col-filters" style="min-width: 150px">
                        <div class="sui-layout-sidebar edit-sui-layout-sidebar">
                          <SearchSort v-show="thereAreResults()" v-model="sortBy"
                            :options="sortOptions"
                          />
                            <div class="sui-sorting__label mt-2">Precio entre:</div>
                                <v-range-slider
                                    class="mt-6 mr-5"
                                    v-model="slprecio"
                                    step="1"
                                    :model-value="slprecio"
                                    :max=slpreciomax
                                    thumb-label="always"
                                    thumb-size="12"
                                    color="black"
                                    min="0"
                                    @update:modelValue="$event => handleSliderRangeChange('slprecio', 'precio')"
                                ></v-range-slider>
                            <SearchFacet
                                nombre="Precios"
                                ref="precioComponentRef"
                                :checked="precio"
                                :facet="searchState.facets.precio[0]"
                                @change="$event => handleFacetChange($event, 'precio')"
                            />
                            <SearchFacet
                                nombre="Programa Formativo"
                                :checked="campanya"
                                :facet="searchState.facets.campanya[0]"
                                @change="$event => handleFacetChange($event, 'campanya')"
                            />
                            <SearchFacet
                                nombre="Idioma"
                                :checked="idioma"
                                :facet="searchState.facets.idioma[0]"
                                @change="$event => handleFacetChange($event, 'idioma')"
                            />
                            <SearchFacet
                                    nombre="Tipo de Curso"
                                    :checked="tipo_de_curso"
                                    :facet="searchState.facets.tipo_de_curso[0]"
                                    @change="$event => handleFacetChange($event, 'tipo_de_curso')"
                            />
                            <SearchFacet
                                    nombre="Nombre vertical"
                                    :checked="nombre_vertical"
                                    :facet="searchState.facets.nombre_vertical[0]"
                                    @change="$event => handleFacetChange($event, 'nombre_vertical')"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" md="10" class="min-vw-10 min-vh-10">
                        <div class="sui-layout-main edit-sui-layout-main" >
                            <div class="sui-layout-main-header">
                                <div class="sui-layout-main-header__inner">
                                    <SearchPagingInfo :search-state="searchState"/>
                                    <SearchResultsPerPage
                                            v-show="thereAreResults"
                                            v-model.number="resultsPerPage"
                                    />
                                </div>
                            </div>

                            <div class="sui-layout-main-body mt-5">
                                <SearchResults
                                        v-show="thereAreResults"
                                        :results="searchState.results"
                                />
                            </div>

                            <div class="sui-layout-main-footer">
                                <SearchPagination
                                        v-show="thereAreResults"
                                        :total-pages="Math.min(searchState.totalPages, 100)"
                                        :click-handler="setCurrentPage"
                                />
                            </div>
                        </div>
                    </v-col>

                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue"
import {SearchDriver} from "@elastic/search-ui";
import {getAppSearchConfig, createDebounce} from "@/utils/search_config";
import SearchResults from "./SearchResults.vue";
import SearchFacet from "./SearchFacet.vue";
import SearchHeader from "./SearchHeader.vue";
import SearchPagingInfo from "./SearchPagingInfo.vue";
import SearchPagination from "./SearchPagination.vue";
import SearchSort from "./SearchSort.vue";
import SearchResultsPerPage from "./SearchResultsPerPage.vue";

export default {
    components: {
        SearchResults,
        SearchFacet,
        SearchHeader,
        SearchPagingInfo,
        SearchPagination,
        SearchSort,
        SearchResultsPerPage
    },

    props : {
        engineSuffix : {
            type: String,
            request: true
        },
        engineType: {
            type: String,
            request: true
        }
    },

    setup(props, ctx) {
        let debounce = ref(null);
        let searchInputValue = ref("");
        let searchState = ref({});
        let idioma = [];
        let campanya = [];
        let driver = new SearchDriver(getAppSearchConfig(props.engineType, props.engineSuffix));
        // Datos del slider
        let slpreciomax = 5000
        let slpreciodefault = [0,slpreciomax]
        let slprecio = ref(slpreciodefault);
        let precio = ref([]);
        // Referencia necesaria
        let precioComponentRef = ref(null);
        let nombre_vertical = [];
        let tipo_de_curso = [];
        let resultsPerPage = ref(10);
        let sortBy = ref("relevancia");
        const sortOptions = [
          {"id": 1, "value": "", "name": "Relevancia"},
          {"id": 2, "value": "precio", "name": "Precio"},
          {"id": 3, "value": "precio_bonificado", "name": "Precio Bonificado"},
          {"id": 4, "value": "fecha_creacion", "name": "Fecha Creacion"},
          {"id": 5, "value": "creditos_cfc", "name": "Créditos CFC"},
          {"id": 6, "value": "creditos_ects", "name": "Créditos ECTS"},
        ];
        let suggest = ref(false);
        // Reference to the SearchHeader component;
        const header = ref(null);

        function handleFormSubmit() {
            // Ask Elastic to change the query term and refresh the results list.
            suggest.value = false;
            driver.getActions().setSearchTerm(searchInputValue.value);
        }

        function handleAutoCompleteAction() {
            /*
            Requests to Elastic to change the query term without refreshing the result list but rather to populate
            Suggestions and Completions Lists
             */
            suggest.value = true;
            if (driver.getState().searchTerm != searchInputValue.value) {
              driver.getActions().setSearchTerm(searchInputValue.value, {
                  autocompleteResults: true,
                  refresh: false,
                  autocompleteSuggestions: true
              })
            }
        }

        function handleAutoCompleteSelection(value) {
            if (value){
                // We refer to the SearchHeader component with the ref variable "header" in order to call it's function.
                searchInputValue.value = header.value.stripHTML(value);
            }
            if (value != driver.getState().searchTerm){
              suggest.value = false;
              driver.getActions().setSearchTerm(searchInputValue.value);
            }
        }

        function handleFacetChange(event, facet) {
            /*
            Reads the event that causes a facet(filter) change in order to update Elastic Search Connection.
            In here we must either update and activate a filter or eliminate such.
             */
            const {value, checked} = event.target;
            const facetFromDriver = driver.getState().facets[facet][0];
            const valueforApi =
                facetFromDriver.type === "range"
                    ? facetFromDriver.data.find((item) => item.value.name === value).value
                    : value;
            if (checked && this[facet].indexOf(value)===-1) {
                // Range filter: {to: upper_bound, from: lower_bound, name: ""}
                driver.removeFilter(facet, {'name': 'dynamic'});
                this[facet].push(value);
                driver.addFilter(facet, valueforApi, "any");
            } else if (!checked) {
                const index = this[facet].indexOf(value);
                if (index > -1) {
                    this[facet].splice(index, 1);
                }
                driver.removeFilter(facet, valueforApi, "any");
            }

            // Si la faceta existe en version slider
            if (this["sl"+facet]) {
                // Restablecemos los valores de los sliders al valor por defecto
                this["sl" + facet] = this["sl" + facet + "default"]
            }
        }

        function handleSliderRangeChange(varname, facet) {
            const variable = this[varname];
            // Si existe la componente de la faceta que no sea dinámica:
            if (this[facet+"ComponentRef"])
                this[facet] = []
            let thresh = 100
            if((variable[1] - variable[0]) >= thresh){
                clearTimeout(debounce.value);
                debounce.value = setTimeout(() => {
                    // Add new filter
                    driver.removeFilter(facet)
                    if(variable[1] < this[varname+"max"]) {
                        driver.addFilter(facet, {"from": variable[0], "to": variable[1], "name": "dynamic"}, 'any')
                    }
                    else
                    {
                        driver.addFilter(facet, {"from": variable[0], "name": "dynamic"}, 'any')
                    }
                }, 800)
            }else{
                this[varname] = [Math.max(thresh,variable[1]-thresh),variable[1]]
            }
        }

        function setCurrentPage(page) {
            driver.setCurrent(page);
        }

        function thereAreResults() {
            return searchState.value.totalResults && searchState.value.totalResults > 0;
        }

        watch(resultsPerPage, async (newResultsPerPage, oldResultsPerPage) => {
            resultsPerPage.value = newResultsPerPage;
            driver.setResultsPerPage(newResultsPerPage);
        });

        watch(sortBy, async (newSortBy, oldSortBy) => {
            sortBy.value = newSortBy;
            driver.setSort(newSortBy, "asc");
        });

        function setResultsPerPageValue(value) {
            resultsPerPage.value = value;
        }

        onMounted(() => {
            // Ask Elastic about the actual state of the connection and update our internal variables.
            const {searchTerm, sortField, resultsPerPage, filters, facets} =
                driver.getState();

            // restoring UI from url query
            searchInputValue.value = searchTerm;
            sortBy.value = sortField;
            setResultsPerPageValue(resultsPerPage)

            // Ask ES about all the active facets and read our internal variables (with the same name) to update their values.
            // More information about the active facets in the current connection can be found at search_config.js
            filters.forEach((filter) => {
                if (facets[filter.field] && facets[filter.field][0].type === "range") {
                    this[filter.field] = filter.values.map((value) => value.name);
                } else if (facets[filter.field]) {
                    this[filter.field] = filter.values;
                }
            });

            // Tell Elastic to send State Updates callbacks
            driver.subscribeToStateChanges((state) => {
                searchState.value = state;
            });


        })
        return {
            searchInputValue,
            searchState,
            nombre_vertical,
            tipo_de_curso,
            idioma,
            precio,
            campanya,
            slpreciomax,
            slprecio,
            slpreciodefault,
            precioComponentRef,
            resultsPerPage,
            sortBy,
            sortOptions,
            header,
            suggest,
            handleFormSubmit,
            handleAutoCompleteAction,
            handleAutoCompleteSelection,
            handleSliderRangeChange,
            handleFacetChange,
            setCurrentPage,
            thereAreResults,
            debounce,
            debounceFilter: createDebounce(),
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/search-engine/search-section.scss";
</style>
