import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import store from '../store';

let languages = []

export async function getPortalsWS() {
    let portals = [];

    try {
        let response = await aiseoBackendPetition('POST', 'sites/getPortals', {'languages': true}, {});
        if (response.result) {
            portals = response.data
            response.data.forEach(portal => {
                if (portal.languages.length > 0) {
                    portal.languages.forEach((idioma) => {
                        languages.push(idioma)
                    })
                }
            })
        } else {
            showNotification('error', response.errors)
        }
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/getPortals');
    }
    return {portals, languages};
}

export function getEnterpriseURLByDomain(domain) {
    let url;

    let portals = store.getters.getStoredPortals
    portals.forEach(portal => {
        if (portal.url === domain) {
            return portal.url
        }
    })

    return url;
}


/*OBTIENE LOS DATOS DE UN PORTAL ESPECIFICO*/
export function getSpecificPortal(id_portal) {
    return store.getters.getStoredPortals.find((specific_portal) => specific_portal.id_portal === parseInt(id_portal));
}


export async function storeEnterpriseData(portal_data, language) {
    if (portal_data !== 0 && language !== '') {
        store.commit('setPortalId', portal_data.id_portal);
        store.commit('setURLEmpresa', portal_data.url_canonica);
        store.commit('setLanguageISO', language.iso_6391);
        store.commit('setLanguageID', language.id_idioma);
        localStorage.setItem('portal_id', portal_data.id_portal);
        localStorage.setItem('url_empresa', portal_data.url_canonica);
        localStorage.setItem('url_min', portal_data.url);
        localStorage.setItem('language_iso', language.iso_6391);
        localStorage.setItem('language_id', language.id_idioma);
    } else {
        store.commit('setPortalId', 0);
        store.commit('setURLEmpresa', 0);
        store.commit('setURLMin', 0);
        store.commit('setLanguageISO', 0);
        store.commit('setLanguageID', 0);
        localStorage.removeItem('portal_id')
        localStorage.removeItem('url_empresa')
        localStorage.removeItem('url_min');
        localStorage.removeItem('language_iso');
        localStorage.removeItem('language_id');
    }

}


export function getNamePortalLanguage(id_portal, id_idioma) {
    const name_portal = getNamePortal(id_portal)
    const name_language = getNameLanguage(id_idioma)
    return {name_portal, name_language}


}


export function getNamePortal(id_portal) {
    const portals = store.getters.getStoredPortals
    if (variableIsDefined(portals)) {
        try {
            const {nombre} = portals.find(
                portal => portal.id_portal === id_portal
            )
            return nombre
        } catch (e) {
            return 'Portal no identificado'
        }
    }
}

export function getNameLanguage(id_language, type = 'id_idioma') {
    const languages = store.getters.getStoredLanguages
    if (variableIsDefined(languages)) {
        try {
            const {nombre} = languages.find(
                languages => languages[type] === id_language
            )
            return nombre
        } catch (e) {
            return 'Idioma no identificado'
        }
    }
}


export async function getAllPortalsWS(filters = {}, all_portals = 1) {
    let params = {
        'exact_search': filters.exact_search ?? false,
        'id_portal': filters.id_portal ?? null,
        'id_country': filters.id_country ?? null,
        'name': filters.name ?? null,
        'url': filters.url ?? null,
        'active': filters.active ?? null,
        'all_portals': all_portals,
        'languages': filters.languages ?? null,
        'id_institution': []
    }
    try {
        return await aiseoBackendPetition('POST', 'sites/getPortals', params, {})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/getPortals');
    }
}

export async function createPortalWS(form_data) {
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'name': form_data.name,
        'url': form_data.url,
        'url_canonica': form_data.url_canonica,
        'logo': form_data.logo,
        'id_portal_diffusion_innotutor': form_data.id_portal_diffusion_innotutor,
        'active': form_data.active ?? 0,
        'id_country': form_data.id_country,
        'score': form_data.score,
        'ws_push': form_data.ws_push,
        'id_accuranker': form_data.id_accuranker,
        'type_portal': form_data.portal_type,
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'sites/insertPortal', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/insertPortal');
    }
}


export async function getPortalsDiffusionInnotutorWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'diffusionInotutor/getPortalsDiffusionInnotutor', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'diffusionInotutor/getPortalsDiffusionInnotutor');
    }
}

export async function updatePortalWS(form_data) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'usuario_logueado': store.getters.getUsername,
        'id_portal': form_data.id_portal,
        'active': form_data.active,
        'name': form_data.name,
        'url': form_data.url,
        'url_canonica': form_data.url_canonica,
        'logo': form_data.logo,
        'id_portal_diffusion_innotutor': form_data.id_portal_diffusion_innotutor,
        'id_country': form_data.id_country,
        'score': form_data.score,
        'ws_push': form_data.ws_push,
        'id_accuranker': form_data.id_accuranker,
        'type_portal': form_data.portal_type,
    }
    try {
        return await aiseoBackendPetition('POST', 'sites/updatePortal', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'sites/updatePortal');
    }

}




