<template>
    <v-row>
        <v-col>
            <v-expansion-panels>
                <v-expansion-panel class="expansion-personalized">
                    <v-expansion-panel-title>Opciones de filtrado</v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.id_language"
                                    placeholder="ID Idioma"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    :error="form_error.id_language"
                                    hide-details
                                ></v-text-field>
                                <span v-if="form_error.id_language"
                                      class="input-error msg-error">El ID Idioma solo puede contener números.</span>
                            </v-col>

                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-text-field
                                    v-model.trim="form_filter.name"
                                    placeholder="Nombre"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-autocomplete
                                    v-model="form_filter.id_language_base"
                                    :items="language_base"
                                    item-title="nombre"
                                    item-value="id_idioma"
                                    variant="underlined"
                                    class="select-primary"
                                    placeholder="Idioma Base"
                                    hide-details
                                    :menu-props="{width: '300px'}"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="3">
                                <v-select
                                    v-model="form_filter.active"
                                    :items="status"
                                    item-title="nombre"
                                    item-value="id_status"
                                    variant="underlined"
                                    class="select-primary"
                                    placeholder="Estado del idioma"
                                    hide-details
                                    :menu-props="{width: '200px'}"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="2">
                                <v-text-field
                                    v-model.trim="form_filter.iso_6391"
                                    placeholder="ISO 6391"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    :class="{error :form_error.iso_6391}"
                                    hide-details
                                ></v-text-field>
                                <span v-if="form_error.iso_6391"
                                      class="input-error msg-error">Formato incorrecto</span>
                            </v-col>
                            <v-col cols="12" sm="4" md="3" lg="2">
                                <v-text-field
                                    v-model.trim="form_filter.iso_2"
                                    placeholder="ISO 2"
                                    class="input-error text-field-primary"
                                    variant="underlined"
                                    :class="{error :form_error.iso_2}"
                                    hide-details
                                ></v-text-field>
                                <span v-if="form_error.iso_2"
                                      class="input-error msg-error">Formato incorrecto</span>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" class="text-start align-self-center">
                                <div style="display: inline-block; width: auto ">
                                    <v-checkbox label="Búsqueda exacta"
                                                v-model="form_filter.exact_search"
                                                :true-value="1"
                                                :false-value="0"
                                                color="blue"
                                                hide-details></v-checkbox>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" md="12" lg="5" class="text-end align-self-center">
                                <v-btn class="btn button-bg-blue"
                                       @click="validateFilter">
                                    Filtrar
                                </v-btn>
                                <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                     title="Eliminar filtros" class="delete-filter ms-4" @click="clearFilters">
                            </v-col>
                        </v-row>

                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup";

export default {
    name: "FiltersGetLanguage",
    props: {
        items_languages: Array,
        getFilters: Function,
        getLanguages: Function,
    },
    setup(props) {
        let form_filter = ref({})
        let form_error = ref({})
        let language_base = ref([])
        let status = ref([
            { nombre: 'Activo', id_status: 1 },
            { nombre: 'Inactivo', id_status: 0 }
        ]);


        onBeforeMount(async () => {
            props.items_languages.forEach((language) => {
                if (!language.iso_6391.includes('-')) {
                    language_base.value.push(language)
                }
            })
        })

        async function validateFilter() {
            let correct = true
            form_error.value = {};
            Object.keys(form_filter.value).forEach(key => {
                if (form_filter.value[key] === "") {
                    delete form_filter.value[key];
                }
            });
            const schemaForm = Yup.object().shape({
                id_language: Yup.string().matches(/^[0-9]+$/, { nullable: true }).optional(),
                iso_6391: Yup.string().matches( /^[a-zA-Z]{1,2}(-[a-zA-Z]{1,2})?$/, { nullable: true }).optional(),
                iso_2: Yup.string().matches(/^[a-zA-Z]{2}$/, { nullable: true }).optional(),
            });
            try {
                await schemaForm.validate(form_filter.value, {abortEarly: false});
                correct = true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                    correct = false
                })
            }
            if (correct) {
                await props.getFilters(form_filter.value)
            }
        }

        async function clearFilters() {
            form_filter.value = {}
            form_error.value = {}
            await props.getLanguages()
        }


        return {
            validateFilter,
            clearFilters,

            form_filter,
            form_error,
            status,
            language_base,
        }
    }
}
</script>


<style scoped lang="scss">
.expansion-personalized {
    border: 1px solid $blue-medium;

    .delete-filter {
        filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
        width: 30px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
        }
    }
}
</style>