import go from "gojs";
import {ResizeMultipleTool} from "gojs/extensionsJSM/ResizeMultipleTool";


var diagram_vertical;
var diagram_horizontal;

export function runMapsVertical() {
    /* el mismo div solo se puede vincular a una instancia de gojs En el método de inicialización,
   podemos usar js para eliminar el div primero y luego usar js para crear un
    nuevo div con la misma identificación.*/
    let vertical_second_diagram = $("#vertical-second-diagram");
    let vertical_primary_diagram = $("#vertical-primary-diagram");
    vertical_second_diagram.remove();
    vertical_primary_diagram.append($('<div id="vertical-second-diagram"></div>'));

    /*Coge los datos del textarea y lo meto en un array dividido por salto de linea*/
    let lines = $("#treeEntry").val().split(/\n/);
    let go_js = go.GraphObject.make;

    /*DEFINIMOS EL DIAGRAMA CON SU ID CORRESPONDIENTE*/
    var my_diagram = go_js(go.Diagram, "vertical-second-diagram",
        {
            resizingTool: new ResizeMultipleTool(),
            "undoManager.isEnabled": true,
            layout: go_js(go.TreeLayout, {
                treeStyle: go.TreeLayout.StyleLastParents,
                arrangement: go.TreeLayout.ArrangementHorizontal,
                /* PROPIEDADES HORIZONTAL*/
                angle: 90,
                layerSpacing: 20,
                nodeSpacing: 20,
                alternateAngle: 90,
                alternateLayerSpacing: 30,
                alternateNodeSpacing: 30

            })
        });

    designMaps(my_diagram, go_js)
    my_diagram.model = go_js(go.TreeModel);
    validation(my_diagram, lines)
    diagram_vertical = my_diagram;
    return my_diagram
}

export function runMapsHorizontal() {
    let horizontal_second_diagram = $("#horizontal-second-diagram");
    let horizontal_primary_diagram = $("#horizontal-primary-diagram");
    horizontal_second_diagram.remove();
    horizontal_primary_diagram.append($('<div id="horizontal-second-diagram"></div>'));
    let lines = $("#treeEntry").val().split(/\n/);
    let go_js = go.GraphObject.make;
    var my_diagram =
        go_js(go.Diagram, "horizontal-second-diagram",
            {
                resizingTool: new ResizeMultipleTool(),
                "undoManager.isEnabled": true,
                layout: go_js(go.TreeLayout, {
                    alternateAngle: 0,
                    alternateAlignment: go.TreeLayout.AlignmentStart,
                    alternateNodeIndent: 15,
                    alternateNodeIndentPastParent: 1,
                    alternateNodeSpacing: 15,
                    alternateLayerSpacing: 40,
                    alternateLayerSpacingParentOverlap: 1,
                    alternatePortSpot: new go.Spot(0.001, 1, 20, 0),
                    alternateChildPortSpot: go.Spot.Left

                })
            });
    designMaps(my_diagram, go_js)
    my_diagram.model = go_js(go.TreeModel);
    validation(my_diagram, lines)
    diagram_horizontal = my_diagram;
    return my_diagram
}

export function textStyle() {
    return {
        margin: 6,
        wrap: go.TextBlock.WrapFit,
        textAlign: "left",
        editable: true,
        font: "700 12px Calibri",
    }
}

export function designMaps(my_diagram, go_js) {
    /*Especifico el tipo de arbol que quiero , en este caso Horizontal*/
    //Diseño de celdas para movimiento de nodos
    my_diagram.toolManager.draggingTool.isGridSnapEnabled = true;
    my_diagram.toolManager.resizingTool.isGridSnapEnabled = true;
    my_diagram.toolManager.draggingTool.gridSnapCellSize = new go.Size(0.5, 0.5);
    my_diagram.nodeTemplate =
        go_js(go.Node, "Auto",
            {deletable: false,},
            new go.Binding("text", "name"),

            go_js(go.TextBlock,
                {
                    font: "700 12px Calibri",
                    textAlign: "left",
                    margin: 10, maxSize: new go.Size(50, NaN)

                },
                new go.Binding("text", "name"))
        );

    /*1º ESCALON MAPA CONCEPTUAL*/
    my_diagram.nodeTemplateMap.add("Titulo",
        go_js(go.Node, "Auto", {resizable: true, resizeObjectName: "R"},
            new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
            /*BORDE*/
            go_js(go.Shape, "RoundedRectangle",
                {
                    name: "R",
                    fill: "#72b5e8", strokeWidth: 0	//fill: color del borde , strokewidth: tamaño del borde

                }),

            /*LETRA*/
            go_js(go.TextBlock, "Source", textStyle(), {
                    stroke: "whitesmoke",	//stroke: color para el texto
                    maxSize: new go.Size(200, NaN),
                    font: "600 21px Calibri",
                    textAlign: "center"
                },
                new go.Binding("text", "text").makeTwoWay())
        ));

    /*2º ESCALON MAPA CONCEPTUAL*/
    my_diagram.nodeTemplateMap.add("Epigrafe",
        go_js(go.Node, "Auto", {resizable: true, resizeObjectName: "R"},
            new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
            go_js(go.Shape, "RoundedRectangle",
                {
                    name: "R",
                    fill: "#8e8e8e", strokeWidth: 0

                }),
            go_js(go.TextBlock, "Source", textStyle(), {
                    stroke: "whitesmoke",
                    maxSize: new go.Size(200, NaN),
                    font: "400 18px Corbel",
                    textAlign: "center"
                },
                new go.Binding("text", "text").makeTwoWay())
        ));
    /*3º ESCALON MAPA CONCEPTUAL*/
    my_diagram.nodeTemplateMap.add("Subepigrafe",
        go_js(go.Node, "Auto", {resizable: true, resizeObjectName: "R"},
            new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
            go_js(go.Shape, "RoundedRectangle",
                {
                    name: "R",
                    fill: "#fef0e4", strokeWidth: 0,

                }),
            go_js(go.TextBlock, "Source", textStyle(), {
                    stroke: "#565656",
                    maxSize: new go.Size(200, NaN),
                    font: "400 16px Corbel",
                    textAlign: "center"
                },
                new go.Binding("text", "text").makeTwoWay())
        ));
    /* 4º ESCALON MAPA CONCEPTUAL*/
    my_diagram.nodeTemplateMap.add("Informacion",
        go_js(go.Node, "Auto", {resizable: true, resizeObjectName: "R"},
            new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
            go_js(go.Shape, "RoundedRectangle",
                {
                    name: "R",
                    fill: "white",
                    stroke: "#6bb1e7", strokeWidth: 1,
                    stretch: go.GraphObject.Fill,
                    spot1: new go.Spot(0, 0, 8, 7), spot2: new go.Spot(1, 1, -10, -11)
                },
                new go.Binding("fill", "color")),
            go_js(go.TextBlock, "Source", textStyle(), {
                    stroke: "#565656",
                    maxSize: new go.Size(200, NaN),
                    font: "400 16px Corbel",
                    spacingAbove: 10,//INTERLINEADO
                    // spot1: new go.Spot(0, 0, 8, 7), spot2: new go.Spot(1, 1, -10, -11)
                },
                new go.Binding("text", "text").makeTwoWay())
        ));


    // define the Link template
    my_diagram.linkTemplate =
        go_js(go.Link,  // the whole link panel
            {routing: go.Link.Orthogonal, corner: 2, selectable: false},
            go_js(go.Shape, {strokeWidth: 0.5, stroke: '#6bb1e7'}));  // the gray link shape

    my_diagram.linkTemplate =
        go_js(go.Link,
            {routing: go.Link.Orthogonal, corner: 0},
            go_js(go.Shape, {strokeWidth: 1, stroke: "#6bb1e7"})); // the link shape

    return my_diagram
}

var errors_tabulation;
var error_line;

export function validation(my_diagram, lines) {
    let current_epigraph;
    let current_subepigraph;
    let i;
    let info = [];
    let zero = 0;
    errors_tabulation = [];
    error_line = [];
    for (i = 0; i < lines.length; i++) {
        if (lines[i] !== "") {
            lines[i] = lines[i].trimEnd();
            if (i === 0) {//TITULO
                /*addNodeData AÑADE UN NODO CON LOS DATOS QUE LE PASAMOS */
                my_diagram.model.addNodeData({
                    key: "1",
                    /*trim ELIMINA ESPACIOS EN BLANCO PRINCIPIO O FINAL CADENA*/
                    name: lines[i].trim(),
                    category: "Titulo",
                    text: lines[i].trim()
                });
                /*DEVUELVE TODOS LOS RESULTADO QUE COINCIDAN CON EL SALTO DE LINEA */
            } else if (lines[i].match(/\t/g) === null) {
                errors_tabulation.push(i + 1)
                /*DEVUELVE TODOS LOS RESULTADOS QUE COINCIDAN CON EL SALTO DE LINEA*/
            } else if (lines[i].match(/\t/g).length === 1) {//EPIGRAFE
                if (info.length > 0) {
                    my_diagram.model.addNodeData({
                        key: String(i),
                        parent: String(i - info.length),
                        category: "Informacion",
                        text: info.join("")
                    });//INFO
                    info.splice(0, info.length);
                    zero = 0;
                }
                my_diagram.model.addNodeData({
                    key: String(i + 1),
                    parent: String(1),
                    category: "Epigrafe",
                    text: lines[i].trim()
                });
                current_epigraph = i + 1;
                current_subepigraph = i + 1;
            } else if (lines[i].match(/\t/g).length === 2) { //SUBEPIGRAFE
                if (info.length > 0) {
                    my_diagram.model.addNodeData({
                        key: String(i),
                        parent: String(i - info.length),
                        category: "Informacion",
                        text: info.join("")
                    });//INFO
                    info.splice(0, info.length);
                    zero = 0;
                }
                my_diagram.model.addNodeData({
                    key: String(i + 1),
                    parent: String(current_epigraph),
                    category: "Subepigrafe",
                    text: lines[i].trim()
                });
                current_subepigraph = i + 1;
            } else if (lines[i].match(/\t/g).length === 3) { //Información
                if (zero === 1) {
                    info.push("\n• " + lines[i].trim());
                } else {
                    if (i === lines.length - 1 || lines[i + 1].match(/\t/g) == null) {
                        my_diagram.model.addNodeData({
                            key: String(i + 1),
                            parent: String(i),
                            category: "Informacion",
                            text: lines[i]
                        });
                    } else {
                        if (lines[i + 1].match(/\t/g).length !== 3) {
                            my_diagram.model.addNodeData({
                                key: String(i + 1),
                                parent: String(i),
                                category: "Informacion",
                                text: lines[i]
                            });
                        } else {
                            info.push("• " + lines[i].trim());
                            zero = 1;
                        }
                    }

                }
                if (i === lines.length - 1 && zero === 1) {
                    my_diagram.model.addNodeData({
                        key: String(i + 1),
                        parent: String(i - info.length + 1),
                        category: "Informacion",
                        text: info.join("")
                    });
                    zero = 0;
                }
            }
        } else {
            error_line.push(i + 1)
        }
    }
    return my_diagram
}

export function showError() {
    /*INCORRECTA TABULACIÓN*/
    let object_no_repeat = {};
    errors_tabulation.forEach(element => {
        object_no_repeat[element] = element;
    })

    let only_errors = Object.values(object_no_repeat);

    /*ESPACIOS INCORRECTOS*/
    let object_line_no_repeat = {};
    error_line.forEach(element => {
        object_line_no_repeat[element] = element;
    })

    let only_line_errors = Object.values(object_line_no_repeat);

    const errors = {}

    if (only_errors.length > 0) errors['only_errors'] = "La linea " + only_errors + " no tiene tabulador"

    if (only_line_errors.length > 0) errors['only_line_errors'] = "Hay un espacio vacío en la linea " + only_line_errors

    return errors
}

export function printMapsV() {
    let pngImage = diagram_vertical.makeImage({
        scale: 1,
        type: "image/png",
    });
    let url = pngImage.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream', 'data:image/png');
    let a = $("<a>")
        .attr("href", url)
        .attr("download", "MAPA_.png")
        .appendTo("body");
    a[0].click();

    a.remove();
}

export function printMapsH() {
    let pngImage = diagram_horizontal.makeImage({
        scale: 1,
        type: "image/png",
    });
    let url = pngImage.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream', 'data:image/png');
    let a = $("<a>")
        .attr("href", url)
        .attr("download", "MAPA_.png")
        .appendTo("body");
    a[0].click();

    a.remove();
}

export function cleanTextMaps() {
    location.reload();

    // $('.treeEntry').value = "";
}