<template>
    <BaseFilterUrl
        :getDeleteFilter="deleteFilter"
        :getApplyBaseFilters="applyFilter"
        :image_screen="true"
    >
        <template #filters>
            <v-col cols="12" md="6" lg="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.ids_simo"
                    label="Ids simo, separadas por ';'"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.ids_innotutor"
                    label="Ids Innotutor, separadas por ';'"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.codes_innotutor"
                    label="Códigos innotutor, separados por ';'"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="filter_searches.name_search"
                    label="Buscar por nombre..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="company_items"
                    v-model="filter_searches.company"
                    item-value="empresa"
                    item-title="empresa"
                    class="select-primary personalize-text-field field-with-label"
                    no-data-text="No hay datos disponibles"
                    variant="underlined"
                    label="Empresa"
                    :menu-props="{width: '250px'}"
                    dense
                    hide-details
                >
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="program_items"
                    v-model="filter_searches.program"
                    item-value="id_programa_formativo"
                    item-title="nombre_programa_formativo"
                    class="select-primary personalize-text-field field-with-label"
                    no-data-text="No hay datos disponibles"
                    variant="underlined"
                    label="Programa"
                    :menu-props="{width: '250px'}"
                    hide-details
                >

                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="type_items"
                    v-model="filter_searches.type"
                    item-value="id_tipo"
                    item-title="tipo"
                    class="select-primary personalize-text-field field-with-label"
                    no-data-text="No hay datos disponibles"
                    variant="underlined"
                    label="Tipo"
                    :menu-props="{width: '250px'}"
                    hide-details
                >

                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    :items="subtype_items"
                    v-model="filter_searches.subtype"
                    item-value="id_subtipo"
                    item-title="subtipo"
                    class="select-primary personalize-text-field field-with-label"
                    no-data-text="No hay datos disponibles"
                    variant="underlined"
                    label="Subtipo"
                    :menu-props="{width: '250px'}"
                    hide-details
                >

                </v-select>
            </v-col>
            <v-col cols="12" md="12" lg="6">
                <v-select
                    :items="degrees_type_items"
                    v-model="filter_searches.degree_type"
                    item-value="id_tipo_titulacion"
                    item-title="nombre_tipo_titulacion"
                    class="select-primary personalize-text-field field-with-label"
                    no-data-text="No hay datos disponibles"
                    variant="underlined"
                    label="Tipo de titulación"
                    :menu-props="{width: '250px'}"
                    hide-details
                >
                </v-select>
            </v-col>

        </template>
    </BaseFilterUrl>
</template>

<script>
import BaseFilterUrl from "@/components/Generic/BaseFilterUrl.vue";
import {onBeforeMount, ref} from "vue";
import {getCompaniesWS, getDegreeTypesWS, getProgramsWS, getSubTypesWS, getTypesWS} from "@/utils/urls";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "FilterCourseListLogo",
    components: {BaseFilterUrl},
    props: {
        getDeleteFilter: Function,
        getApplyFilter: Function,
    },
    setup(props) {
        let degrees_type_items = ref([])
        let company_items = ref([])
        let program_items = ref([])
        let type_items = ref([])
        let subtype_items = ref([])
        let filter_searches = ref({})

        onBeforeMount(async () => {
            await loadAllFilters()
        })

        //CARGA LOS FILTROS PARA LOS SELECTORES DE LA PANTALLA DE ASIGNACIÓN MASIVA DE LOGOS Y DIPLOMAS
        async function loadAllFilters() {
            let response = {}
            //EMPRESA
            response = await getCompaniesWS()
            response['result'] ? company_items.value = [{empresa: '----- NINGUNA -----'}, ...response['data']] : showNotification('error', response['errors'])

            //PROGRAMA
            response = await getProgramsWS()
            response['result'] ? program_items.value = [{
                id_programa_formativo: -1,
                nombre_programa_formativo: '----- NINGUNO -----'
            }, ...response['data']] : showNotification('error', response['errors'])

            //TIPO
            response = await getTypesWS()
            response['result'] ? type_items.value = [{
                id_tipo: -1,
                tipo: '----- NINGUNO -----'
            }, ...response['data']] : showNotification('error', response['errors'])

            //SUBTIPO
            response = await getSubTypesWS()
            response['result'] ? subtype_items.value = [{
                id_subtipo: -1,
                subtipo: '----- NINGUNO -----'
            }, ...response['data']] : showNotification('error', response['errors'])

            //TIPO TITULACIÓN
            response = await getDegreeTypesWS()
            response['result'] ? degrees_type_items.value = [{
                id_tipo_titulacion: -1,
                nombre_tipo_titulacion: '----- NINGUNO -----'
            }, ...response['data']] : showNotification('error', response['errors'])
        }

        const applyFilter = (common_filters) => {
            /* FORMAR LOS ARRAY DE ID SI LOS HUBIESE */
            filter_searches.value = {
                ...filter_searches.value,
                'array_id_simo': formatFilter(filter_searches.value['ids_simo']),
                'array_id_innotutor': formatFilter(filter_searches.value['ids_innotutor']),
                'array_cod_innotutor': formatFilter(filter_searches.value['codes_innotutor'])
            }
            props.getApplyFilter({...common_filters, ...filter_searches.value})
        }

        function formatFilter(filter) {
            return variableIsDefined(filter) ? filter.split(';') : null
        }

        const deleteFilter = () => {
            filter_searches.value = {}
            props.getDeleteFilter(filter_searches.value)
        }

        return {
            applyFilter,
            deleteFilter,

            filter_searches,
            degrees_type_items,
            subtype_items,
            type_items,
            program_items,
            company_items,
        }
    }
}
</script>

<style scoped lang="scss">
</style>