import {createTokenAISEO, showNotification, variableIsDefined} from "@/utils/aux_functions";
import store from "@/store";
import axios from "axios";

/**
 * @param type (string) => GET, POST. Obligatorio
 * @param url (string) => URL DEL WS. Obligatorio
 * @param data (array) => PARÁMETROS QUE NECESITA EL WS. Obligatorio
 * @param params_token (array) => PARÁMETROS QUE NECESITA EL WS. Obligatorio
 * @param petition (string) => AJAX, AXIOS. Opcional
 * @param content_type (string) Opcional
 * @returns {Promise<*|AxiosResponse<any>>}
 */
export async function aiseoBackendPetition(type, url, data, params_token, petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {

    let token = createTokenAISEO(params_token)

    let main_data = {
        'usuario_logueado': store.getters.getUsername,
        'token': token,
        'debug': process.env.NODE_ENV === 'development' ? 1 : 1,
    }
    let final_data = Object.fromEntries(Object.entries(main_data).concat(Object.entries(data)).sort())

    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: process.env.VUE_APP_AISEO_BACKEND_URL + url,
                data: final_data,
                headers: {
                    'content-type': content_type
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(process.env.VUE_APP_AISEO_BACKEND_URL + url,
                        final_data,
                        {
                            headers: {
                                'content-type': content_type
                            }
                        }
                    )
                case 'get':
                    return axios.get(process.env.VUE_APP_AISEO_BACKEND_URL + url + final_data,
                        {
                            headers: {
                                'content-type': content_type
                            }
                        }
                    )
            }
    }

}

export async function uploadFileToAiseo(file, related_entity, related_field, related_node, portal_id = store.getters.getPortalID, language = store.getters.getLanguageISO) {
    let params_token = {
        'relatedEntity': related_entity,
        'relatedField': related_field,
        'relatedNodeIdentificator': related_node
    };

    let file_to_send = variableIsDefined(file[0]) ? file[0] : file

    let data = {
        'file': file_to_send,
        'portal': portal_id,
        'languageISO': language,
        'relatedEntity': related_entity,
        'relatedField': related_field,
        'relatedNodeIdentificator': related_node
    }
    try {
        return await aiseoBackendPetition('POST', 'files/uploadFile', data, params_token, 'axios', 'multipart/form-data')
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'files/uploadFile');
    }
}

export async function uploadImgWS(file, related_entity, related_field, related_node) {
    try {
        return await uploadFileToAiseo(file, related_entity, related_field, related_node)
    } catch (e) {
        showNotification('error', 'Se ha producido un error al enviar el fichero')
    }
}

export async function uploadFileToOrigin(file, mimeType) {
    let params_token = {
        'portal': store.getters.getPortalID,
        'usuario_logueado': store.getters.getUsername
    };

    let data = {
        'portal': store.getters.getPortalID,
        'file': file[0],
        'mimeType': mimeType,
    }

    try {
        return await aiseoBackendPetition('POST', 'seoPanel/uploadImageToOrigin', data, params_token, 'axios', 'multipart/form-data')
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'seoPanel/uploadImageToOrigin');
    }
}