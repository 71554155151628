import {ref} from "vue";
import {getAllDegreesUrlWS} from "@/utils/degrees_course";
import {getPortalFormativeProgramsWS} from "@/utils/training_program";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

const useQualifications = () => {
    //Lista de datos relacionadas con los diplomas
    const list_diplomas = ref([])
    const diploma_id_list = ref([])
    const diploma_weight_list = ref([])

    //Lista de datos relacionadas con los logos
    const list_logos = ref([])
    const logo_id_list = ref([])
    const logo_weight_list = ref([])

    const degree_data = ref({})
    let selected_type_element = ref()


    /*
    * OBTIENE TODOS LOS LOGOS/TITULACIONES DE
    * UNA URL O DE UNA CAMPAÑA
    * */
    async function getAllDegrees(selected_element, element_id, language, portal) {
        let response
        selected_type_element.value = selected_element
        switch (selected_element) {
            case 'url':
                response = await getAllDegreesUrlWS(element_id)
                break;

            case 'campaign':
                response = await getPortalFormativeProgramsWS(portal, element_id, 1, language)
                break;
        }

        if (response['result']) {
            if (variableIsDefined(response['data'])) {
                if (variableIsDefined(response['data'].diplomas_list)) {
                    list_diplomas.value = response['data'].diplomas_list.sort((a, b) => a.peso - b.peso);
                } else {
                    list_diplomas.value = []
                }
                if (variableIsDefined(response['data'].logos_list)) {
                    list_logos.value = response['data'].logos_list.sort((a, b) => a.peso - b.peso);
                } else {
                    list_logos.value = []
                }
            } else {
                list_diplomas.value = []
                list_logos.value = []
            }
        } else {
            showNotification('error', response['errors'])
        }
    }

    /*
    * RELLENA LOS DATOS DE LOS ARRAY
    * QUE CONTROLARÁ LOS PESOS DE
    * LAS TITULACIONES Y LOS AGRUPA
    * EN EL OBJETO degree_data
    * */
    async function fillDegreeData() {
        if(variableIsDefined(list_diplomas.value)) {
            list_diplomas.value = list_diplomas.value.sort((a, b) => a.peso - b.peso);

            list_diplomas.value.forEach((diploma) => {
                diploma_id_list.value.push(diploma.id_diploma);
                diploma_weight_list.value[diploma.id_diploma] = diploma.peso;
            })
        }
        if(variableIsDefined(list_logos.value)){
            list_logos.value = list_logos.value.sort((a, b) => a.peso - b.peso);

            list_logos.value.forEach((logo) => {
                logo_id_list.value.push(logo.id_logo);
                logo_weight_list.value[logo.id_logo] = logo.peso;
            })
        }

        degree_data.value = {
            'logos_list': logo_id_list.value,
            'diplomas_list': diploma_id_list.value,
            'diplomas_weight': diploma_weight_list.value,
            'logos_weight': logo_weight_list.value,
        }
    }

    /*
    * SE LLAMA AL ASIGNAR UN NUEVO LOGO O
    * DIPLOMA PARA QUE LAS LISTAS
    * SE MANTENGAN ACTUALIZADAS
    * */
    const changeDegreeData = (data) => {
        degree_data.value = data;
    }

    return {
        getAllDegrees,
        changeDegreeData,
        fillDegreeData,

        list_diplomas,
        diploma_id_list,
        diploma_weight_list,
        list_logos,
        logo_id_list,
        logo_weight_list,
        degree_data,
    }
}

export default useQualifications