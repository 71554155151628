<template>
    <article class="search-result">
        <div class="img-container">
            <img
                class="img-course"
                v-if="result.imagen_cdn && result.imagen_cdn.raw"
                :src="result.imagen_cdn.raw"
                alt="IMAGEN"
            />
            <img v-else-if="result.destacados_imagen && result.destacados_imagen.raw"
                 class="img-course"
                 :src="destacadoImagenUrl()"
                 alt="DESTACADO IMAGEN"
            />
            <div v-else
                 class="img-course-default"
            ></div>
        </div>
        <div v-if='result.titulo_curso'>
          <div class="box-title">
            <span
              class="search-result__heading edit-search-result__heading">{{ result.titulo_curso.raw }}</span>
          </div>
          <div class="search-result__body">
              <div class="search-result__data">
                  <div v-if="result.porcentaje_descuento && result.porcentaje_descuento.raw">
                      <p class="discount">{{ result.porcentaje_descuento.raw }}% de descuento</p></div>
                  <div v-if="result.precio" class="box-price">
                      <span class="price">{{ result.precio.raw }} EUR </span>
                      <span v-if="result.porcentaje_descuento && result.porcentaje_descuento.raw"
                        class="price_real">
                        {{ Math.floor(result.precio.raw / ((100 - result.porcentaje_descuento.raw) / 100)) }}
                          EUR
                      </span>
                  </div>
                  <v-btn v-if="result.id_portal.raw == 540" class="btn button-primary" target="_blank" :href='"https://euroinnova.com/"+result.url.raw' >Ver Curso Homologado</v-btn>
                  <v-btn v-else-if="result.id_portal.raw == 272" class="btn button-primary" target="_blank" :href='"https://www.euroinnovaformazione.it/"+result.url.raw' >Ver Curso Homologado</v-btn>
                  <br/>
              </div>
          </div>
        </div>
        <div v-else>
          <span
            class="search-result__heading edit-search-result__heading">{{ result.title.raw }}</span>
            <div class="search-result__body">
              <div class="search-result__data">
                <p class='text-subtitle-2 font-weight-light'>{{ result.descripcion_seo.raw }}</p>
              </div>
            </div>
          <v-btn class="btn button-primary" target="_blank" :href='"https://euroinnova.com/"+result.url.raw' >Ver Blog </v-btn>
        </div>
    </article>
</template>

<script>
export default {
    props: {
        result: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        let result = props.result
        function destacadoImagenUrl() {
            return "http://cdn.euroinnova.edu.es/euroinnova_es/img_destacados/m/" + result.destacados_imagen.raw + '.jpg'
        }

        return {
            destacadoImagenUrl,
        }
    }
};
</script>

<style lang="scss">
@import "@/styles/components/search-engine/search-results.scss";
@import "@/styles/general.scss";

</style>
