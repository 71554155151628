<template>
    <v-btn
        @click="displayModalCheckURL()"
        class="btn button-bg-blue button-action"
    >
        Comprobar enlaces rotos
    </v-btn>


    <v-dialog v-model="display_dialog_check_urls">
        <ModalURLCheck
            :displayDialog="displayModalCheckURL"
            :seo_description="seo_description"
        ></ModalURLCheck>
    </v-dialog>
</template>

<script>
import ModalURLCheck from "@/components/SEOEdition/ModalURLCheck.vue";
import {ref} from "vue";

export default {
    name: "BrokenLinks",
    components: {ModalURLCheck},
    props:{
        seo_description: String,
    },
    setup(){
        let display_dialog_check_urls = ref(false);

        function displayModalCheckURL() {
            display_dialog_check_urls.value = !display_dialog_check_urls.value;
        }

        return{
            display_dialog_check_urls,
            displayModalCheckURL,
        }
    }
}
</script>


<style scoped lang="scss">

</style>