<template>
    <v-container class="container-home">
        <InstitutionPortalSelection
            v-if="!hide_value"
            @selectionIsComplete="changeHideValue"
        >
        </InstitutionPortalSelection>
        <v-row v-else class="row-home">
            <template v-if="is_user_ute">
                <v-col cols="12" sm="6" md="4">
                    <router-link to="/bridge/mapa-conceptual" class="link-unestyled">
                        <HomeCard
                            route_name="Generador mapa conceptual"
                            :route_img="require('@/assets/icons/navbar/diagram.svg')"
                            route_alt="icono diagrama"
                        ></HomeCard>
                    </router-link>
                </v-col>
            </template>
            <template v-else>
                <template v-for="route in route_system" :key="route.name">
                    <v-col cols="12" sm="6" md="4" v-if="route.access">
                        <router-link @click.prevent="goToLink(route)" :to="getRoute(route)" class="link-unestyled">
                            <HomeCard
                                :route_name="route.name"
                                :route_img="route.image"
                                :route_alt="route.alt"
                                :disabled="!route.active"
                            ></HomeCard>
                        </router-link>
                    </v-col>
                </template>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {systemRoute} from "@/router/routes_home";
import HomeCard from "@/components/Home/HomeCard.vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import store from "@/store";
import {useStore} from "vuex";
import {isUserUTE} from "@/utils/auth";
import InstitutionPortalSelection from "@/components/Home/InstitutionPortalSelection.vue";
import {useRoute} from "vue-router";

export default {
    name: "HomeView",
    components: {InstitutionPortalSelection, HomeCard},
    setup() {
        const store = useStore();
        const route = useRoute();

        const route_children_menu = computed(() => store.getters.getChildrenMenu)

        let route_system = ref({})
        let is_user_ute = ref(true);

        let hide_value = ref(false)

        onBeforeMount(() => {
            route_system.value = systemRoute()
            is_user_ute.value = isUserUTE();
            store.commit('setShowPortalInstitution', false);
            localStorage.setItem('show_portal_institution', JSON.stringify(false))

            try {
                if (store.getters.getChildrenMenu.children) {
                    route_system.value.forEach((route) => {
                        if (route.name === store.getters.getChildrenMenu.title) {
                            route_system.value = route.children

                        }
                    })
                }
            } catch (error) {
                showNotification('error', error)
            }
        })

        watch(route_children_menu, (value, oldValue) => {
            if (!value) {
                route_system.value = systemRoute()
            }
        })

        function goToLink(route) {
            if (route.children.length > 0) {
                route_system.value = route.children
                store.commit('setChildrenMenu', route.name);
                localStorage.setItem('children_menu', JSON.stringify(route.name))
            }
            if (route.name === "Volver") {
                route_system.value = systemRoute()
                store.commit('setChildrenMenu', false);
                localStorage.setItem('children_menu', JSON.stringify(false))
            }
        }

        const getRoute = (route) => {
            return route.active ? route.path : ''
        }

        const changeHideValue = (new_value) => {
            hide_value.value = new_value
        }

        return {
            changeHideValue,
            getRoute,
            goToLink,
            route_system,
            is_user_ute,
            route,
            hide_value,
        }
    }
}
</script>

<style lang="scss">
.container-home {
    height: calc(100vh - 172px);
    position: relative;

    .row-home {
        min-height: inherit;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
}

@media (max-width: 990px) {
    .container-home {
        .row-home {
            padding-bottom: 90px;
        }
    }

    #main-app {
        //padding-bottom: 0;
    }
}
</style>