<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/form_icon.svg')" alt="icono de typeform" width="28" height="28">
                    Lista de Typeforms
                </span>
            </v-col>
            <v-col cols="12" sm="3" md="3" lg="2" v-if="!general_loading">
                <v-btn
                    class="btn button-bg-blue w-100"
                    hide-details
                    type="button"
                    @click="showDialogTypeform('create')"
                >
                    Añadir
                </v-btn>
            </v-col>
        </v-row>

        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-form @submit.prevent="searchTypeforms">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="filter_search.typeform_name"
                            label="Buscar por nombre..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-form @submit.prevent="searchTypeforms">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="filter_search.typeform_id"
                            label="Buscar por id..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table typeform-table-especific"
                        theme-color="#F4A649"
                        buttons-pagination
                        :headers="headers"
                        :items="items"
                        v-model:server-options="server_options"
                        :server-items-length="server_items_length"
                        :loading="loading"
                        rowsPerPageOptions="10"
                        must-sort
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-edit_typeform="item">
                            <img :src="require('@/assets/icons/edit.svg')"
                                 alt="Editar"
                                 class="icon-data-table icon-edit"
                                 title="Editar"
                                 id="copy"
                                 @click="selected_typeform = item; showDialogTypeform('edit')"
                            >
                        </template>
                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </v-container>

    <v-dialog v-model="open_modal_typeform" persistent>
        <FormTypeform
            :show-dialog="showDialogTypeform"
            :refresh-table="getAllTypeforms"
            :typeform="selected_typeform"
            :mode="mode"
        >
        </FormTypeform>
    </v-dialog>
</template>

<script>
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {onBeforeMount, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {hasPermission} from "@/utils/auth";
import {getAllTypeformWS} from "@/utils/typeform";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import FormTypeform from "@/components/Forms/FormTypeform.vue";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "TypeformListView",
    components: {
        Loading,
        FormTypeform,
        Vue3EasyDataTable,
    },

    setup() {
        const route = useRoute()

        let loading = ref(false)
        let general_loading = ref(false)
        const headers = ref([])
        const items = ref([])
        const server_items_length = ref(0)
        const filter_search = ref({})
        let mode = ref();
        let server_options = ref({
            page: 1,
            rowsPerPage: 10,
            sortBy: 'id_formulario',
            sortType: 'asc'
        });

        const open_modal_typeform = ref(false);

        const selected_typeform = ref()

        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route)
            await getAllTypeforms()
            setDatatableHeader()
            general_loading.value = false
        });


        watch(server_options, async () => {
                await getAllTypeforms();
            },
            {deep: true}
        );

        /*
        *
        * INICIALIZA LOS HEADERS DE LA TABLA
        *
        * */
        const setDatatableHeader = () => {
            headers.value = [
                {text: 'ID', value: 'id_formulario', sortable: true},
                {text: 'Nombre', value: 'nombre', sortable: true},
                {text: 'Remitente', value: 'remitente_nombre', sortable: true},
                {text: 'Campaña', value: 'campanya_nombre', sortable: true},
                {text: 'Curso', value: 'curso_nombre', sortable: true},
                {text: 'Enviar cupón a Zoho ', value: 'enviar_cupon_a_zoho', sortable: true},
                {text: 'Canal ', value: 'nombre_canal', sortable: true},
                {text: ' ', value: 'edit_typeform'},
            ]
        }

        /*
        *
        * OBTIENE TODOS LOS TYPEFORM Y
        * RELLENA EL ARRAY DE ITEMS CON ELLOS
        *
        * */
        const getAllTypeforms = async () => {
            loading.value = true
            let order_by = ''
            let order_direction = ''

            if (variableIsDefined(server_options.value.sortBy) && variableIsDefined(server_options.value.sortType)) {
                order_by = server_options.value.sortBy;
                order_direction = server_options.value.sortType;
            }

            const response = await getAllTypeformWS(server_options.value.page, server_options.value.rowsPerPage, filter_search.value, order_direction, order_by)
            try {
                if (response['result']) {
                    items.value = response['data']['data']
                    server_items_length.value = response['data'].total
                } else {
                    showNotification('error', response['errors'])
                }
            } catch (e) {
                showNotification('error', 'Error en la petición: getListadoTypeform')
            }

            loading.value = false
        }


        /*
        *
        * POSICIONA LA TABLA EN LA PRIMERA
        * PÁGINA Y RECUPERA LOS TYPEFORM
        * APLICANDO UN FILTRO DE BUSQUEDA
        * */
        const searchTypeforms = async () => {
            server_options.value.page = 1
            await getAllTypeforms()
        }

        /*
        *
        * FUNCIONES PARA MOSTRAR/OCULTAR LOS DIALOG
        *
        * */
        function showDialogTypeform(action = "") {
            mode.value = action;
            open_modal_typeform.value = !open_modal_typeform.value
        }

        /****************************************/

        return {
            getAllTypeforms,
            searchTypeforms,
            showDialogTypeform,

            general_loading,
            open_modal_typeform,
            filter_search,
            headers,
            items,
            loading,
            selected_typeform,
            server_items_length,
            server_options,
            mode
        }
    }
}
</script>

<style lang="scss" scoped>
.typeform-table-especific {
    .icon-data-table {
        &:hover {
            filter: none;
        }
    }

    .icon-edit {
        &:hover {
            content: url('@/assets/icons/hover-edit.svg');
        }
    }
}
</style>