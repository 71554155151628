<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/strikethrough.svg')" alt="icono de palabras negativas" width="28" height="28">
                    Listado de palabras negativas
                </span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
        <v-row align="center" justify="space-between">
            <v-col cols="12" xs="12" lg="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model="filter_search"
                    label="Buscar palabra"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" lg="5" v-if="manager_negative_words">
                <v-row class="row-add-word">
                    <v-col cols="8" xs="4" lg="9" xl="7">
                        <v-text-field
                            placeholder="Nueva palabra negativa"
                            class="input-error text-field-secondary"
                            :error="new_word_error"
                            v-model="new_negative_word"
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                        <span v-if="new_word_error" class="input-error msg-error">
                            {{ new_word_error_message }}
                        </span>
                    </v-col>
                    <v-col cols="3" xs="2" sm="2" lg="3" xxl="2" class="text-end">
                        <v-btn class="btn button-primary"
                               @click="addNewNegativeWord()"
                        >
                            Añadir
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <Vue3EasyDataTable
                    table-class-name="primary-table table-negative"
                    theme-color="#F4A649"
                    buttons-pagination
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :rows-per-page="25"
                    :search-field="'negative_word'"
                    :search-value="filter_search"
                    rows-per-page-message="Elementos por página: "
                    rows-of-page-separator-message="de"
                    empty-message="No hay datos disponibles"
                >
                    <template #item-btn_edit="item" v-if="manager_negative_words">
                        <img :src="require('@/assets/icons/edit.svg')"
                             alt="Editar"
                             class="icon-data-table icon-edit"
                             title="Editar"
                             id="copy"
                             @click="selectWord(item, 'edit')"
                        >
                    </template>
                    <template #item-btn_delete="item" v-if="manager_negative_words">
                        <img :src=" require('@/assets/icons/trash.svg')"
                             alt="Borrar"
                             class="icon-data-table icon-delete"
                             title="Borrar"
                             id="delete"
                             @click="selectWord(item, 'delete')"
                        >
                    </template>
                </Vue3EasyDataTable>
            </v-col>
        </v-row>
        </template>
    </v-container>

    <v-dialog v-model="dialog_edit">
        <ModalEditWords
            :editFunction="editNegativeWord"
            :validateWord="validateNewWord"
            :displayDialogEdit="displayDialogEdit"
            :title_msg_drop="'Modificar ' + selected_word.negative_word"
            :state_loading="loading_dialog"
        >
        </ModalEditWords>
    </v-dialog>

</template>

<script>
import FormComment from "@/components/Comments/FormComment.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import TeachersModal from "@/components/Teachers/TeachersModal.vue";
import {onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import {hasPermission, userHaveAllowedRol} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import ModalEditWords from "@/components/NegativeWords/ModalEditWords.vue";
import {addWordsWS, deleteWordsWS, editWordsWS, getNegativeWordsWS} from "@/utils/negative_words";
import {fireSweetAlert, hideLoading, initSweetAlert, showLoading} from "@/utils/sweetalert";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "NegativeWordsView",
    components: {
        Loading,
        ModalEditWords,
        TeachersModal,
        Vue3EasyDataTable,
        FormComment
    },
    setup() {
        const route = useRoute();
        let general_loading = ref(false)
        let loading = ref(false);
        let loading_dialog = ref(false);

        let items = ref([]);

        let headers = ref([
            {text: 'PALABRA NEGATIVA', value: 'negative_word'},
            {text: '', value: 'btn_edit'},
            {text: '', value: 'btn_delete'},
        ]);

        let new_negative_word = ref('');
        let new_word_error = ref(false);
        let new_word_error_message = ref('');

        let dialog_edit = ref(false);

        let selected_word = ref();
        let filter_search = ref();

        let manager_negative_words = ref(false)

        onBeforeMount(async () => {
            general_loading.value = true
            await hasPermission(route);
            await getWordsByPortal();
            manager_negative_words.value = userHaveAllowedRol("PERMISO_ADMIN_NEGATIVE_WORDS")
            initSweetAlert()
            general_loading.value = false
        });

        /*
        *
        * FUNCIONES PARA OBTENER
        * LAS PALABRAS
        *
        * */
        async function getWordsByPortal() {
            loading.value = true;
            let response_all_words = await getNegativeWordsWS();
            if (response_all_words['result']) {
                items.value = response_all_words['data']['negative_words'];
            } else {
                showNotification("error", response_all_words['errors'])
            }

            loading.value = false
        }

        /**********************************************/


        /*
        *
        * FUNCIÓN PARA VALIDAR QUE LA PALABRA
        * A AÑADIR SEA VÁLIDA
        *
        * */
        function validateNewWord(negative_word) {
            let result = {
                'message': '',
                'correct': true,
            };

            new_word_error.value = false;

            let exist_word = items.value.findIndex((word) => word.negative_word.toUpperCase() === negative_word.trim().toUpperCase()) > -1;

            if (!variableIsDefined(negative_word.trim())) {
                result['correct'] = false;
                result['message'] = 'El campo no puede estar vacío';
            } else if (exist_word) {
                result['correct'] = false;
                result['message'] = 'La palabra introducida ya está registrada';
            }

            return result;
        }


        /*
        *
        * FUNCIONES PARA AÑADIR,
        * EDITAR Y ELIMINAR PALABRAS
        *
        * */
        async function addNewNegativeWord() {
            let result = validateNewWord(new_negative_word.value);
            if (result.correct) {
                let response_add_word = await addWordsWS(new_negative_word.value.trim());
                if (response_add_word['data'] && response_add_word['result']) {
                    await getWordsByPortal();
                    new_negative_word.value = '';
                    showNotification('success', 'Palabra añadida correctamente');
                } else {
                    showNotification("error", response_add_word['errors'])
                }
            } else {
                new_word_error.value = true;
                new_word_error_message.value = result.message;
            }
        }

        async function editNegativeWord(new_word) {
            loading_dialog.value = true;
            let response_change_word = await editWordsWS(new_word, selected_word.value.id_word);
            if (response_change_word['data'] && response_change_word['result']) {
                await getWordsByPortal();
                showNotification('success', 'Palabra modificada correctamente');
            } else {
                showNotification("error", response_change_word['errors'])
            }

            loading_dialog.value = false;
            displayDialogEdit();
        }

        async function deleteNegativeWord() {

            let response_delete_word = await deleteWordsWS(selected_word.value.id_word);
            if (response_delete_word['data'] && response_delete_word['result']) {
                await getWordsByPortal();
                showNotification('success', 'Palabra eliminada correctamente');
            } else {
                showNotification("error", response_delete_word['errors'])
            }

        }

        /******************************************************************/

        /*
        *
        * FUNCIONES PARA ABRIR LOS MODALES
        *
        * */
        function selectWord(word, action) {
            selected_word.value = {
                'negative_word': word.negative_word,
                'id_word': word.id_word,
            };

            switch (action) {
                case 'delete':
                    displayDialogDelete();
                    break;

                case 'edit':
                    displayDialogEdit();
                    break;
            }
        }

        async function displayDialogDelete() {
            await fireSweetAlert("Eliminar palabra negativa", deleteNegativeWord)
        }

        function displayDialogEdit() {
            dialog_edit.value = !dialog_edit.value;
        }

        /**************************************/

        return {
            displayDialogDelete,
            addNewNegativeWord,
            displayDialogEdit,
            editNegativeWord,
            validateNewWord,
            selectWord,
            manager_negative_words,
            new_word_error_message,
            new_negative_word,
            new_word_error,
            loading_dialog,
            general_loading,
            selected_word,
            filter_search,
            dialog_edit,
            headers,
            loading,
            items,
        }
    }
}
</script>

<style lang="scss">
.table-negative {
    th:nth-child(2), th:nth-child(3) {
        width: 5vw;
    }

    .icon-data-table {
        width: 25px;
        height: auto;
        cursor: pointer;

        &:hover {
            filter: none;
        }
    }

    .icon-edit {
        &:hover {
            content: url('@/assets/icons/hover-edit.svg');
        }
    }

    .icon-delete {
        &:hover {
            content: url('@/assets/icons/hover-trash.svg');
        }
    }

    .vue3-easy-data-table__footer {
        flex-wrap: wrap;
    }
}

.row-add-word {
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1024px) {
    .row-add-word {
        justify-content: start;
    }
}

</style>