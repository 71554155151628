<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6" class="text-start">
                <span class="main-title-page">Información sobre roles</span>
            </v-col>
        </v-row>
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-else>
            <FilterGetRolesByUrl
                :items_data="items_data"
                :items_roles="items_roles"
                :items_types="items_types"
                :getRolesByUrl="getRolesByUrl"
                :getFilters="getFilters"
            />
            <v-row>
                <v-col>
                    <Vue3EasyDataTable
                        :key="key_table"
                        table-class-name="primary-table"
                        theme-color="#F4A649"
                        :headers="header_table"
                        :items="items_data"
                        :loading="loading_table"
                        :rowsPerPage="10"
                        buttons-pagination
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                        must-sort
                    >

                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
    </v-container>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getAllRolesWS} from "@/utils/admin_functions";
import {getRolesByUrlWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import FilterGetRolesByUrl from "@/components/Admin/InformationRoles/FilterGetRolesByUrl.vue";
import {getCatalogTypeAcceseslWS} from "@/utils/admin_functions";

export default {
    name: "InformationRolesView",
    components: {FilterGetRolesByUrl, Vue3EasyDataTable, Loading},

    setup() {
        let loading = ref(false)
        let header_table = ref([
            {text: 'Url', value: 'url', sortable: true},
            {text: 'Rol', value: 'role_name', sortable: true},
            {text: 'Descripción permiso', value: 'individual_description'},
            {text: 'Fichero', value: 'location_of_component'},
            {text: 'Tipo', value: 'catalog_type', sortable: true},
        ])
        let items_data = ref([])
        let items_roles = ref([])
        let items_types = ref([])
        let form_filter = ref([])
        let loading_table = ref(false)

        const key_table = ref(0);
        let loading_filter = ref({
            btn_save: false,
            btn_delete: false,
        })

        onBeforeMount(async () => {
            loading.value = true
            initSweetAlert()
            await getRolesByUrl()
            await getRoles()
            await getTypes()
            loading.value = false
        })

        async function getRolesByUrl(data_filters) {
            if(data_filters){
                Object.entries(data_filters).forEach(([key, value]) => {
                    form_filter.value[key] = value;
                });
            }

            loading_table.value = true
            let response = await getRolesByUrlWS(data_filters);

            if (response['result']) {
                items_data.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }

        async function getRoles(data_filters = {}) {
            loading_table.value = true
            let response = await getAllRolesWS(data_filters)
            if (response['result']) {
                items_roles.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }

        async function getTypes(data_filters = {}) {
            loading_table.value = true
            let response = await getCatalogTypeAcceseslWS(data_filters)
            if (response['result']) {
                items_types.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }

        async function getFilters(filters) {
            loading_filter.value.btn_save = true
            key_table.value++;
            await getRolesByUrl(filters)
            loading_filter.value.btn_save = false
        }

        return {
            getFilters,
            getRoles,
            getRolesByUrl,

            loading,
            loading_table,
            header_table,
            items_data,
            loading_filter,
            key_table,
            items_roles,
            items_types,


        }
    }
}
</script>


<style scoped lang="scss">

</style>
