<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/create_redirect.svg')" alt="icono de redirección" width="28" height="28">
                    Redirecciones generales
                </span>
            </v-col>
        </v-row>
        <template v-if="!pending_language">
            <template v-if="page_loading">
                <Loading/>
            </template>
            <template v-else>
                <v-form @submit.prevent="searchURL">
                    <v-row>
                        <v-col cols="12">
                        <v-text-field
                            class="text-field-secondary"
                            v-model="filter_search"
                            label="Buscar por url de origen..."
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="text-start align-self-center">
                        <div style="display: inline-block; width: auto ">
                            <v-checkbox
                                label="Busqueda exacta"
                                v-model="check_exact_search"
                                :true-value="true"
                                :false-value="false"
                                color="blue"
                                hide-details
                            ></v-checkbox>
                        </div>
                    </v-col>
                    <v-col cols="12" md="9" class="text-end align-self-center">
                        <v-btn
                            class="btn button-primary mt-2 mt-sm-0 ms-sm-3"
                            @click="setQueueExcelElement"
                            :loading="create_redirection_loading"
                            :disabled="table_items.length === 0"
                        >Descargar Excel Redirecciones
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-end align-self-center">
                        <v-btn class="btn button-primary personalize-btn"
                               @click="displayDialogImports"
                        >Importar desde CSV
                            <img :src="require('@/assets/icons/import-document.svg')"
                                 alt="Editar"
                                 class="import-icon"
                                 title="Importar desde CSV"
                                 id="copy">
                        </v-btn>

                        <v-btn
                            class="btn button-bg-blue mt-2 mt-sm-0 ms-sm-3"
                            @click="newRedirection"
                            :loading="create_redirection_loading"
                        >AÑADIR REDIRECCIÓN
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

                <v-row>
                    <v-col cols="12">
                        <Vue3EasyDataTable
                            table-class-name="primary-table"
                            theme-color="#F4A649"
                            buttons-pagination
                            :headers="headers"
                            :server-items-length="serverItemsLength"
                            :items="table_items"
                            :loading="table_loading"
                            :rows-per-page="25"
                            v-model:server-options="server_options"
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-url_destino="item">
                                <template v-if="item.url_destino !== null">
                                    {{ item.url_destino }}
                                </template>
                                <template v-else>
                                    N/C
                                </template>
                            </template>

                            <template #item-btn_manage_redirections="item">
                                <v-menu class="table-blog">
                                    <template v-slot:activator="{ props }">
                                        <img :src="require('@/assets/icons/more-options.svg')"
                                             class="btn button-bg-white icon-actions"
                                             alt="Icono puntos"
                                             v-bind="props">
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-title @click="selectRedirection(item.url_origen)"
                                                               style="cursor: pointer">
                                                Modificar
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-title @click="selectedCopyRedirection(item)"
                                                               style="cursor: pointer">
                                                Copiar a
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>

                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>
            </template>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <span>Selecciona un idioma para continuar.</span>
                </v-col>
            </v-row>
        </template>
    </v-container>

    <v-dialog v-model="display_modal_create_redirection">
        <ModalNewRedirection
            :displayDialogPortalRedirection="displayDialogCreateRedirection"
            :getPortalRedirections="getPortalRedirections"
            :all_redirections="table_items"
            :redirection_selected="redirection_selected"
        >
        </ModalNewRedirection>
    </v-dialog>

    <v-dialog v-model="display_modal_update_delete">
        <ModalUpdateDeleteRedirection
            :showDialog="closeModal"
            :origin_url="selected_redirection_origin_url"
            :redirection_general="true"
        >
        </ModalUpdateDeleteRedirection>
    </v-dialog>

    <v-dialog v-model="display_modal_import" persistent>
        <ModalImportDocument
            :displayDialogImports="displayDialogImports"
            @send="importRedirections"
            :href_template="'/Plantilla_redirecciones.csv'"
            :download_template="'Plantilla_redirecciones.csv'"
        ></ModalImportDocument>

    </v-dialog>

</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {computed, onBeforeMount, ref, watch} from "vue";
import {hasPermission} from "@/utils/auth";
import {getPortalRedirectionsWS, importRedirectionsWS, queueExportRedirectionsFileWS} from "@/utils/url_redirections";
import ModalUpdateDeleteRedirection from "@/components/Redirections/ModalUpdateDeleteRedirection.vue";
import ModalNewRedirection from "@/components/Redirections/ModalNewRedirection.vue";
import Loading from "@/components/Generic/Loading.vue";
import FormStatus from "@/components/Forms/FormStatus.vue";
import ModalImportDocument from "@/components/Redirections/ModalImportDocument.vue";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {showNotification} from "@/utils/aux_functions";

export default {
    name: "RedirectionsView",
    components: {
        Loading,
        ModalNewRedirection,
        ModalImportDocument,
        FormStatus,
        ModalUpdateDeleteRedirection,
        Vue3EasyDataTable,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        
        const table_loading = ref(false);
        const page_loading = ref(false);
        const create_redirection_loading = ref(false);

        let language = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        const pending_language = ref(false);
        const portals = ref([]);

        const table_items = ref([]);
        const serverItemsLength = ref(0);
        const headers = ref([
            {text: 'COD. HTTP', value: 'codigo_http'},
            {text: 'URL ORIGEN', value: 'url_origen'},
            {text: 'URL DESTINO', value: 'url_destino'},
            {text: 'ACCIONES', value: 'btn_manage_redirections'}
        ]);
        const server_options = ref({
            page: 1,
            rowsPerPage: 25,
        });

        const filter_search = ref();
        const check_exact_search = ref(false);

        const display_modal_update_delete = ref(false);
        const display_modal_create_redirection = ref(false);
        const display_modal_import = ref(false);

        const selected_redirection_origin_url = ref('');
        const redirection_selected = ref({})

        onBeforeMount(async () => {
            page_loading.value = true
            await hasPermission(route);
            portals.value = computed(() => store.getters.getStoredPortals).value
            initSweetAlert()
            language.value !== 0 ? await getPortalRedirections() : pending_language.value = true
            page_loading.value = false
        });

        watch(portal, async () => {
            table_items.value = [];
            await getPortalRedirections();
        });

        watch(server_options, async () => {
                await getPortalRedirections();
            },
            {deep: true}
        );

        const importRedirections = async (file, callback) => {
            callback(await importRedirectionsWS(file));
        }

        /*
        * OBTIENE TODAS LAS REDIRECCIONES DE UN PORTAL
        * */
        async function getPortalRedirections() {
            table_loading.value = true;
            let response = await getPortalRedirectionsWS(server_options.value.page, server_options.value.rowsPerPage, filter_search.value, check_exact_search.value);

            if (response['result']) {
                table_items.value = response['data']['data'];
                serverItemsLength.value = response['data'].total;
            }
            table_loading.value = false;
        }

        async function searchURL() {
            server_options.value.page = 1;
            await getPortalRedirections();
        }

        function newRedirection() {
            redirection_selected.value = null
            displayDialogCreateRedirection()
        }

        async function setQueueExcelElement() {
            await fireSweetAlert('Descargar Excel redirecciones', queueExportRedirectionsFile, 'El proceso se añadira a una cola y se enviará el fichero por correo al finalizar el proceso')
        }

        async function queueExportRedirectionsFile(){
            create_redirection_loading.value = true
            let response = await queueExportRedirectionsFileWS(filter_search.value, check_exact_search.value);

            if (response['result']){
                showNotification('success', 'Proceso encolado con éxito')
            }else{
                showNotification('error', response['errors'])
            }
            create_redirection_loading.value = false
        }

        function selectedCopyRedirection(item) {
            redirection_selected.value = item
            redirection_selected.value.title_modal = 'Copiar redirección'
            displayDialogCreateRedirection()
        }

        function selectRedirection(url) {
            selected_redirection_origin_url.value = url;
            displayDialogUpdateDeleteRedirection();
        }

        /*
        * FUNCIONES PARA MOSTRAR Y OCULTAR MODALES
        * */
        function displayDialogUpdateDeleteRedirection() {
            display_modal_update_delete.value = !display_modal_update_delete.value;
        }

        function displayDialogImports() {
            display_modal_import.value = !display_modal_import.value;
        }

        function displayDialogCreateRedirection() {
            display_modal_create_redirection.value = !display_modal_create_redirection.value;
        }

        async function closeModal(changed = false) {
            if (changed) {
                await getPortalRedirections();
            }
            displayDialogUpdateDeleteRedirection();
        }
        /*******************************************************/

        return {
            setQueueExcelElement,
            closeModal,
            displayDialogCreateRedirection,
            displayDialogImports,
            getPortalRedirections,
            newRedirection,
            searchURL,
            selectRedirection,
            selectedCopyRedirection,
            importRedirections,


            check_exact_search,
            display_modal_create_redirection,
            display_modal_import,
            display_modal_update_delete,
            filter_search,
            page_loading,
            headers,
            table_items,
            create_redirection_loading,
            pending_language,
            portals,
            redirection_selected,
            selected_redirection_origin_url,
            serverItemsLength,
            server_options,
            table_loading,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/redirection.scss";
</style>