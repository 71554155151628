import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

export async function getAllCommentsWS(filters_json, filter_name_course, filter_generic_comment, server_options) {
    let params = {
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
        'url': variableIsDefined(filter_name_course) ? filter_name_course : '',
        'comment_generic': filter_generic_comment,
        'filters': filters_json,
        'page_size': server_options.rowsPerPage
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'comments/getAllComments?page=' + server_options.page, params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/getAllComments');
    }
}

export async function getAllStatusCommentsWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/getAllStatusComments', {}, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/getAllStatusComments');
    }
}

export async function bulkChangeStatusWS(accumulation_id_state, json_ids_comment) {

    let params = {
        'id_status': accumulation_id_state,
        'comments_list': json_ids_comment,
    }
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/bulkChangeStatus', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/bulkChangeStatus');
    }
}

export async function insertCommentWS(form_data, url_course) {
    let comment_object = {
        "name": form_data.name,
        "surname": form_data.surname,
        "nickname": form_data.nickname,
        "city": form_data.city,
        "score": form_data.score,
        "id_status": form_data.id_status,
        "content": {
            "basic_text": form_data.basic_text,
            "decisive_content": form_data.decisive_content,
            "learned_content": form_data.learned_content,
            "enjoyed_content": form_data.enjoyed_content,
            "missed_content": form_data.missed_content,
        },
        "opinion_checks": {
            "include_opinions": variableIsDefined(form_data.include_opinions) ? form_data.include_opinions : 0,
        }
    }

    let json_form_data = JSON.stringify(comment_object)
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }
    let params = {
        'comment_data': json_form_data,
        'url': url_course,
        'id_portal': store.getters.getPortalID,
        'language': store.getters.getLanguageISO,
    }

    try {
        return await aiseoBackendPetition('POST', 'comments/insertComment', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/insertComment');
    }
}

export async function updateCommentWS(form_data) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername
    }

    let comment_object = {
        "name": form_data.name,
        "surname": form_data.surname,
        "nickname": form_data.nickname,
        "city": form_data.city,
        "score": form_data.score,
        "id_status": form_data.id_status,
        "content": {
            "basic_text": form_data.basic_text,
            "decisive_content": form_data.decisive_content,
            "learned_content": form_data.learned_content,
            "enjoyed_content": form_data.enjoyed_content,
            "missed_content": form_data.missed_content,
        },
        "opinion_checks": {
            "include_opinions": form_data.include_opinions,
        }
    }
    let json_form_data = JSON.stringify(comment_object)

    let params = {
        'id_comment': form_data.id_comentario,
        'comment_data': json_form_data,
    }
    try {
        return await aiseoBackendPetition('POST', 'comments/updateComment', params, params_token);
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'comments/updateComment');
    }
}




