<template>
    <v-container>
        <div class="fullscreen">
            <span class="error-404">404</span>
            <span class="error-text">Oops! No hemos encontrado lo que buscas</span>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "PageNotFoundView"
}
</script>

<style scoped lang="scss">
.error-404 {
    display: block;
    background: -webkit-linear-gradient($blue-light, $blue-darker);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 190px;
    font-weight: bolder;
    color: $primary-color-2;
}

.error-text {
    display: block;
    font-size: 35px;
    font-weight: 400;
}

@media (max-width: 1024px) {
    .error-404 {
        font-size: 150px;
    }
    .error-text {
        font-size: 30px;
    }
}

@media (max-width: 700px) {
    .error-404 {
        font-size: 100px;
    }
    .error-text {
        font-size: 18px;
        text-align: center;
    }
}
</style>