import store from "@/store";
import {useRouter} from "vue-router";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {b64EncodeUnicode, showNotification} from "@/utils/aux_functions";
import {
    localGetIDRolByAlias
} from "@/utils/admin_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";


export async function hasPermission(route) {
    const router = useRouter();
    let has_permission = true;
    if (isLogged()) {
        switch (route.name) {
            case 'editor-seo':
                has_permission = userHaveAllowedRol("PERMISO_EDITOR_SEO");
                break;
            case 'editor-curso':
                has_permission = userHaveAllowedRol("PERMISO_EDITOR_CURSO");
                break;
            case 'traductor':
                has_permission = userHaveAllowedRol("PERMISO_TRADUCTOR");
                break;
            case 'configuracion-gads':
                has_permission = userHaveAllowedRol("PERMISO_GADS");
                break;
            case 'redactores':
                has_permission = userHaveAllowedRol("AUTOR");
                break;
            case 'gestion-versiones':
                has_permission = userHaveAllowedRol("PERMISO_CONTROL_VERSIONES_CURSO");
                break;
            case 'redirecciones-generales':
                has_permission = userHaveAllowedRol("PERMISO_REDIRECT_URLS");
                break;
            case 'busqueda-reemplazo-masivo':
                has_permission = userHaveAllowedRol("BUSCAR_Y_REEMPLAZAR");
            case 'listado-typeforms':
                has_permission = userHaveAllowedRol("TYPEFORM_USER");
                break;
            case 'admin':
            case 'roles':
            case 'grupos':
            case 'roles-usuario':
            case 'usuarios-grupo':
            case 'grupos-roles':
                has_permission = userHaveAllowedRol("ROOT");
                break;

        }

        if (!route.fullPath.includes('acceso-denegado')) {
            await saveLogVisit(route.fullPath, has_permission)
        }
        if (isUserUTE()){
            has_permission = route.name === 'mapa-conceptual'
        }

        return (has_permission ? true : router.push({name: 'not-allowed'}))
    }
    return router.push({name: 'inicio'})
}


export async function authenticate(username, password) {
    const encrypted_password = b64EncodeUnicode(password)
    const encrypted_token_password = b64EncodeUnicode(process.env.VUE_APP_AISEO_AUTH_PASSWORD)

    return $.ajax({
        type: "POST",
        url: process.env.VUE_APP_AICORE_URL_LOGIN,
        data: {
            username: username,
            password: encrypted_password,
            username_aicore: process.env.VUE_APP_AISEO_AUTH_USER,
            password_aicore: encrypted_token_password,
        }
    });
}

export function isLogged() {
    return store.getters.getUsername !== ""
}

export async function logout() {
    localStorage.clear()
    store.commit('setUser', "")
}

export async function getSincrocursoToken() {
    return $.ajax({
        type: "POST",
        url: process.env.VUE_APP_SINCROCURSO_URL_TOKEN,
        data: {
            username: process.env.VUE_APP_AISEO_AUTH_USER,
            password: process.env.VUE_APP_AISEO_AUTH_PASSWORD,
        }
    });
}


export async function saveLogVisit(to, visited) {
    if (process.env.NODE_ENV !== 'development') {
        var data = {
            url: to,
            username: store.getters.getUsername,
            visualizado: visited ? 1 : 0,
            application: 'aiseo'
        };
        await aicoreBackendPetition('GET', 'Logs/save_log_visualizacion_app', data)
    }
}


/*FUNCIONES PARA GESTIONAR LOS ROLES DEL USUARIO Y SUS PERMISOS*/
export async function getUserRolesWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }

    let params = {
        'username': store.getters.getUsername,
    }

    try {
        return await aiseoBackendPetition('POST', 'users/getAllRolesUser', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'users/getAllRolesUser');
    }
}


export function userHaveAllowedRol(alias) {
    let user_roles = store.getters.getRolesUser;
    let id_rol =  localGetIDRolByAlias(alias)

    return user_roles.includes(parseInt(id_rol)) || user_roles.includes(localGetIDRolByAlias("ROOT"))

}

export function isUserUTE(){
    const user_ute = ['usuario.ute', 'usuario.ute2', 'usuario.ute3']
    let username = store.getters.getUsername;
    return  user_ute.indexOf(username) !== -1;
}