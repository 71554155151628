<template>
    <BaseModalTemplate
            :title="`Revertir cambios en la cola con ID ${log_id}`"
            :open-close-dialog="openModalRevert"
            :loading="loading"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <p class="subtitle-secondary">Para revertir los cambios, se creará un nuevo elemento en cola con los siguientes datos</p>
                </v-col>
                <template v-if="!variableIsDefined(JSON.parse(revert_data.configuration)[0].revert)">
                    <v-col cols="12" md="4">
                        <p class="titles">BÚSQUEDA</p>
                        <span>{{ revert_data.replace }}</span><br>
                    </v-col>
                    <v-col cols="12" md="4">
                        <p class="titles">REEMPLAZO</p>
                        <span>{{ revert_data.search }}</span><br>
                    </v-col>
                </template>
                <v-col cols="12" md="5" v-if="variableIsDefined(revert_data.configuration)">
                    <p class="titles">CONFIGURACIÓN</p>
                    <template v-for="config in JSON.parse(revert_data.configuration)">
                        <p>
                            <span class="subtitle-secondary">Tabla: </span><span>{{ config.tabla }}</span> <span class="subtitle-secondary">Campo: </span><span>{{config.campo}}</span>
                        </p>
                    </template>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <p class="titles">URLS AFECTADAS</p>
                    <template v-if="variableIsDefined(changed_urls)">
                        <Vue3EasyDataTable
                            table-class-name="primary-table modal-table"
                            theme-color="#F4A649"
                            buttons-pagination
                            :headers="headers"
                            :items="changed_urls"
                            must-sort
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-id_portal="item">
                                {{ getNamePortal(item.id_portal) }}
                            </template>

                            <template #item-id_idioma="item">
                                {{ getNameLanguage(item.id_idioma) }}
                            </template>
                        </Vue3EasyDataTable>
                    </template>
                    <template v-else>
                        <span>Sin datos</span>
                    </template>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12" md="2" xl="2">
                    <v-btn
                        variant="outlined"
                        @click="btnSavePressed"
                        class="btn button-primary"
                        :loading="loading"
                    >
                        Aceptar
                    </v-btn>
                </v-col>
                <v-col cols="12" md="2" xl="2">
                    <v-btn
                        variant="outlined"
                        class="btn button-primary"
                        @click="openModalRevert"
                    >
                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getNameLanguage, getNamePortal} from "../../utils/portal";
import {getLogElementReplacementWS, revertElementChangesWS} from "@/utils/find_replace_text";
import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
    name: "ModalRevertReplaced",
    methods: {getNameLanguage, getNamePortal, variableIsDefined},
    components: {Vue3EasyDataTable, BaseModalTemplate},
    props: {
        openModalRevert: Function,
        log_id: Number,
        revert_data: Object,
        loadNewElements: Function,
    },
    setup(props) {
        let loading = ref(false)
        let changed_urls = ref([]);
        const {configuration, replace, search, strict, search_by_regex} = props.revert_data
        const headers = [
            {text: 'ID URL', value: 'id_url'},
            {text: 'URL', value: 'url'},
            {text: 'Portal', value: 'id_portal'},
            {text: 'Idioma', value: 'id_idioma'},
        ]

        onBeforeMount(async () => {
            loading.value = true
            let response = await getLogElementReplacementWS(props.log_id)
            if (response['result']) {
                changed_urls.value = response['data'][0].response.URLs
            } else {
                showNotification('error', response['errors'])
            }
            loading.value = false
        })

        //CREAR NUEVO ELEMENTO EN COLA
        const btnSavePressed = async () => {
            loading.value = true
            const response = await revertElementChangesWS({configuration: JSON.parse(configuration), replace: search, search: replace, strict, urls: changed_urls.value, search_by_regex: search_by_regex}, props.log_id)

            if (response['result']){
                await props.loadNewElements()
                props.openModalRevert()
            }else{
                showNotification('error', response['errors'])
            }
            loading.value = false
        }

        return {
            btnSavePressed,
            changed_urls,
            loading,
            headers
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/styles/components/modal-log.scss";
</style>