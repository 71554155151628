<template>
    <QueueTemplate
        :change-element-state="changeStatusElementsDiplomasWS"
        :get-all-queue-elements="getQueuedElementsDiplomaWS"
        :headers="headers"
        page_title="Gestión diplomas encolados"
        :custom_item_slots="['item-titulo_logo','item-id_portal', 'item-id_idioma','item-urls']"
        @send-element-data="receiveLogDiplomas"
        process_key_name="id_lote"
    >
        <template #custom-title>
            <span class="main-title-page">
                <img :src="require('@/assets/icons/navbar/cola-diplomas.svg')" alt="icono de diplomas encolados" width="28" height="28">
                Gestión diplomas encolados
            </span>
        </template>
        <!--  TEMPLATE PARA LOS FILTROS    -->
        <template #queue-filters="{form_data}">
            <v-col cols="12" sm="6" md="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model.number="form_data.diploma_id"
                    type="number"
                    placeholder="Buscar por ID del diploma..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    persistent-hint
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.url_name"
                    placeholder="Buscar por url..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                ></v-text-field>
            </v-col>
        </template>

        <!-- TEMPLATES PARA LOS HEADER DE LA TABLA   -->
        <template #item-titulo_logo="{item}">
            <a class="logo-url" :href="item.url_diploma" target="_blank"> {{ item.titulo_diploma }} </a>
        </template>
        <template #item-id_portal="{item}">
            {{ getNamePortal(parseInt(item.id_portal)) }}
        </template>
        <template #item-id_idioma="{item}">
            {{ getNameLanguage(parseInt(item.id_idioma)) }}
        </template>
        <template #item-urls="{item}">
            <v-btn
                class="btn button-bg-white"
                @click="selectElement(item.urls)"
            >
                VER URLs
            </v-btn>
        </template>
    </QueueTemplate>
    <!--MODAL URL-->
    <v-dialog v-model="show_element_urls_modal" persistent>
        <ModalUrls
            :openModalURLs="openModalUrls"
            :element_urls="selected_elements_urls"
            title_url="Diploma aplicado a cursos"
        ></ModalUrls>
    </v-dialog>
    <!--MODAL LOG-->
    <v-dialog v-model="show_element_log_modal" persistent>
        <ModalLogImages
            :openModalLogs="openModalLog"
            :element_log="data_log"
            :loading_log="loading_log"
            title_log="Log Diplomas"
        ></ModalLogImages>
    </v-dialog>
</template>

<script>
import QueueTemplate from "@/components/Generic/QueueTemplate.vue";
import {
    changeStatusElementsDiplomasWS,
    getLogElementDiplomaWS,
    getQueuedElementsDiplomaWS
} from "@/utils/diplomas_queue";
import {ref} from "vue";
import {getNameLanguage, getNamePortal} from "../../utils/portal";
import ModalUrls from "@/components/QueueImages/ModalUrls.vue";
import ModalLogImages from "@/components/QueueImages/ModalLogImages.vue";
import {showNotification} from "@/utils/aux_functions";
export default {
    name: "DiplomasQueueView",
    methods: {changeStatusElementsDiplomasWS, getNameLanguage, getNamePortal, getQueuedElementsDiplomaWS},
    components: {ModalLogImages, ModalUrls, QueueTemplate},

    setup() {
        let headers = ref([
            {text: 'ID LOTE', value: 'id_lote', sortable: true},
            {text: 'ID DIPLOMA', value: 'id_diploma', sortable: true},
            {text: 'TÍTULO DIPLOMA', value: 'titulo_diploma', width: 200},
            {text: 'PESO', value: 'peso', width: 50},
            {text: 'PORTAL', value: 'id_portal'},
            {text: 'IDIOMA', value: 'id_idioma'},
            {text: 'URLS', value: 'urls'},
            {text: 'FECHA ACTUALIZACIÓN', value: 'updated_at', sortable: true},
            {text: 'EMPLEADO', value: 'username'},
            {text: 'ESTADO', value: 'id_estado'},
            {text: 'REINTENTOS', value: 'reintentos', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let loading_log = ref(false)
        let data_log = ref({})
        let selected_elements_urls = ref()
        let show_element_urls_modal = ref(false)
        let show_element_log_modal = ref(false)


        /*MODAL PARA VER LAS URLS*/
        const openModalUrls = () => {
            show_element_urls_modal.value = !show_element_urls_modal.value
        }
        /*MODAL PARA ABRIR EL LOG*/
        const openModalLog = () => {
            show_element_log_modal.value = !show_element_log_modal.value
        }

        const selectElement = (urls) => {
            selected_elements_urls.value = Array.from(urls)
            openModalUrls()
        }

        const receiveLogDiplomas = async (element_data) => {
            openModalLog()
            await getLogDiplomas(element_data.id_lote)
        }

        /*LLAMADA PARA OBTENER EL LOG PASANDO EL ID_LOGO*/
        async function getLogDiplomas(id_lote) {
            loading_log.value = true
            let response = await getLogElementDiplomaWS(id_lote)
            if (response['result']) {
                data_log.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_log.value = false
        }


        return {
            selectElement,
            openModalUrls,
            openModalLog,
            receiveLogDiplomas,

            headers,
            selected_elements_urls,
            show_element_urls_modal,
            show_element_log_modal,
            data_log,
            loading_log,

        }
    }
}
</script>

<style scoped lang="scss">
</style>