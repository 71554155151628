<template>
    <BaseModalTemplate
        :title="title_form"
        :open-close-dialog="displayModalImgRanking"
        :loading="loading"
        card_extra_classes="card-modal-checkboxs"
    >
        <template #body-modal-content>
            <v-row>
                <CheckBoxesImage
                    :all_ranking="all_ranking"
                    :ranking_actives_course="ranking_actives_course"
                    :checkIdActive="checkIdActive"
                    :selectCheckBox="selectCheckBox"
                    :ranking="true"
                ></CheckBoxesImage>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row class="justify-content-center justify-content-sm-end">
                <v-col cols="12" sm="4" md="3" lg="2" class="text-sm-end" >
                    <v-btn class="btn button-primary"
                           @click="refreshImage()"
                           :loading="loading"
                    >
                        Recargar
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg='2' class="text-sm-end me-md-2">
                    <v-btn class="btn button-primary"
                           @click="addSelection(ranking_selected, img_ranking_selected)">
                        Guardar Imagen
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getAllRankingsUrlWS, getAllRankingWS} from "@/utils/ranking";
import {showNotification} from "@/utils/aux_functions";
import CheckBoxesImage from "@/components/Generic/CheckBoxesImage.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalImageRanking",
    components: {BaseModalTemplate, CheckBoxesImage},
    props: {
        displayModalImgRanking: Function,
        title_form: String,
        id_url: String,
        addSelection: Function,
    },
    setup(props) {
        let loading = ref(false);
        let all_ranking = ref({})
        let check_boxes_active = ref([])
        let ranking_actives_course = ref([])
        let ranking_selected = ref()
        let img_ranking_selected = ref()

        onBeforeMount(async () => {
            loading.value = true
            await getAllRanking()
            await rankingCourse()
            loading.value = false
        })

        async function getAllRanking() {
            let response = await getAllRankingWS();
            if (response['result']) {
                all_ranking.value = response['data']
            } else {
                showNotification('error', response['errors']);
            }
        }

        async function rankingCourse() {
            let response = await getAllRankingsUrlWS(props.id_url);
            if (response['result']) {
                check_boxes_active.value = response['data'].rankings_list
                check_boxes_active.value.forEach((ranking_active) => {
                    ranking_actives_course.value.push(ranking_active.id_ranking)
                })
            } else {
                showNotification('error', response['errors']);
            }
        }

        function selectCheckBox(img_ranking, new_img_ranking) {
            if (new_img_ranking.length > check_boxes_active.value.length + 1) {
                new_img_ranking.splice(new_img_ranking.length - 2, 1)
                ranking_selected.value = new_img_ranking[new_img_ranking.length - 1]
            } else {
                ranking_selected.value = new_img_ranking[new_img_ranking.length - 1]
            }
            img_ranking_selected.value = img_ranking
        }


        function checkIdActive(id_ranking) {
            let active = check_boxes_active.value.findIndex((ranking) => ranking.id_ranking === id_ranking)
            return active >= 0;
        }

        async function refreshImage() {
            loading.value = true
            await getAllRanking()
            await rankingCourse()
            loading.value = false
        }


        return {
            refreshImage,
            loading,
            all_ranking,
            ranking_actives_course,
            selectCheckBox,
            checkIdActive,
            check_boxes_active,
            ranking_selected,
            img_ranking_selected,
        }
    }
}
</script>


<style lang="scss">
@import "@/styles/components/modal-image-degree";
</style>