<template>
    <v-container>
        <template v-if="general_loading">
            <Loading/>
        </template>

        <template v-else>
            <v-row>
                <v-col cols="12" sm="9" md="9" lg="9">
                    <span class="main-title-page">{{ id_url_course }}</span>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" class="col-btn-title" v-if="variableIsDefined(id_url_course)">
                    <v-btn class="btn button-bg-blue" @click="displayDialog" :disabled="loading">ASIGNAR</v-btn>
                </v-col>
                <v-col cols="12" v-else><span class="error-msg">La url no contiene ningún curso</span></v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="btn button-primary" @click="displayAllDegrees">Previsualizar todas las titulaciones
                        del curso
                    </v-btn>
                </v-col>
            </v-row>
            <div class="text-start" v-if="loading">
                <img class="loading-gif-s" id="loading-gif" :src="require('@/assets/img/loading.gif')"
                     alt="Loading Gif">
            </div>

            <div v-else v-if="variableIsDefined(id_url_course)">
                <DraggableQualificationsTemplate
                    :degree_data="degree_data"
                    :logos_list="list_logos"
                    :diploma_list="list_diplomas"
                    :id_element="id_url_course"
                    :getAllDegreesUrl="getAllDegrees"
                    type_component="qualifications"
                    txt_not_data_diplomas="No hay diplomas asociados a este curso"
                    txt_not_data_logos="No hay logos asociados a este curso"
                ></DraggableQualificationsTemplate>

                <v-dialog v-model="display" scrollable persistent>
                    <ModalDegreesCourse
                        :id_url_course="id_url_course"
                        :displayDialog="displayDialog"
                        :getAllDegreesUrl="getAllDegreesCourse"
                        :degree_data="degree_data"
                        :changeDegreeData="changeDegreeData"
                        type_component="qualifications"
                        title_dialog="Editar titulaciones"
                    ></ModalDegreesCourse>
                </v-dialog>

                <v-dialog v-model="display_all_degrees" scrollable persistent>
                <ModalAllDegrees
                    :displayDialog="displayAllDegrees"
                    :id_url_course="id_url_course"
                ></ModalAllDegrees>
                </v-dialog>
            </div>
        </template>
    </v-container>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import ImageCard from "@/components/ImagesLibrary/ImageCard.vue";
import ModalDegreesCourse from "@/components/DegreesCourse/ModalDegreesCourse.vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {VueDraggableNext} from 'vue-draggable-next'
import Loading from "@/components/Generic/Loading.vue";
import DraggableQualificationsTemplate from "@/components/DegreesCourse/DraggableQualificationsTemplate.vue";
import useQualifications from "@/utils/composables/use_qualifications.js";
import ModalAllDegrees from "@/components/DegreesCourse/ModalAllDegrees.vue";

export default {
    name: "LogosCourseView",
    methods: {variableIsDefined},
    components: {
        ModalAllDegrees,
        DraggableQualificationsTemplate,
        Loading,
        ModalDegreesCourse,
        ImageCard,
        draggable: VueDraggableNext
    },

    setup() {
        let router = useRouter();
        let route = useRoute();

        let id_url_course = ref(''); //Nombre de la url
        let general_loading = ref(false)

        let display_all_degrees = ref(false)
        let display = ref(false);
        let loading = ref(false)

        let show_save_btn = ref(false);

        const {
            list_diplomas,
            list_logos,
            degree_data,
            getAllDegrees,
            changeDegreeData,
            fillDegreeData,
        } = useQualifications()

        onBeforeMount(async () => {
            general_loading.value = true
            await getUrlRoute()
            await getAllDegrees('url', id_url_course.value)
            await fillDegreeData();
            general_loading.value = false
        })

        async function getUrlRoute() {
            await router.isReady()
            if (Object.entries(route.query).length !== 0) {
                id_url_course.value = route.query['url-curso'];
            }
        }

        async function getAllDegreesCourse() {
            await getAllDegrees('url', id_url_course.value)
        }

        function displayDialog() {
            display.value = !display.value;
        }

        function displayAllDegrees() {
            display_all_degrees.value = !display_all_degrees.value;
        }


        return {
            getAllDegrees,
            changeDegreeData,
            displayAllDegrees,
            displayDialog,
            getAllDegreesCourse,

            general_loading,
            display_all_degrees,
            display,
            loading,
            list_logos,
            degree_data,
            id_url_course,
            list_diplomas,
            show_save_btn,
        }
    }
}
</script>

<style lang="scss">
.title-section {
    font-size: 15px;
    font-weight: bold;
    color: $gray-medium-bold;
}

.subtitle-section {
    font-style: italic;
    font-size: 13px;
}

.degree-row {
    flex-direction: column;
}

.col-btn-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.draggable_image {
    &:hover {
        cursor: move;
    }
}
.v-btn__content {
    grid-area: content;
    justify-content: center;
    white-space: normal;
}
</style>