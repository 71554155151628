<template>
    <BaseModalTemplate
        :title="title_form_status"
        :open-close-dialog="displayModal"
        :loading="loading"
        :send-form="saveStatus"
        card_extra_classes="modal-width-xs"
    >
        <template #body-modal-content>
            <v-row>
                <!--NOMBRE-->
                <v-col cols="12">
                    <span class="input-title">Nombre del estado *</span>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model.trim="form_data.name"
                        :class="{error :form_error.name}"
                        class="input-error text-field-primary"
                        variant="underlined"
                        hide-details
                    ></v-text-field>
                    <span v-if="form_error.name" class="input-error msg-error">{{
                            form_error.name
                        }}</span>
                </v-col>

                <!--DESCRIPTION-->
                <v-col cols="12">
                    <span class="input-title">Descripción del estado *</span>
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        v-model.trim="form_data.description"
                        :class="{error :form_error.description}"
                        variant="outlined"
                        class="input-error text-area-primary personalize-scroll"
                        outline
                        bg-color="white"
                        density="compact"
                        rows="3"
                        hide-details
                    ></v-textarea>
                    <span v-if="form_error.description" class="input-error msg-error">{{
                            form_error.description
                        }}</span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {updateStatusCommentWS, insertStatusCommentWS} from "@/utils/comment_status";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";


export default {
    name: "FormStatus",
    components: {BaseModalTemplate, Loading},
    props: {
        getAllStatusComments: Function,
        displayModal: Function,
        title_form_status: String,
        status_selected: Object
    },
    setup(props) {
        let loading = ref(false)
        let form_data = ref({})
        let form_error = ref({})

        onBeforeMount(async () => {
            loading.value = true
            if (variableIsDefined(props.status_selected)) {
                form_data.value = _.cloneDeep(props.status_selected)
            }
            loading.value = false
        })

        async function validation() {
            form_error.value = {};
            let regex_name = /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s\º\ª']+$/;

            let schemaForm = Yup.object().shape({
                name: Yup.string().matches(regex_name, {
                    message: 'Introduce un dato válido',
                    nullable: false
                }).required('El campo es obligatorio'),
                description: Yup.string().required('El campo es obligatorio')
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                });
            }
        }

        async function saveStatus() {
            await validation()
            if (Object.keys(form_error.value).length === 0) {

                let json_status_data = JSON.stringify(form_data.value)
                if (!variableIsDefined(props.status_selected)) {
                    let response = await insertStatusCommentWS(json_status_data)
                    if (response['result']) {
                        showNotification('success', 'Estado creado correctamente')
                    } else {
                        showNotification('error', response['errors'])
                    }
                } else {
                    let response = await updateStatusCommentWS(props.status_selected.id_status, json_status_data)
                    if (response['result']) {
                        showNotification('success', 'Estado actualizado correctamente')
                    } else {
                        showNotification('error', response['errors'])

                    }
                }
                props.displayModal()
                props.getAllStatusComments()
            }
        }


        return {
            saveStatus,
            loading,
            form_data,
            form_error,
        }
    }
}
</script>

<style scoped lang="scss">
</style>