<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Listado de grupos por roles</span>
            </v-col>
        </v-row>
        <template v-if="loading_page">
            <Loading/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" sm="7" md="6" lg="4">
                    <v-select
                        class="input-error select-secondary"
                        placeholder="Selecciona un rol"
                        item-title="name"
                        item-value="id_rol"
                        v-model="rol_selected"
                        :items="rol_list"
                        @update:modelValue="getAllGroupsByRol(rol_selected.id_rol)"
                        variant="outlined"
                        return-object
                        hide-details
                        no-data-text="No hay datos disponibles"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item
                                v-bind="props"
                                :title="item?.raw?.name"
                            >
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div>
                                <span>{{ item?.raw?.name }}</span>
                            </div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row justify="start">
                <v-col cols="12" sm="7" md="8" class="users-col">
                    <div class="list-title">Lista de grupos</div>
                    <template v-if="loading_group" class="text-start">
                        <img class="loading-gif-s" id="loading-gif" :src="require('@/assets/img/loading.gif')"
                             alt="Loading Gif">
                    </template>
                    <template v-else>
                        <template v-if="show_groups">
                            <template v-if="groups_list.length > 0">
                                <v-list class="personalize-scroll list-users">
                                    <v-list-item
                                        class="text-list"
                                        v-for="group in groups_list"
                                        :key="group.gidNumber"
                                        :title="`${group.name} (${group.gidNumber})`"
                                        :value="group.name"
                                        color="blue"
                                        @click="showUserGroup(group.name)"
                                    ></v-list-item>
                                </v-list>
                            </template>
                            <template v-else>
                                <span>No hay grupos asignados en este rol</span>
                            </template>
                        </template>
                        <template v-else>
                            <div class="default-text">
                                <span>Selecciona un rol para mostrar los grupos</span>
                            </div>
                        </template>
                    </template>

                </v-col>
                <v-col cols="12" sm="5" md="4" class="users-col">
                    <template v-if="show_user_group">
                        <div class="list-title">Usuarios del grupo</div>
                        <template v-if="loading_user" class="text-start">
                            <img class="loading-gif-s" id="loading-gif" :src="require('@/assets/img/loading.gif')"
                                 alt="Loading Gif">
                        </template>
                        <template v-else>
                        <v-list class="personalize-scroll list-users" v-if="user_group.length>0">
                            <v-list-item
                                class="text-list"
                                v-for="user in user_group"
                                :key="user.nombre_usuario"
                                :title="user.nombre_usuario"
                            ></v-list-item>
                        </v-list>
                        <span v-else>No hay usuarios para el grupo seleccionado</span>
                        </template>
                    </template>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification} from "@/utils/aux_functions";
import {getAllRolesWS, getAllGroupsByRolWS, getAllUsersByGroupWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerUserRolesView",
    components: {Loading},

    setup() {
        const route = useRoute();
        let rol_list = ref([]);
        let rol_selected = ref([]);
        let show_groups = ref(false);
        let groups_list = ref([]);
        let loading_group = ref(false);
        let loading_page = ref(false);
        let loading_user = ref(false)
        let user_group = ref([])
        let show_user_group = ref(false)

        onBeforeMount(async () => {
            loading_page.value = true;
            await hasPermission(route);
            await loadAllRoles();
            loading_page.value = false;
        })


        async function loadAllRoles() {
            let response = await getAllRolesWS();
            if (response['result']) {
                rol_list.value = response['data'];
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function getAllGroupsByRol(rol_id) {
            loading_group.value = true;
            user_group.value=[]
            show_user_group.value = false
            let response = await getAllGroupsByRolWS(rol_id)
            if (response['result']) {
                groups_list.value = response['data'];
            } else {
                showNotification('error', response['errors'])
            }
            loading_group.value = false;
            show_groups.value = true;
        }

        async function showUserGroup(group_selected){
            show_user_group.value = []
            loading_user.value = true
            let response = await getAllUsersByGroupWS(group_selected)
            if (response['result']){
                show_user_group.value = true
                user_group.value = response['data']
            }else{
                showNotification('error', response['errors'])
            }
            loading_user.value = false
        }

        return {
            getAllGroupsByRol,
            showUserGroup,
            rol_list,
            groups_list,
            rol_selected,
            show_groups,
            loading_group,
            loading_page,
            loading_user,
            user_group,
            show_user_group,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/admin/manager-user-roles.scss";
</style>