<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <div class="access-deny">
                    <img :src="require('@/assets/img/warning.svg')" class="logo-access" alt="Plus">
                    <p class="text-access">No tienes permiso para acceder a este lugar</p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: "AccessDenyView",
}
</script>

<style scoped lang="scss">
.access-deny {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    height: 90vh;
    align-items: center;


    .logo-access {
        width: 12vw;
        height: auto;
    }

    .text-access {
        margin-top: 30px;
        letter-spacing: 1px;
        font-size: 25px;
        color: $primary-color-2;
        font-weight: bold;
    }
}


@media (max-width: 1024px) {
    .access-deny {
        .logo-access {
            width: 20vw;
        }

        .text-access {
            font-size: 25px;
            text-align: center;
        }
    }
}

@media (max-width: 700px) {
    .access-deny {
        .logo-access {
            width: 40vw;
        }

        .text-access {
            font-size: 21px;
            text-align: center;
        }
    }
}

</style>