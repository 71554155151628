<template>
    <BaseModalTemplate
        :title="`Actualizar imagen ${image.fileName}`"
        :open-close-dialog="displayDialog"
        card_extra_classes="w-75"
        :send-form="submitForm"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">Nueva imagen*</span>
                </v-col>
                <v-col cols="12">
                    <v-img class="preview-image" v-if="preview" :src="preview"></v-img>
                    <v-file-input
                        accept="image/*"
                        prepend-icon="mdi-camera"
                        v-model="form_data.new_image"
                        type="file"
                        label="Ingrese nueva imagen"
                        hide-details="auto"
                        variant="underlined"
                        chips
                        show-size
                        :rules="file_rules"
                        class="file-input-primary underlined"
                        :class="{error :form_error.new_image}"
                        @change="previewImage(form_data.new_image)"
                        @click:clear="clearImage"
                    ></v-file-input>
                    <span v-if="variableIsDefined(form_error.new_image)"
                          class="input-error msg-error">Debe añadir una imagen</span>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row justify="end">
                <v-col cols="12" md="2" xl="2">
                    <v-btn
                        variant="outlined"
                        @click="submitForm"
                        class="btn button-primary"
                        :loading="loading_save"
                    >
                        Actualizar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {updateFileWS} from "@/utils/image_library";
import * as Yup from "yup";

export default {
    name:"ModalUpdateImage",
    methods: {variableIsDefined},
    components: {BaseModalTemplate},
    props: {
        displayDialog: Function,
        image: Object,
    },

    setup(props) {
        let image_name = ref('');
        let preview = ref('');

        let form_data = ref({})
        let form_error = ref({});

        let loading_save = ref(false)

        let file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']

        //PREVISUALIZACIÓN DE LA IMAGEN
        function previewImage() {
            let reader = new FileReader();
            let file = form_data.value.new_image

            if (variableIsDefined(file)) {
                reader.readAsDataURL(file);

                reader.onload = e => {
                    preview.value = e.target.result;
                }
            }
        }

        function clearImage() {
            preview.value = ""
            form_data.value.new_image = null;
        }

        //ENVIO Y VALIDACIÓN DE FORMULARIOS
        async function submitForm() {
            if (await validateFormData()) {
                loading_save.value = true

                let response = await updateFileWS(form_data.value, props.image.id_file);
                if(response['status'] === 200){
                    if(response['data']['data']['success']){
                        showNotification('success', 'Imagen subida correctamente')
                        location.reload();
                    }else{
                        showNotification('error', response['data']['errors'])
                    }
                }else{
                    showNotification('error', 'Error al hacer la petición: files/uploadFile')
                }

                loading_save.value = false
                props.displayDialog();
            }
        }

        async function validateFormData() {
            let correct = true;
            form_error.value = {};

            const schemaForm = Yup.object().shape({
                new_image: Yup.mixed().required(),
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                correct = true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                    correct = false
                })
            }
            return correct;
        }

        return {
            submitForm,
            previewImage,
            clearImage,

            form_data,
            file_rules,
            image_name,
            form_error,
            preview,
            loading_save
        }
    }
}

</script>

<style scoped lang="scss">

</style>