<template>
    <v-col>
        <template v-if="loading">
            <Loading class_loading="loading-gif-s"/>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <span class="title-table-preview">Previsualización cambios texto masivo</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cosl="auto">
                    <span v-if="!form_data.search_by_regex" class="subtitle-table-preview">Palabra buscada:</span>
                    <span v-else class="subtitle-table-preview">Expresión usada:</span>
                    <span class="ms-2">{{ form_data_preview.word_search }}</span>
                </v-col>
                <v-col cosl="auto">
                    <span class="subtitle-table-preview">Palabra reemplazada:</span>
                    <span class="ms-2"> {{ form_data_preview.word_replace }} </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-table density="compact"
                             class="personalize-scroll personalize-table-simple">
                        <thead>
                        <tr>
                            <th class="text-left" style="font-weight: bold">
                                Tabla
                            </th>
                            <th class="text-left" style="font-weight: bold">
                                Campo
                            </th>
                            <th class="text-left" style="font-weight: bold">
                                Total registros
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="config in form_data_preview.total_field"
                        >
                            <td>{{ config.table }}</td>
                            <td>{{ config.field }}</td>
                            <td>{{ config.coincidences }}</td>

                        </tr>
                        </tbody>
                    </v-table>
                </v-col>
                <v-col cols="12" md="6" v-if="form_data_preview.warning.length>0">
                    <v-card></v-card>
                    <v-table density="compact"
                             class="personalize-scroll personalize-table-simple">
                        <thead class="personalize-scroll">
                        <tr>
                            <th class="text-left" style="font-weight: bold">
                                Información
                            </th>
                        </tr>
                        </thead>
                        <tbody class="personalize-scroll">
                        <tr
                            v-for="warning in form_data_preview.warning"
                        >
                            <td>{{ warning }}</td>

                        </tr>
                        </tbody>
                    </v-table>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <div :class="{ 'disabled-table': reload_table }">
                        <Vue3EasyDataTable
                            table-class-name="primary-table"
                            theme-color="#F4A649"
                            buttons-pagination
                            :headers="headers"
                            :items="items_table"
                            :loading="loading_table"
                            v-model:server-options="server_options"
                            :server-items-length="server_items_length"
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #expand="{original, original_ampliado, modificado_ampliado}">
                                <div class="box-extended">
                                    <span class="title-txt-extended">Registro original ampliado:</span><br>
                                    <span class="txt-entended">{{ original_ampliado }}</span><br>
                                    <span class="title-txt-extended">Registro reemplazado ampliado:</span><br>
                                    <span class="txt-entended">{{ modificado_ampliado }}</span>
                                </div>
                            </template>
                        </Vue3EasyDataTable>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-end">
                    <v-btn class="btn button-bg-blue" :loading="loading"
                           @click="confirmReplacement"
                           :disabled="deactivate_btn || loading_table">REEMPLAZAR TODOS
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-col>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import Loading from "@/components/Generic/Loading.vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {previewSearchReplaceWS} from "@/utils/find_replace_text";

export default {
    name: "PreviewReplace",
    methods: {variableIsDefined},
    components: {Loading, Vue3EasyDataTable},
    props: {
        loading: Boolean,
        setElement: Function,
        loading_btn: Boolean,
        config_table: Array,
        form_data: Object,
        config_select: Array,
        server_options: Object,
        item_portal_language: Array,
        loading_btn_replace: Boolean

    },
    emits: ['loading_table'],

    setup(props, {emit}) {
        const headers = ref([
            {text: 'URL', value: 'url'},
            {text: 'REGISTRO ORIGINAL', value: 'original'},
            {text: 'REGISTRO MODIFICADO', value: 'modificado'},
            {text: 'TABLA MODIFICADA', value: 'table'},
            {text: 'CAMPO', value: 'field'},
        ]);
        const server_items_length = ref(0)
        const server_options = ref({
            page: 1,
            rowsPerPage: 10,
        })
        let items_table = ref([])
        let form_data_preview = ref({
            word_search: '',
            word_replace: '',
            warning: '',
            total_field: '',
        })
        let deactivate_btn = ref(false)
        let loading_table = ref(false)
        let reload_table = ref(false)


        onBeforeMount(async () => {
            initSweetAlert()
        })

        watch(server_options, async (oldValue, newValue) => {
                if (oldValue.page !== newValue.page || oldValue.rowsPerPage !== newValue.rowsPerPage) {
                    emit('loading_table', true)
                    await previewSearchReplace()
                    reload_table.value = false
                    emit('loading_table', false)
                }
            },
            {deep: true}
        );

        watch(
            [() => props.form_data.replace_word, () => props.form_data.search_word,
                () => props.form_data.iso_2, () => props.form_data.strict, () => props.form_data.all_portal],
            async ([replaceWordNew, searchWordNew, iso_2New, strictNew, all_portalNew], [replaceWordOld, searchWordOld, iso_2Old, strictOld, all_portalOld]) => {
                if (loading_table.value === false) {
                    if (replaceWordNew !== replaceWordOld || iso_2New !== iso_2Old || searchWordNew !== searchWordOld || strictNew !== strictOld || all_portalNew !== all_portalOld) {
                        reload_table.value = true
                    }
                }
            }
        );

        watch(() => props.config_select, async (oldValue, newValue) => {
            if (loading_table.value === false) {
                if (oldValue !== newValue) {
                    reload_table.value = true
                }
            }
        });

        watch(() => props.item_portal_language, async (oldValue, newValue) => {
            if (oldValue !== newValue) {
                reload_table.value = true
            }
        });

        async function previewSearchReplace() {
            loading_table.value = true
            deactivate_btn.value = false
            reload_table.value = false

            if (props.loading_btn_replace === true) {
                server_options.value.page = 1
            }
            let response = await previewSearchReplaceWS(props.config_table, props.form_data, server_options.value.page, server_options.value.rowsPerPage)
            items_table.value = []
            server_items_length.value = 0
            if (response['result']) {
                const {parameters, warnings, configuration, changes} = response['data'];

                if (configuration.length !== 0) {
                    form_data_preview.value.total_field = configuration;
                    if (changes) {
                        items_table.value = changes['data'];
                        server_items_length.value = changes.total
                        if (changes.data.length === 0) {
                            deactivate_btn.value = true
                        }
                    } else {
                        deactivate_btn.value = true
                    }
                }

                form_data_preview.value.word_replace = parameters.replace;
                form_data_preview.value.word_search = parameters.search;
                form_data_preview.value.warning = warnings;

            } else {
                showNotification('error', response['errors'])
            }
            loading_table.value = false
        }

        function confirmReplacement() {
            fireSweetAlert('Confirmar reemplazo', props.setElement, 'El proceso se llevará a cabo de manera interna. Podrás verificar el progreso desde la pantalla de colas.')
        }

        return {
            confirmReplacement,
            previewSearchReplace,

            headers,
            server_options,
            server_items_length,
            items_table,
            form_data_preview,
            deactivate_btn,
            loading_table,
            reload_table,
        }
    }
}
</script>


<style scoped lang="scss">
.title-table-preview {
    font-size: 18px;
    font-weight: bold;
    color: $blue-medium;
    display: block;
}

.subtitle-table-preview {
    font-weight: bold;
}

.box-extended {
    padding: 20px;
    border-radius: 5px;

    .title-txt-extended {
        font-weight: bold;
        color: $gray-medium-bold;
        font-size: 16px;
        padding: 50px 0;
    }

    .txt-entended {
        font-weight: normal;
    }

    &hover {
        font-weight: normal;
    }
}

.personalize-table-simple {
    overflow-y: auto;
    max-height: 18vh;
}

@media (max-width: 600px) {
    .subtitle-table-preview {
        font-size: 12px;
    }
    .box-extended {
        .title-txt-extended {
            font-size: 14px;
        }
    }
}

.disabled-table {
    pointer-events: none;
    opacity: 0.5;
}

</style>