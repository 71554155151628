<template>
    <v-row>
        <v-col cols="12" xl="3" lg="4" sm="6"
               v-for="result in results"
               class="search-section__search-result"
        >
            <div class="sui-results-container edit-sui-results-container search-section__search-results">
                <SearchResult :result="result"/>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import SearchResult from "./SearchResult.vue";

export default {
    components: {
        SearchResult
    },
    props: {
        results: {
            type: Array,
            required: true
        }
    }
};
</script>

<style lang="scss">
@import "@/styles/components/search-engine/search-results.scss";
</style>
