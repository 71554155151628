<template>
    <BaseModalTemplate
        :title=" `${title_modal}  redactor`"
        :hide_actions="disable_update"
        :open-close-dialog="displayDialog"
    >
        <template #body-modal-content>
            <v-form
                v-model="valid"
                :disabled="disable_update === true"
            >
                <v-row>
                    <!--Imagen de perfil-->
                    <v-col
                        cols="12">
                                <span class="input-title">
                                        Imagen de perfil</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-img v-if="image" class="preview" :src="image"></v-img>
                        <v-file-input v-if="!disable_update"
                              accept="image/png, image/jpeg, image/webp, image/jpg"
                              prepend-icon="mdi-camera"
                              placeholder="Ingrese su foto"
                              type="file"
                              show-size
                              :rules="file_rules"
                              v-model="form_data.profile_image"
                              @change="previewImage(form_data.profile_image)"
                              @click:clear="clearImage"
                              label="Ingrese su imagen"
                              hide-details="auto"
                              variant="underlined"
                              chips
                              class="file-input-primary underlined"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <!--Nombre usuario-->
                    <v-col
                        cols="12">
                                    <span class="input-title">
                                            Identificador innotutor *</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            class="input-error select-primary"
                            placeholder="Pulse para asignar usuario"
                            v-model="selected_user"
                            :items="list_available_users"
                            @update:modelValue="selectCopywriter()"
                            variant="underlined"
                            return-object
                            hide-details
                            :disabled="disable_update || disable_username"
                            :class="{error :variableIsDefined(form_error.id_author)}"
                        >
                            <template v-slot:item="{ props, item }">
                                <v-list-item
                                    v-bind="props"
                                    :title="item?.raw?.name"
                                >
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div>
                                    <span>{{ item?.raw?.username }}</span>
                                </div>
                            </template>
                        </v-select>
                        <span v-if="form_error.id_author"
                              class="input-error msg-error"
                        >
                                {{ form_error.id_author }}
                            </span>
                    </v-col>
                </v-row>
                <v-row>
                    <!--Nombre y apellidos-->
                    <v-col
                        cols="12">
                                <span class="input-title">
                                        Nombre y apellidos *</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="form_data.name"
                            placeholder="Nombre y apellidos"
                            required
                            disabled
                            variant="underlined"
                            hide-details
                            class="input-error text-field-primary"
                            :error="variableIsDefined(form_error.name)"
                        ></v-text-field>
                        <span v-if="form_error.name"
                              class="input-error msg-error"
                        >
                                {{ form_error.name }}
                            </span>
                    </v-col>
                </v-row>

                <v-row>
                    <!--Descripción-->
                    <v-col cols="12">
                        <span class="input-title">Descripción *</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <SimpleTextEditor
                            :content="form_data.description"
                            min_height='100px'
                            max_height='200px'
                            default_text="Introduce una descripción"
                            :show_error="!!form_error.description"
                            ref="content_description"
                        ></SimpleTextEditor>
                        <span v-if="form_error.description" class="input-error msg-error">
                                {{ form_error.description }}
                            </span>

                    </v-col>
                </v-row>

                <v-row>
                    <!--URL-->
                    <v-col cols="12">
                            <span class="input-title">
                                URL *
                            </span>
                        <p class="m-0">Landing url del autor</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="form_data.url"
                            placeholder="Url"
                            required
                            variant="underlined"
                            hide-details
                            class="input-error text-field-primary"
                            :class="{error :variableIsDefined(form_error.url)}"
                        ></v-text-field>
                        <span v-if="form_error.url" class="input-error msg-error">
                                {{ form_error.url }}
                            </span>
                    </v-col>
                </v-row>
                <v-row>
                    <!--Educación-->
                    <v-col
                        cols="12"><span class="input-title">Educación *</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <SimpleTextEditor :content="form_data.education"
                                          min_height='100px'
                                          max_height='200px'
                                          default_text="Introduce la educación"
                                          :show_error="!!form_error.education"

                                          ref="content_education"
                        ></SimpleTextEditor>
                        <span v-if="form_error.education" class="input-error msg-error">
                                  {{ form_error.education }}
                            </span>
                    </v-col>
                </v-row>
                <v-row>
                    <!--Experiencia-->
                    <v-col cols="12">
                        <span class="input-title">Experiencia *</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <SimpleTextEditor :content="form_data.experience"
                                          min_height='100px'
                                          max_height='200px'
                                          default_text="Introduce la experiencia"
                                          :show_error="!!form_error.experience"
                                          ref="content_experience"
                        ></SimpleTextEditor>
                        <span v-if="form_error.experience" class="input-error msg-error">
                                {{ form_error.experience }}
                            </span>
                    </v-col>
                </v-row>
                <v-row>
                    <!--Temas-->
                    <v-col cols="12">
                            <span class="input-title">
                                Temas sobre los que escribe *
                            </span>
                    </v-col>
                </v-row>
                <v-row class="align-items-center">
                    <v-col v-if="!disable_update" cols="12" xs="12" sm="8" md="9" lg="9">
                        <v-text-field
                            type="text"
                            v-model="theme"
                            @keyup.enter="addThemes()"
                            placeholder="Escribe sobre"
                            required
                            variant="underlined"
                            hide-details
                            class="input-error text-field-primary"
                            :class="{error :form_error.themes}"
                        ></v-text-field>
                        <span v-if="form_error.themes"
                              class="input-error msg-error"
                        >
                                {{ form_error.themes }}
                            </span>
                    </v-col>
                    <v-col v-if="!disable_update" cols="12" xs="12" sm="4" md="3" lg="3">
                        <v-container>
                            <v-btn
                                class="btn button-primary"
                                @click="addThemes"
                                :disabled="disable_update"
                            >Añadir tema
                            </v-btn>
                        </v-container>
                    </v-col>
                    <v-col cols="12" class="boxes-themes">
                        <template v-for="theme in list_themes">
                            <v-card class="box_area_redactor">
                                <v-card-text class="text-area">{{ theme }}</v-card-text>

                                <v-icon v-if="!disable_update" class="icons-close"
                                        @click="deleteTheme(theme)">mdi-close
                                </v-icon>
                            </v-card>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="title_modal !== 'Nuevo'">
                        <p class="input-title mb-0">
                            Linkedin
                        </p>
                        <span>Solo podrá modificar este dato desde Innotutor</span>
                        <v-text-field
                            v-if="variableIsDefined(form_data.linkedin)"
                            v-model="form_data.linkedin"
                            disabled
                            bg-color="white"
                            hide-details
                            class="text-area-primary"
                        >
                        </v-text-field>
                        <p v-else class="pending-data-text m-0"><i>Sin especificar</i></p>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <p class="input-title">
                            Estado redactor
                        </p>
                        <v-checkbox-btn
                            v-model="form_data.active"
                            label="Activado"
                            class="check-active"
                            :disabled="disable_update"
                            :true-value="1"
                            :false-value="0"
                        ></v-checkbox-btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12" class="text-end" v-if="form_error.general">
                    <span class="input-error msg-error">Se ha detectado un error, revise el formulario.</span>
                </v-col>
                <v-col cols="12">
                    <v-btn class="btn button-bg-blue"
                           hide-details
                           @click="sendForm"
                           :loading="disable_btn_save"
                    >
                        Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>


<script>
import {onBeforeMount, ref} from "vue";
import * as Yup from "yup"
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {sendCopywriterDataWS} from "@/utils/copywriters";
import {getAllUsersRolWS, getIDRolWS} from "@/utils/admin_functions";
import {uploadImgWS} from "@/utils/aiseo_petition";
import SimpleTextEditor from "@/components/Editor/SimpleTextEditor.vue";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormCopywriter",
    components: {BaseModalTemplate, Loading, SimpleTextEditor},
    methods: {variableIsDefined},

    props: {
        displayDialog: Function,
        updateAuthorsList: Function,
        disable_update: Boolean,
        title_modal: String,
        copywriter: Object,
    },

    setup(props) {
        const content_description = ref(null)
        const content_education = ref(null)
        const content_experience = ref(null)
        const copywriter_image = ref('')
        const disable_btn_save = ref(false)
        const disable_username = ref(false)
        const form_data = ref({})
        const form_error = ref({})
        const general_loading = ref(false)
        const image = ref(null)
        const list_available_users = ref()
        const list_themes = ref([])
        const regex = /^[a-zA-ZÀ-ÿ {º}{ª}.,-]+$/
        const selected_user = ref(null)
        const theme = ref('')
        const uploading = ref(false)
        const valid = ref(true)
        let file_rules = [files => !files || !files.some(file => file.size > 2e6) || 'ERROR. La imagen debe pesar menos de 2 MB']
        let received_data = _.cloneDeep(props.copywriter)
        let update_image = false

        onBeforeMount(async () => {
            general_loading.value = true
            form_data.value.themes = '';
            loadCopywriter();
            await getAllUsersRol();
            general_loading.value = false
        })

        function selectCopywriter() {
            form_data.value.id_author = selected_user.value.id_user;
            form_data.value.name = selected_user.value.name;
        }

        async function getAllUsersRol() {
            let rol_id = await getIDRolWS('autor')
            if (rol_id) {
                let response = await getAllUsersRolWS(rol_id);
                if (response['result']) {
                    list_available_users.value = response['data'];
                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                showNotification('error', 'No hay usuarios del rol indicado para seleccionar')
            }

        }

        function loadCopywriter() {
            if (Object.keys(received_data).length !== 0) {
                form_data.value = received_data;
                selected_user.value = form_data.value;
                image.value = form_data.value.profile_image;
                form_data.value.profile_image = null;
                form_data.value.id_author = received_data.id_usuario;

                if (variableIsDefined(form_data.value.themes)) {
                    form_data.value.themes.split(';').forEach((item) => {
                        if (item !== '') {
                            list_themes.value.push(item);
                        }
                    });
                    list_themes.value = check_duplicate_items(list_themes.value)
                    form_data.value.themes = list_themes.value.join(";") + ';';
                }
                disable_username.value = true;
            }
        }

        async function sendForm() {
            disable_btn_save.value = true
            let img_correct = await uploadImg();

            if(img_correct) {
                (copywriter_image.value !== null && copywriter_image.value !== '') ?
                    form_data.value.id_profile_image = copywriter_image.value :
                    form_data.value.id_profile_image = null;
                form_data.value.education = content_education.value.received_content || ""
                form_data.value.experience = content_experience.value.received_content || ""
                form_data.value.description = content_description.value.received_content || ""
                await dataValidation();
                if (Object.keys(form_error.value).length === 0) {
                    let response = [];
                    form_data.value.active ? form_data.value.active = 1 : form_data.value.active = 0;
                    form_data.value.update_image = update_image
                    response = await sendCopywriterDataWS(form_data.value, props.title_modal);

                    if (response['result'] && response['data']) {
                        showNotification('success', '¡Autor subido correctamente!');
                        await props.updateAuthorsList();
                        props.displayDialog();
                    } else {
                        showNotification('error', response['errors'])
                    }
                } else {
                    clearImage();
                }
            }else{
                clearImage();
            }

            disable_btn_save.value = false
        }

        async function dataValidation() {
            form_error.value = {};
            const schemaForm = Yup.object().shape({
                url: Yup.string().required(true),
                id_author: Yup.string().required(true),
                education: Yup.string().required(true),
                experience: Yup.string().required(true),
                description: Yup.string().required(true),
                themes: Yup.string().required(true),
            })
            Object.keys(form_data.value).forEach(function (key) {
                if ((key === "url" || key === 'education' || key === 'experience' || key === 'description')) {
                    form_data.value[key] = form_data.value[key].trim()
                }
            })


            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})

            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value.general = true
                    if (error.path === 'themes') {
                        form_error.value.themes = 'Se requiere un tema mínimo'
                    } else {
                        form_error.value[error.path] = 'El campo no puede estar vacío';
                    }
                })

            }
        }

        function check_duplicate_items(list) {
            const list_unique_words = new Set();
            return list.filter((word) => {
                if (list_unique_words.has(word)) {
                    return false;
                }
                list_unique_words.add(word);
                return true;
            });
        }

        async function uploadImg() {
            update_image = false;
            if (variableIsDefined(form_data.value.profile_image)) {
                uploading.value = true

                const response = await uploadImgWS(form_data.value.profile_image, 'redactor', 'imagen', form_data.value.id_author);
                uploading.value = false

                if (response.data.result) {
                    copywriter_image.value = response.data.data['fileID'].toString();
                    showNotification('success', 'Imagen subida correctamente')
                    update_image = true;
                    return true
                }else{
                    return false
                }

            }else{
                return true
            }
        }

        function previewImage() {
            let reader = new FileReader();
            const file = form_data.value.profile_image;

            reader.readAsDataURL(file);

            reader.onload = e => {
                image.value = e.target.result;
            }
        }

        function clearImage() {
            image.value = '';
        }

        function addThemes() {
            form_error.value.themes = ""
            theme.value = theme.value.trim()
            const not_number = /\d+/;
            if (theme.value !== '' && !not_number.test(theme.value) && regex.test(theme.value)) {
                if (!list_themes.value.includes(theme.value)) {
                    form_data.value.themes += theme.value + ';';
                    list_themes.value.push(theme.value);
                    theme.value = '';
                } else {
                    form_error.value.themes = "El tema está duplicado"
                }
            } else {
                form_error.value.themes = "Inserte un dato válido"
            }
            if (variableIsDefined(list_themes.value)) {
                list_themes.value = check_duplicate_items(list_themes.value)
            }
        }

        function deleteTheme(theme) {
            list_themes.value.splice(list_themes.value.findIndex((item) => {
                return item === theme
            }), 1);
            form_data.value.themes = form_data.value.themes.replace(theme + ';', '');
        }


        return {
            sendForm,
            previewImage,
            clearImage,
            addThemes,
            deleteTheme,
            selectCopywriter,


            general_loading,
            image,
            form_data,
            form_error,
            valid,
            theme,
            list_themes,
            disable_username,
            list_available_users,
            selected_user,
            disable_btn_save,
            file_rules,
            content_education,
            content_experience,
            content_description,

        }
    }
}
</script>

<style lang="scss">
.preview {
    max-width: 120px !important;
    max-height: 120px !important;
    border-radius: 11px;
    border: 1px solid $black;
    margin-bottom: 1vh;
}

.check-active {
    color: $blue-medium;

    .v-label {
        color: $black;
        opacity: revert;
    }
}

.boxes-themes {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;

    .box_area_redactor {
        box-shadow: none;
        height: auto;
        width: fit-content;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $blue-medium;
        border: 1px solid $blue-medium;
        margin-right: 1vw;
        margin-bottom: 1vh;

        .text-area {
            padding: 3px 15px;
        }

        .icons-close {
            filter: $filter-sidebar-active;
        }

        .icons-loading {
            display: none;
        }
    }

}
</style>