<template>
    <v-container class="blog-list-container">
        <v-row align="center">
            <v-col cols="6" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/sidebar/icon-blog.svg')" alt="icono blogs" width="28" height="28">
                    Blogs
                </span>
            </v-col>
            <v-col cols="6" lg="2" class="text-end">
                <v-btn
                    class="btn button-border-black btn-icon"
                    prepend-icon="mdi-tune"
                    @click="showHideFilters"
                >
                    Filtros
                </v-btn>
            </v-col>
            <v-col cols="12" class="text-start">
                <span>863 Blogs Publicados </span>
            </v-col>
        </v-row>

        <div v-if="show_filters" class="url-filters-container">
            <NewUrlFilter

            >
                <template #filters>
                    <v-col cols="12">
                        <v-text-field
                            v-model.trim="filter_data.url"
                            class="text-field-secondary"
                            label="Introduce URL"
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                        ></v-text-field>
                        <v-checkbox
                            v-model="filter_data.exact_search"
                            label="Búsqueda exacta"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3"
                           class="align-self-start">
                        <v-select v-model="filter_data.state_urls"
                                  class="input-error select-secondary personalize-text-field field-with-label"
                                  label="Selecciona un estado"
                                  :items="options_state"
                                  item-title="title_state"
                                  item-value="value_state"
                                  bg-color="transparent"
                                  hide-details
                                  no-data-text="No hay datos disponibles"
                        >
                        </v-select>
                    </v-col>

                    <v-col cols="12" sm="4"
                           class="align-self-start">
                        <InputCalendar
                            msg_hint="Filtrar por fecha de creación y de actualización"
                            label_input="Filtro de fecha"
                            :deleteFilters="deleteFilters"
                            ref="filtered_date"
                        ></InputCalendar>


                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-checkbox
                            v-model="filter_data.exact_search"
                            label="Borradores"
                            :true-value="true"
                            :false-value="false"
                            color="blue"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </template>
            </NewUrlFilter>
        </div>


        <v-btn
            class="btn button-bg-blue add-blog-btn btn-icon"
            @click="openCloseNewURLDialog"
            prepend-icon="mdi-plus"
        >
            Alta URL
        </v-btn>
    </v-container>

<!--  MODAL PARA INSERTAR NUEVAS URLS GENÉRICAS  -->
    <v-dialog class=""
              v-model="new_url_open_dialog" persistent>
        <ModalRegisterURL
            :displayDialog="openCloseNewURLDialog"
            title_modal="Alta de URL"
        />
    </v-dialog>

</template>

<script>
import ModalUrl from "@/components/UrlList/ModalUrl.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import CopySEOModal from "@/components/CopySEO/CopySEOModal.vue";
import FormComment from "@/components/Comments/FormComment.vue";
import ModalEditUrl from "@/components/UrlList/ModalEditUrl.vue";
import FilterUrl from "@/components/UrlList/FilterUrl.vue";
import TeachersModal from "@/components/Teachers/TeachersModal.vue";
import Loading from "@/components/Generic/Loading.vue";
import ModalPortal from "@/components/Admin/ManagerPortal/ModalPortal.vue";
import {ref} from "vue";
import ModalRegisterURL from "@/components/UrlList/ModalRegisterURL.vue";
import NewUrlFilter from "@/components/UrlList/NewUrlFilter.vue";
import InputCalendar from "@/components/Generic/InputCalendar.vue";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: 'BlogsView',
    components: {
        InputCalendar,
        NewUrlFilter,
        ModalRegisterURL,
        ModalPortal,
        Loading,
        TeachersModal,
        FilterUrl,
        ModalEditUrl,
        FormComment,
        CopySEOModal,
        Vue3EasyDataTable,
        ModalUrl
    },
    setup(){
        let new_url_open_dialog = ref()
        let show_filters = ref(false)

        //variables para los filtros
        let filter_data = ref({})
        let options_state = ref([
            {title_state: 'Activo', value_state: 1},
            {title_state: 'Inactivo', value_state: 0},
        ])

        const openCloseNewURLDialog = () => {
            new_url_open_dialog.value = !new_url_open_dialog.value;
        }

        const showHideFilters = () => {
            show_filters.value = !show_filters.value
        }

        const deleteFilters = () => {

        }

        return{
            deleteFilters,
            showHideFilters,
            openCloseNewURLDialog,
            options_state,
            show_filters,
            new_url_open_dialog,
            filter_data,
        }
    }
}
</script>

<style lang="scss">
    .blog-list-container {
        .add-blog-btn{
            position: absolute;
            bottom: 30px;
            right: 30px;
        }

        .url-filters-container {
            padding: 20px;
            border: 1px solid $base-text-color;
            border-radius: 16px;
        }
    }
    
    @media (max-width: 900px) {
        .blog-list-container {
            height: calc(100vh - 265px);
            overflow: auto;

            .add-blog-btn{
                bottom: 100px;
                right: 10px;
            }
        }
    }

</style>