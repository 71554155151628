<template>
    <h5 class="course-categorization-explication">* Categorización de la oferta formativa en el portal</h5>

    <template v-for="(category) in portal_categorization">
        <v-autocomplete
            v-model="form_categorization[category.cleaned_name]"
            @update:modelValue="setCategorizationValues(category.cleaned_name)"
            :items="categorization_values[category.cleaned_name]"
            item-title="nombre"
            item-value="tid"
            variant="underlined"
            class="select-primary field-with-label"
            :disabled="disabled_categorization[category.cleaned_name]"
        >
            <template v-slot:label>
                <span>{{ category.category_name }} <span class='red-asterisk'>*</span></span>
            </template>
        </v-autocomplete>
    </template>

    <v-autocomplete
        label="Selecciona tipo de contenido"
        v-model="form_data.tipo_contenido_seo"
        :items="seo_content"
        item-title="nombre"
        item-value="tid"
        variant="underlined"
        class="select-primary field-with-label"
    >
    </v-autocomplete>
</template>

<script>
import {
    getPortalAreas,
    getPortalCategories,
    getPortalCategoriesByArea,
    getPortalCategorizationWS, getPortalSubcategories, getPortalSubcategoriesByCategory
} from "@/utils/web_categorization";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";

export default {
    name: "Categorization",
    emits:['setFinalCategoriesValues', 'catecorizationCheck'],
    props: {
        form_data: Object,
        seo_content: Array,
    },
    setup(props, {emit}) {
        let portal_categorization = ref([]);
        let form_categorization = ref([]);
        let categorization_values = ref({});
        let loading_categorization = ref([{'areas_web': false}, {'categorias_web': false}, {'subcategorias_web': false}])
        let disabled_categorization = ref([{'areas_web': false}, {'categorias_web': false}, {'subcategorias_web': false}])
        let form_data = ref(props.form_data);
        let categorizationCheck = ref([]);

        onBeforeMount(async () => {
            await getPortalCategorization()
            await loadCategorizationData();

            if (variableIsDefined(form_data.value.tipo_contenido_seo)) {
                form_data.value.tipo_contenido_seo = parseInt(form_data.value.tipo_contenido_seo)
            }
        })

        async function getPortalCategorization() {
            let response = await getPortalCategorizationWS();
            if (response['result']) {
                portal_categorization.value = response['data']

                categorizationCheck.value = {
                    area: false,
                    facultad: false,
                    escuela: false
                };
                response['data'].forEach(category => {
                    form_categorization.value[category.cleaned_name] = 0
                    categorization_values.value[category.cleaned_name] = []

                    if (category.cleaned_name === 'areas_web' && category.values.length > 0) {
                        categorizationCheck.value.area = true;
                    }
                    if (category.cleaned_name === 'categorias_web' && category.values.length > 0) {
                        categorizationCheck.value.facultad = true;
                    }
                    if (category.cleaned_name === 'subcategorias_web' && category.values.length > 0) {
                        categorizationCheck.value.escuela = true;
                    }
                })
                emit('categorizationCheck', categorizationCheck.value);
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function loadCategorizationData() {
            let size_categorization = Object.keys(form_categorization.value).length;

            if (size_categorization === 3) {
                setAreasSelectValues(form_data.value.areaTID, false)
                if (form_data.value.areaTID > 0) {
                    setCategoriesSelectValues(form_data.value.areaTID, false, form_data.value.categoryTID)
                    if (form_data.value.categoryTID > 0) {
                       await setSubcategoriesSelectValues(form_data.value.categoryTID, false, form_data.value.subcategoryTID)
                    } else {
                        categorization_values.value.subcategorias_web = [{
                            tid: 0,
                            nombre: '--SIN SUBCATEGORÍA--',
                            tid_parent: null
                        }]
                    }
                } else {
                    categorization_values.value.categorias_web = [{
                        tid: 0,
                        nombre: '--SIN CATEGORÍA--',
                        tid_parent: null
                    }]
                    categorization_values.value.subcategorias_web = [{
                        tid: 0,
                        nombre: '--SIN SUBCATEGORÍA--',
                        tid_parent: null
                    }]
                }
            } else if (size_categorization === 2) {
                setCategoriesSelectValues(0, false, form_data.value.categoryTID, true)
                if (form_data.value.categoryTID > 0) {
                    await setSubcategoriesSelectValues(form_data.value.categoryTID, false, form_data.value.subcategoryTID)
                } else {
                    categorization_values.value.subcategorias_web = [{
                        tid: 0,
                        nombre: '--SIN SUBCATEGORÍA--',
                        tid_parent: null
                    }]
                }

            } else if (size_categorization === 1) {
                await setSubcategoriesSelectValues(0, false, form_data.value.subcategoryTID, true)
            }
        }

        async function setCategorizationValues(category_name) {
            switch (category_name) {
                case 'areas_web':
                    setAreasSelectValues(form_categorization.value.areas_web)
                    setCategoriesSelectValues(form_categorization.value.areas_web, true, 0)
                   await setSubcategoriesSelectValues(form_categorization.value.categorias_web, true, 0)
                    break;
                case 'categorias_web':
                    await setSubcategoriesSelectValues(form_categorization.value.categorias_web, true, 0)
                    break;
            }
        }

        function setAreasSelectValues(id_area) {
            loading_categorization.value.areas_web = true;
            toggleStatusCategory('categorias_web', true)
            toggleStatusCategory('subcategorias_web', true)

            if (variableIsDefined(id_area)) {
                id_area = parseInt(id_area)
            } else {
                id_area = 0;
                disabled_categorization.value.categorias_web = true;
            }
            let response = getPortalAreas();

            if (Object.keys(response).length > 0) {
                categorization_values.value.areas_web = response;
                categorization_values.value.areas_web.forEach(area => {
                    if (area.tid === id_area) {
                        form_categorization.value.areas_web = area
                        if (area.tid > 0) {
                            toggleStatusCategory('categorias_web', false)
                        }
                    }
                })
                toggleStatusCategory('areas_web', false)
            }
            loading_categorization.value.areas_web = false;
        }

        function setCategoriesSelectValues(area_id, override, category_id = 0, is_parent = false) {
            loading_categorization.value.categorias_web = true;
            toggleStatusCategory('categorias_web', false)
            toggleStatusCategory('subcategorias_web', true)
            if (override) {
                form_categorization.value.categorias_web = 0;
                form_categorization.value.subcategorias_web = 0;
            }

            if (!is_parent) {
                if (typeof area_id === "object") {
                    area_id = area_id.tid
                }

                if (variableIsDefined(area_id) && area_id > 0) {
                    area_id = parseInt(area_id)
                    let response = getPortalCategoriesByArea(area_id);
                    if (Object.keys(response).length > 0) {
                        categorization_values.value.categorias_web = response;
                        if (variableIsDefined(category_id) && category_id > 0) {
                            fillAutocompleteCategory(category_id)
                        } else {
                            toggleStatusCategory('subcategorias_web', true)
                        }
                        toggleStatusCategory('categorias_web', false)
                    }
                } else {
                    toggleStatusCategory('categorias_web', true)
                }
            } else {
                let response = getPortalCategories()
                if (Object.keys(response).length > 0) {
                    categorization_values.value.categorias_web = response;

                    if (variableIsDefined(category_id) && category_id > 0) {
                        fillAutocompleteCategory(category_id)

                    } else {
                        toggleStatusCategory('subcategorias_web', true)
                    }
                } else {
                    toggleStatusCategory('categorias_web', true)
                }
            }
            loading_categorization.value.categorias_web = false;
        }

        async function setSubcategoriesSelectValues(category_id, override, subcategory_id = 0, is_parent = false) {
            toggleStatusCategory('subcategorias_web', false)
            if (override) {
                form_categorization.value.subcategorias_web = 0;
            }
            if (!is_parent) {

                if (typeof category_id === "object" && category_id!==null) {
                    category_id = category_id.tid
                }
                if (category_id===null){
                    category_id=0
                }
                if (variableIsDefined(category_id) && category_id > 0) {
                    category_id = parseInt(category_id)
                    let response = getPortalSubcategoriesByCategory(category_id);
                    if (Object.keys(response).length > 0) {
                        categorization_values.value.subcategorias_web = response;
                        fillAutocompleteSubcategory(subcategory_id)
                    }

                } else {
                    toggleStatusCategory('subcategorias_web', true)
                }
            } else {
                let response = getPortalSubcategories();
                if (Object.keys(response).length > 0) {
                    categorization_values.value.subcategorias_web = response;
                    fillAutocompleteSubcategory(subcategory_id)
                } else {
                    toggleStatusCategory('subcategorias_web', true)
                }
            }
            loading_categorization.value.subcategorias_web = false;
        }

        function fillAutocompleteCategory(category_id) {
            categorization_values.value.categorias_web.forEach(category => {
                if (category.tid === parseInt(category_id)) {
                    form_categorization.value.categorias_web = category
                    if (category.tid > 0) {
                        toggleStatusCategory('subcategorias_web', false)
                    }
                }
            })
        }

        function fillAutocompleteSubcategory(subcategory_id) {
            if (variableIsDefined(subcategory_id) && subcategory_id > 0) {
                subcategory_id = parseInt(subcategory_id)
                categorization_values.value.subcategorias_web.forEach(subcategory => {
                    if (subcategory.tid === subcategory_id) {
                        form_categorization.value.subcategorias_web = subcategory
                    }
                })
            }
        }

        function toggleStatusCategory(category, disabled) {
            switch (category) {
                case 'areas_web':
                    disabled_categorization.value.areas_web = disabled;
                    break;
                case 'categorias_web':
                    disabled_categorization.value.categorias_web = disabled;
                    break;
                case 'subcategorias_web':
                    disabled_categorization.value.subcategorias_web = disabled;
                    break;
            }
        }

        async function setFinalCategoriesValues() {
            if (typeof form_categorization.value.areas_web === 'object') {
                form_data.value.areaTID = form_categorization.value.areas_web.tid
            } else {
                form_data.value.areaTID = form_categorization.value.areas_web
            }

            if (typeof form_categorization.value.categorias_web === 'object' && form_categorization.value.categorias_web!==null ) {
                form_data.value.categoryTID = form_categorization.value.categorias_web.tid
            } else {
                form_data.value.categoryTID = form_categorization.value.categorias_web
            }

            if (typeof form_categorization.value.subcategorias_web === 'object' && form_categorization.value.subcategorias_web!==null) {
                form_data.value.subcategoryTID = form_categorization.value.subcategorias_web.tid
            } else {
                form_data.value.subcategoryTID = form_categorization.value.subcategorias_web
            }
        }


        return {
            getPortalCategorization,
            setCategorizationValues,
            setFinalCategoriesValues,

            portal_categorization,
            form_categorization,
            categorization_values,
            loading_categorization,
            disabled_categorization,
            form_data,

        }
    }
}
</script>

<style scoped>
    .red-asterisk {
        color: red;
    }
</style>