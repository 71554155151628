<template>
    <div class="sui-paging-info">
        Showing
        <strong>{{ start() }} - {{ Math.min(end(), searchState.totalResults) }}</strong>
        out of <strong>{{ searchState.totalResults }}</strong> for:
        <em>"{{ searchState.searchTerm }}"</em>
    </div>
</template>

<script>
export default {
    name: 'SearchPagingInfo',
    props: {
        searchState: {
            required: true,
            type: Object
        }
    },
    setup(props, ctx) {
        function start() {
            return props.searchState.totalResults === 0
                ? 0
                : (props.searchState.current - 1) * props.searchState.resultsPerPage + 1;
        };

        function end() {
            return props.searchState.totalResults <= props.searchState.resultsPerPage
                ? props.searchState.totalResults
                : start() + props.searchState.resultsPerPage - 1;
        };

        return {
            start,
            end
        }

    }
}
</script>
