<template>
    <BaseModalTemplate
        :title="title_modal"
        :open-close-dialog="displayDialog"
        :loading="loading"
    >
        <template #body-modal-content>
            <v-form ref="form" class="col-12" :displayDialog="displayDialog">
                <v-container class="container-form">
                    <v-row>
                        <v-col cols="12" v-if="url_course!==null && variableIsDefined(url_course)">
                            <span
                                style="font-weight: bold; font-size: 16px"> El comentario se realiza sobre el curso:</span>
                            <span style="font-style: italic; font-size: 16px" class="ms-1">{{ url_course }}</span>
                        </v-col>
                        <v-col cols="12" v-else>
                            <span style="font-weight: bold; font-size: 16px">
                                Comentario genérico</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Nombre del autor"
                                class="input-error text-field-primary field-with-label personalize-text-field"
                                v-model.trim="form_data.name"
                                variant="underlined"
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apellidos del autor"
                                class="input-error text-field-primary field-with-label personalize-text-field"
                                v-model.trim="form_data.surname"
                                variant="underlined"
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Apodo del autor *"
                                class="input-error text-field-primary field-with-label personalize-text-field"
                                v-model.trim="form_data.nickname"
                                variant="underlined"
                                :class="{error :form_error.nickname}"
                                hide-details
                            ></v-text-field>
                            <span v-if="form_error.nickname"
                                  class="input-error msg-error">{{ form_error.nickname }}</span>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Provincia *"
                                class="input-error text-field-primary field-with-label personalize-text-field "
                                v-model.trim="form_data.city"
                                :class="{error :form_error.city}"
                                hide-details
                                variant="underlined"
                            ></v-text-field>
                            <span v-if="form_error.city"
                                  class="input-error msg-error">{{ form_error.city }}</span>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-autocomplete
                                label="Selecciona un estado *"
                                class="input-error select-primary personalize-text-field field-with-label mt-0 mt-sm-4"
                                v-model.trim="form_data.id_status"
                                :items="all_status"
                                :class="{error :form_error.id_status}"
                                item-title="name"
                                item-value="id_status"
                                hide-details
                                variant="underlined"
                                no-data-text="No hay datos disponibles"
                                :menu-props="{width: '300px'}"
                            ></v-autocomplete>
                            <span v-if="form_error.id_status"
                                  class="input-error msg-error">{{ form_error.id_status }}</span>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <span class="title-punctuation">Puntuación del curso *</span>
                            <v-slider
                                class="slider-modal mt-7"
                                v-model="form_data.score"
                                :step="1"
                                :model-value="form_data.score"
                                min=0
                                max=10
                                thumb-label="always"
                                strict
                                hide-details
                                :class="{error :form_error.score}"
                            ></v-slider>
                            <span v-if="form_error.score"
                                  class="input-error msg-error">{{ form_error.score }}</span>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                class="input-error text-area-secondary"
                                :class="{error :form_error.basic_text}"
                                v-model.trim="form_data.basic_text"
                                label="Comentario del curso *"
                                hide-details
                                rows="3"
                                variant="underlined"
                            ></v-textarea>
                            <span v-if="form_error.basic_text"
                                  class="input-error msg-error">{{ form_error.basic_text }}</span>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                class="input-error text-area-secondary"
                                v-model.trim="form_data.decisive_content"
                                label="¿Qué te hizo decidirte por nuestro curso?"
                                hide-details
                                rows="3"
                                variant="underlined"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                class="input-error text-area-secondary"
                                v-model.trim="form_data.learned_content"
                                label="¿Qué has aprendido con el curso?"
                                hide-details
                                rows="3"
                                variant="underlined"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                class="input-error text-area-secondary"
                                v-model.trim="form_data.enjoyed_content"
                                label="¿Qué es lo que más te ha gustado de este curso?"
                                hide-details
                                rows="3"
                                variant="underlined"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                class="input-error text-area-secondary"
                                v-model.trim="form_data.missed_content"
                                label="¿Qué has echado en falta del curso?"
                                hide-details
                                rows="3"
                                variant="underlined"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12" class="text-start align-self-center">
                            <div style="display: inline-block; width: auto ">
                                <v-checkbox
                                    label="Mostrar opinión"
                                    v-model="form_data.include_opinions"
                                    :true-value="1"
                                    :false-value="0"
                                    color="blue"
                                    hide-details
                                ></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <v-btn @click="saveComment" class="btn button-bg-blue" :loading="save_comment">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import * as Yup from "yup";
import {getAllStatusCommentsWS, insertCommentWS, updateCommentWS} from "@/utils/comments";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormComment",
    methods: {variableIsDefined},
    components: {BaseModalTemplate},
    props: {
        displayDialog: Function,
        getAllComments: Function,
        selected_comment: Object,
        url_course: String,
        title_modal: String,
    },
    setup(props) {
        let url_course = ref('')
        let loading = ref(false)
        let form_data = ref({});
        let all_status = ref([]);
        let form_error = ref({});
        let half_score = ref();
        let save_comment = ref(false)


        onBeforeMount(async () => {
            loading.value = true
            if (variableIsDefined(props.selected_comment)) {
                form_data.value = _.cloneDeep(props.selected_comment)
                url_course.value = _.cloneDeep(props.selected_comment.url)
            } else {
                form_data.value = {score: 0}
                if (variableIsDefined(props.url_course)) {
                    url_course.value = props.url_course
                } else {
                    url_course.value = null
                }
            }
            await getAllStatusComments()
            loading.value = false
        })

        async function getAllStatusComments() {
            let response = await getAllStatusCommentsWS()
            if (response['data'] && response['result']) {
                all_status.value = response['data']
            } else {
                showNotification("error", response['errors'])
            }
        }

        async function validateComment() {
            form_error.value = {}

            const schemaForm = Yup.object().shape({
                nickname: Yup.string().required(true),
                id_status: Yup.string().required(true),
                score: Yup.string().required(true),
                city: Yup.string().required(true),
                basic_text: Yup.string().required(true),
            })
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});

            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = "Es obligatorio rellenar este campo."
                })
            }
        }

        async function updateComment() {
            let response = await updateCommentWS(form_data.value);
            if (response['data'] && response['result']) {
                showNotification('success', 'Modificación correcta.')
                props.displayDialog()
            } else {
                showNotification('error', "Error en la modificación.")
            }
        }

        async function saveComment() {
            await validateComment()
            if (Object.keys(form_error.value).length === 0) {
                save_comment.value = true
                if (variableIsDefined(props.selected_comment)) {
                    await updateComment()
                    props.getAllComments()
                } else {
                    let response = await insertCommentWS(form_data.value, url_course.value)
                    if (response['data'] && response['result']) {
                        showNotification('success', 'Comentario creado')
                        if(!variableIsDefined(url_course.value)){
                            props.getAllComments()
                        }
                        props.displayDialog()
                    } else {
                        showNotification('error', response['errors'])
                    }
                }
                save_comment.value = false

            }
        }


        return {
            saveComment,
            all_status,
            form_data,
            form_error,
            half_score,
            loading,
            url_course,
            save_comment,
        }
    }
}
</script>
<style scoped lang="scss">
.title-punctuation {
    font-size: 15px;
    color: $gray-darker;
}
</style>