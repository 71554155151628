<template>
    <BaseModalTemplate
        card_extra_classes="modal-width-xs"
        :title="title_msg_drop"
        :open-close-dialog="displayDialogEdit"
        :send-form="validateEditedWord"
        body_extra_classes="modal-height-xs"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        placeholder="Escriba un nuevo valor"
                        class="input-error text-field-secondary"
                        :error ="new_word_value_error"
                        v-model="edited_word"
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                    <span v-if="new_word_value_error" class="input-error msg-error">{{new_word_error_message}}</span>

                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalEditWords",
    components: {BaseModalTemplate},
    props: {
        editFunction: Function,
        validateWord: Function,
        displayDialogEdit: Function,
        title_msg_drop: String,
        state_loading: Boolean,
    },
    setup(props){
        let edited_word = ref('');
        let new_word_value_error = ref(false);
        let new_word_error_message = ref('');

        /*
        *
        * VALIDA QUE LA PALABRA
        * EDITADA SEA VÁLIDA
        *
        * */
        function validateEditedWord(){
            let result = props.validateWord(edited_word.value);

            if(result.correct){
                props.editFunction(edited_word.value.trim())
            }else{
                new_word_value_error.value = true;
                new_word_error_message.value = result.message;
            }
        }

        return{
            validateEditedWord,

            new_word_error_message,
            new_word_value_error,
            edited_word,
        }
    }
}
</script>

<style lang="scss">

</style>