<template>
    <QueueTemplate
        ref="replace_ref"
        :change-element-state="changeStateElementProposalWS"
        :get-all-queue-elements="getAllElementsProposalWS"
        :headers="headers"
        page_title="Propuestas encoladas"
        @send-element-data="receiveElementData"
        :custom_item_slots="['item-keyword_actual', 'item-keyword_propuesta', 'item-url_futura_redireccion', 'item-estado_kw','item-id_portal','item-id_idioma']"
    >
        <template #custom-title>
            <span class="main-title-page">
                <img :src="require('@/assets/icons/navbar/tag.svg')" alt="icono de keyword" width="28" height="28">
                Propuestas encoladas
            </span>
        </template>
        <!--  TEMPLATE FILTROS      -->
        <template #queue-filters="{form_data}">
            <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.keyword_actual"
                    label="Keyword actual"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.keyword_propuesta"
                    label="Keyword propuesta"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3">
                <div style="display: inline-block; width: auto ">
                    <v-checkbox
                        label="Búsqueda exacta"
                        v-model="form_data.strict"
                        :true-value="1"
                        :false-value="0"
                        color="blue"
                        hide-details
                    ></v-checkbox>
                </div>
            </v-col>
        </template>
        <!--   TEMPLATES PARA MODIFICAR LOS HEADER DE LA TABLA     -->
        <template #item-url_futura_redireccion="{item}">
            <template v-if="variableIsDefined(item.url_futura_redireccion)">
                {{item.url_futura_redireccion}}
            </template>
            <template v-else>
                Sin datos
            </template>
        </template>

        <template #item-keyword_actual="{item}">
            <template v-if="variableIsDefined(item.keyword_actual)">
                {{item.keyword_actual}}
            </template>
            <template v-else>
                Sin datos
            </template>
        </template>

        <template #item-keyword_propuesta="{item}">
            <template v-if="variableIsDefined(item.keyword_propuesta)">
                {{item.keyword_propuesta}}
            </template>
            <template v-else>
                Sin datos
            </template>
        </template>

        <template #item-estado_kw="{item}">
            <template v-if="variableIsDefined(item.estado_kw)">
                {{item.estado_kw}}
            </template>
            <template v-else>
                Sin datos
            </template>
        </template>

        <template #item-id_portal="{item}">
            {{ getNamePortal(parseInt(item.id_portal)) }}
        </template>
        <template #item-id_idioma="{item}">
            {{ getNameLanguage(parseInt(item.id_idioma)) }}
        </template>

        <!--  FIN TEMPLATES PARA MODIFICAR LOS HEADER DE LA TABLA     -->

        <!--   MODALES QUE ABREN LAS ACCIONES DE CADA ELEMENTO     -->
        <template #modal-element-log>
            <v-dialog v-model="show_log_modal" persistent>
                <ModalLogProposalQueue
                    :open_modal_log="openModalLog"
                    :log_id="log_id">
                </ModalLogProposalQueue>
            </v-dialog>
        </template>


    </QueueTemplate>
</template>

<script>
import QueueTemplate from "@/components/Generic/QueueTemplate.vue";
import {ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {changeStateElementProposalWS, getAllElementsProposalWS} from "@/utils/proposal_keyword";
import {getNameLanguage, getNamePortal} from "@/utils/portal";
import ModalElementData from "@/components/FindReplaceText/ModalElementData.vue";
import ModalLogProposalQueue from "@/components/Keyword/ModalLogProposalQueue.vue";
import ModalLogFindReplace from "@/components/FindReplaceText/ModalLogFindReplace.vue";

export default {
    name: "QueueProposalKeywordView",
    methods: {getNameLanguage, getNamePortal, changeStateElementProposalWS, getAllElementsProposalWS, variableIsDefined},
    components: {ModalLogFindReplace, ModalLogProposalQueue, ModalElementData, QueueTemplate},
    setup() {
        let headers = ref([
            {text: 'ID', value: 'id', sortable: true},
            {text: 'URL', value: 'url'},
            {text: 'KW ACTUAL', value: 'keyword_actual'},
            {text: 'KW PROPUESTA', value: 'keyword_propuesta'},
            {text: 'URL FUTURA REDIRECCIÓN', value: 'url_futura_redireccion'},
            {text: 'ESTADO KW', value: 'estado_kw'},
            {text: 'IDIOMA', value: 'id_idioma'},
            {text: 'PORTAL', value: 'id_portal'},
            {text: 'ACTUALIZADO', value: 'updated_at', sortable: true},
            {text: 'USUARIO', value: 'id_empleado'},
            {text: 'ESTADO', value: 'id_estado'},
            {text: 'REINTENTOS', value: 'reintentos', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])

        let show_log_modal = ref(false)
        let show_revert_modal = ref(false)

        let log_id = ref()
        let revert_data = ref() //Datos del elemento seleccionado para revertir los cambios

        let selected_element_id = ref()
        let selected_element_config = ref([])

        let replace_ref = ref(null)

        //Funciones para abrir los modales
        const receiveElementData = (element_data) => {
            log_id.value = element_data.id
            openModalLog()
        }

        const loadDataForReverseModal = (element_data) => {
            log_id.value = element_data.id
            revert_data.value = element_data
            openModalRevert()
        }

        const openModalLog = () => {
            show_log_modal.value = !show_log_modal.value
        }

        const openModalRevert = () => {
            show_revert_modal.value = !show_revert_modal.value
        }

        /************************************************/


        //VUELVE A CARGAR LOS ELEMENTOS EN COLA
        const reloadElements = async () => {
            await replace_ref.value.loadQueueElements()
        }


        return{
            loadDataForReverseModal,
            openModalLog,
            openModalRevert,
            receiveElementData,
            reloadElements,

            headers,
            log_id,
            revert_data,
            show_log_modal,
            show_revert_modal,
            replace_ref,
            selected_element_id,
            selected_element_config,
        }
    }
}
</script>

<style lang="scss">

</style>