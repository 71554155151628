<template>
    <div class="sui-sorting">
        <div class="sui-sorting__label">Ordenar por</div>
        <select
                name="sortBy"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
        >
          <option v-for="value in options" v-bind:key="value.id" :value=value.value>{{ value.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props : {
        options : {
            type: Array,
            required: true
        },
        modelValue: {
          type: String,
          required: true
        }
    },
    setup() {

    }
};
</script>
