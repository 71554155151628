<template>
    <BaseModalTemplate
        :open-close-dialog="displayDialog"
        title="Asignación de portal e idioma"
        card_extra_classes="modal-width-xs"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <v-select
                        :items="portals"
                        item-title="nombre"
                        item-value="id_portal"
                        v-model="form_data.id_portal"
                        variant="underlined"
                        placeholder="PORTAL"
                        class="input-error select-primary"
                        @update:modelValue="changePortalSelection()"
                        hide-details
                        no-data-text="No hay datos disponibles"
                        :menu-props="{width: 400}"
                    >
                    </v-select>
                    <span v-if="form_error.portal_error"
                          class="input-error msg-error">El campo no puede estar vacio</span>
                </v-col>

                <v-col cols="12">
                    <v-select
                        :items="languages"
                        item-title="nombre"
                        item-value="iso_6391"
                        v-model="form_data.iso_6391"
                        variant="underlined"
                        placeholder="IDIOMA"
                        class="input-error select-primary"
                        :error="form_error.language_error"
                        :disabled="disable_languages || disabled_general"
                        hide-details
                        no-data-text="No hay datos disponibles"
                        :menu-props="{width: 200}"
                    >
                    </v-select>
                    <span v-if="form_error.language_error"
                          class="input-error msg-error">El campo no puede estar vacio</span>
                </v-col>
            </v-row>
            <span class="input-title"></span>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12" class="align-content-end">
                    <v-btn class="btn button-bg-blue" @click="sendForm" :loading="save_teacher">Enviar</v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {insertTeacherWS} from "@/utils/teachers";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "FormTeacherPortal",
    components: {BaseModalTemplate},
    props: {
        displayDialog: Function,
        portals: Array,
        teacher_name: String,
        teacher_username: String,
    },

    setup(props) {
        let disabled_general = ref(false)
        let disable_portals = ref(false)
        let disable_languages = ref(true)
        let disable_url = ref(false)


        let form_data = ref({});
        let form_error = ref({});
        let disable = ref(false);
        let languages = ref([]);
        let save_teacher = ref(false)


        function showLanguages() {
            disable_languages.value = false;
            props.portals.forEach(portal => {
                if (portal.id_portal === form_data.value.id_portal) {
                    languages.value = portal.languages;
                }
            })
        }

        function changePortalSelection() {
            form_data.value.iso_6391 = '';
            showLanguages();

        }

        async function sendForm() {
            validateForm();
            save_teacher.value = true
            if (Object.keys(form_error.value).length === 0) {
                let teacher_data = {
                    'usuario': props.teacher_username,
                    'activo': 0,
                    'imagen_final': null,
                    'titulacion': null,
                    'experiencia': null,
                    'showLinkedin': 0,
                }
                let response = await insertTeacherWS(teacher_data, form_data.value.iso_6391, form_data.value.id_portal)
                if (response['result']){
                    showNotification('success', 'Se ha vinculado correctamente')
                }else{
                    showNotification('error', response['errors'])
                }
                save_teacher.value = false
                props.displayDialog()
            }
        }

        function validateForm() {
            clearErrors();
            if (!variableIsDefined(form_data.value.id_portal)) {
                form_error.value.portal_error = true;
            }

            if (!variableIsDefined(form_data.value.iso_6391)) {
                form_error.value.language_error = true;
            }
        }

        function clearErrors() {
            form_error.value = {}
        }

        return {
            changePortalSelection,
            sendForm,
            disable_portals,
            disable_languages,
            disable_url,
            disabled_general,
            form_data,
            form_error,
            disable,
            languages,
            save_teacher,
        }
    }

}
</script>


<style scoped lang="scss">

</style>