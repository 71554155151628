import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification} from "@/utils/aux_functions";
import store from "@/store";

export async function translateTextWS(type, text, language_from, language_to){
    var params = {
        text: text,
        iso_source_language: language_from,
        iso_target_language: language_to,
        username: store.getters.getUsername,
        translator: "GoogleCloudTranslations",
        is_xml: false
    }

    try {
        if (type === 'texto') {
            return await aicoreBackendPetition('POST', '/Translator/translate_text', JSON.stringify(params), 'ajax', 'application/json')
        } else {
            return await aicoreBackendPetition('POST', '/Translator/translate_html', JSON.stringify(params), 'ajax', 'application/json')
        }
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/Translator/translate_text -- /Translator/translate_html');
    }
}