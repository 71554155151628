<template>
    <v-row>
        <v-col cols="12" md="6">
            <span class="input-title">
                URL enlace
            </span>
            <v-text-field
                    class="input-error text-field-secondary"
                    placeholder="Url enlace"
                    v-model.trim="row_data.url_enlace"
                    bg-color="white"
                    color="transparent"
                    hide-details
                    :error="link_box_error.url_enlace"
            ></v-text-field>
            <span v-if="link_box_error.url_enlace" class="input-error msg-error">
                        Tiene que definirse una Url de enlace
                    </span>
        </v-col>
        <v-col cols="12" md="6">
            <span class="input-title">
                Texto enlace
            </span>
            <v-text-field
                    v-model.trim="row_data.texto_enlace"
                    class="input-error text-field-secondary"
                    placeholder="Texto enlace"
                    bg-color="white"
                    color="transparent"
                    hide-details
                    :error="link_box_error.texto_enlace"
            ></v-text-field>
            <span v-if="link_box_error.texto_enlace" class="input-error msg-error">
                        Tiene que definirse un texto de enlace
                    </span>
        </v-col>
        <v-col cols="12">
            <span class="input-title">
                URL imagen
            </span>
            <v-text-field
                    v-model.trim="row_data.url_imagen"
                    class="input-error text-field-secondary"
                    placeholder="Url imagen"
                    bg-color="white"
                    color="transparent"
                    hide-details
                    :error="link_box_error.url_image"
            ></v-text-field>
            <span v-if="link_box_error.url_image" class="input-error msg-error">
                        Tiene que definirse una url para la imagen
                    </span>
        </v-col>
    </v-row>
    <v-row class="check-container">
        <v-col >
            <v-checkbox-btn
                    v-model="row_data.active"
                    label="Activado"
                    class="check-active"
                    :disabled="loading"
                    :true-value="1"
                    :false-value="0"
            ></v-checkbox-btn>
        </v-col>
    </v-row>
    <v-row class="row-link-btn">
        <v-col cols="12" class="pb-0" v-if="disabled_data || box_created">
            <v-checkbox
                    label="Eliminar"
                    v-model="delete_link_box"
                    :true-value="true"
                    :false-value="false"
                    color="blue"
                    hide-details
                    :disabled="loading"
            ></v-checkbox>
        </v-col>
        <v-col cols="12" v-if="!box_created ">
            <v-btn class="btn button-bg-white button-row-link"
                   hide-details
                   @click="addLinkBoxToMap"
                   :loading="loading"

            >
                Añadir
            </v-btn>
        </v-col>
        <v-col cols="12" class="pt-0" v-else-if="disabled_data || box_created">
            <v-btn
                    class="btn button-bg-white"
                    hide-details
                    @click="manageLinkBox()"
                    :loading="loading"
            >
                <span v-if="delete_link_box"> Eliminar</span>
                <span v-else>Actualizar </span>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {addLinkBoxWS, deleteLinkBoxWS, updateLinkBoxWS} from "@/utils/link_boxes";

export default {
    name: "RowLinkBox",
    props: {
        getAllLinks: Function,
        addLinkBox: Function,
        link_boxes: Array,
        link_box: Object,
        url: String,
    },
    setup(props) {
        let row_data = ref({
            'url_enlace': '',
            'texto_enlace': '',
            'url_imagen': '',
            'active': 0,
        });
        let link_box_error = ref({});
        let delete_link_box = ref(false);
        let disabled_data = ref(false);
        let box_created = ref(false);

        let initial_data = ref({});

        let loading = ref(false);

        onBeforeMount(() => {
            box_created.value = Object.keys(props.link_box).length > 0;

            autocompleteValue();
        });

        /*
        *
        * RELLENA LOS DATOS DE LA CAJA DE ENLACES AL MONTAR EL COMPONENTE, PARA EVITAR PROBLEMAS AL BORRAR
        * FILAS EN LAS PRIMERAS POSICIONES
        *
        * */
        function autocompleteValue() {
            if (box_created.value) {
                row_data.value['url_enlace'] = props.link_box.url_enlace;
                row_data.value['texto_enlace'] = props.link_box.texto_enlace;
                row_data.value['url_imagen'] = props.link_box.url_imagen;
                row_data.value['active'] = props.link_box.activo;

                initial_data.value['url_enlace'] = row_data.value['url_enlace'];
                initial_data.value['texto_enlace'] = row_data.value['texto_enlace'];
                initial_data.value['url_imagen'] = row_data.value['url_imagen'];
                initial_data.value['active'] = row_data.value['active'];

                disabled_data.value = true;
            }
        }


        //AÑADE EL OBJETO CON LOS DATOS DEL FORMULARIO AL MAP DE CAJAS DE ENLACES
        async function addLinkBoxToMap() {
            loading.value = true;

            if (validateBoxes()) {
                //AÑADIR ENLACE
                let response = await addLinkBoxWS(row_data.value, props.url);

                if (response['result']) {
                    await props.addLinkBox();

                    showNotification('success', response['data']['message'])
                } else {
                    showNotification('error', response['errors'])
                }
            }
            loading.value = false;
        }

        /*
        * Antes de actualizar comprueba que se haya
        * modificado algún dato
        * */
        function dataHasChanged() {
            if (initial_data.value['url_enlace'] !== row_data.value['url_enlace'] ||
                initial_data.value['texto_enlace'] !== row_data.value['texto_enlace'] ||
                initial_data.value['url_imagen'] !== row_data.value['url_imagen'] ||
                initial_data.value['active'] !== row_data.value['active']
            ) {
                return true
            } else {
                showNotification('info', 'Los datos no se han modificado');
                return false;
            }
        }

        //ACTUALIZA LOS DATOS DE LA CAJA DE ENLACES
        async function updateLinkBox() {
            loading.value = true;

            if (dataHasChanged() && validateBoxes()) {
                let link_data = {
                    'id': props.link_box.id_enlace_relacionado,
                }

                initial_data.id_enlace_relacionado = props.link_box.id_enlace_relacionado;

                const {url_enlace, texto_enlace, url_imagen, active} = row_data.value

                if (initial_data.value['url_enlace'] !== url_enlace) {
                    link_data['url_enlace'] = url_enlace;
                    initial_data.value['url_enlace'] = url_enlace;
                }

                if (initial_data.value['texto_enlace'] !== texto_enlace) {
                    link_data['texto_enlace'] = texto_enlace;
                    initial_data.value['texto_enlace'] = texto_enlace;
                }

                if (initial_data.value['url_imagen'] !== url_imagen) {
                    link_data['url_imagen'] = url_imagen;
                    initial_data.value['url_imagen'] = url_imagen;
                }

                if (initial_data.value['active'] !== active) {
                    link_data['active'] = active;
                    initial_data.value['active'] = active;
                }

                //ACTUALIZAR LA PREGUNTA EN LA BBDD
                let response = await updateLinkBoxWS(link_data, props.url);
                if (response['result']) {
                    await props.getAllLinks()
                    showNotification('success', 'Actualizado correctamente')
                } else {
                    showNotification('error', [response['errors']])
                }
            }

            loading.value = false;
        }

        //ELIMINA EL OBJETO DEL ARRAY DE PREGUNTAS
        async function eraseElement() {
            loading.value = true;
            let response = await deleteLinkBoxWS(props.link_box.id_enlace_relacionado, props.url)

            if (response['result']) {
                let index = props.link_boxes.findIndex(e => e.id_enlace_relacionado === props.link_box.id_enlace_relacionado)
                props.link_boxes.splice(index,1)

                showNotification('success', 'Eliminada correctamente')
            } else {
                showNotification('error', response['errors'])
            }
            loading.value = false;
        }

        function manageLinkBox() {
            return delete_link_box.value ? eraseElement() : updateLinkBox();
        }

        /*VALIDA LOS DATOS DEL FORMULARIO ANTES DE GUARDAR LA PREGUNTA*/
        function validateBoxes() {
            let correct = true;
            link_box_error.value = {};

            if (!variableIsDefined(row_data.value['url_enlace'])) {
                correct = false;
                link_box_error.value['url_enlace'] = true;
            }

            if (!variableIsDefined(row_data.value['texto_enlace'])) {
                correct = false;
                link_box_error.value['texto_enlace'] = true;
            }

            if (!variableIsDefined(row_data.value['url_imagen'])) {
                correct = false;
                link_box_error.value['url_image'] = true;
            }

            return correct;
        }


        return {
            addLinkBoxToMap,
            manageLinkBox,

            delete_link_box,
            disabled_data,
            link_box_error,
            box_created,
            row_data,
            loading,
        }
    }
}
</script>

<style lang="scss">
</style>