<template>
    <h5 class="course-categorization-explication">*  Categorización interna de los cursos</h5>
    <v-autocomplete
        label="Area"
        v-model="form_categorization.department"
        @update:modelValue="setCategorizationValues('department')"
        :items="categorization_values.department"
        item-title="nombre"
        item-value="id"
        variant="underlined"
        class="select-primary field-with-label"
        :disabled="disabled_categorization['department']"
        :loading="loading_categorization['department']"
    >
    </v-autocomplete>

    <v-autocomplete
        label="Facultad"
        v-model="form_categorization.area_knowledge"
        @update:modelValue="setCategorizationValues('area_knowledge')"
        :items="categorization_values.area_knowledge"
        item-title="nombre"
        item-value="id"
        variant="underlined"
        class="select-primary field-with-label"
        :disabled="disabled_categorization['area_knowledge']"
        :loading="loading_categorization['area_knowledge']"
    >
    </v-autocomplete>

    <v-autocomplete
        label="Escuela"
        v-model="form_categorization.school"
        @update:modelValue="setCategorizationValues('school')"
        :items="categorization_values['school']"
        item-title="nombre"
        item-value="id"
        variant="underlined"
        class="select-primary field-with-label"
        :disabled="disabled_categorization['school']"
        :loading="loading_categorization['school']"
    >
    </v-autocomplete>


</template>

<script>

import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";
import {
    getAreasKnowledgeByDepartment,
    getBasicCategorizationWS,
    getSchoolByAreaKnowledge
} from "@/utils/standar_categorization";
import {cloneDeep} from "lodash";

export default {
    name: "BasicCategorization",
    emits: ['setBasicFinalCategoriesValues'],
    props: {
        form_data: Object,
    },
    setup(props) {
        /*Categorización común*/
        let basic_categorization = [];
        let form_categorization = ref({});
        let categorization_values = ref({});

        let form_categorization_departments = ref([]);
        let form_categorization_area_knowledge = ref([]);
        let form_categorization_school = ref([]);

        let loading_categorization = ref([{'department': false}, {'area_knowledge': false}, {'school': false}])
        let disabled_categorization = ref([{'department': false}, {'area_knowledge': false}, {'school': false}])

        let form_data = ref(props.form_data);
        let departmentTID = form_data.value.id_departamento
        let areaKnowledgeTID = form_data.value.id_area_conocimiento
        let schoolTID = form_data.value.id_escuela

        onBeforeMount(async () => {
            await getBasicCategorization();
            await loadCategorizationData();
        })

        async function getBasicCategorization() {
            let response = await getBasicCategorizationWS();
            if (response['result']) {
                basic_categorization = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function loadCategorizationData() {

            setDepartmentSelectValues(departmentTID, false)
            if (departmentTID > 0) {
                setAreaKnowledgeSelectValues(departmentTID, false, areaKnowledgeTID)
                if (areaKnowledgeTID > 0) {
                    setSchoolSelectValues(areaKnowledgeTID, false, schoolTID)
                } else {
                    categorization_values.value.school = [{
                        id: 0,
                        nombre: '--SIN ESCUELA--',
                    }]
                }
            } else {
                categorization_values.value.area_knowledge = [{
                    id: 0,
                    nombre: '--SIN FACULTAD--',
                }]
                categorization_values.value.school = [{
                    id: 0,
                    nombre: '--SIN ESCUELA--',
                }]
            }

        }

        function setDepartmentSelectValues(id_department) {
            loading_categorization.value.department = true;
            toggleStatusCategory('area_knowledge', true)
            toggleStatusCategory('school', true)
            id_department = variableIsDefined(id_department) ? parseInt(id_department) : 0;

            let response = cloneDeep(basic_categorization);
            if (Object.keys(response).length > 0) {
                categorization_values.value.department = response;
                categorization_values.value.department.unshift({id: 0, nombre: '--SIN AREA--'})
                fillAutocompleteDepartment(id_department)
                toggleStatusCategory('department', false)

            }
            loading_categorization.value.department = false;
        }

        function setAreaKnowledgeSelectValues(department_id, override, area_knowledge_id = 0) {
            loading_categorization.value.area_knowledge = true;
            toggleStatusCategory('area_knowledge', false)
            toggleStatusCategory('school', true)
            if (override) {
                form_categorization.value.area_knowledge = 0;
                form_categorization.value.school = 0;
            }

            department_id = typeof department_id === "object" ? department_id.id : department_id

            if (variableIsDefined(department_id) && department_id > 0) {
                department_id = parseInt(department_id)
                let response = getAreasKnowledgeByDepartment(basic_categorization, department_id);
                if (Object.keys(response).length > 0) {
                    categorization_values.value.area_knowledge = response;
                    if (variableIsDefined(area_knowledge_id) && area_knowledge_id > 0) {
                        fillAutocompleteAreaKnowledge(area_knowledge_id)
                    }else{
                        toggleStatusCategory('school', true)
                    }
                    toggleStatusCategory('area_knowledge', false)
                }
            } else {
                toggleStatusCategory('area_knowledge', true)
            }

            loading_categorization.value.area_knowledge = false;
        }

        function setSchoolSelectValues(area_knowledge_id, override, school_id = 0) {
            toggleStatusCategory('school', false)
            if (override) {
                form_categorization.value.school = 0;
            }

            if (typeof area_knowledge_id === "object" && area_knowledge_id !== null) {
                area_knowledge_id = area_knowledge_id.id
            }
            if (area_knowledge_id === null) {
                area_knowledge_id = 0
            }
            if (variableIsDefined(area_knowledge_id) && area_knowledge_id > 0) {
                area_knowledge_id = parseInt(area_knowledge_id)
                let response = getSchoolByAreaKnowledge(categorization_values.value.area_knowledge, area_knowledge_id);
                if (Object.keys(response).length > 0) {
                    categorization_values.value.school = response;
                    fillAutocompleteSchool(school_id)
                }
            } else {
                toggleStatusCategory('school', true)
            }

            loading_categorization.value.school = false;
        }

        function fillAutocompleteDepartment(id_department) {
            categorization_values.value.department.forEach(dep => {
                if (dep.id === id_department) {
                    form_categorization.value.department = dep
                    if (id_department > 0) {
                        toggleStatusCategory('area_knowledge', false)
                    }
                }
            })
        }

        function fillAutocompleteAreaKnowledge(area_knowledge_id) {
            categorization_values.value.area_knowledge.forEach(area => {
                if (area.id === parseInt(area_knowledge_id)) {
                    form_categorization.value.area_knowledge = area
                    if (area.id > 0) {
                        toggleStatusCategory('area_knowledge', false)
                    }
                }
            })
        }

        function fillAutocompleteSchool(school_id) {
            if (variableIsDefined(school_id) && school_id > 0) {
                school_id = parseInt(school_id)
                categorization_values.value.school.forEach(sc => {
                    if (sc.id === school_id) {
                        form_categorization.value.school = sc
                    }
                })
            }
        }

        function toggleStatusCategory(category, disabled) {
            switch (category) {
                case 'department':
                    disabled_categorization.value.department = disabled;
                    break;
                case 'area_knowledge':
                    disabled_categorization.value.area_knowledge = disabled;
                    break;
                case 'school':
                    disabled_categorization.value.school = disabled;
                    break;
            }
        }

        async function setBasicFinalCategoriesValues() {
            if (typeof form_categorization.value.department === 'object') {
                form_data.value.id_departamento = form_categorization.value.department.id
            } else {
                form_data.value.id_departamento = form_categorization.value.department
            }

            if (typeof form_categorization.value.area_knowledge === 'object' && form_categorization.value.area_knowledge !== null) {
                form_data.value.id_area_conocimiento = form_categorization.value.area_knowledge.id
            } else {
                form_data.value.id_area_conocimiento = form_categorization.value.area_knowledge
            }

            if (typeof form_categorization.value.school === 'object' && form_categorization.value.school !== null) {
                form_data.value.id_escuela = form_categorization.value.school.id
            } else {
                form_data.value.id_escuela = form_categorization.value.school
            }
        }

        async function setCategorizationValues(category_name) {
            switch (category_name) {
                case 'department':
                    setDepartmentSelectValues(form_categorization.value.department, true, 0)
                    setAreaKnowledgeSelectValues(form_categorization.value.department, true, 0)
                    setSchoolSelectValues(form_categorization.value.area_knowledge, true, 0)
                    break;
                case 'area_knowledge':
                    setSchoolSelectValues(form_categorization.value.area_knowledge, true, 0)
                    break;
            }
        }

        return {
            setCategorizationValues,
            setBasicFinalCategoriesValues,
            form_categorization_departments,
            form_categorization_area_knowledge,
            form_categorization_school,
            basic_categorization,
            form_categorization,
            categorization_values,
            loading_categorization,
            disabled_categorization,
        }
    }
}
</script>

<style lang="scss">

</style>