<template>
    <BaseModalTemplate
        :open-close-dialog="displayModalLanguages"
        :title="options_modal.title_modal"
        card_extra_classes="modal-width-xs"
        :hide_actions="options_modal.see_btn_actions"
    >

        <template #body-modal-content>
            <div v-if="general_loading" class="general-loading">
                <Loading/>
            </div>
            <template v-if="!general_loading">
                <v-row>
                    <v-col cols="12">
                        <!--NOMBRE-->
                        <span class="input-title">Nombre *</span>
                        <v-text-field
                            v-model.trim="form_data.nombre"
                            variant="underlined"
                            class="input-error text-field-primary"
                            :error="form_error.name"
                            :disabled="options_modal.disabled_fields"
                            hide-details
                        ></v-text-field>
                        <span v-if="form_error.name"
                              class="input-error msg-error">{{ form_error.msg_name }}</span>
                    </v-col>

                    <!--ISO 6391-->
                    <v-col cols="12" md="12">
                        <span class="input-title">ISO 6391 *</span>
                        <v-autocomplete
                            v-model="language_iso_6391.iso_6391"
                            :items="all_languages_ISO_6391"
                            item-title="name_id_iso"
                            item-value="iso_6391"
                            variant="underlined"
                            class="select-primary"
                            hide-details
                            @update:modelValue="assignData(language_iso_6391.iso_6391)"
                            :menu-props="{width: '200px'}"
                            no-data-text="No hay datos disponibles"
                            :error="form_error.iso_6391"
                            :disabled="options_modal.disabled_fields"
                        ></v-autocomplete>
                        <span v-if="form_error.iso_6391"
                              class="input-error msg-error">{{ form_error.msg_iso_6391 }}</span>
                    </v-col>
                    <!--ID IDIOMA BASE-->
                    <v-col cols="12" md="4">
                        <span class="input-title">ID Idioma Base</span>
                        <v-text-field
                            v-model="name_language_base"
                            variant="underlined"
                            class="input-error text-field-primary"
                            hide-details
                            :disabled="active_idioma_base || options_modal.disabled_fields"></v-text-field>
                    </v-col>

                    <!--ISO 2-->
                    <v-col cols="12" md="4">
                        <span class="input-title">ISO 2 *</span>
                        <v-text-field
                            v-model.trim="iso_2"
                            class="input-error text-field-primary"
                            variant="underlined"
                            hide-details
                            :disabled="active_idioma_base || options_modal.disabled_fields"
                        ></v-text-field>
                    </v-col>

                    <!--ACTIVE-->
                    <v-col v-if="options_modal.option ==='details'">
                        <span class="input-title">Estado: </span>
                        <v-text-field
                            v-model="form_data.activo"
                            :value="form_data.activo === 1 ? 'Activo' : (form_data.activo === 0 ? 'Inactivo' : '')"
                            variant="underlined"
                            class="select-primary"
                            :disabled="options_modal.disabled_fields"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="align-self-center" v-else>
                        <div style="display: inline-block; width: auto ">
                            <v-checkbox
                                label="Activar Idioma"
                                v-model="form_data.activo"
                                :true-value="1"
                                :false-value="0"
                                color="blue"
                                :disabled="options_modal.disabled_fields"
                                hide-details
                            ></v-checkbox>
                        </div>
                    </v-col>
                </v-row>
            </template>

        </template>
        <template #actions-modal>
            <template v-if="!general_loading">
                <v-row>
                    <v-col cols="12" class="text-end">
                        <v-btn class="btn button-primary" @click="saveLanguage" :loading="disabled_save">
                            {{ options_modal.title_btn }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </template>
    </BaseModalTemplate>
</template>
<script>
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {getAllLanguagesIso6391WS, insertLanguageWS, updateLanguageWS} from "@/utils/language";
import {objectsHasSameInfo, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {fireSweetAlert} from "@/utils/sweetalert";
import {getNameLanguage} from "@/utils/portal";

export default {
    name: "ModalLanguage",
    methods: {variableIsDefined},
    components: {Loading, BaseModalTemplate},
    props: {
        displayModalLanguages: Function,
        options_modal: Object,
        form_data: Object,
        items_languages: Array,
        getLanguages: Function,
        displayStatusLanguage: Function,
        disableLanguage: Function,
    },
    setup(props) {
        let general_loading = ref(false)
        let disabled_save = ref(false)
        let form_data = ref({})
        let form_error = ref({})
        let all_languages = ref([])
        let all_languages_ISO_6391 = ref([])
        let items_languages_ISO_6391 = []
        let active_idioma_base = ref(true)
        let language_iso_6391 = ref({})
        let name_language_base = ref()
        let iso_2 = ref()
        let status = ref([
            {name: 'Activo', value: 1,},
            {name: 'Inactivo', value: 0,}
        ])


        onBeforeMount(async () => {
            general_loading.value = true
            await getAllLanguagesISO6391()
            all_languages_ISO_6391.value = items_languages_ISO_6391.map(language => {
                return {
                    ...language,
                    name_id_iso: `${language.name_language} (${language.iso_6391})`
                };
            });
            if (Object.keys(props.form_data).length > 0) {
                form_data.value = _.cloneDeep(props.form_data);
                language_iso_6391.value.iso_6391 = form_data.value.iso_6391
                language_iso_6391.value.name_language = await getNameLanguageISO(language_iso_6391.value.iso_6391)
                await assignData(form_data.value.iso_6391)
            }
            general_loading.value = false

        })

        async function getAllLanguagesISO6391() {
            let response = await getAllLanguagesIso6391WS()
            if (response['result']) {
                items_languages_ISO_6391 = response['data']
            }
        }

        async function getNameLanguageISO(id_iso) {
            return getNameLanguage(id_iso, 'iso_6391')
        }

        async function searchIDLanguageBase(language_base) {
            let name_language = null
            props.items_languages.forEach((language) => {
                if (!language.iso_6391.includes('-')) {
                    if (language.iso_6391 === language_base) {
                        name_language = language.id_idioma
                    }
                }
            })
            return name_language

        }

        async function assignData(iso_6391) {
            let language_base;
            let id_language_base = null;

            if (variableIsDefined(iso_6391)) {
                if (iso_6391.includes('-')) {
                    const split_iso_6391 = iso_6391.split('-');
                    language_base = split_iso_6391[0]
                    iso_2.value = split_iso_6391[1]
                    id_language_base = await searchIDLanguageBase(language_base)
                } else {
                    language_base = null
                    id_language_base = null
                    iso_2.value = iso_6391
                }

                name_language_base.value = await getNameLanguageISO(language_base)
                form_data.value.id_idioma_base = id_language_base;
                form_data.value.iso_2 = iso_2.value;
                form_data.value.iso_6391 = iso_6391;
            }
        }

        async function saveLanguage() {
            disabled_save.value = true
            if (await validateForm()) {
                if (await validateLanguageTable()) {
                    if (!form_data.value.hasOwnProperty('activo')) {
                        form_data.value.activo = 0;
                    }
                    if (Object.keys(props.form_data).length === 0) {
                        await insertLanguage()
                    } else {
                        await checkChanges()
                    }
                }

            }
            disabled_save.value = false
        }

        async function validateForm() {
            let correct = true;
            form_error.value = {};
            if (!variableIsDefined(language_iso_6391.value.iso_6391)) {
                correct = false
                form_error.value.iso_6391 = true
                form_error.value.msg_iso_6391 = 'El campo no puede estar vacío'
            }
            if (!variableIsDefined(form_data.value.nombre)) {
                correct = false
                form_error.value.name = true
                form_error.value.msg_name = 'El campo no puede estar vacío'
            }
            return correct;
        }

        async function validateLanguageTable() {
            let correct = true;
            form_error.value = {};
            if (variableIsDefined(form_data.value.nombre) && variableIsDefined(language_iso_6391.value.iso_6391)) {
                props.items_languages.forEach((langugage) => {
                    if (langugage.nombre === form_data.value.nombre && langugage.id_idioma !== props.form_data.id_idioma) {
                        correct = false
                        form_error.value.name = true
                        form_error.value.msg_name = 'Ya existe otro registro con el mismo nombre'
                    }
                    if (langugage.iso_6391 === language_iso_6391.value.iso_6391 && langugage.id_idioma !== props.form_data.id_idioma) {
                        correct = false
                        form_error.value.iso_6391 = true
                        form_error.value.msg_iso_6391 = 'Ya existe otro registro con el mismo ISO 6391'
                    }
                })
            }
            return correct;
        }

        async function insertLanguage() {
            let errors_ws = []
            let response = await insertLanguageWS(form_data.value)
            if (response['result']) {
                showNotification('success', response['data']['message'], true)
                await props.getLanguages()
                props.displayModalLanguages()
            } else {
                response['errors'].forEach((error) => {
                    errors_ws.push(error.title)
                    showNotification('error', errors_ws, true)
                })
            }
        }

        async function checkChanges(){
            if (form_data.value.activo === 0 && form_data.value.activo !== props.form_data.activo){
                await displayStatusLanguage()
            }else{
                await updateLanguage()
            }
        }

        async function updateLanguage() {
            let errors_ws = []
            if (!objectsHasSameInfo(props.form_data, form_data.value, true)) {
                let response = await updateLanguageWS(form_data.value)
                if (response['result']) {
                    if (form_data.value.activo === 0) {
                        await props.disableLanguage(form_data.value.id_idioma, form_data.value.activo, 'modal_update')
                    }
                    showNotification('success', response['data']['message'], true)
                    await props.getLanguages()
                    props.displayModalLanguages()
                } else {
                    response['errors'].forEach((error) => {
                        errors_ws.push(error.title)
                        showNotification('error', errors_ws, true)
                    })
                }
            } else {
                showNotification('info', 'No se han registrado cambios.')
                props.displayModalLanguages()
            }

        }

        function displayStatusLanguage(){
            let msg_state = 'El idioma se eliminará de todos los portales asociados. No podrás deshacer esta acción.';
            fireSweetAlert(`¿Quieres desactivar el estado del idioma?`, updateLanguage, msg_state)
        }



        return {
            saveLanguage,
            assignData,

            general_loading,
            form_data,
            form_error,
            disabled_save,
            all_languages,
            all_languages_ISO_6391,
            active_idioma_base,
            language_iso_6391,
            name_language_base,
            iso_2,
            status,

        }
    }
}
</script>


<style scoped lang="scss">

</style>

