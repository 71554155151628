<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="main-title-page">Listado de usuarios por grupo</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10" sm="7" md="6" lg="4">
                <v-form @submit.prevent="getAllUsersByGroup">
                    <v-text-field
                        v-model.trim="filter_group"
                        class="input-error text-field-secondary"
                        placeholder="Buscar grupo..."
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        :error="group_name_error"
                        hide-details
                    ></v-text-field>
                </v-form>
            </v-col>
            <v-col cols="2" sm="1" class="align-self-center">
                <img :src="require('@/assets/icons/delete-left-solid.svg')" alt="Eliminar campo usuario"
                     class="delete-filter" @click="cleanGroup()">
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="10" sm="7" md="6" lg="4" class="pe-4">
                <span v-if="group_name_error" class="input-error msg-error">
                        El nombre del grupo no puede estar vacío
                    </span>
            </v-col>
        </v-row>
        <v-row justify="start">
            <v-col cols="12" class="users-col">
                <div class="list-title">Usuarios</div>
                <template v-if="loading">
                    <Loading/>
                </template>
                <template v-else>
                    <template v-if="show_users">
                        <template v-if="items.length > 0">
                            <Vue3EasyDataTable
                                table-class-name="primary-table users-by-group-table"
                                theme-color="#F4A649"
                                :headers="headers"
                                :items="items"
                                :loading="loading"
                                buttons-pagination
                                :rows-per-page="50"
                                :hide-footer="items.length <= 50"
                                rows-per-page-message="Elementos por página: "
                                rows-of-page-separator-message="de"
                                empty-message="No hay datos disponibles"
                            >
                            </Vue3EasyDataTable>
                        </template>
                        <template v-else>
                            <span>No hay usuarios asignados en este grupo</span>
                        </template>
                    </template>
                    <template v-else>
                        <div class="default-text">
                            <span>Introduce un grupo para mostrar los usuarios</span>
                        </div>
                    </template>
                </template>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {useRoute} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import {getAllUsersByGroupWS} from "@/utils/admin_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupUsers",
    components: {Loading, Vue3EasyDataTable},

    setup() {
        const route = useRoute();
        const show_users = ref(false);
        const group_name_error = ref(false);

        const loading = ref(false);

        const filter_group = ref(null)
        const items = ref([])
        const headers = ref([
            {text: 'NOMBRE USUARIO', value: 'nombre_usuario'},
        ])

        onBeforeMount(async () => {
            await hasPermission(route);
        })


        async function getAllUsersByGroup() {
            loading.value = true;

            if(variableIsDefined(filter_group.value)){
                group_name_error.value = false
                const {result, data, errors} = await getAllUsersByGroupWS(filter_group.value)
                if (result) {
                    items.value = data;
                } else {
                    showNotification('error', errors)
                }
                show_users.value = true;
            }else{
                group_name_error.value = true
            }

            loading.value = false;
        }

        const cleanGroup = () => {
            filter_group.value = null
            show_users.value = false
        }

        return {
            cleanGroup,
            getAllUsersByGroup,

            filter_group,
            group_name_error,
            headers,
            items,
            loading,
            show_users,
        }
    }
}
</script>

<style lang="scss" >

.users-by-group-table {
    .vue3-easy-data-table__main {
        border-radius: 8px;
        border: 2px solid $blue-medium !important;
    }
}



</style>