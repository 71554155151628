<template>
    <QueueTemplate
        :active_portal_language_general="true"
        :change-element-state="changeStatusElementsLogoWS"
        :get-all-queue-elements="getAllQueuedLogosWS"
        :headers="headers"
        page_title="Gestión logos encolados"
        :custom_item_slots="['item-titulo_logo','item-id_portal', 'item-id_idioma','item-urls']"
        @send-element-data="receiveLogLogos"
        process_key_name="id_lote"
    >
        <template #custom-title>
            <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/cola-logos.svg')" alt="icono de logo encolados" width="28" height="28">
                    Gestión logos encolados
                </span>
        </template>
        <!--  TEMPLATE PARA LOS FILTROS    -->
        <template #queue-filters="{form_data}">
            <v-col cols="12" sm="6" md="4">
                <v-text-field
                    class="text-field-secondary"
                    v-model.number="form_data.logo_id"
                    type="number"
                    placeholder="Buscar por ID del logo..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    persistent-hint
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    class="text-field-secondary"
                    v-model.trim="form_data.url_name"
                    placeholder="Buscar por url..."
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hint="Búsquedas exactas"
                    persistent-hint
                ></v-text-field>
            </v-col>
        </template>

        <!-- TEMPLATES PARA LOS HEADER DE LA TABLA   -->
        <template #item-titulo_logo="{item}">
            <a class="logo-url" :href="item.url_logo" target="_blank"> {{ item.titulo_logo }} </a>
        </template>
        <template #item-id_portal="{item}">
            {{ getNamePortal(parseInt(item.id_portal)) }}
        </template>
        <template #item-id_idioma="{item}">
            {{ getNameLanguage(parseInt(item.id_idioma)) }}
        </template>
        <template #item-urls="{item}">
            <v-btn
                class="btn button-bg-white"
                @click="selectElement(item.urls)"
            >
                VER URLs
            </v-btn>
        </template>
    </QueueTemplate>

    <v-dialog v-model="show_element_urls_modal" persistent>
        <ModalUrls
            :openModalURLs="openModalUrls"
            :element_urls="selected_elements_urls"
            title_url="Logo aplicado a cursos"
        ></ModalUrls>
    </v-dialog>

    <v-dialog v-model="show_element_log_modal" persistent>
        <ModalLogImages
            :openModalLogs="openModalLog"
            :element_log="data_log"
            :loading_log="loading_log"
            title_log="Log Logos"
        ></ModalLogImages>
    </v-dialog>
</template>

<script>
import QueueTemplate from "@/components/Generic/QueueTemplate.vue";
import {changeStatusElementsLogoWS, getAllQueuedLogosWS, getLogElementLogoWS} from "@/utils/logos_queue";
import {ref} from "vue";
import {getNameLanguage, getNamePortal} from "../../utils/portal";
import ModalUrls from "@/components/QueueImages/ModalUrls.vue";
import ModalLogImages from "@/components/QueueImages/ModalLogImages.vue";
import {showNotification} from "@/utils/aux_functions";

export default {
    name: "LogosQueueView",
    methods: {changeStatusElementsLogoWS, getNameLanguage, getNamePortal, getAllQueuedLogosWS},
    components: {ModalLogImages, ModalUrls, QueueTemplate},
    setup() {
        let loading_log = ref(false)
        let headers = ref([
            {text: 'ID LOTE', value: 'id_lote', sortable: true},
            {text: 'ID LOGO', value: 'id_logo', sortable: true},
            {text: 'TÍTULO LOGO', value: 'titulo_logo', width: 200},
            {text: 'PESO', value: 'peso', width: 50},
            {text: 'PORTAL', value: 'id_portal'},
            {text: 'IDIOMA', value: 'id_idioma'},
            {text: 'URLS', value: 'urls'},
            {text: 'FECHA ACTUALIZACIÓN', value: 'updated_at', sortable: true},
            {text: 'EMPLEADO', value: 'username'},
            {text: 'ESTADO', value: 'id_estado'},
            {text: 'REINTENTOS', value: 'reintentos', sortable: true},
            {text: 'ACCIONES', value: 'btn_actions'},
        ])
        let show_element_urls_modal = ref(false)
        let show_element_log_modal = ref(false)
        let selected_elements_urls = ref()
        let data_log = ref({})


        /*MODAL PARA VER LAS URLS*/
        const openModalUrls = () => {
            show_element_urls_modal.value = !show_element_urls_modal.value
        }

        /*MODAL PARA ABRIR EL LOG*/
        const openModalLog = () => {
            show_element_log_modal.value = !show_element_log_modal.value
        }

        /*LLAMADA PARA OBTENER EL LOG PASANDO EL ID_LOGO*/
        async function getLogLogos(id_logo) {
            loading_log.value = true
            let response = await getLogElementLogoWS(id_logo)
            if (response['result']) {
                data_log.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
            loading_log.value = false
        }


        const receiveLogLogos = async (element_data) => {
            openModalLog()
            await getLogLogos(element_data.id_lote)
        }

        const selectElement = (urls) => {
            selected_elements_urls.value = Array.from(urls)
            openModalUrls()
        }

        return {
            openModalUrls,
            openModalLog,
            selectElement,
            receiveLogLogos,

            loading_log,
            data_log,
            headers,
            show_element_urls_modal,
            show_element_log_modal,
            selected_elements_urls,
        }
    }
}
</script>

<style lang="scss" scoped>
.logo-url {
    color: $black;
    text-decoration: none;
}
</style>