<template>
    <table class="table tabla-datos me-5">
        <tbody>
        <tr v-for="(val,key) in data">
            <th class="res-datos-recibidos-titulo"><strong>{{ formatTitle(key) }}:</strong></th>
            <td class="res-datos-recibidos">{{ val }}</td>
        </tr>
        </tbody>
    </table>
</template>


<script>
export default {
    name: "KeywordData",
    props: {
        data: Object,
    },

    setup() {
        const formatTitle = (title) => {
            return title.replace('_', ' ')
        }
        return {
            formatTitle
        }
    }
}
</script>
<style lang="scss" scoped>
.tabla-datos {
    vertical-align: center;
    horiz-align: center;

    .res-datos-recibidos-titulo {
        text-transform: capitalize;
        font-size: 16px;
    }

    .res-datos-recibidos {
        font-size: 17px;
    }
}

@media (max-width: 900px) {
    .tabla-datos {
        .res-datos-recibidos-titulo {
            font-size: 14px;
        }

        .res-datos-recibidos {
            font-size: 16px;
        }
    }
}

</style>
