<template>
    <BaseModalTemplate
        title="Modificar Url"
        :open-close-dialog="displayDialogEdit"
        :loading="loading"
        :send-form="saveNewUrl"
        body_extra_classes="modal-height-xs"
    >
        <template #body-modal-content>
            <v-row class="ms-1 me-1 mt-3">
                <v-col cols="12">
                    <v-text-field variant="underlined"
                                  hide-details class="input-error text-field-primary" v-model.trim="new_url"
                                  :error="error_url"></v-text-field>
                    <span v-if="error_url"
                          class="input-error msg-error">{{ error_url_msg }}</span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>


<script>

import UrlListView from "@/views/UrlList/UrlListView.vue";
import {ref} from "vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: 'ModalEditUrl',
    components: {BaseModalTemplate, UrlListView},
    props: {
        url_select_edit: String,
        loading: Boolean,
        displayDialogEdit: Function,
        saveUrlEdit: Function,
        type_url: String,

    },
    setup(props) {
        let new_url = ref(props.url_select_edit)
        let error_url = ref(false)
        let error_url_msg = ref('')

        async function saveNewUrl() {
            error_url.value = false;
            error_url_msg.value = '';
            let regex_url
            if (props.type_url==='contenido_seo'){
                regex_url = /^[a-z0-9\/\-_.]+$/
            }else if(props.type_url==='ficha_producto'){
                regex_url = /^[a-zA-Z0-9\/\-_]+$/i
            }
            if (regex_url.test(new_url.value) && variableIsDefined(regex_url)) {
                props.saveUrlEdit(new_url.value)
                props.displayDialogEdit()
            } else {
                error_url.value = true;
                error_url_msg.value = 'Formato de URL no válido';
            }
        }

        return {
            saveNewUrl,
            new_url,
            error_url,
            error_url_msg,
        }
    }
}

</script>


<style scoped lang="scss">
</style>