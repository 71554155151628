<template>
    <v-list id="sidebar-nav" class="list-group rounded-0 personalize-scroll-sidebar">
        <template v-if="is_user_ute">
            <router-link class="list-group-item link-no-child" to="/bridge/mapa-conceptual">
                <v-list-item :class="{ 'active-link': isActiveRoute('/bridge/mapa-conceptual') }">
                    <template v-slot:prepend>
                        <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-line.svg')" alt="icono línea" height="28" width="10">
                        <img class="icon-sidebar" :src="require('@/assets/icons/navbar/diagram.svg')" alt='icono diagrama' height="28" width="28">
                    </template>
                    <span class="nav-link">Generador mapa conceptual</span>
                </v-list-item>
            </router-link>
        </template>
        <template v-else>
            <template v-for="(route, index) in route_system">
<!--    SI LA RUTA NO TIENE HIJOS            -->
                <template v-if="route.children.length === 0">
                    <router-link class="list-group-item link-no-child" v-if="route.access" :to='getRoute(route)' @click="closeIfMobileMenu(route.active)">
                        <v-list-item
                            :disabled="!route.active"
                            :class="{ 'active-link': isActiveRoute(route.path) }"
                        >
                            <template v-slot:prepend>
                                <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-line.svg')" alt="icono línea" height="28" width="10">
                                <img class="icon-sidebar" :src="route.image" :alt=route.alt height="28" width="28">
                            </template>
                            <span class="nav-link">{{ route.name }}</span>
                        </v-list-item>
                    </router-link>
                </template>
<!--    SI LA RUTA TIENE HIJOS            -->
                <template v-else>
                    <div class="list-group-item link-with-child item-parent" v-if="route.access">
                        <v-list-item :disabled="!route.active" @click="openParent(index)" :class="{ 'active-link': (isActiveRoute(route.path) || active_parent === index) }">
                            <template v-slot:prepend>
                                <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-line.svg')" alt="icono línea" height="28" width="10">
                                <img class="icon-sidebar" :src="route.image" :alt=route.alt height="28" width="28">
                            </template>

                            <div class="d-flex justify-content-between">
                                <span class="nav-link">{{ route.name }}</span>
                                <img class="icon-sidebar" :src="getIconSrc(index)" alt="icono abrir" height="32" width="32">
                            </div>
                        </v-list-item>
                        <v-list v-if="isParentOpen(index)" class="list-group-child">
                            <template v-for="route_child in route.children">
                                <router-link class="item-child list-group-item" v-if="route_child.alt !== 'Atras'" :to='getRoute(route_child)' @click="closeIfMobileMenu(route_child.active)">
                                    <v-list-item
                                        :disabled="!route_child.active"
                                        :class="{ 'active-link': isActiveRoute(route_child.path) }"
                                    >
                                        <template v-slot:prepend>
                                            <img class="icon-sidebar" :src="require('@/assets/icons/sidebar/icon-line.svg')" alt="icono línea" height="28" width="10">
                                            <img class="icon-sidebar" :src="route_child.image" :alt=route_child.alt height="28" width="28">
                                        </template>
                                        <span class="nav-link">{{ route_child.name }}</span>
                                    </v-list-item>
                                </router-link>
                            </template>
                        </v-list>
                    </div>
                </template>
            </template>
        </template>
    </v-list>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {systemRoute} from "@/router/routes_home";
import {isUserUTE} from "@/utils/auth";
import {useRoute} from "vue-router";

export default {
    name: "Sidebar",
    props: {
        openCloseSidebar: Function,
        closeMobileMenu: Function,
        is_closed: Boolean,
        is_mobile: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const route = useRoute();
        let route_system = ref({})
        let is_user_ute = ref(true);
        let active_parent = ref()

        onBeforeMount(async () => {
            route_system.value = systemRoute()
            is_user_ute.value = isUserUTE();
        })

        watch(() => props.is_closed, (new_value) => {
            if (new_value) {
                active_parent.value = null
            }
        });


        const isActiveRoute = (routePath) => {
            return route.path.split('?')[0] === routePath.split('?')[0];
        };

        const openParent = (index) => {
            active_parent.value = active_parent.value === index ? null : index;

            if (active_parent.value && props.is_closed){
                props.openCloseSidebar()
            }
        };

        const isParentOpen = (index) => {
            return active_parent.value === index
        }

        const getIconSrc = (index) => {
            return active_parent.value === index
                ? require('@/assets/icons/arrow-top.svg')
                : require('@/assets/icons/arrow-bottom.svg');
        }

        $(document).ready(function () {
            $(".item-parent").on('click', function (e) {
                e.stopImmediatePropagation()
                let $parent = $(this);
                let $sibling = $parent.next();
                $sibling.toggleClass('show')
            })
        });

        const closeIfMobileMenu = (is_valid) => {
            if (props.is_mobile && is_valid){
                props.closeMobileMenu()
            }
        }

        const getRoute = (route) => {
            return route.active ? route.path : ''
        }

        return {
            getRoute,
            closeIfMobileMenu,
            isActiveRoute,
            openParent,
            getIconSrc,
            isParentOpen,

            route_system,
            is_user_ute,
            active_parent
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/components/sidebar.scss";
</style>