<template>
    <v-select v-if="!route.fullPath.includes('/bridge') &&  allowedPathWithLanguageSelector(this.$route.path)"
              :items="[]"
              placeholder="Instituciones educativas"
              item-title="nombre"
              item-value="id_portal"
              v-model="selected_institution"
              variant="plain"
              return-object
              class="portal-select select-navbar"
              no-data-text="No hay datos disponibles"
              :menu-props="select_menu_props"
              hide-details
              menu-icon="mdi-chevron-down"
              prepend-icon=""
    >
        <template v-slot:prepend-inner="">
            <v-img :src="require('@/assets/icons/navbar/icon-institutions.svg')" height="24" width="24"></v-img>
        </template>
        <template v-slot:selection="{ item }">
            <div class="portal">
                <v-img :src="getPortalLogo(item.raw.logo)" @error="handleImageError" class="portal-logo"></v-img>
                <span class="portal-name">{{ item.title }}</span>
            </div>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :prepend-avatar="getPortalLogo(item?.raw?.logo)"
                :title="item?.raw?.nombre"
            >
            </v-list-item>
        </template>
    </v-select>
    <v-select v-if="allowedPathWithLanguageSelector(this.$route.path)"
        :items="portals"
        item-title="nombre"
        item-value="id_portal"
        placeholder="Portales"
        v-model="portal_selected"
        variant="plain"
        return-object
        class="select-navbar portal-select"
        @update:modelValue="changePortalBySelect"
        no-data-text="No hay datos disponibles"
        :menu-props="select_menu_props"
        hide-details
        menu-icon="mdi-chevron-down"
    >
        <template v-slot:prepend-inner="" v-if="!variableIsDefined(portal_selected)">
            <v-img :src="require('@/assets/icons/navbar/icon-portal-select.svg')" height="24" width="24"></v-img>
        </template>
        <template v-slot:selection="{ item }">
            <div class="portal">
                <v-img :src="'https://cdn.mediavalet.com/eunl/euroinnova/' + item.raw.logo" @error="handleImageError(item)" class="portal-logo"></v-img>
                <span class="portal-name">{{ item.title }}</span>
            </div>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item
                v-bind="props"
                :title="item.raw.nombre"
                :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/' + item.raw.logo"
            >
            </v-list-item>
        </template>
    </v-select>


    <v-row class="nav-bar-selections" v-if="!route.fullPath.includes('/bridge') && store.getters.getShowPortalInstitution">
        <v-col cols="2" sm="1" md="2" class="p-0 text-end">
            <span class="text-navbar">Selección: </span>
        </v-col>
        <v-col cols="6" sm="4" md="6" class="d-flex pr-0">
            <template v-for="selected_element in [...selected_portals_and_institutions.slice(0,2)]">
                <v-chip
                    class="entity-chip"
                    :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/'+getSpecificPortal(selected_element).logo"
                >
                    {{`${getSpecificPortal(selected_element).nombre}`}}
                </v-chip>
            </template>
        </v-col>
        <v-col cols="1" md="1" class="p-0">
            <v-menu class="select-menu">
                <template v-slot:activator="{ props }">
                    <v-chip class="" color="blue" v-bind="props" v-if="selected_portals_and_institutions.length > 2">
                        {{`+${selected_portals_and_institutions.length - 2}`}}
                    </v-chip>
                </template>

                <v-list class="selected-entities-list">
                    <v-list-item
                        class="selected-entity-group"
                    >
                        <v-list-item-title>
                            <span class="dropdown-item-text">Instituciones educativas</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        class="selected-entity"
                        v-for="institution in store.getters.getSelectedInstitutions"
                        :key="institution"
                        :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/'+getSpecificPortal(institution).logo"
                    >
                        <v-list-item-title>
                            <span class="dropdown-item-text">{{getSpecificPortal(institution).nombre}}</span>
                        </v-list-item-title>
                    </v-list-item>

                    <!-- A PARTIR DE AQUÍ PORTALES -->
                    <v-list-item
                        class="selected-entity-group"
                    >
                        <v-list-item-title>
                            <span class="dropdown-item-text">Portales</span>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        class="selected-entity"
                        v-for="portal in store.getters.getSelectedPortals"
                        :key="portal"
                        :prepend-avatar="'https://cdn.mediavalet.com/eunl/euroinnova/'+getSpecificPortal(portal).logo"
                    >
                        <v-list-item-title>
                            <span class="dropdown-item-text">{{getSpecificPortal(portal).nombre}}</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        <v-col cols="2" class="p-0">
            <v-btn class="btn button-modify" @click="openCloseChangePortal">
                Modificar
            </v-btn>
        </v-col>
    </v-row>

    <v-dialog
        v-model="open_change_portals"
        :fullscreen="is_mobile"
    >
        <v-card class="change-portal-dialog">
            <v-card-title class="change-portal-dialog-header">
                <v-row class="h-100 m-0 align-center" v-if="is_mobile">
                    <v-col cols="12" class="d-flex justify-space-between">
                        <img src="@/assets/logos/logotipo-eduseo.ai-full-color.svg" alt="EDUSEO" width="181" height="32"
                             class="d-inline-block align-text-top"
                             title="Inicio"
                             id="logo-aiseo-menu">
                    </v-col>
                </v-row>
                <template v-else>
                    <span class="title-modal">Modificar selección de portales</span>
                    <v-btn class="btn-close-modal" @click="openCloseChangePortal" :ripple="false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-card-title>

            <v-card-text class="change-portal-dialog-body">
                <InstitutionPortalSelection
                    :closeDialog="openCloseChangePortal"
                    :is_dialog="true"
                >
                </InstitutionPortalSelection>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {computed, onBeforeMount, ref} from "vue";
import {allowedPathWithLanguageSelector} from "@/utils/config.js";
import {isEmptyArray, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {getSpecificPortal, storeEnterpriseData} from "@/utils/portal";
import InstitutionPortalSelection from "@/components/Home/InstitutionPortalSelection.vue";
import Sidebar from "@/components/Navigation/Sidebar.vue";
import axios from "axios";

export default {
    name: "LanguageSelector",
    components: {Sidebar, InstitutionPortalSelection},
    methods: {
        getSpecificPortal,
        isEmptyArray,
        variableIsDefined,
        allowedPathWithLanguageSelector,
    },
    props: {
        renderApp: Function,
        is_mobile: Boolean
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        let portals = computed(() => store.getters.getStoredPortals).value;
        let portal_selected = ref([]);
        let language_selected = ref([]);

        let portal_active_id = computed(() => store.getters.getPortalID);
        let language_active_iso = computed(() => store.getters.getLanguageISO);

        let selected_institution = ref([])

        let select_menu_props = ref({
            width: `${props.is_mobile ? '400px' : '500px'}`,
            contentClass: 'select-menu',
        })

        let selected_portals_and_institutions = computed(() => [...store.getters.getSelectedInstitutions, ...store.getters.getSelectedPortals])

        let open_change_portals = ref(false)

        let img_errors = ref({});

        onBeforeMount(async () => {
            await router.isReady()

            let portal_param = route.query.portal !== undefined ? route.query.portal : portal_active_id.value;
            let language_param = route.query.language !== undefined ? route.query.language : language_active_iso.value;
            await changePortal(portal_param, language_param, false)
        })

        const changePortalBySelect = async () => {
            if (existPortal(portal_selected.value.id_portal) && portal_selected.value.languages.length > 0){
                await changePortal(portal_selected.value.id_portal, portal_selected.value.languages[0].iso_6391, true)
            }else{
                await storeEnterpriseData(0, '')
                language_selected.value = [];
                showNotification('error', 'Ha ocurrido un error al cambiar de portal')
            }
        }

        const changePortal = async (portal_id, language_iso, force = true) => {
            let language_found = null;
            let portal_found = null;
            let search_index_portal = existPortal(portal_id)

            if (search_index_portal >= 0) {
                portal_found = portals[search_index_portal];
                portal_selected.value = portal_found
                language_selected.value = portal_found.languages

                let search_index_language_iso = portal_found.languages.findIndex((language) => language.iso_6391 === language_iso)
                if (search_index_language_iso >= 0) {
                    language_found = portal_found.languages[search_index_language_iso]
                    language_selected.value = language_found
                    await storeEnterpriseData(portal_found, language_found)
                    props.renderApp();

                    if (route.query.portal !== undefined && route.query.language !== undefined && force) {
                        let path = route.fullPath.replace('portal=' + route.query.portal, 'portal=' + portal_found.id_portal)
                        path = path.replace('language=' + route.query.language, 'language=' + language_found.iso_6391)
                        await router.push(path)
                    }
                } else {
                    await setLanguageByDefault(true);
                    props.renderApp();
                }
            } else {
                await setLanguageByDefault();
                props.renderApp();
            }
        }

        const setLanguageByDefault = async (with_message = false) => {
            if (with_message) {
                showNotification('error', 'No se ha establecido una configuración válida de idioma. Se pondrá Euroinnova EDU - Español')
            }
            if (route.query.portal !== undefined && route.query.language !== undefined) {
                let path = route.fullPath.replace('portal=' + route.query.portal, 'portal=540')
                path = path.replace('language=' + route.query.language, 'language=es')
                await router.push(path)
            }
            let search_index_portal = existPortal(540)
            portal_selected.value = portals[search_index_portal]
            let search_index_language_iso = portals[search_index_portal].languages.findIndex((language) => language.iso_6391 === 'es')
            language_selected.value = portals[search_index_portal].languages[search_index_language_iso];
            await storeEnterpriseData(portals[search_index_portal], portals[search_index_portal].languages[search_index_language_iso])
        }

        const existPortal = (portal_id) => {
            return portals.findIndex((portal) => portal.id_portal === parseInt(portal_id))
        }


        const openCloseChangePortal = () => {
            open_change_portals.value = !open_change_portals.value
        }



        const getPortalLogo = async (logo_name) => {
            /*if (await checkImageStatus('https://cdn.mediavalet.com/eunl/euroinnova/' + logo_name)) {
                return 'https://cdn.mediavalet.com/eunl/euroinnova/' + logo_name
            } else {
                return require('@/assets/icons/navbar/icon-portal-select.svg')
            }*/
        }

        function handleImageError(item_id) {
            console.log(item_id)
            //img_errors.value[item_id] = 1;
        }

        const checkImageStatus = async (url) => {
            try {
                const response = await axios.get(url, {validateStatus: false});
                if (response.status === 404) {
                    console.log('La imagen no existe o devuelve un 404');
                    return false;
                }
                console.log('La imagen existe');
                return true;
            } catch (error) {
                console.error('Error al verificar la imagen:', error);
                return false;
            }
        }

        return {
            handleImageError,
            getPortalLogo,
            store,
            route,
            portals,
            portal_selected,
            language_selected,
            selected_institution,
            select_menu_props,
            selected_portals_and_institutions,
            open_change_portals,
            img_errors,

            changePortal,
            changePortalBySelect,
            openCloseChangePortal,
        }
    }
}
</script>

<style lang="scss">

.nav-bar-selections {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;

    .text-navbar {
        font-size: 14px;
    }

    .entity-chip {
        .v-chip__underlay {
            background-color: transparent;
            border: 1px solid $image-border-gray;
            opacity: unset;
        }

        .v-chip__content {
            display: inline-block;
            font-size: 14px;
            max-width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .show-more-chip{
        .v-chip__underlay {
            background-color: $blue-light;
            opacity: unset;
        }

        .v-chip__content {
            color: $blue-medium;
        }
    }

    .button-modify {
        border: 1px solid $black;
        border-radius: 50px;
        padding: 4px 10px;
        height: auto !important;
        .v-btn__content {
            font-size: 14px;
        }

        .v-field__input {
            height: auto !important;
        }
    }
}

.selected-entities-list {
    .selected-entity-group .dropdown-item-text {
        font-size: 14px;
        font-weight: bold;
    }

    .selected-entity .dropdown-item-text {
        font-size: 14px;
    }
}

.change-portal-dialog{
    height: 90vh;
    .change-portal-dialog-header{
        height: 100px;
        background-color: $background;
    }
}



@media (min-width: 630px) {
    .nav-bar-selections {
        justify-content: flex-end;
    }
}

@media (min-width: 990px) {
    .change-portal-dialog{
        .change-portal-dialog-header{
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title-modal {
                word-wrap: break-word;
                white-space: normal;
                color: $black;
                font-size: 24px;
                margin-left: 20px;
            }

            .btn-close-modal {
                position: inherit;
                float: right;
                background: none;
                box-shadow: none;
                color: $gray-medium;
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 1020px) {
    .nav-bar-selections {
        justify-content: flex-start;

        .entity-chip {
            .v-chip__content {
                max-width: 150px;
                min-width: 70px;
            }
        }
    }
}

</style>