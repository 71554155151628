export function allowedPathWithLanguageSelector(path) {
    return [
        '/bridge/editor-curso',
        '/bridge/editor-seo',
        '/bridge/propuesta-keywords',
        '/bridge/listado-urls',
        '/bridge/listado-comentarios',
        '/bridge/cursos',
        '/bridge/redactores',
        '/bridge/docentes',
        '/bridge/redirecciones-generales',
        '/bridge/cursos-prioritarios',
        '/bridge/asignacion-logos-diplomas',
        '/bridge/gestion-logos-diplomas',
        '/bridge/cola-logos',
        '/bridge/cola-diplomas',
    ].includes(path)
}


