import store from "@/store";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification} from "@/utils/aux_functions";
import {cloneDeep} from "lodash";


export async function getDeparmentsWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'getDeparments', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getDeparments');
    }
}

export async function getAreasKnowledgeWS(id_department = null) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'departmentTID': id_department
    }

    try {
        return await aiseoBackendPetition('POST', 'getAreasKnowledge', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getAreasKnowledge');
    }
}

export async function getSchoolWS(id_area_knowledge = null) {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    let params = {
        'areaConocimientoTID': id_area_knowledge
    }

    try {
        return await aiseoBackendPetition('POST', 'getSchools', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getSchools');
    }
}


export async function getBasicCategorizationWS() {
    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    };

    try {
        return await aiseoBackendPetition('POST', 'getCategorization2', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getCategorization2');
    }

}

export function getAreasKnowledgeByDepartment(main_categorization, department_id) {
    let areas_knowledge = [];

    main_categorization.forEach(dep => {
        if (dep.id === department_id) {
            areas_knowledge = cloneDeep(dep.areas_conocimiento)
        }
    })
    areas_knowledge.unshift({id: 0, nombre: '--SIN FACULTAD--'})
    return areas_knowledge;
}

export function getSchoolByAreaKnowledge(main_categorization, area_knowledge_id) {
    let school = [];
    main_categorization.forEach(area => {
        if (area.id === area_knowledge_id) {
            school = cloneDeep(area.escuelas)
        }
    })
    school.unshift({id: 0, nombre: '--SIN ESCUELA--'})
    return school;
}
