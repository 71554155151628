<template>
    <v-row>
        <slot name="filters"></slot>
        <!--   FILTROS COMUNES -->
        <v-col cols="12" md="4" lg="4"
               class="align-self-start">
            <v-select v-model="filters.category_type"
                      class="select-secondary personalize-text-field field-with-label"
                      label="Tipo de categorías"
                      :items="categories_types"
                      item-title="type_name"
                      item-value="type_id"
                      bg-color="white"
                      @update:modelValue="searchCategories()"
                      :menu-props="{width: '250px'}"
                      hide-details>
            </v-select>
        </v-col>
        <v-col cols="12" md="8"
               :lg="type_order_col_template ? '8' : '5'"
               :xl="type_order_col_template ? '8' : '6'"
               class="align-self-start">
            <v-autocomplete
                :disabled="!type_selected"
                v-model="filters.category_id"
                class="input-error select-secondary personalize-text-field field-with-label"
                label="Categoría"
                :items="categories"
                item-title="nombre"
                item-value="id_categoria"
                :error="category_error"
                hide-details
                bg-color="transparent"
                :menu-props="{width: '250px'}"
            ></v-autocomplete>
            <span v-if="category_error" class="input-error msg-error">
                            Debe rellenar la categoría para aplicar los filtros
                        </span>
        </v-col>
    </v-row>
    <!--   BOTÓN DE FILTRAR Y DE LIMPIAR FILTROS   -->
    <v-row justify="end" no-gutters class="pt-2 sm:pt-0">
        <v-col cols="12" class="btns-col">
            <v-btn class="btn button-primary" @click="deleteCommonFilters" :loading="loading_filters">
                Limpiar
            </v-btn>
            <v-btn
                class="btn button-bg-blue btn-icon"
                @click="applyCommonFilters"
                :loading="loading_filters"
                prepend-icon="mdi-check"
            >
                Filtrar
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getAreasWS, getCategoriesWS, getSubcategoriesWS} from "@/utils/web_categorization";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "NewUrlFilter",
    props: {
        getApplyBaseFilters: Function,
        getDeleteFilter: Function,
        image_screen: Boolean,
        type_order_col_template: {
            type: Boolean,
            default: true,
        }
    },
    setup(props) {
        let loading_filters = ref(false)
        let filters = ref({})
        let categories_types = ref([
            {type_name: 'Áreas web', type_id: 1},
            {type_name: 'Categorías web', type_id: 2},
            {type_name: 'Subcategorías web', type_id: 3},
        ])
        let categories = ref()
        let type_selected = ref(false)
        let category_error = ref(false)

        onBeforeMount(async () => {
            if (props.image_screen) {
                categories_types.value.splice(0, 1)
            }
        })

        async function searchCategories() {
            let search_type = filters.value.category_type
            let response

            filters.value.category_id = null
            type_selected.value = false

            switch (search_type) {
                case 1:
                    response = await getAreasWS()
                    break;
                case 2:
                    response = await getCategoriesWS()
                    break;
                case 3:
                    response = await getSubcategoriesWS()
                    break;
            }

            if (response.result) {
                categories.value = response.data
                type_selected.value = true
            }
        }

        function checkCategoryFilter() {
            let correct = true
            category_error.value = false

            if (!variableIsDefined(filters.value.category_id)) {
                category_error.value = true
                correct = false
            }

            return correct
        }

        function applyCommonFilters() {
            /*CATEGORIA*/
            loading_filters.value = true
            if (variableIsDefined(filters.value.category_type)) {
                if (checkCategoryFilter()) {
                    filters.value.category = JSON.stringify({
                        type: filters.value.category_type,
                        id: filters.value.category_id
                    })
                }
            } else {
                filters.value.category = null
            }

            if ((variableIsDefined(filters.value.category_type) && variableIsDefined(filters.value.category))
                || (!variableIsDefined(filters.value.category_type) && !variableIsDefined(filters.value.category))) {
                props.getApplyBaseFilters(filters.value)
            }
            loading_filters.value = false

        }

        let deleteCommonFilters = () => {
            filters.value = {}
            category_error.value = false
            props.getDeleteFilter()
        }

        return {
            applyCommonFilters,
            deleteCommonFilters,
            searchCategories,

            loading_filters,
            filters,
            category_error,
            categories,
            categories_types,
            type_selected,
        }
    }
}
</script>

<style lang="scss">
    .btns-col {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
</style>